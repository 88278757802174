import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../store/exercisesStore';
import wizardStore from '../../../../../../features/LessonsKanban/components/LessonWizard/store/wizardStore';
import { ReactComponent as IconArrow } from '../../../../../../assets/svg/lessons-arrow.svg';
import { ExerciseWizard } from '../../../../../../features/LessonsKanban/components/LessonWizard/components';
import { WizardViews } from '../../../../../../features/LessonsKanban/components/LessonWizard/LessonWizard';
import { CloseButton, Modal } from '../../../../../../UI';
import {
    ExerciseMediaTypes,
    GrammarExerciseTypes,
} from '../../../../data/constants';
import cl from './GenerateExerciseModal.module.css';

const GenerateExerciseModal = ({ visible, setVisible, onAdd }) => {
    const { t } = useTranslation();
    const [view, step] = wizardStore.getCurrentViewAndStep();
    const { currentStepIndex, exerciseType, exerciseKind } = wizardStore;

    const renderTitle = () => {
        switch (step) {
            case 'source':
            case 'generate':
                return (
                    <p
                        className={cl.shiftedTitle}
                    >{`${t(`lesson_wizard.${exerciseType}_exercise`)} / ${t(`exercises.${exerciseKind}`)}: ${t(`lesson_wizard.exercise_${step}`)}`}</p>
                );
            case 'kind':
                return (
                    <p className={cl.shiftedTitle}>
                        {t(`lesson_wizard.${exerciseType}_${view}_kind_title`)}
                    </p>
                );
            case 'type':
                return (
                    <p className={cl.shiftedTitle}>
                        {t('lesson_wizard.exercise_select_params')}
                    </p>
                );
            case 'select':
                return (
                    <p className={cl.shiftedTitle}>
                        {t('lesson_wizard.exercise_type_title')}
                    </p>
                );
            default:
                return (
                    <p className={cl.title}>
                        {t(`lesson_wizard.${view}_${step}_title`)}
                    </p>
                );
        }
    };

    const handleBackPageArrow = () => {
        if (step === 'kind') {
            wizardStore.prevStep();
        }
        if (
            exerciseType === ExerciseMediaTypes.Grammar &&
            exerciseKind === GrammarExerciseTypes.StatementsTransformation &&
            step === 'generate'
        ) {
            wizardStore.prevStep();
        }
        wizardStore.prevStep();
    };

    const renderBackArrow = () => {
        if (currentStepIndex === 0) return null;
        return (
            <IconArrow className={cl.backArrow} onClick={handleBackPageArrow} />
        );
    };

    const handleExerciseCreate = (data) => {
        if (onAdd) {
            onAdd({ id: data.id, title: data.title });
            return;
        }
        exercisesStore.setChaptersModalType('');
        exercisesStore.setExercises([data, ...exercisesStore.exercises]);
        setTimeout(() => {
            exercisesStore.setCurrentExercise(data);
        }, 300);
    };

    useEffect(() => {
        if (visible) {
            wizardStore.setCurrentView(WizardViews.Exercise);
        } else {
            wizardStore.setCurrentView(WizardViews.CreateLesson);
            wizardStore.resetExerciseParams();
            wizardStore.resetWordPack();
        }
    }, [visible]);

    return (
        <Modal
            className={cl.createExercise}
            visible={visible}
            setVisible={setVisible}
        >
            {renderTitle()}
            {renderBackArrow()}
            <div className={cl.modalInner}>
                <ExerciseWizard onAdd={handleExerciseCreate} />
            </div>

            <CloseButton onClick={() => setVisible(false)} />
        </Modal>
    );
};

export default observer(GenerateExerciseModal);
