import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userDialoguesStore from '../../../UserPlatform/pages/UserDialogues/store/userDialogues';
import userStore from '../../../UserPlatform/store/userStore';
import { CloseButton } from '../../../../UI';
import { ReactComponent as IconStar } from '../../../../assets/svg/user-icon-star.svg';
import cl from './SituationDoneAlert.module.css';

const SituationDoneAlert = ({ isHistory }) => {
    const { t } = useTranslation();

    const handleCompleteClose = () => {
        userStore.setActiveChapter({});
        userDialoguesStore.setCurrentSituation({});
    };

    return (
        <div className={cl.doneAlertContainer}>
            <div className={cl.doneCard}>
                <div className={cl.doneCardInner}>
                    <IconStar className={cl.starIcon} />
                    <p>{t('user_view.dialogue_completed_message')}</p>
                    {!isHistory && (
                        <p className={cl.subtitle}>
                            {t('user_view.dialogue_completed_message_subtitle')}
                        </p>
                    )}
                    <CloseButton onClick={handleCompleteClose} />
                </div>
            </div>
        </div>
    );
};

export default observer(SituationDoneAlert);
