import { makeAutoObservable } from 'mobx';

class UserDialoguesStore {
    situations = [];
    filteredSituations = [];
    categories = [];

    searchString = '';
    categoryId = '';

    currentSituation = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setSituations(situations) {
        this.situations = situations;
    }

    setFilteredSituations(filteredSituations) {
        this.filteredSituations = filteredSituations;
    }

    setCategories(categories) {
        this.categories = categories;
    }

    setCategoryId(categoryId) {
        this.categoryId = categoryId;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setCurrentSituation(currentSituation) {
        this.currentSituation = currentSituation;
    }

    resetSearch() {
        this.setSituations([]);
    }
}
const userDialoguesStore = new UserDialoguesStore();
export default userDialoguesStore;
