import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../store/interface';
import userStore from '../../store/userStore';
import ProgressService from '../../../../api/ProgressService';
import { CreateWordsCategoryModal } from '../../../../pages/TeacherContent/views/DictionaryView/components';
import { Button } from '../../../../UI';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { WordPacksStatuses } from '../../../../data/common';

const AddWordPack = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const [createPackModal, setCreatePackModal] = useState(false);

    const handlePackAdd = (data) => {
        ProgressService.updateWordPackStatus({
            id: data.id,
            status: WordPacksStatuses.Active,
        });
        userStore.addFavWordPack({ ...data, words: [], wordsCount: 0 });
    };

    return (
        <>
            <Button
                text={t('exercises.add_words')}
                variant={Chapters.Dictionary}
                onClick={() => setCreatePackModal(true)}
            />
            <CreateWordsCategoryModal
                lang={lang}
                visible={createPackModal}
                setVisible={setCreatePackModal}
                onAdd={handlePackAdd}
            />
        </>
    );
};

export default observer(AddWordPack);
