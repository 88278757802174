import React from 'react';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './UserSummaryPreview.module.css';

const UserSummaryPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;

    const { selectedIndex } = results;
    const options = data?.options ?? [];

    const getOptionClassName = (o, i) => {
        const classNames = [cl.answer];
        classNames.push(
            i === selectedIndex
                ? isCompleted
                    ? o.correct
                        ? cl.correct
                        : cl.wrong
                    : cl.active
                : ''
        );
        return classNames.join(' ');
    };

    return (
        <>
            <QuestionsLabel label={'summary_user_info'} />
            <div className={cl.previewCont}>
                {options
                    .sort((a, b) => a.text.localeCompare(b.text))
                    .map((o, i) => (
                        <div
                            className={getOptionClassName(o, i)}
                            onClick={() => {
                                setResults({ selectedIndex: i });
                            }}
                            key={o.id}
                        >
                            {o.text}
                        </div>
                    ))}
            </div>
        </>
    );
};

export default UserSummaryPreview;
