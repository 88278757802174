import React, { useEffect, useRef, useState } from 'react';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import cl from './UserMultichoicePreview.module.css';

const UserMultichoicePreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const wordRef = useRef();
    const tooltipRef = useRef();
    const positionRef = useRef();

    const { sentences } = exerciseObj.data;

    const flatWords = sentences?.map((s) => s.words).flat() ?? [];
    const hidden = flatWords.filter((w) => w.options);

    const userAnswers =
        results.userAnswers ||
        hidden.reduce((acc, item) => {
            acc[item.id] = '';
            return acc;
        }, {});

    const words = flatWords.map((w) =>
        w?.options
            ? {
                  ...w,
                  options: w?.options?.sort((a, b) => a.localeCompare(b) ?? []),
              }
            : w
    );

    const [currentDropdown, setCurrentDropdown] = useState(null);
    const [displayAbove, setDisplayAbove] = useState(false);

    const renderWord = (w) => {
        if (!w?.options) return w.word;

        const classNames = [cl.dropdown];
        if (currentDropdown === w.id) classNames.push(cl.selected);
        if (userAnswers[w.id]) classNames.push(cl.active);
        if (isCompleted) {
            classNames.push(
                userAnswers[w.id] === w.word ? cl.correct : cl.wrong
            );
        }

        return (
            <span
                key={w.id}
                className={classNames.join(' ')}
                onClick={() => handleWordClick(w.id)}
                ref={currentDropdown === w.id ? tooltipRef : null}
            >
                <IconArrow className={cl.dropdownIcon} />
                {userAnswers[w.id]}
                <div
                    className={cl.dropdownTooltip}
                    ref={currentDropdown === w.id ? positionRef : null}
                >
                    <div className={cl.optionsCont}>
                        {w?.options
                            .filter((o) => o.length)
                            .map((o, i) => (
                                <span
                                    key={`${o}${i}`}
                                    onClick={() => handleOptionClick(w.id, o)}
                                >
                                    {o}
                                </span>
                            ))}
                    </div>
                </div>
            </span>
        );
    };

    const handleWordClick = (id) => {
        setCurrentDropdown(currentDropdown === id ? null : id);
    };

    const handleOptionClick = (wordId, option) => {
        setResults({ userAnswers: { ...userAnswers, [wordId]: option } });
    };

    const handleClickOutside = (e) => {
        if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
            setCurrentDropdown(null);
        }
    };

    const positionTooltip = () => {
        if (positionRef.current && wordRef.current) {
            const rect = wordRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const tooltipHeight = positionRef.current.offsetHeight || 200;

            setDisplayAbove(rect.bottom + tooltipHeight > windowHeight);

            positionRef.current.style.position = 'fixed';
            positionRef.current.style.top = displayAbove
                ? `${rect.top - tooltipHeight}px`
                : `${rect.bottom}px`;
            positionRef.current.style.left = `${rect.left}px`;
            positionRef.current.style.zIndex = 1000;
        }
    };

    useEffect(() => {
        positionTooltip();
    }, [currentDropdown, displayAbove]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tooltipRef]);

    return (
        words.length !== 0 && (
            <>
                <QuestionsLabel label={'multichoice_user_info'} />
                <div className={cl.exerciseText}>
                    {words.map((w) => (
                        <span
                            ref={currentDropdown === w.id ? wordRef : null}
                            className={cl.wordContainer}
                            key={w.id}
                        >
                            {renderWord(w)}
                            {w.endChar && <span>{w.endChar}</span>}
                        </span>
                    ))}
                </div>
            </>
        )
    );
};

export default UserMultichoicePreview;
