import React from 'react';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../store/exercisesStore';
import cl from './ContentContainer.module.css';

const ContentContainer = ({ className, children, view }) => {
    const { view: storeView } = exercisesStore;

    const getClassName = () => {
        const classNames = [cl.container];
        if (view && view !== storeView) classNames.push(cl.hidden);
        if (className) classNames.push(className);
        return classNames.join(' ');
    };

    return <div className={getClassName()}>{children}</div>;
};

export default observer(ContentContainer);
