import React from 'react';
import { observer } from 'mobx-react-lite';
import learnSituationStore from '../../store';
import { Button } from '../../../../UI';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import cl from './LearnSituationControls.module.css';

const LearnSituationControls = () => {
    const { statements, statementIndex } = learnSituationStore;

    return (
        <div className={cl.statementControls}>
            {statementIndex !== 0 && (
                <Button
                    variant={'white'}
                    icon={<IconArrow className={cl.arrowIconLeft} />}
                    className={cl.arrowButtonLeft}
                    onClick={learnSituationStore.prevStatement}
                />
            )}
            {statementIndex < statements.length - 1 && (
                <Button
                    variant={'white'}
                    icon={<IconArrow className={cl.arrowIconRight} />}
                    className={cl.arrowButtonRight}
                    onClick={learnSituationStore.nextStatement}
                />
            )}
        </div>
    );
};

export default observer(LearnSituationControls);
