import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../../UI';
import player from '../../../../store/player';
import languagesStore from '../../../../store/interface';
import TranslationService from '../../../../api/TranslationService';
import { ReactComponent as IconHide } from '../../../../assets/svg/hide.svg';
import { ReactComponent as IconVisible } from '../../../../assets/svg/visible.svg';
import cl from './TranslationDisplay.module.css';

const TranslationDisplay = ({ currentIndex }) => {
    const { lang, nativeLang } = languagesStore;
    const { blurTranslation, sentences } = player;
    const currentSentence = player.sentences[currentIndex] || {};

    const contRef = useRef();
    const textRef = useRef();

    useEffect(() => {
        if (!player.sentences || !currentSentence) return;
        translateText();
    }, [currentIndex, nativeLang, sentences]);

    const checkIsNeedTranslation = async (currentIndex) => {
        if (currentIndex == null || !currentSentence) return;

        const untranslatedSentences = player.sentences
            .slice(currentIndex, currentIndex + 5)
            .filter((s) => s?.text && !s.translations?.[nativeLang])
            .map((s) => s.text);

        if (untranslatedSentences.length > 0) {
            const translation = await translateAndApply(untranslatedSentences);
            return translation;
        }
    };

    const translateAndApply = async (sourceArr) => {
        if (sourceArr.length === 0) return;

        const { data } = await TranslationService.translateTextArray({
            sourceArr,
            sourceLang: lang,
            targetLang: nativeLang,
        });
        const { targetArr } = data;

        sourceArr.forEach((sourceText, idx) => {
            const translatedText = targetArr[idx];
            const index = player.sentences.findIndex(
                (el) => el.text === sourceText
            );

            if (
                index !== -1 &&
                !player.sentences[index]?.translations?.[nativeLang]
            ) {
                player.editTranslation(index, translatedText, nativeLang);
            }
        });

        return targetArr[0];
    };

    const translateText = async () => {
        if (currentSentence.translations?.[nativeLang]) {
            checkIsNeedTranslation(currentIndex + 1);
        } else {
            checkIsNeedTranslation(currentIndex);
        }
    };

    const isShowText = () => {
        if (currentSentence.words) {
            const { currentTime } = player;
            const lastWord = currentSentence.words.slice(-1)[0];
            const { offset, duration } = lastWord;
            if (currentTime >= offset + duration) return false;
        }
        return true;
    };

    const getTranslation = () => {
        if (!currentSentence.translations) return '';
        return (
            player.highlightTranslation ||
            currentSentence.translations[nativeLang]
        );
    };

    return (
        <div ref={contRef} className={cl.translationCont}>
            {player.translationOverlay && <div className={cl.overlay} />}
            <div
                style={{
                    opacity: isShowText() ? 1 : 0,
                }}
                ref={textRef}
                className={`${cl.translationText} ${blurTranslation ? cl.blur : ''}`}
                dangerouslySetInnerHTML={{
                    __html: getTranslation(),
                }}
            />
            <Button
                className={cl.eyeButton}
                onClick={() => player.setBlurTranslation(!blurTranslation)}
                icon={blurTranslation ? <IconVisible /> : <IconHide />}
            />
        </div>
    );
};

export default observer(TranslationDisplay);
