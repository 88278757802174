import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import PodcastService from '../../../../api/PodcastService';
import { useTranslation } from 'react-i18next';
import podcastsStore from '../../store/podcastsStore';
import { BlacklistPodcastCard, PodcastCard } from '..';
import { List } from '../../../../UI';

const PodcastList = ({ isLoading }) => {
    const { t } = useTranslation();

    const { blacklist, paginationEnd, podcasts, level } = podcastsStore;

    const scrollRef = useRef(0);

    const handleTrackListScroll = (e) => {
        if (paginationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > scrollRef.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                podcastsStore.setNextPage();
            }
        }
        scrollRef.current = list.scrollLeft;
    };

    const handleBlockPodcast = async (id) => {
        const res = await PodcastService.blacklistPodcast(id);
        if (res.status === 200) {
            podcastsStore.deletePodcastById(id);
        }
    };

    if (!podcasts.length && !isLoading) {
        return (
            <p style={{ textAlign: 'center', margin: 'auto' }}>
                {t('podcasts.empty_alert')}
            </p>
        );
    }

    return (
        <List onScroll={handleTrackListScroll} isLoading={isLoading}>
            {podcasts.map((p) =>
                blacklist ? (
                    <BlacklistPodcastCard
                        key={p.id}
                        podcastObj={p}
                        onUnblock={podcastsStore.deletePodcastById}
                    />
                ) : (
                    <PodcastCard
                        key={p.id}
                        podcastObj={p}
                        onBlock={handleBlockPodcast}
                        mode={'regular'}
                        setComments={podcastsStore.setComments}
                        currentLevel={level}
                    />
                )
            )}
        </List>
    );
};

export default observer(PodcastList);
