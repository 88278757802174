import React from 'react';
import { AdjustableInput, QuestionsLabel } from '..';
import cl from './UserClozePreview.module.css';

const UserClozePreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;

    const { sentences } = data;

    const flatWords =
        sentences
            ?.map((s, i) => {
                let hiddenIndex = 0;
                return s.words.map((w) =>
                    w.hidden
                        ? { ...w, sentenceIndex: i, hiddenIndex: hiddenIndex++ }
                        : w
                );
            })
            .flat() ?? [];

    const userAnswers =
        results.userAnswers ||
        sentences.map((s) =>
            Array.from(
                { length: s.words.filter((w) => w.hidden).length },
                () => null
            )
        );

    const renderWord = (w) => {
        if (!w.hidden || !userAnswers.length) return w.word;

        const sentence = userAnswers[w.sentenceIndex] || [];
        const answer = sentence[w.hiddenIndex] || '';

        if (isCompleted) {
            const isCorrect = answer === w.word;
            return (
                <p
                    className={`${cl.answer} ${isCorrect ? cl.correct : cl.wrong}`}
                >
                    {answer}
                </p>
            );
        }

        return (
            <AdjustableInput
                isPdf={false}
                isActive={!!answer.length}
                isCorrect={w.isCorrect}
                value={answer}
                onChange={(e) => handleKeyboardInput(w, e.target.value)}
                key={w.id}
            />
        );
    };

    const handleKeyboardInput = (wordObj, input) => {
        const { sentenceIndex, hiddenIndex } = wordObj;

        setResults({
            userAnswers: userAnswers.map((answersArray, sIndex) =>
                sIndex === sentenceIndex
                    ? answersArray.map((answer, aIndex) =>
                          aIndex === hiddenIndex ? input : answer
                      )
                    : answersArray
            ),
        });
    };

    return (
        <>
            <QuestionsLabel label={'cloze_user_info'} />
            {flatWords.length !== 0 && (
                <div className={cl.exerciseText}>
                    {flatWords.map((w) => (
                        <span className={cl.wordContainer} key={w.id}>
                            {renderWord(w)}
                            {w.endChar && <span>{w.endChar}</span>}
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

export default UserClozePreview;
