import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { EmptyChaptersAlert, Loader } from '../../../../UI';
import { ChapterCard, GenerateChaptersButtons } from '..';
import cl from './ChaptersList.module.css';

const ChaptersList = ({
    isLoading,
    chapters,
    type,
    current,
    setCurrent,
    onDelete,
    onSelect,
    isFilter,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cl.wrapper}>
            <div className={cl.chaptersContainer}>
                {isLoading ? (
                    <div className={cl.emptyAlert}>
                        <Loader style={{ opacity: 0.2 }} />
                    </div>
                ) : chapters.length ? (
                    <>
                        <div className={cl.list}>
                            {chapters.map((chapter) => (
                                <ChapterCard
                                    key={chapter.id}
                                    type={type}
                                    chapter={chapter}
                                    isActive={current?.id === chapter.id}
                                    setActive={setCurrent}
                                    onSelect={onSelect}
                                    onDelete={onDelete}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <EmptyChaptersAlert
                        title={t(
                            isFilter
                                ? `exercises.empty_search_${type}_title`
                                : `exercises.empty_fav_${type}_title`
                        )}
                        subtitle={t(
                            isFilter
                                ? `exercises.empty_search_${type}_subtitle`
                                : `exercises.empty_fav_${type}_subtitle`
                        )}
                    />
                )}
            </div>

            <GenerateChaptersButtons variant={type} view={type} />
        </div>
    );
};

export default observer(ChaptersList);
