import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import exercisesPlayer from '../../../../pages/TeacherContent/store/exercisesPlayer';
import exercisesStore from '../../../../pages/TeacherContent/store/exercisesStore';
import guestOnboarding from '../../../../pages/TeachersTutorial/store/guestOnboarding';
import languagesStore from '../../../../store/interface';
import InfoModal from '../../../../pages/TeachersTutorial/components/InfoModal/InfoModal';
import {
    ExercisePlayer,
    ExercisePlayerControls,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { Button, Loader } from '../../../../UI';
import MultichoicePreview from '../MultichoicePreview/MultichoicePreview';
import SubstitutionPreview from '../SubstitutionPreview/SubstitutionPreview';
import ClozePreview from '../ClozePreview/ClozePreview';
import QuizPreview from '../QuizPreview/QuizPreview';
import SentenceOrderPreview from '../SentenceOrderPreview/SentenceOrderPreview';
import WordsOrderPreview from '../WordsOrderPreview/WordsOrderPreview';
import SummaryPreview from '../SummaryPreview/SummaryPreview';
import AdvancedQuizPreview from '../AdvancedQuizPreview/AdvancedQuizPreview';
import FreeFormQuestionsPreview from '../FreeFormQuestionsPreview/FreeFormQuestionsPreview';
import { TicksInSecond } from '../../../../data/common';

import { Chapters } from '../../../LessonsKanban/data/constants';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../pages/TeacherContent/data/constants';
import { getAllExercisesByLang } from '../../../../pages/TeachersTutorial/data/exersises';
import { ReactComponent as IconVisible } from '../../../../assets/svg/visible.svg';
import { ReactComponent as IconHide } from '../../../../assets/svg/hide.svg';
import cl from './GuestExercisePreview.module.css';

const DemoExercise = observer(({ exerciseObj, isLoading }) => {
    const { t } = useTranslation();
    const { showAnswers } = exercisesStore;

    const containerRef = useRef();

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const isExerciseHaveAnswers = () => {
        return ![
            MediaExerciseTypes.FreeFormQuestions,
            GrammarExerciseTypes.StatementsTransformation,
            MediaExerciseTypes.Presentation,
        ].includes(exerciseObj.type);
    };

    const getMediaPreview = () => {
        if (exerciseObj.trackId && exerciseObj.trackInterval) {
            return (
                <div className={cl.videoContainer} key={exerciseObj.trackId}>
                    <ExercisePlayer isPreview={true} />
                    <ExercisePlayerControls mode={'video'} isPreview={true} />
                </div>
            );
        }
        if (exerciseObj.text) {
            return <div className={cl.exerciseText}>{exerciseObj.text}</div>;
        }

        return null;
    };

    const getPreview = () => {
        switch (exerciseObj.type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoicePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Summary:
                return (
                    <SummaryPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Presentation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        showInput={true}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showInput={true}
                    />
                );
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        showInput={true}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleShowAnswers = () => {
        exercisesStore.setShowAnswers(!showAnswers);
    };

    const renderButtons = () => {
        const flag = isExerciseHaveAnswers();

        return flag ? (
            <div className={cl.buttons}>
                <Button
                    text={t(
                        showAnswers ? 'buttons.reset' : 'exercises.show_answers'
                    )}
                    variant={showAnswers ? 'dark' : 'grey'}
                    icon={
                        showAnswers ? (
                            <IconHide className={cl.iconStrokeWhite} />
                        ) : (
                            <IconVisible className={cl.iconStroke} />
                        )
                    }
                    onClick={handleShowAnswers}
                />
            </div>
        ) : null;
    };

    useEffect(() => {
        if (showAnswers) {
            scrollToBottom();
        }
    }, [showAnswers]);

    if (isLoading) return <Loader style={{ margin: 'auto' }} />;

    return (
        <>
            <div className={cl.exerciseContainer} ref={containerRef}>
                <p className={cl.exerciseTitle}>{exerciseObj.title}</p>
                <p className={cl.exerciseDescription}>
                    {exerciseObj.description}
                </p>

                {getMediaPreview()}
                {getPreview()}
            </div>
            <div className={cl.previewButtons}>{renderButtons()}</div>
        </>
    );
});

const GuestExercisePreview = ({ exerciseId }) => {
    const { t } = useTranslation();
    const { interfaceLang } = languagesStore;
    const exercisesList = getAllExercisesByLang(interfaceLang);

    const [exerciseObj, setExerciseObj] = useState({});

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    useEffect(() => {
        const getData = async () => {
            const currentExercise = exercisesList.find(
                (eObj) => eObj.id === exerciseId
            ) ?? { title: 'exercise not found' };
            if (currentExercise?.trackId && currentExercise?.trackInterval) {
                await getTrack({
                    trackId: currentExercise.trackId,
                    trackInterval: currentExercise.trackInterval,
                });
            } else {
                exercisesPlayer.resetMediaParams();
            }
            setExerciseObj(currentExercise);
        };
        getData();
    }, [exerciseId, interfaceLang]);

    useEffect(() => {
        exercisesStore.setShowAnswers(false);
    }, [exerciseId]);

    return (
        <div className={cl.previewContainer}>
            <DemoExercise exerciseObj={exerciseObj} isLoading={trackLoading} />
            <InfoModal
                delay={200}
                onboardingKey={guestOnboarding.isOnboarding(Chapters.Exercise)}
                variant={Chapters.Exercise}
                title={t('teacher_tutorial.exercises_info')}
                subtitle={t('teacher_tutorial.exercises_info_subtitle')}
            />
        </div>
    );
};

export { DemoExercise };

export default observer(GuestExercisePreview);
