const devSituationChapters = [
    {
        id: 'f8db0d64-49f3-45b0-977b-f1095f80eacb',
        title: 'Navigating Airport Uncertainties: Can, Could, Might, Must',
        picture:
            '/s3/situation-images/671eb67b-fbb9-4d81-8c0a-717f0a7faec5.webp',
        categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
        categoryTitle: 'Transportation',
        level: ['B2'],
        info: {
            categoryId: 'fd4e7cb4-6bda-4792-a899-06c4feca7854',
            id: '491e85d5-366b-4e42-a9ae-0bb6e2565532',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
        },
    },
    {
        id: 'b24a9a30-c128-43bd-9049-3f091786faaf',
        title: 'Souvenir Shopping: A Lesson in British Slang',
        picture:
            '/s3/situation-images/c2a350eb-9c3e-41a8-8aa0-6f33687d0f28.webp',
        categoryId: '60845447-3c94-4f66-95ed-ff9df3911f6a',
        categoryTitle: 'Shopping',
        level: ['B2'],
        info: {
            categoryId: '60845447-3c94-4f66-95ed-ff9df3911f6a',
            id: 'aebb0343-d9ce-49f6-94a2-deb5214e7c54',
            processed: false,
            gender: 'Male',
            modality: 'Neutral',
            language: 'EN',
        },
    },
    {
        id: '31a57cf8-6d0b-4084-b4fa-5f020f748bba',
        title: "Where's My Luggage?",
        picture:
            '/s3/situation-images/334cc053-4aaf-42c3-ae31-e3030654a988.webp',
        categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
        categoryTitle: 'General Language',
        level: ['B1'],
        info: {
            categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
            id: '8f9d2428-7cdd-4bc9-ae13-bf75c0848ec6',
            processed: false,
            gender: 'Female',
            modality: 'Friendly',
            language: 'EN',
        },
    },
    {
        id: '59741d3d-95d9-4c9e-b8dd-af76da3d579f',
        title: "Royal Excursion: Conversing in the Queen's English",
        picture:
            '/s3/situation-images/9c24671a-9a6e-443a-af0f-f69eee990941.webp',
        categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
        categoryTitle: 'General Language',
        level: ['B2'],
        info: {
            categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
            id: '802b81c9-e306-4707-918f-0558ccee6278',
            processed: false,
            gender: 'Female',
            modality: 'Neutral',
            language: 'EN',
        },
    },
    {
        id: '3d8a85b4-8577-4fcd-a4c3-41a9a40dc194',
        title: 'Booking a hotel room',
        picture:
            '/s3/situation-images/7352f741-9008-4b19-9c72-80ee76eb4287.webp',
        categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
        categoryTitle: 'General Language',
        level: ['A2'],
        info: {
            categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
            id: '3e0a78c0-2119-4498-ace0-511d99b05acd',
            processed: false,
            gender: 'Female',
            modality: 'Neutral',
            language: 'EN',
        },
    },
    {
        id: '377b5572-f263-44f7-9d43-80e428fa4d5c',
        title: 'Boarding Pass: Ready for Takeoff',
        picture:
            '/s3/situation-images/efa00f9e-4e94-4519-a06a-8b49cc484c11.webp',
        categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
        categoryTitle: 'General Language',
        level: ['B1'],
        info: {
            categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
            id: '8ae3d15a-0324-4912-bdaa-afa4b42c59f4',
            processed: false,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
        },
    },
];

const prodSituationChapters = [
    {
        id: '90ce5e45-d8c6-4e60-9cf6-a98899e67736',
        title: 'Buying Over-the-Counter Medication',
        picture:
            '/s3/situation-images/31b888b6-3612-4c82-8508-ae90916b2a49.webp',
        categoryId: '60845447-3c94-4f66-95ed-ff9df3911f6a',
        categoryTitle: 'Shopping',
        info: {
            categoryId: '60845447-3c94-4f66-95ed-ff9df3911f6a',
            id: '71772ef2-bcfe-4c39-9746-977609ff56d8',
            processed: true,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B1', 'B2'],
        },
    },
    {
        id: '3d947edf-ff90-4d1c-b9fd-1077108dc139',
        title: 'Sponsoring Family Members',
        picture:
            '/s3/situation-images/84a06a38-11b4-4d0a-ab9d-eb51c990bdab.webp',
        categoryId: 'e970e615-4d01-4658-b1b4-83bae8ab8e6b',
        categoryTitle: 'Residence Permit',
        info: {
            categoryId: 'e970e615-4d01-4658-b1b4-83bae8ab8e6b',
            id: 'b6c140ae-caa3-4c02-a0d8-edbd25b27106',
            processed: false,
            gender: 'Male',
            modality: 'Rude',
            language: 'EN',
            level: ['B2', 'C1'],
        },
    },
    {
        id: '2051cb0c-6604-411c-af55-6208433a3fb4',
        title: 'Speaking to a Personal Trainer/Fitness Instructor',
        picture:
            '/s3/situation-images/a70cd70a-288a-45ac-ab0c-8d5013b9e044.webp',
        categoryId: '2f5dbc9c-efb9-43c6-bc85-787b7a588c84',
        categoryTitle: 'Healthcare',
        info: {
            categoryId: '2f5dbc9c-efb9-43c6-bc85-787b7a588c84',
            id: 'e84e92e7-ede2-436d-9eea-a3e21a96ac02',
            processed: true,
            gender: 'Male',
            modality: 'Neutral',
            language: 'EN',
            level: ['B1', 'B2'],
        },
    },
    {
        id: '4d8d1a77-7011-460d-99e7-abfa8d2d92da',
        title: 'Overcoming Discrimination in the Workplace',
        picture:
            '/s3/situation-images/3999d553-5606-4b79-98b6-c893e16fdef2.webp',
        categoryId: '945eeed3-41d1-4265-b18d-f477944ccea9',
        categoryTitle: 'Discrimination and Prejudice',
        info: {
            categoryId: '945eeed3-41d1-4265-b18d-f477944ccea9',
            id: '67442428-41f0-48cc-bdd8-d14a66324602',
            processed: true,
            gender: 'Male',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2', 'C1', 'C2'],
        },
    },
    {
        id: '421c55a4-06d9-447c-92cb-761b3f95eecc',
        title: 'Resources for Cultural or Religious Support Networks',
        picture:
            '/s3/situation-images/094496e7-68e0-4a94-a7b7-a0c0f7dd4cde.webp',
        categoryId: '89b0496e-88c1-4718-a565-983145178941',
        categoryTitle: 'Cultural and Religious Practices',
        info: {
            categoryId: '89b0496e-88c1-4718-a565-983145178941',
            id: '9765ccb2-b88f-4d62-914d-bb5e55eb16bf',
            processed: true,
            gender: 'Female',
            modality: 'Friendly',
            language: 'EN',
            level: ['B2'],
        },
    },
    {
        id: '9b3074fc-168d-4343-b275-738c9cc81000',
        title: 'Discussing Project Progress and Future Plans in the Workplace',
        picture:
            '/s3/situation-images/18a9627d-51ee-4ded-80fe-c9e5b7e25a7a.webp',
        categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
        categoryTitle: 'General Language',
        info: {
            categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
            id: 'b7038ca1-2e55-409c-bfb9-53f21da85e31',
            processed: false,
            gender: 'Female',
            modality: 'Friendly',
            language: 'EN',
            level: ['C1'],
        },
    },
];

export const getSituationsByEnv = (environment) =>
    environment === 'dev' ? devSituationChapters : prodSituationChapters;
