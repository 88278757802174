import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import wordsStore from '../../../../../../store/words';
import languagesStore from '../../../../../../store/interface';
import { Button, CloseButton, MicroButton, Loader } from '../../../../../../UI';
import {
    DictionaryServerErrors,
    mapDictionaryServerErrors,
} from '../../data/constants';
import { ReactComponent as IconSound } from '../../../../../../assets/svg/icon-sound.svg';
import cl from './WordCard.module.css';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';

const WordCard = ({ currentWord, onClose, className }) => {
    const { t } = useTranslation();

    const requestIdRef = useRef(0);

    const { lang } = languagesStore;

    const [wordObj, setWordObj] = useState({});
    const [error, setError] = useState('');
    const [audio] = useState(new Audio());

    const [partsOfSpeech, setPartsOfSpeech] = useState([]);

    const [getWord, isLoading] = useFetching(async () => {
        setError('');
        if (!currentWord) return;

        const requestId = ++requestIdRef.current;

        const response = await wordsStore.getCachedWordByText({
            word: currentWord.word,
            sourceLang: lang,
            targetLang: lang,
        });

        if (response && response.errorCode) {
            setError(mapDictionaryServerErrors[response.errorCode]);
            return;
        }

        if (requestId !== requestIdRef.current) return;

        const { data: wObj } = response;

        if (!wObj) {
            setError('generating');
            return;
        }

        setPartsOfSpeech(wObj?.data?.map((w) => w.partOfSpeech ?? ''));
        setWordObj({ ...wObj });
        if (!wObj.data) return;
        setPartsOfSpeech(wObj.data);
    });

    const handleAudioClick = (path) => {
        audio.pause();
        audio.src = path;
        setTimeout(() => {
            audio.play();
        });
    };

    const renderFormsBlock = ({ forms }) => {
        if (!forms || forms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.forms')}</p>
                <p className={cl.infoText}>{forms.join(', ')}</p>
            </div>
        );
    };

    const renderSynonymsBlock = ({ synonyms }) => {
        if (!synonyms || synonyms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.synonyms')}</p>
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            </div>
        );
    };

    const renderCardBody = () => {
        if (isLoading) return <Loader className={cl.wordLoader} />;
        if (error) {
            return (
                <div className={cl.alert}>
                    <p className={cl.infoText}>
                        {`${t(`dictionary_administration.${error}_word`)}`}
                    </p>

                    {error !== DictionaryServerErrors.Generating && (
                        <p className={cl.infoText}>
                            {t('dictionary_administration.delete_word')}
                        </p>
                    )}
                    <Button
                        variant={Chapters.Dictionary}
                        text={t('buttons.refresh')}
                        onClick={getWord}
                    />
                </div>
            );
        }

        return (
            <>
                {partsOfSpeech[0]?.pronunciation && (
                    <p
                        className={cl.wordText}
                    >{`${partsOfSpeech[0].pronunciation}`}</p>
                )}
                {partsOfSpeech.map((part) => (
                    <div className={cl.card} key={part.id}>
                        <p className={cl.partOfSpeech}>{part.partOfSpeech}</p>
                        {renderFormsBlock(part)}
                        {renderSynonymsBlock(part)}
                        {part.examples && part.examples.length !== 0 && (
                            <div className={cl.infoCont}>
                                <p className={cl.infoLabel}>
                                    {t('demo_page.phrases')}
                                </p>
                                {part.examples.map((ex) => (
                                    <p className={cl.exampleText} key={ex.id}>
                                        <MicroButton
                                            variant={'grey'}
                                            size={'small'}
                                            icon={
                                                <IconSound
                                                    className={cl.soundIcon}
                                                />
                                            }
                                            onClick={() =>
                                                handleAudioClick(ex.voicePath)
                                            }
                                        />

                                        {ex.example}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </>
        );
    };

    useEffect(() => {
        setWordObj({ ...currentWord });
        getWord();
        if (currentWord) {
            audio.pause();
            audio.src = currentWord.voicePath ?? '';
        } else {
            audio.src = '';
        }
    }, [currentWord]);

    if (!currentWord) return null;

    return (
        <div className={`${cl.wordCard} ${className ? className : ''}`}>
            <div className={cl.wordText}>
                {wordObj.language === 'de' && wordObj.article}
                {wordObj?.word && <span>{wordObj.word}</span>}
                {wordObj?.voicePath && (
                    <MicroButton
                        variant={'grey'}
                        size={'regular'}
                        icon={<IconSound className={cl.soundIcon} />}
                        onClick={() => handleAudioClick(wordObj.voicePath)}
                    />
                )}
            </div>
            {renderCardBody()}

            {onClose && <CloseButton onClick={onClose} />}
        </div>
    );
};

export default observer(WordCard);
