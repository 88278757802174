export const Chapters = {
    Track: 'track',
    Situation: 'situation',
    Exercise: 'exercise',
    Dictionary: 'words',
    File: 'file',
};

export const Views = {
    Lessons: 'lessons',
    Students: 'students',
    Teachers: 'teachers',
    Tutorial: 'tutorial',
    Library: 'library',
};

export const ChapterModals = {
    [Chapters.Track]: 'track_modal',
    [Chapters.Situation]: 'situation_modal',
    [Chapters.Exercise]: 'exercise_modal',
    [Chapters.Dictionary]: 'words_modal',
};

export const StudentLessonStatuses = {
    Available: 'available',
    Done: 'done',
    Unfinished: 'unfinished',
};

export const StudentExerciseStatuses = {
    Unfinished: 'unfinished',
    AwaitingEvaluation: 'awaitingEvaluation',
    Done: 'done',
};

export const SelectWordsCategoryModalType = 'words_category_modal';

export const LessonsGroupBy = {
    Difficulty: 'difficulty',
    Grammar: 'grammar',
    Vocabulary: 'vocabulary',
};

export const PartsOfSpeech = {
    Any: 'any',
    Noun: 'noun',
    Pronoun: 'pronoun',
    Verb: 'verb',
    Adjective: 'adjective',
    Adverb: 'adverb',
    Preposition: 'preposition',
    Conjunction: 'conjunction',
    Interjection: 'interjection',
};

export const SynthesisVoiceIds = [
    // 'NOpBlnGInO9m6vDvFkFC',
    // 'UgBBYS2sOqTuMpoF3BR0',
    // '56AoDkrOh6qfVPDXZ7Pt',
    // 'tnSpp4vdxKPjI9w0GnoV',
    // 'NFG5qt843uXKj4pFvR7C',
    // 'yl2ZDV1MzN4HbQJbMihG',
    // 'Fahco4VZzobUeiPqni1S',
    // 'xctasy8XvGp2cVO9HL9k',
    // 'P7x743VjyZEOihNNygQ9',
    // 'wJqPPQ618aTW29mptyoc',
    // '1hlpeD1ydbI2ow0Tt3EW',
    // 'Z3R5wn05IrDiVCyEkUrK',
    // '4u5cJuSmHP9d6YRolsOu',
    'TxGEqnHWrfWFTfGW9XjX',
    'EXAVITQu4vr4xnSDxMaL',
];
