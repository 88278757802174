import React from 'react';
import { observer } from 'mobx-react-lite';
import { Input, PageWrapper, Select, Toolbar, ViewTitle } from '../../../../UI';
import { useTranslation } from 'react-i18next';
import userExercisesStore from './store/userExercises';
import userStore from '../../store/userStore';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { UserChapterPreview, UserContentContainer } from '../../components';
import { ReactComponent as IconExercise } from '../../../../assets/svg/lessons-exercise.svg';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { ExerciseTypes } from '../../../../pages/ExercisesAdministration/data/constants';
import cl from './UserExercises.module.css';

const UserExercises = ({ isLoading }) => {
    const { t } = useTranslation();

    const { historyExercises } = userStore;

    const { currentExercise, type, searchString } = userExercisesStore;

    const typeOptions = ExerciseTypes.map((type) => ({
        value: type,
        name: t(`exercises.${type}`),
    }));

    const getFilteredExercises = () => {
        return historyExercises.filter((eObj) => {
            const matchesTitle = searchString
                ? eObj.title.toLowerCase().includes(searchString.toLowerCase())
                : true;
            const matchesType = type ? eObj.type === type : true;
            return matchesTitle && matchesType;
        });
    };

    return (
        <PageWrapper additionalClass={cl.exercisesWrapper}>
            <ViewTitle
                text={t('user_view.exercises_history')}
                icon={<IconExercise />}
            />
            <UserContentContainer>
                <Toolbar variant={Chapters.Exercise}>
                    <Input
                        className={cl.toolbarInput}
                        value={searchString}
                        onChange={userExercisesStore.setSearchString}
                        placeholder={t('user_view.search')}
                    />
                    <Select
                        className={cl.toolbarInput}
                        variant={'white'}
                        value={type}
                        options={typeOptions}
                        onChange={userExercisesStore.setType}
                        label={t('exercises.type')}
                    />
                </Toolbar>
                <ChaptersList
                    chapters={getFilteredExercises()}
                    type={Chapters.Exercise}
                    current={currentExercise}
                    setCurrent={userExercisesStore.setCurrentExercise}
                    isLoading={isLoading}
                />
                <UserChapterPreview
                    chapter={currentExercise}
                    type={Chapters.Exercise}
                />
            </UserContentContainer>
        </PageWrapper>
    );
};

export default observer(UserExercises);
