import React from 'react';
import { PageWrapper } from '../../UI';
import cl from './Policies.module.css';

const Policies = ({ type }) => {
    const renderPolicy = () => {
        switch (type) {
            case 'policy':
                return (
                    <>
                        <div className={cl.paragraph}>
                            <p className={cl.title}>PRIVACY POLICY</p>
                            <p>
                                Pony App built the Pony app as a Commercial app.
                                This SERVICE is provided by Pony App and is
                                intended for use as is.
                            </p>
                            <p>
                                This page is used to inform visitors regarding
                                our policies with the collection, use, and
                                disclosure of Personal Information if anyone
                                decided to use our Service.
                            </p>
                            <p>
                                If you choose to use our Service, then you agree
                                to the collection and use of information in
                                relation to this policy. The Personal
                                Information that we collect is used for
                                providing and improving the Service. We will not
                                use or share your information with anyone except
                                as described in this Privacy Policy.
                            </p>
                            <p>
                                The terms used in this Privacy Policy have the
                                same meanings as in our Terms and Conditions,
                                which are accessible at Pony unless otherwise
                                defined in this Privacy Policy.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Information Collection and Use</strong>
                            </p>
                            <p>
                                For a better experience, while using our
                                Service, we may require you to provide us with
                                certain personally identifiable information. The
                                information that we request will be retained by
                                us and used as described in this privacy policy.
                            </p>
                            <p>
                                The app does use third-party services that may
                                collect information used to identify you.
                            </p>
                            <p>
                                Link to the privacy policy of third-party
                                service providers used by the app:
                            </p>
                            <ul>
                                <li>
                                    <a href="https://www.google.com/policies/privacy/">
                                        - Google Play Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://firebase.google.com/support/privacy?hl=ru">
                                        - Google Analytics for firebase
                                    </a>
                                </li>
                                <li>
                                    <a href="https://onesignal.com/privacy_policy">
                                        - OneSignal
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/privacy/policy/">
                                        - Facebook
                                    </a>
                                </li>
                                <li>
                                    <a href="https://amplitude.com/privacy">
                                        - Amplitude
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Log Data</strong>
                            </p>
                            <p>
                                We want to inform you that whenever you use our
                                Service, in a case of an error in the app we
                                collect data and information (through
                                third-party products) on your phone called Log
                                Data. This Log Data may include information such
                                as your device Internet Protocol (“IP”) address,
                                device name, operating system version, the
                                configuration of the app when utilizing our
                                Service, the time and date of your use of the
                                Service, and other statistics.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Cookies</strong>
                            </p>
                            <p>
                                Cookies are files with a small amount of data
                                that are commonly used as anonymous unique
                                identifiers. These are sent to your browser from
                                the websites that you visit and are stored on
                                your device's internal memory.
                            </p>
                            <p>
                                This Service does not use these “cookies”
                                explicitly. However, the app may use third-party
                                code and libraries that use “cookies” to collect
                                information and improve their services. You have
                                the option to either accept or refuse these
                                cookies and know when a cookie is being sent to
                                your device. If you choose to refuse our
                                cookies, you may not be able to use some
                                portions of this Service.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Service Providers</strong>
                            </p>
                            <p>
                                We may employ third-party companies and
                                individuals due to the following reasons:
                            </p>
                            <ul>
                                <li> - To facilitate our Service;</li>
                                <li>
                                    {' '}
                                    - To provide the Service on our behalf;
                                </li>
                                <li>
                                    {' '}
                                    - To perform Service-related services; or
                                </li>
                                <li>
                                    {' '}
                                    - To assist us in analyzing how our Service
                                    is used.
                                </li>
                            </ul>
                            <p>
                                We want to inform users of this Service that
                                these third parties have access to their
                                Personal Information. The reason is to perform
                                the tasks assigned to them on our behalf.
                                However, they are obligated not to disclose or
                                use the information for any other purpose.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Security</strong>
                            </p>
                            <p>
                                We value your trust in providing us your
                                Personal Information, thus we are striving to
                                use commercially acceptable means of protecting
                                it. But remember that no method of transmission
                                over the internet, or method of electronic
                                storage is 100% secure and reliable, and we
                                cannot guarantee its absolute security.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Links to Other Sites</strong>
                            </p>
                            <p>
                                This Service may contain links to other sites.
                                If you click on a third-party link, you will be
                                directed to that site. Note that these external
                                sites are not operated by us. Therefore, we
                                strongly advise you to review the Privacy Policy
                                of these websites. We have no control over and
                                assume no responsibility for the content,
                                privacy policies, or practices of any
                                third-party sites or services.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Children’s Privacy</strong>
                            </p>
                            <p>
                                These Services do not address anyone under the
                                age of 13. We do not knowingly collect
                                personally identifiable information from
                                children under 13 years of age. In the case we
                                discover that a child under 13 has provided us
                                with personal information, we immediately delete
                                this from our servers. If you are a parent or
                                guardian and you are aware that your child has
                                provided us with personal information, please
                                contact us so that we will be able to do the
                                necessary actions.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Changes to This Privacy Policy</strong>
                            </p>
                            <p>
                                We may update our Privacy Policy from time to
                                time. Thus, you are advised to review this page
                                periodically for any changes. We will notify you
                                of any changes by posting the new Privacy Policy
                                on this page.
                            </p>
                            <p>This policy is effective as of 2023-10-20</p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>Contact Us</strong>
                            </p>
                            <p>
                                If you have any questions or suggestions about
                                our Privacy Policy, do not hesitate to contact
                                us at{' '}
                                <a href="mailto:kazarang.enterprise@gmail.com">
                                    kazarang.enterprise@gmail.com
                                </a>
                                .
                            </p>
                        </div>
                    </>
                );
            case 'terms':
                return (
                    <>
                        <div className={cl.paragraph}>
                            <p className={cl.title}>
                                END USER LICENCE AGREEMENT
                            </p>
                            <p className={cl.info}>
                                Last updated January 18, 2024
                            </p>
                            <p>
                                Pony is licensed to You (End-User) by Pony,
                                located and registered at Karl Heinrich Waggerl
                                - Gasse 1 , Pressbaum, Austria 3033, Austria
                                ('Licensor'), for use only under the terms of
                                this Licence Agreement. Our VAT number is
                                ATU78165405.
                            </p>
                            <p>
                                By downloading the Licensed Application from
                                Apple's software distribution platform ('App
                                Store'), and any update thereto (as permitted by
                                this Licence Agreement), You indicate that You
                                agree to be bound by all of the terms and
                                conditions of this Licence Agreement, and that
                                You accept this Licence Agreement. App Store is
                                referred to in this Licence Agreement as
                                'Services'.
                            </p>
                            <p>
                                The parties of this Licence Agreement
                                acknowledge that the Services are not a Party to
                                this Licence Agreement and are not bound by any
                                provisions or obligations with regard to the
                                Licensed Application, such as warranty,
                                liability, maintenance and support thereof.
                                Pony, not the Services, is solely responsible
                                for the Licensed Application and the content
                                thereof.
                            </p>
                            <p>
                                Pony when purchased or downloaded through the
                                Services, is licensed to You for use only under
                                the terms of this Licence Agreement. The
                                Licensor reserves all rights not expressly
                                granted to You. Pony is to be used on devices
                                that operate with Apple's operating systems
                                ('iOS' and 'Mac OS').
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>TABLE OF CONTENTS</strong>
                            </p>
                            <p>1. THE APPLICATION</p>
                            <p>2. SCOPE OF LICENCE</p>
                            <p>3. TECHNICAL REQUIREMENTS</p>
                            <p>4. MAINTENANCE AND SUPPORT</p>
                            <p>5. USE OF DATA</p>
                            <p>6. USER-GENERATED CONTRIBUTIONS</p>
                            <p>7. CONTRIBUTION LICENCE</p>
                            <p>8. LIABILITY</p>
                            <p>9. WARRANTY</p>
                            <p>10. PRODUCT CLAIMS</p>
                            <p>11. LEGAL COMPLIANCE</p>
                            <p>12. CONTACT INFORMATION</p>
                            <p>13. TERMINATION</p>
                            <p>
                                14. THIRD-PARTY TERMS OF AGREEMENTS AND
                                BENEFICIARY
                            </p>
                            <p>15. INTELLECTUAL PROPERTY RIGHTS</p>
                            <p>16. APPLICABLE LAW</p>
                            <p>17. MISCELLANEOUS</p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>1. THE APPLICATION</strong>
                            </p>
                            <p>
                                Pony ('Licensed Application') is a piece of
                                software created to help people to learn foreign
                                languages — and customised for iOS mobile
                                devices ('Devices'). It is used to learn
                                languages by watching videos.
                            </p>
                            <p>
                                The Licensed Application is not tailored to
                                comply with industry-specific regulations
                                (Health Insurance Portability and Accountability
                                Act (HIPAA), Federal Information Security
                                Management Act (FISMA), etc.), so if your
                                interactions would be subjected to such laws,
                                you may not use this Licensed Application. You
                                may not use the Licensed Application in a way
                                that would violate the Gramm-Leach-Bliley Act
                                (GLBA).
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>2. SCOPE OF LICENCE</strong>
                            </p>
                            <p>
                                2.1 You are given a non-transferable,
                                non-exclusive, non-sublicensable licence to
                                install and use the Licensed Application on any
                                Devices that You (End-User) own or control and
                                as permitted by the Usage Rules, with the
                                exception that such Licensed Application may be
                                accessed and used by other accounts associated
                                with You (End-User, The Purchaser) via Family
                                Sharing or volume purchasing.
                            </p>
                            <p>
                                2.2 This licence will also govern any updates of
                                the Licensed Application provided by Licensor
                                that replace, repair, and/or supplement the
                                first Licensed Application, unless a separate
                                licence is provided for such update, in which
                                case the terms of that new licence will govern.
                            </p>
                            <p>
                                2.3 You may not share or make the Licensed
                                Application available to third parties (unless
                                to the degree allowed by the Usage Rules, and
                                with Pony's prior written consent), sell, rent,
                                lend, lease or otherwise redistribute the
                                Licensed Application.
                            </p>
                            <p>
                                2.4 You may not reverse engineer, translate,
                                disassemble, integrate, decompile, remove,
                                modify, combine, create derivative works or
                                updates of, adapt, or attempt to derive the
                                source code of the Licensed Application, or any
                                part thereof (except with Pony's prior written
                                consent).
                            </p>
                            <p>
                                2.5 You may not copy (excluding when expressly
                                authorised by this licence and the Usage Rules)
                                or alter the Licensed Application or portions
                                thereof. You may create and store copies only on
                                devices that You own or control for backup
                                keeping under the terms of this licence, the
                                Usage Rules, and any other terms and conditions
                                that apply to the device or software used. You
                                may not remove any intellectual property
                                notices. You acknowledge that no unauthorised
                                third parties may gain access to these copies at
                                any time. If you sell your Devices to a third
                                party, you must remove the Licensed Application
                                from the Devices before doing so.
                            </p>
                            <p>
                                2.6 Violations of the obligations mentioned
                                above, as well as the attempt of such
                                infringement, may be subject to prosecution and
                                damages.
                            </p>
                            <p>
                                2.7 Licensor reserves the right to modify the
                                terms and conditions of licensing.
                            </p>
                            <p>
                                2.8 Nothing in this licence should be
                                interpreted to restrict third-party terms. When
                                using the Licensed Application, You must ensure
                                that You comply with applicable third-party
                                terms and conditions.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>3. TECHNICAL REQUIREMENTS</strong>
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>4. MAINTENANCE AND SUPPORT</strong>
                            </p>
                            <p>
                                4.1 The Licensor is solely responsible for
                                providing any maintenance and support services
                                for this Licensed Application. You can reach the
                                Licensor at the email address listed in the App
                                Store Overview for this Licensed Application.
                            </p>
                            <p>
                                4.2 Pony and the End-User acknowledge that the
                                Services have no obligation whatsoever to
                                furnish any maintenance and support services
                                with respect to the Licensed Application.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>5. USE OF DATA</strong>
                            </p>
                            <p>
                                You acknowledge that Licensor will be able to
                                access and adjust Your downloaded Licensed
                                Application content and Your personal
                                information, and that Licensor's use of such
                                material and information is subject to Your
                                legal agreements with Licensor and Licensor's
                                privacy policy, which can be found at the bottom
                                of the Licensed Application.
                            </p>
                            <p>
                                You acknowledge that the Licensor may
                                periodically collect and use technical data and
                                related information about your device, system,
                                and application software, and peripherals, offer
                                product support, facilitate the software
                                updates, and for purposes of providing other
                                services to you (if any) related to the Licensed
                                Application. Licensor may also use this
                                information to improve its products or to
                                provide services or technologies to you, as long
                                as it is in a form that does not personally
                                identify you.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>6. USER-GENERATED CONTRIBUTIONS</strong>
                            </p>
                            <p>
                                The Licensed Application may invite you to chat,
                                contribute to, or participate in blogs, message
                                boards, online forums, and other functionality,
                                and may provide you with the opportunity to
                                create, submit, post, display, transmit,
                                perform, publish, distribute, or broadcast
                                content and materials to us or in the Licensed
                                Application, including but not limited to text,
                                writings, video, audio, photographs, graphics,
                                comments, suggestions, or personal information
                                or other material (collectively,
                                'Contributions'). Contributions may be viewable
                                by other users of the Licensed Application and
                                through third-party websites or applications. As
                                such, any Contributions you transmit may be
                                treated as non-confidential and non-proprietary.
                                When you create or make available any
                                Contributions, you thereby represent and warrant
                                that:
                            </p>
                            <p>
                                1. The creation, distribution, transmission,
                                public display, or performance, and the
                                accessing, downloading, or copying of your
                                Contributions do not and will not infringe the
                                proprietary rights, including but not limited to
                                the copyright, patent, trademark, trade secret,
                                or moral rights of any third party.
                            </p>
                            <p>
                                2. You are the creator and owner of or have the
                                necessary licences, rights, consents, releases,
                                and permissions to use and to authorise us, the
                                Licensed Application, and other users of the
                                Licensed Application to use your Contributions
                                in any manner contemplated by the Licensed
                                Application and this Licence Agreement.
                            </p>
                            <p>
                                3. You have the written consent, release, and/or
                                permission of each and every identifiable
                                individual person in your Contributions to use
                                the name or likeness or each and every such
                                identifiable individual person to enable
                                inclusion and use of your Contributions in any
                                manner contemplated by the Licensed Application
                                and this Licence Agreement.
                            </p>
                            <p>
                                4. Your Contributions are not false, inaccurate,
                                or misleading.
                            </p>
                            <p>
                                5. Your Contributions are not unsolicited or
                                unauthorised advertising, promotional materials,
                                pyramid schemes, chain letters, spam, mass
                                mailings, or other forms of solicitation.
                            </p>
                            <p>
                                6. Your Contributions are not obscene, lewd,
                                lascivious, filthy, violent, harassing,
                                libellous, slanderous, or otherwise
                                objectionable (as determined by us).
                            </p>
                            <p>
                                7. Your Contributions do not ridicule, mock,
                                disparage, intimidate, or abuse anyone.
                            </p>
                            <p>
                                8. Your Contributions are not used to harass or
                                threaten (in the legal sense of those terms) any
                                other person and to promote violence against a
                                specific person or class of people.
                            </p>
                            <p>
                                9. Your Contributions do not violate any
                                applicable law, regulation, or rule.
                            </p>
                            <p>
                                10. Your Contributions do not violate the
                                privacy or publicity rights of any third party.
                            </p>
                            <p>
                                11. Your Contributions do not violate any
                                applicable law concerning child pornography, or
                                otherwise intended to protect the health or
                                well-being of minors.
                            </p>
                            <p>
                                12. Your Contributions do not include any
                                offensive comments that are connected to race,
                                national origin, gender, sexual preference, or
                                physical handicap.
                            </p>
                            <p>
                                13. Your Contributions do not otherwise violate,
                                or link to material that violates, any provision
                                of this Licence Agreement, or any applicable law
                                or regulation.
                            </p>
                            <p>
                                Any use of the Licensed Application in violation
                                of the foregoing violates this Licence Agreement
                                and may result in, among other things,
                                termination or suspension of your rights to use
                                the Licensed Application.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>7. CONTRIBUTION LICENCE</strong>
                            </p>
                            <p>
                                By posting your Contributions to any part of the
                                Licensed Application or making Contributions
                                accessible to the Licensed Application by
                                linking your account from the Licensed
                                Application to any of your social networking
                                accounts, you automatically grant, and you
                                represent and warrant that you have the right to
                                grant, to us an unrestricted, unlimited,
                                irrevocable, perpetual, non-exclusive,
                                transferable, royalty-free, fully-paid,
                                worldwide right, and licence to host, use copy,
                                reproduce, disclose, sell, resell, publish,
                                broad cast, retitle, archive, store, cache,
                                publicly display, reformat, translate, transmit,
                                excerpt (in whole or in part), and distribute
                                such Contributions (including, without
                                limitation, your image and voice) for any
                                purpose, commercial advertising, or otherwise,
                                and to prepare derivative works of, or
                                incorporate in other works, such as
                                Contributions, and grant and authorise
                                sublicences of the foregoing. The use and
                                distribution may occur in any media formats and
                                through any media channels.
                            </p>
                            <p>
                                This licence will apply to any form, media, or
                                technology now known or hereafter developed, and
                                includes our use of your name, company name, and
                                franchise name, as applicable, and any of the
                                trademarks, service marks, trade names, logos,
                                and personal and commercial images you provide.
                                You waive all moral rights in your
                                Contributions, and you warrant that moral rights
                                have not otherwise been asserted in your
                                Contributions.
                            </p>
                            <p>
                                We do not assert any ownership over your
                                Contributions. You retain full ownership of all
                                of your Contributions and any intellectual
                                property rights or other proprietary rights
                                associated with your Contributions. We are not
                                liable for any statements or representations in
                                your Contributions provided by you in any area
                                in the Licensed Application. You are solely
                                responsible for your Contributions to the
                                Licensed Application and you expressly agree to
                                exonerate us from any and all responsibility and
                                to refrain from any legal action against us
                                regarding your Contributions.
                            </p>
                            <p>
                                We have the right, in our sole and absolute
                                discretion, (1) to edit, redact, or otherwise
                                change any Contributions; (2) to recategorise
                                any Contributions to place them in more
                                appropriate locations in the Licensed
                                Application; and (3) to prescreen or delete any
                                Contributions at any time and for any reason,
                                without notice. We have no obligation to monitor
                                your Contributions.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>8. LIABILITY</strong>
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>9. WARRANTY</strong>
                            </p>
                            <p>
                                9.1 Licensor warrants that the Licensed
                                Application is free of spyware, trojan horses,
                                viruses, or any other malware at the time of
                                Your download. Licensor warrants that the
                                Licensed Application works as described in the
                                user documentation.
                            </p>
                            <p>
                                9.2 No warranty is provided for the Licensed
                                Application that is not executable on the
                                device, that has been unauthorisedly modified,
                                handled inappropriately or culpably, combined or
                                installed with inappropriate hardware or
                                software, used with inappropriate accessories,
                                regardless if by Yourself or by third parties,
                                or if there are any other reasons outside of
                                Pony's sphere of influence that affect the
                                executability of the Licensed Application.
                            </p>
                            <p>
                                9.3 You are required to inspect the Licensed
                                Application immediately after installing it and
                                notify Pony about issues discovered without
                                delay by email provided in Contact Information.
                                The defect report will be taken into
                                consideration and further investigated if it has
                                been emailed within a period of ninety (90) days
                                after discovery.
                            </p>
                            <p>
                                9.4 If we confirm that the Licensed Application
                                is defective, Pony reserves a choice to remedy
                                the situation either by means of solving the
                                defect or substitute delivery.
                            </p>
                            <p>
                                9.5 In the event of any failure of the Licensed
                                Application to conform to any applicable
                                warranty, You may notify the Services Store
                                Operator, and Your Licensed Application purchase
                                price will be refunded to You. To the maximum
                                extent permitted by applicable law, the Services
                                Store Operator will have no other warranty
                                obligation whatsoever with respect to the
                                Licensed Application, and any other losses,
                                claims, damages, liabilities, expenses, and
                                costs attributable to any negligence to adhere
                                to any warranty.
                            </p>
                            <p>
                                9.6 If the user is an entrepreneur, any claim
                                based on faults expires after a statutory period
                                of limitation amounting to twelve (12) months
                                after the Licensed Application was made
                                available to the user. The statutory periods of
                                limitation given by law apply for users who are
                                consumers.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>10. PRODUCT CLAIMS</strong>
                            </p>
                            <p>
                                Pony and the End-User acknowledge that Pony, and
                                not the Services, is responsible for addressing
                                any claims of the End-User or any third party
                                relating to the Licensed Application or the
                                End-User’s possession and/or use of that
                                Licensed Application, including, but not limited
                                to:
                            </p>
                            <p>(i) product liability claims;</p>
                            <p>
                                (ii) any claim that the Licensed Application
                                fails to conform to any applicable legal or
                                regulatory requirement; and
                            </p>
                            <p>
                                (iii) claims arising under consumer protection,
                                privacy, or similar legislation, including in
                                connection with Your Licensed Application’s use
                                of the HealthKit and HomeKit.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>11. LEGAL COMPLIANCE</strong>
                            </p>
                            <p>
                                You represent and warrant that You are not
                                located in a country that is subject to a US
                                Government embargo, or that has been designated
                                by the US Government as a 'terrorist supporting'
                                country; and that You are not listed on any US
                                Government list of prohibited or restricted
                                parties.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>12. CONTACT INFORMATION</strong>
                            </p>
                            <p>
                                For general inquiries, complaints, questions or
                                claims concerning the Licensed Application,
                                please contact:
                            </p>
                            <p>Oleg Gribkov</p>
                            <p>Karl Heinrich Waggerl - Gasse 1</p>
                            <p>Pressbaum, Austria 3033</p>
                            <p>Austria</p>
                            <p>oleg.gribkov@gmail.com</p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>13. TERMINATION</strong>
                            </p>
                            <p>
                                The licence is valid until terminated by Pony or
                                by You. Your rights under this licence will
                                terminate automatically and without notice from
                                Pony if You fail to adhere to any term(s) of
                                this licence. Upon Licence termination, You
                                shall stop all use of the Licensed Application,
                                and destroy all copies, full or partial, of the
                                Licensed Application.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>
                                    14. THIRD-PARTY TERMS OF AGREEMENTS AND
                                    BENEFICIARY
                                </strong>
                            </p>
                            <p>
                                Pony represents and warrants that Pony will
                                comply with applicable third-party terms of
                                agreement when using Licensed Application.
                            </p>
                            <p>
                                In Accordance with Section 9 of the
                                'Instructions for Minimum Terms of Developer's
                                End-User Licence Agreement', Apple's
                                subsidiaries shall be third-party beneficiaries
                                of this End User Licence Agreement and — upon
                                Your acceptance of the terms and conditions of
                                this Licence Agreement, Apple will have the
                                right (and will be deemed to have accepted the
                                right) to enforce this End User Licence
                                Agreement against You as a third-party
                                beneficiary thereof.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>
                                    15. INTELLECTUAL PROPERTY RIGHTS
                                </strong>
                            </p>
                            <p>
                                Pony and the End-User acknowledge that, in the
                                event of any third-party claim that the Licensed
                                Application or the End-User's possession and use
                                of that Licensed Application infringes on the
                                third party's intellectual property rights,
                                Pony, and not the Services, will be solely
                                responsible for the investigation, defence,
                                settlement, and discharge or any such
                                intellectual property infringement claims.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>16. APPLICABLE LAW</strong>
                            </p>
                            <p>
                                This Licence Agreement is governed by the laws
                                of Austria excluding its conflicts of law rules.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p>
                                <strong>17. MISCELLANEOUS</strong>
                            </p>
                            <p>
                                17.1 If any of the terms of this agreement
                                should be or become invalid, the validity of the
                                remaining provisions shall not be affected.
                                Invalid terms will be replaced by valid ones
                                formulated in a way that will achieve the
                                primary purpose.
                            </p>
                            <p>
                                17.2 Collateral agreements, changes and
                                amendments are only valid if laid down in
                                writing. The preceding clause can only be waived
                                in writing.
                            </p>
                        </div>
                        <div className={cl.paragraph}>
                            <p className={cl.title}>TERMS & CONDITIONS</p>
                            <p>
                                By downloading or using the app, these terms
                                will automatically apply to you – you should
                                make sure therefore that you read them carefully
                                before using the app. You’re not allowed to copy
                                or modify the app, any part of the app, or our
                                trademarks in any way. You’re not allowed to
                                attempt to extract the source code of the app,
                                and you also shouldn’t try to translate the app
                                into other languages or make derivative
                                versions. The app itself, and all the
                                trademarks, copyright, database rights, and
                                other intellectual property rights related to
                                it, still belong to Pony App.
                            </p>
                            <p>
                                Pony App is committed to ensuring that the app
                                is as useful and efficient as possible. For that
                                reason, we reserve the right to make changes to
                                the app or to charge for its services, at any
                                time and for any reason. We will never charge
                                you for the app or its services without making
                                it very clear to you exactly what you’re paying
                                for.
                            </p>
                            <p>
                                The Pony app stores and processes personal data
                                that you have provided to us, to provide our
                                Service. It’s your responsibility to keep your
                                phone and access to the app secure. We therefore
                                recommend that you do not jailbreak or root your
                                phone, which is the process of removing software
                                restrictions and limitations imposed by the
                                official operating system of your device. It
                                could make your phone vulnerable to
                                malware/viruses/malicious programs, compromise
                                your phone’s security features and it could mean
                                that the Pony app won’t work properly or at all.
                            </p>
                            <p>
                                The app does use third-party services that
                                declare their Terms and Conditions.
                            </p>
                            <p>
                                Link to Terms and Conditions of third-party
                                service providers used by the app
                            </p>
                            <ul>
                                <li>
                                    <a href="https://www.google.com/policies/privacy/">
                                        - Google Play Services
                                    </a>
                                </li>
                                <li>
                                    <a href="https://firebase.google.com/support/privacy?hl=ru">
                                        - Google Analytics for firebase
                                    </a>
                                </li>
                                <li>
                                    <a href="https://onesignal.com/privacy_policy">
                                        - OneSignal
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/privacy/policy/">
                                        - Facebook
                                    </a>
                                </li>
                            </ul>
                            <p>
                                You should be aware that there are certain
                                things that Pony App will not take
                                responsibility for. Certain functions of the app
                                will require the app to have an active internet
                                connection. The connection can be Wi-Fi or
                                provided by your mobile network provider, but
                                Pony App cannot take responsibility for the app
                                not working at full functionality if you don’t
                                have access to Wi-Fi, and you don’t have any of
                                your data allowance left.
                            </p>
                            <p>
                                If you’re using the app outside of an area with
                                Wi-Fi, you should remember that the terms of the
                                agreement with your mobile network provider will
                                still apply. As a result, you may be charged by
                                your mobile provider for the cost of data for
                                the duration of the connection while accessing
                                the app, or other third-party charges. In using
                                the app, you’re accepting responsibility for any
                                such charges, including roaming data charges if
                                you use the app outside of your home territory
                                (i.e. region or country) without turning off
                                data roaming. If you are not the bill payer for
                                the device on which you’re using the app, please
                                be aware that we assume that you have received
                                permission from the bill payer for using the
                                app.
                            </p>
                            <p>
                                Along the same lines, Pony App cannot always
                                take responsibility for the way you use the app
                                i.e. You need to make sure that your device
                                stays charged – if it runs out of battery and
                                you can’t turn it on to avail the Service, Pony
                                App cannot accept responsibility.
                            </p>
                            <p>
                                With respect to Pony App’s responsibility for
                                your use of the app, when you’re using the app,
                                it’s important to bear in mind that although we
                                endeavor to ensure that it is updated and
                                correct at all times, we do rely on third
                                parties to provide information to us so that we
                                can make it available to you. Pony App accepts
                                no liability for any loss, direct or indirect,
                                you experience as a result of relying wholly on
                                this functionality of the app.
                            </p>
                            <p>
                                At some point, we may wish to update the app.
                                The app is currently available on Android & iOS
                                – the requirements for the both systems(and for
                                any additional systems we decide to extend the
                                availability of the app to) may change, and
                                you’ll need to download the updates if you want
                                to keep using the app. Pony App does not promise
                                that it will always update the app so that it is
                                relevant to you and/or works with the Android &
                                iOS version that you have installed on your
                                device. However, you promise to always accept
                                updates to the application when offered to you,
                                We may also wish to stop providing the app, and
                                may terminate use of it at any time without
                                giving notice of termination to you. Unless we
                                tell you otherwise, upon any termination, (a)
                                the rights and licenses granted to you in these
                                terms will end; (b) you must stop using the app,
                                and (if needed) delete it from your device.
                            </p>
                            <p>
                                <strong>
                                    Changes to This Terms and Conditions
                                </strong>
                            </p>
                            <p>
                                We may update our Terms and Conditions from time
                                to time. Thus, you are advised to review this
                                page periodically for any changes. We will
                                notify you of any changes by posting the new
                                Terms and Conditions on this page.
                            </p>
                            <p>
                                These terms and conditions are effective as of
                                2023-10-20
                            </p>
                            <p>
                                <strong>Contact Us</strong>
                            </p>

                            <p>
                                If you have any questions or suggestions about
                                our Terms and Conditions, do not hesitate to
                                contact us at{' '}
                                <a href="mailto:kazarang.enterprise@gmail.com">
                                    kazarang.enterprise@gmail.com
                                </a>
                                .
                            </p>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    };
    return (
        <PageWrapper>
            <div className={cl.policyCont}>{renderPolicy()}</div>
        </PageWrapper>
    );
};

export default Policies;
