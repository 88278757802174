import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../App';
import guestOnboarding from './store/guestOnboarding';
import { FirstSection, Footer } from './components';
import LessonsExample from './components/LessonsExample/LessonsExample';
import InteractionExample from './components/InteractionExample/InteractionExample';
import CostSection from './components/CostSection/CostSection';
import AuthModal from '../../components/AuthModal/AuthModal';
import ProfitSection from './components/ProfitSection/ProfitSection';
import ChapterExamples from './components/ChapterExamples/ChapterExamples';
import BubblesParalax from './components/BubblesParalax/BubblesParalax';
import cl from './TeachersTutorial.module.css';

const TeachersTutorial = () => {
    const { isAuth } = authStore;

    useEffect(() => {
        const handleResize = () => {
            guestOnboarding.setCurrentItem(null);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`${cl.teacherLanding} ${isAuth ? cl.blocked : ''}`}>
            <BubblesParalax />
            <FirstSection />
            <ChapterExamples />
            <LessonsExample />
            <InteractionExample />
            <ProfitSection />
            <CostSection />
            <Footer />
            <AuthModal />
        </div>
    );
};

export default observer(TeachersTutorial);
