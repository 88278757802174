import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import CategoryService from '../../../../api/CategoryService';
import exercisesStore from '../../store/exercisesStore';
import languagesStore from '../../../../store/interface';
import {
    Checkbox,
    Input,
    Select,
    Separator,
    ToggleButton,
    Toolbar,
} from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import {
    ExerciseMediaTypes,
    getExerciseTypesByMedia,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../data/constants';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';

const ContentSearchBar = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const {
        unassigned,
        difficulty,
        type,
        mediaType,
        view,
        existingCategoryOptions,
        existingSituationCategoryOptions,
    } = exercisesStore;

    const exercisesMediaTypeOptions = [
        ...Object.values(ExerciseMediaTypes),
    ].map((mediaType) => ({
        value: mediaType,
        name: t(`lesson_wizard.${mediaType}_exercise`),
    }));

    const [exercisesTypeOptions, setExercisesTypeOptions] = useState([]);

    const [getTrackCategories] = useFetching(async () => {
        const { data } = await CategoryService.getCategories({
            lang,
        });
        exercisesStore.setCategoryOptions(
            data.map((c) => ({ value: c.id, name: c.translation || c.title }))
        );
    });

    const getVocabularySearchString = () => {
        switch (view) {
            case Chapters.Track: {
                return exercisesStore.trackVocabularySearch;
            }
            case Chapters.Situation: {
                return exercisesStore.situationVocabularySearch;
            }
            default:
                return '';
        }
    };

    const getSearchString = () => {
        switch (view) {
            case Chapters.Track: {
                return exercisesStore.trackSearch;
            }
            case Chapters.Situation: {
                return exercisesStore.situationSearch;
            }
            case Chapters.Exercise: {
                return exercisesStore.exerciseSearch;
            }
            case Chapters.Dictionary: {
                return exercisesStore.dictionarySearch;
            }
            default:
                return '';
        }
    };

    useEffect(() => {
        getTrackCategories();
    }, [lang]);

    useEffect(() => {
        exercisesStore.setDefaultSearchBarParams();
    }, [view]);

    useEffect(() => {
        if (!mediaType) {
            setExercisesTypeOptions(
                [
                    ...Object.values(GrammarExerciseTypes),
                    ...Object.values(MediaExerciseTypes),
                ].map((type) => ({ value: type, name: t(`exercises.${type}`) }))
            );
        } else {
            const availableTypes = getExerciseTypesByMedia(mediaType);
            setExercisesTypeOptions(
                Object.values(availableTypes).map((type) => ({
                    value: type,
                    name: t(`exercises.${type}`),
                }))
            );
        }
    }, [mediaType]);

    return (
        <Toolbar>
            <ToggleButton
                nullable
                options={LanguageLevels}
                value={difficulty}
                onChange={exercisesStore.setDifficulty}
                variant={'round'}
            />
            {view === Chapters.Exercise && (
                <>
                    <Select
                        label={t('exercises.section')}
                        style={{ minWidth: 220 }}
                        variant={'white'}
                        value={mediaType}
                        onChange={exercisesStore.setMediaType}
                        options={exercisesMediaTypeOptions}
                    />
                    <Select
                        label={t('exercises.type')}
                        style={{ minWidth: 220 }}
                        variant={'white'}
                        value={type}
                        onChange={exercisesStore.setType}
                        options={exercisesTypeOptions}
                    />
                </>
            )}
            {view === Chapters.Track && (
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={exercisesStore.trackCategory}
                    onChange={exercisesStore.setTrackCategory}
                    options={existingCategoryOptions}
                />
            )}
            {view === Chapters.Situation && (
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={exercisesStore.situationCategory}
                    onChange={exercisesStore.setSituationCategory}
                    options={existingSituationCategoryOptions}
                />
            )}
            <Checkbox
                label={t('exercises.not_used_chapter')}
                value={unassigned}
                onChange={exercisesStore.setUnassigned}
            />
            <Separator />
            {[Chapters.Situation, Chapters.Track].includes(view) && (
                <Input
                    search
                    placeholder={t('exercises.search_by_content')}
                    value={getVocabularySearchString()}
                    onChange={(val) =>
                        exercisesStore.setVocabularySearchString(val, view)
                    }
                    showReset
                />
            )}
            <Input
                search
                placeholder={t('exercises.search_by_name')}
                value={getSearchString()}
                onChange={(val) => exercisesStore.setSearchString(val, view)}
                showReset
            />
        </Toolbar>
    );
};

export default observer(ContentSearchBar);
