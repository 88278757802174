import React from 'react';
import { t } from 'i18next';
import { Button } from '../../../../UI';
import { ReactComponent as iconPDF } from '../../../../assets/svg/icon-pdf.svg';
import { ReactComponent as iconHyperlinks } from '../../../../assets/svg/icon-hyperlinks.svg';
import { ReactComponent as iconPony } from '../../../../assets/svg/icon-pony.svg';
import { BaseUrl } from '../../../../data/common';
import cl from './InteractionCard.module.css';

const getInteractionIcon = (type) => {
    switch (type) {
        case 'pdf':
            return iconPDF;
        case 'hyperlinks':
            return iconHyperlinks;
        case 'pony':
            return iconPony;
        default:
            return '';
    }
};

const getInteractionTitle = (type) => {
    switch (type) {
        case 'pdf':
            return t('teacher_tutorial.title_pdf');
        case 'hyperlinks':
            return t('teacher_tutorial.title_hyperlinks');
        case 'pony':
            return t('teacher_tutorial.title_pony');
        default:
            return '';
    }
};

const getInteractionDescription = (type) => {
    switch (type) {
        case 'pdf':
            return t('teacher_tutorial.description_pdf');
        case 'hyperlinks':
            return t('teacher_tutorial.description_hyperlinks');
        case 'pony':
            return t('teacher_tutorial.description_pony');
        default:
            return '';
    }
};

const getStyle = (type) => {
    switch (type) {
        case 'pdf':
            return cl.pdf;
        case 'hyperlinks':
            return cl.hyperlinks;
        case 'pony':
            return cl.pony;
        default:
            return '';
    }
};

const InteractionCard = ({ type }) => {
    const InteractionIcon = getInteractionIcon(type);
    const style = getStyle(type);

    const handleOpenDemo = async () => {
        const exerciseId = BaseUrl.includes('dev')
            ? 'fb21e29c-1b53-4fb3-84ff-32b240f821f8'
            : 'fb437fd9-469c-43d1-a932-ecf5e9607114';
        if (type === 'pdf') {
            window.open(
                `${window.location.origin}/hyperlink/${exerciseId}?mode=pdf`,
                '_blank'
            );
        } else {
            window.open(
                `${window.location.origin}/hyperlink/${exerciseId}?mode=preview`,
                '_blank'
            );
        }
    };

    return (
        <div className={`${cl.card} ${style} ${cl[type]}`}>
            <div className={cl.cardImg}>
                <InteractionIcon />
            </div>
            <p className={cl.title}>{getInteractionTitle(type)}</p>
            <p className={cl.description}>{getInteractionDescription(type)}</p>
            {type !== 'pony' && (
                <Button
                    className={cl.button}
                    variant={type === 'pdf' ? 'bordo' : 'navy'}
                    text={t('teacher_tutorial.work_with_student_button')}
                    onClick={handleOpenDemo}
                />
            )}
        </div>
    );
};

export default InteractionCard;
