import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../../../App';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import {
    Button,
    CloseButton,
    Input,
    Label,
    ToggleButton,
} from '../../../../../../UI';
import { LanguageLevels, Roles } from '../../../../../../data/common';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import { GenerateChapterModal } from '../../../../components';
import cl from './CreateWordsCategoryModal.module.css';

const CreateWordsCategoryModal = ({ visible, setVisible, lang, onAdd }) => {
    const { t } = useTranslation();
    const { user } = authStore;
    const isUser = user.role === Roles.User;

    const [title, setTitle] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [createCategory, createLoading] = useFetching(async () => {
        const { data } = await ProgressService.createWordsCategory({
            title,
            lang,
            level: isUser ? user.level : LanguageLevels.indexOf(difficulty),
        });
        if (data) {
            setVisible(false);
            setTitle('');
            onAdd(data);
        }
    });

    const resetErrors = () => {
        setFormErrors({});
    };

    const handleWordsGenerate = () => {
        resetErrors();

        setTimeout(() => {
            const errors = {
                title: !title,
                difficulty: !isUser && !difficulty,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            createCategory();
        });
    };

    useEffect(() => {
        setTitle('');
        setDifficulty('');
        setFormErrors({});
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [title, difficulty]);

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('exercises.create_words_category')}
            variant={Chapters.Dictionary}
            className={`${cl.modal} ${isUser ? cl.user : ''}`}
        >
            <div>
                <Label
                    text={t('exercises.words_category_title')}
                    isError={formErrors['title']}
                />
                <Input
                    variant={
                        formErrors['title'] ? 'erroredSmall' : 'outlinedSmall'
                    }
                    placeholder={t(
                        'exercises.words_category_title_placeholder'
                    )}
                    value={title}
                    onChange={setTitle}
                />
            </div>
            {!isUser && (
                <div>
                    <Label
                        text={t('situations.level')}
                        isError={formErrors['difficulty']}
                    />
                    <ToggleButton
                        style={{ width: 212 }}
                        label={t('users.level')}
                        options={LanguageLevels}
                        value={difficulty}
                        variant={formErrors['level'] ? 'error' : 'outlined'}
                        onChange={setDifficulty}
                    />
                </div>
            )}
            <Button
                className={cl.bottomButton}
                variant={Chapters.Dictionary}
                text={t('buttons.create')}
                onClick={handleWordsGenerate}
                isLoading={createLoading}
            />
            <CloseButton onClick={() => setVisible(false)} />
        </GenerateChapterModal>
    );
};

export default observer(CreateWordsCategoryModal);
