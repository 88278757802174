import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../store/exercisesStore';
import { Button } from '../../../../../../UI';
import { MediaTypes } from '../../../../data/constants';
import { EditMedia } from '..';
import { SelectVideoModal } from '../../../../components';
import { ReactComponent as IconCloud } from '../../../../../../assets/svg/lessons-upload.svg';
import cl from './SelectMedia.module.css';

const SelectMedia = ({ mediaType }) => {
    const { t } = useTranslation();

    const { currentTrack } = exercisesStore;
    const [visible, setVisible] = useState(true);

    const handleReset = () => {
        exercisesStore.setCurrentTrack(null);
        setVisible(true);
    };

    if (mediaType === MediaTypes.GeneratedText) return <></>;

    return (
        <>
            {currentTrack ? (
                <EditMedia mediaType={mediaType} onReset={handleReset} />
            ) : (
                <div className={cl.addButtonContainer}>
                    <Button
                        style={{ gap: 12 }}
                        icon={<IconCloud style={{ minWidth: 16 }} />}
                        text={t('exercises.media_type')}
                        variant={'grey'}
                        onClick={() => setVisible(true)}
                    />
                </div>
            )}

            <SelectVideoModal
                visible={visible}
                setVisible={setVisible}
                onClick={exercisesStore.setCurrentTrack}
                withCategorySelector={true}
            />
        </>
    );
};

export default observer(SelectMedia);
