import React from 'react';
import { CloseButton, Modal } from '../../../../UI';
import cl from './GenerateChapterModal.module.css';

const GenerateChapterModal = ({
    visible,
    setVisible,
    title,
    subtitle,
    variant,
    children,
    className,
    hideClose = false,
}) => {
    return (
        <Modal
            className={`${cl.generateModal} ${className ? className : ''}`}
            visible={visible}
            setVisible={setVisible}
        >
            {title && <p className={`${cl.title} ${cl[variant]}`}>{title}</p>}
            {subtitle && <p className={cl.subtitle}>{subtitle}</p>}

            <div className={cl.modalInner}>{children}</div>

            {!hideClose && <CloseButton onClick={() => setVisible(false)} />}
        </Modal>
    );
};

export default GenerateChapterModal;
