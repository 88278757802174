import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import exercisesPlayer from '../../../../store/exercisesPlayer';
import exercisesStore from '../../../../store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import TrackService from '../../../../../../api/TrackService';
import { ExercisePlayer, ExercisePlayerControls } from '..';
import { Loader } from '../../../../../../UI';
import { LanguageLevels, TicksInSecond } from '../../../../../../data/common';
import {
    MaximumMediaCharactersAmount,
    MediaTypes,
} from '../../../../data/constants';
import cl from './EditMedia.module.css';

const EditMedia = ({ mediaType }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { currentTrack, generatedText, trackText } = exercisesStore;

    const [trackData, setTrackData] = useState({});

    const getMax = (sentences) => {
        let totalLength = 0;
        let max = exercisesPlayer.trackLength;

        for (const t of sentences) {
            const segmentLength = t.text.length;

            if (totalLength + segmentLength > MaximumMediaCharactersAmount) {
                break;
            }

            totalLength += segmentLength;
            max = t.offset;
        }
        return max / TicksInSecond;
    };

    const [getTrack, trackLoading] = useFetching(async () => {
        const { data } = await TrackService.getTrackSentences({
            id: currentTrack.id,
            lang,
        });
        const maxDuration = getMax(data.sentences);

        exercisesPlayer.setTrackData({ ...data, duration: maxDuration });
        setTrackData({ ...data, duration: maxDuration });
        exercisesStore.setTrackText(
            data?.sentences.map((s) => s.text).join(' ') ?? ''
        );
    });

    const getCurrentCharactersAmount = () => {
        const text =
            mediaType === MediaTypes.GeneratedText ? generatedText : trackText;
        if (!text) return <>{'0'}</>;
        const amount = text.split('').length;
        const className =
            amount > MaximumMediaCharactersAmount ? cl.limit : cl.ok;
        return <span className={className}>{amount}</span>;
    };

    useEffect(() => {
        if (currentTrack && trackText) return;
        getTrack();
    }, [currentTrack]);

    if (trackLoading) return <Loader />;

    return (
        <>
            <div className={cl.mediaEditor} key={currentTrack}>
                <div className={cl.trackInfo}>
                    <p className={cl.trackTitle}>{trackData.title}</p>
                    <p className={cl.trackLevel}>
                        {LanguageLevels[trackData.difficulty]}
                    </p>
                </div>
                <div className={cl.container}>
                    <ExercisePlayer isPreview={false} />
                    <p className={cl.textContainer}>{trackText}</p>
                </div>
                <p className={cl.infoText}>
                    {`${t('exercises.limit_info')} ${t('exercises.limit_current')}: `}
                    {getCurrentCharactersAmount()}
                </p>
                <div className={cl.controlsContainer}>
                    <ExercisePlayerControls mode={'video'} isPreview={false} />
                </div>
            </div>
        </>
    );
};

export default observer(EditMedia);
