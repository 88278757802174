import { Roles } from '../data/common';
import {
    AddTrack,
    DictionaryAdministration,
    DictionaryShare,
    ExercisesAdministration,
    FollowTeacher,
    Languages,
    Library,
    HealthCheck,
    Player,
    Podcasts,
    Policies,
    Profile,
    SituationsAdministration,
    SituationShare,
    TeachersView,
    Users,
} from '../pages';
import UserView from '../features/UserPlatform/UserView';
import { Chapters, Views } from '../features/LessonsKanban/data/constants';
import { ExerciseHyperlink } from '../pages/TeacherContent/views/ExercisesView/components';
import { OnboardingKeys } from '../features/Onboarding';

export const ROUTES = {
    home: '/',
    languages: '/languages_list',
    podcasts: '/podcasts_library',
    users: '/users_list',
    add_track: '/add_track',
    profile: '/profile',
    library: '/tracks_library',
    listen: '/listen',
    dictionary: '/dictionary_administration',
    situations: '/situations_administration',

    share_situation: '/v1/share-situation',
    share_dictionary: '/v1/share-dictionary',

    follow_teacher: '/follow-teacher',
    healthcheck: '/healthcheck',
    privacy: '/privacy-policy',
    register: '/register',
    terms: '/terms-and-conditions',
    login: '/login',
    exercises: '/exercises_administration',
    default: '/',
    lessonsLibrary: '/lessons_library',
    students: '/students',
    lessons: '/lessons',
    teacherTracks: '/my_videos',
    teacherDictionary: '/my_words',
    teacherSituations: '/my_dialogues',
    teacherExercises: '/my_exercises',

    userToDo: 'todo',
    userVideos: '/videos',
    userDialogues: '/dialogues',
    userWords: '/words',
    userLibrary: '/library',
    userVideosHistory: '/videos_history',
    userDialoguesHistory: '/dialogues_history',
    userWordsHistory: '/words_history',
    userExercisesHistory: '/exercises_history',

    hyperlinkDemo: '/hyperlink/:id',
};

export const ONBOARDING_ROUTES = [
    {
        route: ROUTES.students,
        components: [
            OnboardingKeys.Confirmation,
            OnboardingKeys.StudentsInfo,
            OnboardingKeys.AddStudent,
            OnboardingKeys.NewStudent,
            OnboardingKeys.AvailableColumn,
            OnboardingKeys.LessonsNavigation,
        ],
    },
    {
        route: ROUTES.lessons,
        components: [
            OnboardingKeys.LessonsInfo,
            OnboardingKeys.AddTheme,
            OnboardingKeys.AwaitThemeAdd,
            OnboardingKeys.AddLesson,
            OnboardingKeys.AwaitLessonAdd,
            OnboardingKeys.SelectLesson,
            OnboardingKeys.LessonAddChapter,
            OnboardingKeys.ExercisesContentSelect,
            OnboardingKeys.AddChapterInfo,
            OnboardingKeys.OpenPonyLibrary,
            OnboardingKeys.ChapterCard,
            OnboardingKeys.AwaitAddChapter,
            OnboardingKeys.ChapterAdded,
            OnboardingKeys.AwaitLessonModalClose,
            OnboardingKeys.StudentsKanban,
            OnboardingKeys.AvailableLessons,
            OnboardingKeys.DragLessonInfo,
            OnboardingKeys.AwaitLessonDrag,
            OnboardingKeys.ShowDoneColumn,
            OnboardingKeys.StudentsDone,
            OnboardingKeys.ContentNavigation,
            OnboardingKeys.TeachersNavigation,
            OnboardingKeys.OnboardingDone,
        ],
    },
];

const ADMIN_ROUTES = [
    {
        path: ROUTES.languages,
        component: <Languages />,
        exact: true,
        title: 'Languages | Pony',
    },
    {
        path: ROUTES.podcasts,
        component: <Podcasts />,
        exact: true,
        title: 'Podcasts | Pony',
    },
    {
        path: ROUTES.users,
        component: <Users />,
        exact: true,
        title: 'Users | Pony',
    },
    {
        path: ROUTES.add_track,
        component: <AddTrack />,
        exact: true,
        title: 'Add Podcast | Pony',
    },
    {
        path: ROUTES.profile,
        component: <Profile />,
        exact: true,
        title: 'Profile | Pony',
    },
    {
        path: ROUTES.library,
        component: <Library />,
        exact: true,
        title: 'Library | Pony',
    },
    {
        path: ROUTES.listen,
        component: <Player />,
        exact: true,
        title: 'Player | Pony',
    },
    {
        path: ROUTES.dictionary,
        component: <DictionaryAdministration />,
        title: 'Dictionary | Pony',
        exact: true,
    },
    {
        path: ROUTES.situations,
        component: <SituationsAdministration />,
        title: 'Situations | Pony',
        exact: true,
    },
    {
        path: ROUTES.healthcheck,
        component: <HealthCheck />,
        exact: true,
        title: 'Healthcheck | Pony',
    },
    {
        path: ROUTES.exercises,
        component: <ExercisesAdministration />,
        exact: true,
        title: 'Exercises | Pony',
    },
    {
        path: ROUTES.share_situation,
        component: <SituationShare />,
        replace: true,
    },
    {
        path: ROUTES.share_dictionary,
        component: <DictionaryShare />,
        replace: true,
    },
];

const USER_ROUTES = [
    {
        path: ROUTES.userDialogues,
        component: <UserView view="dialogues" />,
        exact: true,
        title: 'Dialogues | Pony',
    },
    {
        path: ROUTES.userVideos,
        component: <UserView view="videos" />,

        exact: true,
        title: 'Videos | Pony',
    },
    {
        path: ROUTES.userToDo,
        component: <UserView view="todo" />,

        exact: true,
        title: 'To Do | Pony',
    },
    {
        path: ROUTES.userDialoguesHistory,
        component: <UserView view="dialogues_history" />,
        exact: true,
        title: 'Dialogues History | Pony',
    },
    {
        path: ROUTES.userExercisesHistory,
        component: <UserView view="exercises_history" />,
        exact: true,
        title: 'Exercises History | Pony',
    },
    {
        path: ROUTES.userVideosHistory,
        component: <UserView view="videos_history" />,
        exact: true,
        title: 'Videos History | Pony',
    },
    {
        path: ROUTES.userWordsHistory,
        component: <UserView view="words_history" />,
        exact: true,
        title: 'Words History | Pony',
    },
    {
        path: ROUTES.userLibrary,
        component: <UserView view="library" />,

        exact: true,
        title: 'Library | Pony',
    },
    {
        path: ROUTES.userWords,
        component: <UserView view="words" />,

        exact: true,
        title: 'Words | Pony',
    },
    {
        path: ROUTES.profile,
        component: <Profile />,
        exact: true,
        title: 'Profile | Pony',
    },
    {
        path: ROUTES.follow_teacher,
        component: <FollowTeacher />,
        replace: true,
    },
];

const PUBLIC_ROUTES = [
    {
        path: ROUTES.privacy,
        component: <Policies type={'policy'} />,
        title: 'Privacy Policy | Pony',
        exact: true,
    },
    {
        path: ROUTES.terms,
        component: <Policies type={'terms'} />,
        title: 'End User Licence Agreement | Pony',
        exact: true,
    },

    {
        path: ROUTES.share_situation,
        component: <SituationShare />,
        replace: true,
    },
    {
        path: ROUTES.share_dictionary,
        component: <DictionaryShare />,
        replace: true,
    },
    {
        path: ROUTES.follow_teacher,
        component: <FollowTeacher />,
        replace: true,
    },
    {
        path: ROUTES.hyperlinkDemo,
        component: <ExerciseHyperlink />,
        replace: true,
    },
    {
        path: ROUTES.default,
        component: <TeachersView type={Views.Tutorial} />,
        exact: true,
        title: 'Welcome | Pony',
    },
];

const TEACHER_ROUTES = [
    {
        path: ROUTES.teacherTracks,
        component: <TeachersView type={Chapters.Track} />,
        exact: true,
        title: 'Videos | Pony',
    },
    {
        path: ROUTES.teacherDictionary,
        component: <TeachersView type={Chapters.Dictionary} />,
        exact: true,
        title: 'Words | Pony',
    },
    {
        path: ROUTES.teacherSituations,
        component: <TeachersView type={Chapters.Situation} />,
        exact: true,
        title: 'Dialogues | Pony',
    },
    {
        path: ROUTES.teacherExercises,
        component: <TeachersView type={Chapters.Exercise} />,
        exact: true,
        title: 'Exercises | Pony',
    },
    {
        path: ROUTES.students,
        component: <TeachersView type={Views.Students} />,
        exact: true,
        title: 'Students | Pony',
    },
    {
        path: ROUTES.lessonsLibrary,
        component: <TeachersView type={Views.Library} />,
        exact: true,
        title: 'Lesson Library | Pony',
    },
    {
        path: ROUTES.lessons,
        component: <TeachersView type={Views.Lessons} />,
        exact: true,
        title: 'Lessons | Pony',
    },
    {
        path: ROUTES.profile,
        component: <Profile />,
        exact: true,
        title: 'Profile | Pony',
    },
];

const MARKETING_ROUTES = [
    {
        path: ROUTES.users,
        component: <Users />,
        exact: true,
        title: 'Users | Marketing',
    },
    {
        path: ROUTES.profile,
        component: <Profile />,
        exact: true,
        title: 'Profile | Pony',
    },
];

export const getRoutesByRole = (role) => {
    switch (role) {
        case Roles.Admin:
            return ADMIN_ROUTES;
        case Roles.Teacher:
            return TEACHER_ROUTES;
        case Roles.Guest:
            return PUBLIC_ROUTES;
        case Roles.Marketing:
            return MARKETING_ROUTES;
        case Roles.User:
            return USER_ROUTES;
        default:
            return [];
    }
};

export const getDefaultRouteByRole = (role) => {
    switch (role) {
        case Roles.Marketing:
            return ROUTES.users;
        case Roles.Admin:
            return ROUTES.library;
        case Roles.Teacher:
            return ROUTES.students;
        case Roles.Guest:
            return ROUTES.default;
        case Roles.User:
            return ROUTES.userToDo;
        default:
            return ROUTES.default;
    }
};
