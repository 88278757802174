import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionsLabel } from '..';
import { AdjustableTextarea } from '../../../../UI';
import cl from './FreeFormQuestionsPreview.module.css';

const FreeFormQuestionsPreview = ({
    exerciseObj,
    results,
    isPdf = false,
    isPreview = false,
}) => {
    const { t } = useTranslation();
    const { questions } = exerciseObj?.data;

    const [answer, setAnswer] = useState('');

    useEffect(() => {
        setAnswer('');
    }, [exerciseObj]);

    const renderQuestions = () => {
        return questions?.map((q, i) => {
            const hasNumeration = /^\d+\.\s/.test(q.question);
            const displayedQuestion = hasNumeration
                ? q.question
                : `${i + 1}. ${q.question}`;

            if (isPdf) {
                return (
                    <>
                        <p className={cl.question} key={q.id}>
                            {displayedQuestion}
                        </p>
                        <p className={cl.pdfGap} />
                    </>
                );
            }

            return (
                <p className={cl.question} key={q.id}>
                    {displayedQuestion}
                </p>
            );
        });
    };

    if (results && results.enteredText) {
        return (
            <>
                <QuestionsLabel label={'student_answer'} />
                <p className={cl.studentText}>{results.enteredText}</p>
            </>
        );
    }

    if (results && results.userAnswers) {
        const answers = Object.values(results.userAnswers);
        return (
            <>
                <div className={cl.questions}>
                    {questions?.map((q, i) => (
                        <Fragment key={q.id}>
                            <p
                                className={cl.questionGrey}
                            >{`${i + 1}. ${q.question}`}</p>
                            <p className={cl.studentText}>{answers[i]}</p>
                        </Fragment>
                    ))}
                </div>
            </>
        );
    }

    return (
        <div>
            {questions && (
                <>
                    {!isPdf && !isPreview && (
                        <QuestionsLabel label={'questions'} />
                    )}
                    <div className={cl.questions}>{renderQuestions()}</div>
                </>
            )}
            {isPdf ? (
                questions ? null : (
                    <div className={cl.pdfTextGap} />
                )
            ) : (
                <AdjustableTextarea
                    className={cl.answers}
                    placeholder={t('exercises.answer')}
                    value={answer}
                    onChange={setAnswer}
                />
            )}
        </div>
    );
};

export default FreeFormQuestionsPreview;
