import axios from 'axios';
import { DEVICE_ID } from '../utils/getDeviceId';

const URL = 'v1/dictionary_v2';

const inreasedTimeoutInstance = axios.create({
    timeout: 30000,
});

const headers = {
    'x-actor-device': DEVICE_ID,
};

export default class DictionaryService {
    static async addWordsBatch({ words, sourceLang, targetLang }) {
        try {
            const data = { words, sourceLang };
            if (targetLang !== null && targetLang !== '') {
                data.targetLang = targetLang;
            }
            const { statusText } = await inreasedTimeoutInstance.post(
                `${URL}/words/batch`,
                data,
                {
                    headers,
                }
            );
            return statusText;
        } catch (e) {
            return e?.message || 'Unexpected error occured.';
        }
    }

    static async getBatch({ words, sourceLang }) {
        try {
            const params = new URLSearchParams({
                words,
                sourceLang,
            });

            const response = await axios.get(
                `${URL}/words/batch?${params.toString()}`,
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getWords({
        limit,
        offset,
        searchString,
        partOfSpeech,
        language,
        checked,
        status,
        sortCol,
        sortDir,
    }) {
        try {
            const params = new URLSearchParams();

            const optional = {
                limit,
                offset,
                searchString,
                partOfSpeech,
                language,
                checked,
                status,
                sortCol,
                sortDir,
            };

            Object.keys(optional).forEach((param) => {
                if (
                    optional[param] !== undefined &&
                    optional[param] !== null &&
                    optional[param] !== ''
                ) {
                    params.append(param, optional[param]);
                }
            });

            const response = await axios.get(
                `${URL}/words?${params.toString()}`,
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getQueue({ language, searchString, limit, offset }) {
        try {
            const job = ['generating'];

            const params = new URLSearchParams({ job, limit, offset });

            if (language) {
                params.append('language', language);
            }

            if (searchString) {
                params.append('searchString', searchString);
            }

            const response = await axios.get(
                `${URL}/words?${params.toString()}`,
                {
                    headers,
                }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async markWords({ ids, status }) {
        try {
            const response = await axios.patch(
                `${URL}/words/mark-checked`,
                {
                    ids,
                    status,
                },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getWord({ id, targetLang }) {
        try {
            const params = new URLSearchParams(id);

            if (targetLang) {
                params.append(targetLang, targetLang);
            }

            const response = await axios.get(
                `${URL}/words/one/${id}${
                    targetLang ? `?targetLang=${targetLang}` : ''
                }`,
                { headers }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async regenerateWord({ word, sourceLang }) {
        try {
            const response = await axios.patch(
                `${URL}/words/regenerate`,
                {
                    word: word.toLowerCase(),
                    sourceLang: sourceLang,
                },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async deleteWord({ id }) {
        try {
            const response = await axios.delete(`${URL}/words/${id}`, {
                headers,
            });

            return response;
        } catch (e) {
            return null;
        }
    }

    static async validateWord({ id, status }) {
        try {
            const response = await axios.patch(
                `${URL}/words/invalidate/${id}`,
                { status },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async updateWord({ id, article, pronunciation, forms, synonyms }) {
        try {
            const response = await axios.patch(
                `${URL}/words/${id}`,
                { article, pronunciation, forms, synonyms },
                { headers }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/healthcheck`, { headers });

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getWordByText({ word, sourceLang, targetLang }) {
        try {
            const response = await axios.get(
                `${URL}/words/${word}?sourceLang=${sourceLang}&targetLang=${targetLang}`,
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            if (e.response && e.response.data) {
                return {
                    message: e.response.data.message ?? 'Unexpected error',
                    errorCode: e.response.data.code ?? 400,
                };
            }

            return {
                message: e.message ?? 'Unexpected error',
                errorCode: e.code ?? 400,
            };
        }
    }

    static async addWord({ word, sourceLang, targetLang }) {
        try {
            const data = { word, sourceLang, targetLang };

            const response = await axios.post(`${URL}/words`, data, {
                headers,
            });

            return response;
        } catch (e) {
            if (e.response && e.response.data) {
                return {
                    message: e.response.data.message ?? 'Unexpected error',
                    errorCode: e.response.data.code ?? 400,
                };
            }

            return {
                message: e.message ?? 'Unexpected error',
                errorCode: e.code ?? 400,
            };
        }
    }

    static async translateWord({ word, sourceLang, targetLang }) {
        try {
            const data = { word, sourceLang, targetLang };

            const response = await axios.post(
                `${URL}/words/translations`,
                data,
                {
                    headers,
                }
            );

            return response;
        } catch (e) {
            if (e.response && e.response.data) {
                return {
                    message: e.response.data.message ?? 'Unexpected error',
                    errorCode: e.response.data.code ?? 400,
                };
            }

            return {
                message: e.message ?? 'Unexpected error',
                errorCode: e.code ?? 400,
            };
        }
    }
}
