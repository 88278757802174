import React, { useEffect, useRef, useState } from 'react';
import { CloseButton } from '../../UI';
import cl from './AdjustableTextarea.module.css';

const AdjustableTextarea = ({
    placeholder,
    value,
    onChange,
    onClick,
    onDelete,
    isFocus,
    style,
    className,
    containerStyle = {},
    isError,
    onBlur,
    showDelete = false,
}) => {
    const textAreaRef = useRef(null);
    const [isBlur, setIsBlur] = useState(true);

    useEffect(() => {
        if (isFocus) {
            textAreaRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const input = textAreaRef.current;
        if (input) {
            input.style.height = '0px';
            const scrollHeight = input.scrollHeight;
            input.style.height = scrollHeight + 'px';
        }
    }, [textAreaRef, value]);

    const getClassName = () => {
        const classNames = [className || cl.adjustableInput];
        if (isError) classNames.push(cl.error);

        if (isBlur) {
            classNames.push(cl.blur);
        }

        return classNames.join(' ');
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        onChange(newValue);
    };

    const handleBlur = () => {
        setIsBlur(true);
        onBlur && onBlur();
    };

    const handleKeyDown = (e) => {
        if (showDelete && e.key === 'Enter') {
            textAreaRef.current.blur();
        }
    };

    const handleMouseUp = (e) => {
        textAreaRef.current.focus();
    };

    return (
        <div
            className={cl.inputCont}
            style={{
                position: showDelete ? 'relative' : 'static',
                ...containerStyle,
            }}
            onClick={onClick}
        >
            <textarea
                ref={textAreaRef}
                className={getClassName()}
                style={style}
                rows={1}
                placeholder={placeholder}
                value={value}
                onBlur={handleBlur}
                onChange={handleInputChange}
                onFocus={() => setIsBlur(false)}
                onKeyDown={handleKeyDown}
                onMouseUp={handleMouseUp}
            />
            {showDelete && onDelete && (
                <CloseButton
                    className={`${cl.deleteIcon} ${isBlur ? '' : cl.focus}`}
                    top={'50%'}
                    right={10}
                    size={10}
                    onClick={onDelete}
                />
            )}
        </div>
    );
};

export default AdjustableTextarea;
