import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../store/interface';
import wizardStore from '../../../LessonsKanban/components/LessonWizard/store/wizardStore';
import {
    Button,
    CloseButton,
    Input,
    Label,
    MicroButton,
    Modal,
    Select,
    Tree,
} from '../../../../UI';
import {
    AgeOptions,
    getExamOptions,
    LessonAccessStatuses,
    LevelOptions,
} from '../../../../pages/TeacherContent/data/constants';
import { ReactComponent as IconEdit } from '../../../../assets/svg/lessons-edit.svg';
import cl from './EditLessonModal.module.css';

const EditLessonModal = ({ lesson, onUpdate }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { grammarOptions, vocabularyOptions } = wizardStore;

    const [lessonData, setLessonData] = useState({});
    const [lessonErrors, setLessonErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredVocabulary, setFilteredVocabulary] =
        useState(vocabularyOptions);
    const [isEdit, setIsEdit] = useState(false);

    const { difficulty } = lessonData;

    const handleLessonUpdate = () => {
        setLessonErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof lessonData.difficulty !== 'number',
                vocabularyTopic: !lessonData.vocabularyTopic,
                title: !lessonData.title,
            };
            setLessonErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            setIsEdit(false);
            onUpdate(lessonData);
        });
    };

    const handleInputChange = (key, value) => {
        setLessonData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setIsEdit(false);
    }, []);

    useEffect(() => {
        setLessonData({
            title: lesson?.title ?? '',
            age: lesson?.age ?? '',
            difficulty: lesson?.difficulty ?? '',
            vocabularyTopic: lesson?.vocabularyTopic ?? '',
            grammarTopic: lesson?.grammarTopic ?? '',
            exam: lesson?.exam ?? '',
            isPrivate: lesson?.isPrivate ?? '',
        });
    }, [lesson]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);

        setFilteredGrammar(currentGrammar);

        const currentVocabulary = vocabularyOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredVocabulary(currentVocabulary);
    }, [grammarOptions, vocabularyOptions, difficulty]);

    useEffect(() => {
        setLessonErrors({});
    }, [lessonData.title]);

    return (
        <>
            <MicroButton
                icon={<IconEdit className={cl.iconStroke} />}
                variant={'grey'}
                size={'regular'}
                onClick={() => setIsEdit(true)}
            />
            <Modal
                className={cl.editModal}
                visible={isEdit}
                setVisible={setIsEdit}
            >
                <p className={cl.modalTitle}>{t('exercises.edit_lesson')}</p>

                <div className={cl.flexContainer}>
                    <div>
                        <Label text={t('users.level')} />
                        <Select
                            className={cl.select}
                            variant={
                                lessonErrors['difficulty']
                                    ? 'errorSmall'
                                    : 'grey'
                            }
                            value={lessonData.difficulty}
                            options={LevelOptions}
                            onChange={(value) =>
                                handleInputChange('difficulty', value)
                            }
                            ignoreOverflow
                            hideReset
                        />
                    </div>
                    <div>
                        <Label text={t('lesson_wizard.lesson_access_status')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.isPrivate}
                            options={LessonAccessStatuses.map((l) => ({
                                ...l,
                                name: t(l.name),
                            }))}
                            onChange={(value) =>
                                handleInputChange('isPrivate', value)
                            }
                            ignoreOverflow
                            hideReset
                        />
                    </div>
                    <div>
                        <Label text={t('users.age')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.age}
                            options={AgeOptions}
                            onChange={(value) =>
                                handleInputChange('age', value)
                            }
                            ignoreOverflow
                        />
                    </div>
                    <div>
                        <Label text={t('exercises.exam')} />
                        <Select
                            className={cl.select}
                            variant="grey"
                            value={lessonData.exam}
                            options={getExamOptions(lang)}
                            onChange={(value) =>
                                handleInputChange('exam', value)
                            }
                            ignoreOverflow
                        />
                    </div>
                </div>
                <div>
                    <Label text={t('lesson_wizard.select_lexical_topic')} />
                    <Tree
                        label={t('lesson_wizard.lexical_topic')}
                        variant={
                            lessonErrors['vocabularyTopic']
                                ? 'errorSmall'
                                : 'grey'
                        }
                        value={lessonData.vocabularyTopic}
                        onChange={(value) =>
                            handleInputChange('vocabularyTopic', value)
                        }
                        options={filteredVocabulary}
                        hideReset
                        ignoreOverflow
                        height={240}
                        disabled={lessonData.difficulty === ''}
                        tooltip={t('lesson_wizard.grammar_topic_select_level')}
                    />
                </div>
                <div>
                    <Label text={t('lesson_wizard.select_grammar_topic')} />
                    <Tree
                        label={t('lesson_wizard.grammar_topic')}
                        variant="grey"
                        value={lessonData.grammarTopic}
                        onChange={(value) =>
                            handleInputChange('grammarTopic', value)
                        }
                        options={filteredGrammar}
                        ignoreOverflow
                        height={180}
                        disabled={lessonData.difficulty === ''}
                        tooltip={t('lesson_wizard.grammar_topic_select_level')}
                    />
                </div>
                <div>
                    <Label
                        isError={lessonErrors['title']}
                        text={t('exercises.lesson_title')}
                    />
                    <Input
                        placeholder={t('exercises.lesson_title')}
                        variant={
                            lessonErrors['title'] ? 'erroredSmall' : 'filled'
                        }
                        value={lessonData.title}
                        onChange={(value) => handleInputChange('title', value)}
                    />
                </div>
                <Button
                    style={{ marginTop: 'auto' }}
                    text={t('buttons.save')}
                    onClick={handleLessonUpdate}
                    variant={'purple'}
                />
                <CloseButton onClick={() => setIsEdit(false)} />
            </Modal>
        </>
    );
};

export default observer(EditLessonModal);
