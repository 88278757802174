import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../hooks/useFetching';
import SituationsService from '../../api/SituationsService';
import { authStore } from '../../App';
import languagesStore from '../../store/interface';
import learnSituationStore from './store';
import userStore from '../UserPlatform/store/userStore';
import {
    SelectLearnSituationParams,
    LearnSituationCards,
    SituationDoneAlert,
} from './components';
import { Roles } from '../../data/common';
import { LearningModes } from './data';
import cl from './LearnSituation.module.css';

// TODO add CloseButton for teacher preview mode @saratovkin
const LearnSituation = ({ situation, isModal, onClose, isHistory }) => {
    const location = useLocation();
    const learnSituationRef = useRef();

    const { clickedWords, chapterCloseModal, pendingEvent } = userStore;
    const isToDo = location.pathname.includes('todo');

    const [localProgress, setLocalProgress] = useState({});
    const [isComplete, setIsComplete] = useState(false);
    const [isSwitching, setIsSwitching] = useState(false);
    const [wasPlayed, setWasPlayed] = useState(false);

    const { gender, modality, mode, statements, statementIndex } =
        learnSituationStore;
    const { id } = situation;
    const { lang, nativeLang } = languagesStore;

    const isUser = authStore.user.role === Roles.User;
    const progress = isUser ? userStore.getDialogueProgress(id) : localProgress;

    const isSituationComplete = useMemo(() => {
        if (!isUser) return false;
        return (
            Object.values(progress).reduce((a, b) => a + b) ===
            LearningModes.length
        );
    }, [progress, isUser]);

    const updateProgress = useCallback(
        (newProgress) => {
            if (isHistory) return;

            if (isUser) {
                userStore.updateDialogueProgress({
                    id,
                    lang,
                    favourite: isToDo,
                    progress: newProgress,
                    isLibrary: !isToDo,
                });
            } else {
                setLocalProgress(newProgress);
            }
        },
        [isHistory, isUser, id, lang]
    );

    const [getSituationInfo] = useFetching(async () => {
        const { data } = await SituationsService.getSituationInfo({
            id,
            language: lang,
        });
        learnSituationStore.setInfo(data?.info ?? []);
    });

    const [getSituation, isSituationLoading] = useFetching(async () => {
        const { data } = await SituationsService.getSituation({
            id,
            language: lang.toUpperCase(),
            gender,
            modality,
            targetLanguage: nativeLang.toUpperCase(),
        });

        const { statements } = data;
        learnSituationStore.setStatements(statements);
    }, true);

    const handleClickOutside = (e) => {
        if (
            learnSituationRef.current &&
            !learnSituationRef.current.contains(e.target) &&
            !chapterCloseModal &&
            wasPlayed &&
            (clickedWords.length || !(isToDo || isHistory))
        ) {
            e.preventDefault();
            if (!pendingEvent) {
                userStore.setPendingEvent(e);
            }
            userStore.setChapterCloseModal(true);
        }
    };

    useEffect(() => {
        if (!chapterCloseModal && isUser) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        learnSituationRef,
        clickedWords,
        pendingEvent,
        isUser,
        isToDo,
        isHistory,
        wasPlayed,
    ]);

    useEffect(() => {
        if (isComplete) {
            const progress = LearningModes.reduce((acc, key) => {
                acc[key] = 1;
                return acc;
            }, {});
            userStore.updateDialogueProgress({
                id: userStore.activeChapter.id,
                lang,
                progress,
                favourite: false,
                isLibrary: !isToDo,
            });
            userStore.removeFavDialogue({
                ...userStore.activeChapter,
                listeningCompleted: progress.listen,
                puzzleCompleted: progress.exercise,
                speakingCompleted: progress.speak,
                translationCompleted: progress.translation,
            });
        }
    }, [isComplete]);

    useEffect(() => {
        if (!gender || !modality) return;
        setLocalProgress({});
        getSituation();
    }, [id, gender, nativeLang, modality]);

    useEffect(() => {
        setWasPlayed(false);
        learnSituationStore.resetParams();
        if (id && !situation.info) {
            getSituationInfo();
        } else {
            learnSituationStore.setInfo(situation.info);
        }
    }, [situation]);

    useEffect(() => {
        if (!isUser) return;

        const progress = userStore.getDialogueProgress(id);
        const restoredIndex =
            Math.floor(progress[mode] * (statements.length - 1)) || 0;

        learnSituationStore.setStatementIndex(restoredIndex);
    }, [isUser, id, mode, statements.length]);

    useEffect(() => {
        if (statements.length > 0 && !isComplete && mode) {
            updateProgress({
                ...progress,
                [mode]:
                    Math.round(
                        (statementIndex / (statements.length - 1)) * 100
                    ) / 100,
            });
        }
    }, [isComplete, mode, statementIndex, statements]);

    useEffect(() => {
        if (isSituationComplete) {
            setIsComplete(true);
        }
    }, [isSituationComplete]);

    useEffect(() => {
        setIsSwitching(true);
        learnSituationStore.setMode('');
        setIsSwitching(false);

        if (!isSituationComplete) {
            setIsComplete(false);
        }
    }, [isSituationComplete, id]);

    useEffect(() => {
        if (mode) setWasPlayed(true);
    }, [mode]);

    return (
        <div
            className={`${isModal ? cl.modalContainer : cl.learnContainer}`}
            ref={learnSituationRef}
        >
            <div className={cl.containerInner}>
                {isSituationComplete ? (
                    <SituationDoneAlert isHistory={isHistory} />
                ) : mode ? (
                    <LearnSituationCards isSwitching={isSwitching} />
                ) : (
                    <SelectLearnSituationParams
                        situation={situation}
                        progress={progress}
                        isLoading={isSituationLoading}
                        isHistory={isHistory}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(LearnSituation);
