import React from 'react';
import cl from './UserWordsOrderPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';

const UserWordsOrderPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;
    const { sentences } = data;

    const filledSentences =
        results.filledSentences ||
        sentences.map((s) =>
            Array.from(
                {
                    length: s.text.split(' ').filter((w) => w.trim().length)
                        .length,
                },
                () => ''
            )
        );

    const parsedSentences = sentences.map((s, i) => {
        let words = s.text
            ?.split(' ')
            .filter((w) => w.trim().length)
            .sort((a, b) => a.localeCompare(b));

        const answers = filledSentences[i] || [];

        const answerCounts = new Map();
        answers.forEach((word) =>
            answerCounts.set(word, (answerCounts.get(word) || 0) + 1)
        );

        words = words.filter((word) => {
            if (answerCounts.has(word) && answerCounts.get(word) > 0) {
                answerCounts.set(word, answerCounts.get(word) - 1);
                return false;
            }
            return true;
        });

        return { id: s.id, words, answers };
    });

    const handleOptionClick = (sentenceIdx, option) => {
        const newSentences = filledSentences.map((s, i) => {
            if (i !== sentenceIdx) return s;

            const pos = s.findIndex((a) => !a || a.length === 0);
            if (pos === -1) return s;

            return s.map((a, idx) => (idx === pos ? option : a));
        });

        setResults({ filledSentences: newSentences });
    };

    const handleAnswerRemove = (sentenceIndex, answerIndex) => {
        const newSentences = [...filledSentences];
        newSentences[sentenceIndex] = newSentences[sentenceIndex].map((s, i) =>
            i === answerIndex ? null : s
        );
        setResults({ filledSentences: newSentences });
    };

    const getAnswerClassName = (sentenceIndex, answerIndex, answer) => {
        const classNames = [cl.answer];

        if (answer?.length) classNames.push(cl.active);
        if (isCompleted) {
            const words = sentences[sentenceIndex].text
                .split(' ')
                .filter((w) => w.trim().length);
            classNames.push(
                words[answerIndex] === answer ? cl.correct : cl.wrong
            );
        }

        return classNames.join(' ');
    };

    return (
        <>
            <QuestionsLabel label={'words_order_user_info'} />
            <div className={cl.sentencesCont}>
                {parsedSentences?.map((sentence, sentenceIndex) => (
                    <div className={cl.sentence} key={sentenceIndex}>
                        <div
                            className={`${cl.text} ${sentence.words.length ? '' : cl.done}`}
                        >
                            {sentence.answers.map((answer, answerIndex) => (
                                <span
                                    className={getAnswerClassName(
                                        sentenceIndex,
                                        answerIndex,
                                        answer
                                    )}
                                    key={`${answer}_${answerIndex}`}
                                    onClick={() =>
                                        handleAnswerRemove(
                                            sentenceIndex,
                                            answerIndex
                                        )
                                    }
                                >
                                    {answer}
                                </span>
                            ))}
                        </div>
                        {sentence.words.length !== 0 && (
                            <div className={cl.variantsCont}>
                                {sentence.words.map((option, optionIndex) => (
                                    <span
                                        className={cl.variant}
                                        key={`${option}_${optionIndex}`}
                                        onClick={() =>
                                            handleOptionClick(
                                                sentenceIndex,
                                                option
                                            )
                                        }
                                    >
                                        {option}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default UserWordsOrderPreview;
