import React, { useEffect, useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import parse from 'html-react-parser';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../../App';
import languagesStore from '../../store/interface';
import { Button, Input, Label, PageWrapper, Select } from '../../UI';
import UserService from '../../api/UserService';
import { useFetching } from '../../hooks/useFetching';
import isEmailValid from '../../utils/isEmailValid';
import { AuthModes } from '../../store/auth';
import { Roles } from '../../data/common';
import cl from './Register.module.css';

const Register = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const { pendingTeacher } = authStore;
    const { availableLanguages, languages } = languagesStore;

    const [formErrors, setFormErrors] = useState({});

    const [role, setRole] = useState();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [readyToRegister, setReadyToRegister] = useState(false);
    const [lang, setLang] = useState(i18n.language);
    const [nativeLang, setNativeLang] = useState('');

    const [sendCode, codeLoading, codeError, resetCodeError] = useFetching(
        async () => {
            resetCodeError();
            const { data } = await UserService.sendCode({ email });
            if (data) {
                setReadyToRegister(true);
            }
        }
    );

    const [regUser, regLoading, regError, resetRegError] = useFetching(
        async () => {
            resetRegError();
            await UserService.register({
                email,
                password,
                role,
                code,
                name: `${name} ${surname}`,
                lang,
            });
            authStore.login(email, password, navigate);
        }
    );

    const [handleGoogleLoginSuccess] = useFetching(async (response) => {
        try {
            const { credential } = response;
            if (!credential) return;

            const { data } = await UserService.googleLogin({
                code: credential,
                appType: 'web',
                role,
            });

            if (data.token) {
                authStore.setUser(data.user);
                authStore.setToken(data.token);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Google login failed:', error);
        }
    });

    const handleGoogleLoginFailure = () => {
        console.error('Google login failed');
    };

    const handleNamenput = (text) => {
        setName(text.trim());
    };

    const handleSurnameInput = (text) => {
        setSurname(text.trim());
    };

    const handleEmailInput = (text) => {
        setEmail(text.trim());
    };

    const handleRegistration = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                code: !code,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            regUser();
        });
    };

    const handleLoginRedirect = () => {
        authStore.setAuthMode(AuthModes.Login);
    };

    const handleSendCode = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                email: !isEmailValid(email),
                password: !password,
                name: !name,
                surname: !surname,
                nativeLang: !nativeLang,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            sendCode();
        });
    };

    const renderSignInButton = () => {
        if (readyToRegister) return null;

        return (
            <p className={cl.signInButton}>
                {parse(t('login.login_redirect'), {
                    replace: (node) => {
                        if (node.name === 'span') {
                            return (
                                <span onClick={handleLoginRedirect}>
                                    {node.children[0].data}
                                </span>
                            );
                        }
                    },
                })}
            </p>
        );
    };

    useEffect(() => {
        setFormErrors({});
    }, [code, name, surname, email, password, nativeLang]);

    useEffect(() => {
        if (pendingTeacher) {
            setRole(Roles.User);
        }
    }, [pendingTeacher]);

    return (
        <PageWrapper additionalClass={cl.registerContainer}>
            <div className={cl.container}>
                <div className={cl.numbersLeft} />
                <div className={cl.numbersRight} />
                <div className={cl.containerInner}>
                    <div className={cl.form}>
                        <p className={cl.registrationTitle}>
                            {t(
                                readyToRegister
                                    ? 'login.verification'
                                    : 'login.registration'
                            )}
                        </p>
                        {role ? (
                            readyToRegister ? (
                                <>
                                    <p className={cl.verificationInfo}>{`${t(
                                        'login.verification_info'
                                    )}`}</p>
                                    <p className={cl.email}>{email}</p>
                                </>
                            ) : (
                                <>
                                    {pendingTeacher && (
                                        <p className={cl.teacherInvite}>
                                            {t('login.teacher_invite')}
                                        </p>
                                    )}
                                    <div style={{ marginBottom: 16 }}>
                                        <Label
                                            text={t(
                                                role === Roles.Teacher
                                                    ? 'login.register_language'
                                                    : 'login.register_language_student'
                                            )}
                                        />
                                        <Select
                                            style={{ width: '100%' }}
                                            variant={'outlined'}
                                            value={lang}
                                            options={availableLanguages}
                                            onChange={setLang}
                                            hideReset
                                        />
                                    </div>
                                    <div style={{ marginBottom: 6 }}>
                                        <Label
                                            text={t(
                                                role === Roles.Teacher
                                                    ? 'users.student_lang'
                                                    : 'login.native_language'
                                            )}
                                            isError={formErrors['nativeLang']}
                                        />
                                        <Select
                                            style={{ width: '100%' }}
                                            variant={
                                                formErrors['nativeLang']
                                                    ? 'error'
                                                    : 'outlined'
                                            }
                                            value={nativeLang}
                                            options={languages.filter(
                                                (lObj) => lObj.value !== lang
                                            )}
                                            onChange={setNativeLang}
                                            hideReset
                                        />
                                    </div>
                                    <div style={{ marginBottom: 6 }}>
                                        <Label
                                            text={t('profile.name')}
                                            isError={formErrors['name']}
                                        />
                                        <Input
                                            variant={
                                                formErrors['name']
                                                    ? 'errored'
                                                    : 'outlined'
                                            }
                                            value={name}
                                            onChange={handleNamenput}
                                            type="name"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ marginBottom: 16 }}>
                                        <Label
                                            text={t('profile.surname')}
                                            isError={formErrors['surname']}
                                        />
                                        <Input
                                            variant={
                                                formErrors['surname']
                                                    ? 'errored'
                                                    : 'outlined'
                                            }
                                            value={surname}
                                            type="surname"
                                            onChange={handleSurnameInput}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ marginBottom: 6 }}>
                                        <Label
                                            text={t('email')}
                                            isError={formErrors['email']}
                                        />
                                        <Input
                                            variant={
                                                formErrors['email']
                                                    ? 'errored'
                                                    : 'outlined'
                                            }
                                            value={email}
                                            onChange={handleEmailInput}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ marginBottom: 16 }}>
                                        <Label
                                            text={t('login.password')}
                                            isError={formErrors['password']}
                                        />
                                        <Input
                                            variant={
                                                formErrors['password']
                                                    ? 'errored'
                                                    : 'outlined'
                                            }
                                            value={password}
                                            onChange={setPassword}
                                            type="password"
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    {/* <GoogleOAuthProvider clientId="790550547851-p53t6ft0dajfiheij0u3ei7korus3n6j.apps.googleusercontent.com">
                                        <GoogleLogin
                                            onSuccess={handleGoogleLoginSuccess}
                                            onError={handleGoogleLoginFailure}
                                        />
                                    </GoogleOAuthProvider> */}
                                </>
                            )
                        ) : (
                            <>
                                <p className={cl.roleText}>
                                    {t('roles.select_role')}
                                </p>
                                <div className={cl.roleButtons}>
                                    <Button
                                        variant={'purple'}
                                        className={cl.roleButton}
                                        text={t('roles.register_as_teacher')}
                                        onClick={() => setRole(Roles.Teacher)}
                                    />
                                    <Button
                                        variant={'grey'}
                                        className={cl.roleButton}
                                        text={t('roles.register_as_student')}
                                        onClick={() => setRole(Roles.User)}
                                    />
                                </div>
                            </>
                        )}

                        {readyToRegister && (
                            <>
                                <div style={{ marginTop: 32 }}>
                                    <Label
                                        text={t('login.verification_code')}
                                        isError={formErrors['code']}
                                    />
                                    <Input
                                        variant={
                                            formErrors['code']
                                                ? 'errored'
                                                : 'outlined'
                                        }
                                        value={code}
                                        onChange={setCode}
                                    />
                                </div>
                            </>
                        )}
                        {renderSignInButton()}
                        {role && (
                            <div className={cl.buttons}>
                                <Button
                                    variant={'purple'}
                                    isLoading={
                                        readyToRegister
                                            ? regLoading
                                            : codeLoading
                                    }
                                    text={t(
                                        readyToRegister
                                            ? 'buttons.sign_up'
                                            : 'buttons.next'
                                    )}
                                    onClick={
                                        readyToRegister
                                            ? handleRegistration
                                            : handleSendCode
                                    }
                                />
                            </div>
                        )}

                        {codeError && (
                            <p className={cl.errorMessage}>
                                {t('login.code_error')}
                            </p>
                        )}
                        {regError && (
                            <p className={cl.errorMessage}>
                                {t('login.reg_error')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export default observer(Register);
