import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { PonyPlayer, UserWords } from '..';
import LearnSituation from '../../../LearnSituation/LearnSituation';
import ExercisePreview from '../../../ExercisePreview/ExercisePreview';
import InfoCard from '../../../LearnWords/components/InfoCard/InfoCard';
import { EmptyChaptersAlert } from '../../../../UI';
import { Roles } from '../../../../data/common';
import cl from './UserChapterPreview.module.css';

const UserChapterPreview = ({
    chapter = {},
    type,
    isWordPack,
    isTodo,
    isHistory,
}) => {
    const { t } = useTranslation();
    const { id } = chapter;

    const getPreview = () => {
        switch (type) {
            case Chapters.Track:
                return <PonyPlayer trackId={id} />;

            case Chapters.Exercise:
                return (
                    <ExercisePreview
                        type={Roles.User}
                        exerciseId={id}
                        hideEdit
                    />
                );

            case Chapters.Dictionary:
                return isWordPack ? (
                    <div className={cl.wordPackPreview}>
                        <UserWords categoryId={chapter.id} isTodo={isTodo} />
                    </div>
                ) : (
                    <InfoCard wordObj={chapter} />
                );

            default:
                return null;
        }
    };

    if (!id)
        return (
            <EmptyChaptersAlert
                variant={'outlined'}
                icon={type ?? 'todo'}
                title={t(
                    type
                        ? `user_view.select_${type}`
                        : 'user_view.select_content'
                )}
            />
        );
    if (type === Chapters.Situation)
        return <LearnSituation situation={chapter} isHistory={isHistory} />;

    if (type === Chapters.Dictionary && isWordPack) return getPreview();

    return <div className={cl.previewContainer}>{getPreview()}</div>;
};

export default observer(UserChapterPreview);
