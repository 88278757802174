import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Input } from '../../../../../../UI';
import { splitText } from '../../../../../../pages/DictionaryAdministration/helpers/splitText';
import cl from './ManageVocabularySearch.module.css';

const ManageVocabularySearch = ({
    variant,
    vocabulary,
    setVocabulary,
    onSearch,
    isLoading,
    level,
}) => {
    const { t } = useTranslation();

    const [text, setText] = useState('');

    const handleWordDelete = (idx) => {
        const updatedWords = [...vocabulary];
        updatedWords.splice(idx, 1);
        setText(updatedWords.join(', '));
    };

    useEffect(() => {
        setVocabulary(splitText(text));
    }, [text]);

    return (
        <div className={cl.wordsSearchFilter}>
            <div className={cl.horizontalContainer}>
                <Input
                    placeholder={t('lesson_wizard.type_words_here')}
                    variant={'filled'}
                    value={text}
                    onChange={setText}
                    search
                    showReset
                />
                <Button
                    variant={variant}
                    text={`${t('lesson_wizard.search_by_words')} (${level})`}
                    onClick={onSearch}
                    isLoading={isLoading}
                />
            </div>

            <div className={cl.wordsList}>
                {vocabulary.map((w, i) => (
                    <Chip
                        key={`${w}_${i}`}
                        text={w}
                        onDelete={() => handleWordDelete(i)}
                    />
                ))}
            </div>
        </div>
    );
};

export default observer(ManageVocabularySearch);
