import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import lessonsStore from '../../../../features/LessonsKanban/store/lessonsStore';
import Player from '../../../Player/Player';
import { CategoryWords } from '../../../TeacherContent/views/DictionaryView/components';
import { ChapterSection } from '../../../../features/LessonsKanban/components';
import { CloseButton } from '../../../../UI';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import { ModalHeader } from '../../../../features/LessonModal/components';
import ExercisePreview from '../../../../features/ExercisePreview/ExercisePreview';
import { Situation } from '../../../TeacherContent/views/SituationsView/components';
import cl from './DemoLessonModal.module.css';
import { Roles } from '../../../../data/common';

const DemoLessonModal = ({ lessonObj, onClose }) => {
    const { t } = useTranslation();

    const { currentChapter } = lessonsStore;

    const [currentWord, setCurrentWord] = useState();
    const [tracks, setTracks] = useState([]);
    const [situations, setSituations] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [dictionaries, setDictionaries] = useState([]);

    const chapterRefs = useRef({});

    const chaptersList = [
        {
            items: exercises,
            type: Chapters.Exercise,
        },
        {
            items: situations,
            type: Chapters.Situation,
        },

        {
            items: tracks,
            type: Chapters.Track,
        },
        {
            items: dictionaries,
            type: Chapters.Dictionary,
        },
    ];

    const getChapterPreview = () => {
        switch (currentChapter.type) {
            case Chapters.Track:
                return (
                    <Player
                        key={'modalView'}
                        track={{
                            id: currentChapter.id,
                            title: currentChapter?.title,
                        }}
                    />
                );
            case Chapters.Situation: {
                return <Situation situation={currentChapter} hideSettings />;
            }
            case Chapters.Exercise: {
                return (
                    <ExercisePreview
                        type={Roles.Guest}
                        exerciseId={currentChapter.id}
                    />
                );
            }
            case Chapters.Dictionary: {
                return (
                    <CategoryWords
                        category={currentChapter}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                        isDemo
                        outlined
                    />
                );
            }
            default:
                return (
                    <div className={cl.emptyAlert}>
                        <p className={cl.alertTitle}>
                            {t(`exercises.select_chapter`)}
                        </p>
                    </div>
                );
        }
    };

    const getModalClassName = () => {
        const classNames = [cl.lessonModal];
        return classNames.join(' ');
    };

    const selectFirstChapter = () => {
        const chapters = lessonObj.chapters ?? [];
        const activeChapter = chapters.length ? chapters[0] : {};

        lessonsStore.setCurrentChapter(activeChapter);
    };

    useEffect(() => {
        const chapters = lessonObj.chapters ?? [];

        setTracks(chapters.filter((c) => c.type === Chapters.Track));
        setSituations(chapters.filter((c) => c.type === Chapters.Situation));
        setExercises(chapters.filter((c) => c.type === Chapters.Exercise));
        setDictionaries(chapters.filter((c) => c.type === Chapters.Dictionary));

        selectFirstChapter();
    }, [lessonObj]);

    return (
        <div className={cl.overlay} onClick={onClose}>
            <div
                className={getModalClassName()}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalHeader lesson={lessonObj} />
                <div className={cl.mainContainer}>
                    <div className={cl.aside}>
                        <div className={cl.chaptersList}>
                            <div className={cl.scrollContainer}>
                                {chaptersList.map((c) => (
                                    <ChapterSection
                                        ref={(el) =>
                                            (chapterRefs.current[c.type] = el)
                                        }
                                        key={c.type}
                                        chapters={c.items}
                                        type={c.type}
                                        lessonId={lessonObj.id}
                                        onDelete={() => {}}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`${cl.container} ${cl[currentChapter.type]}`}
                    >
                        {getChapterPreview()}
                    </div>
                </div>
                <div style={{ postion: 'absolute' }}>
                    <CloseButton onClick={onClose} />
                </div>
            </div>
        </div>
    );
};

export default observer(DemoLessonModal);
