import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import player from '../../../../store/player';
import userStore from '../../store/userStore';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import { UserPlayerControls } from '..';
import { Loader } from '../../../../UI';
import { Roles, TicksInSecond } from '../../../../data/common';
import { ReactComponent as IconPlay } from '../../../../assets/svg/player-play.svg';
import cl from './UserPlayer.module.css';

const YTPlayer = require('yt-player');

const UserPlayer = ({ trackId, currentIndex, setCurrentIndex, isToDo }) => {
    const { role } = authStore;
    const [isCursorIdle, setIsCursorIdle] = useState(false);
    const [progress, setProgress] = useState(null);

    const playerRef = useRef(null);
    const controlsRef = useRef();
    const fetchedProgressRef = useRef(0);

    const {
        currentTime,
        duration,
        isPlaying,
        isLoaded,
        externalId,
        wasPlayed,
    } = player;

    const [getProgress] = useFetching(async () => {
        if (role !== Roles.User) return;
        const { data } = await ProgressService.getTrackProgress({ trackId });
        fetchedProgressRef.current = data.progress || 0;
        setProgress(data.progress || 0);
    });

    const [sendProgress] = useFetching(async (progress) => {
        if (role !== Roles.User) return;
        userStore.updateVideoProgress({
            trackId,
            progress,
            isLibrary: !isToDo,
        });
    });

    const onPlayerReady = () => {
        playerRef.current.load(
            `${externalId}`,
            false,
            currentTime / TicksInSecond
        );

        playerRef.current.on('paused', () => player.setIsPlaying(false));
        playerRef.current.on('cued', () => {
            player.setIsLoaded(true);
        });
        playerRef.current.on('ended', () => player.setIsPlaying(false));
        playerRef.current.on('playing', () => player.setIsPlaying(true));

        playerRef.current.off('timeupdate', handleOnProgress);
        playerRef.current.on('timeupdate', handleOnProgress);

        playerRef.current.on('error', (err) => console.error(err));
        playerRef.current.seek(currentTime / TicksInSecond);
        player.setPlayerRef(playerRef.current);
        player.setIsPlaying(false);
    };

    const handleOnProgress = (time) => {
        if (time === 0) return;

        if (player.maxTime && time * TicksInSecond > player.maxTime) {
            player.handleChangeAudioTime(player.maxTime, true);
            player.playerRef.pause();
            return false;
        } else {
            player.handleChangeAudioTime(time);
        }
    };

    const handleVideoClick = (e) => {
        if (controlsRef.current && controlsRef.current.contains(e.target))
            return;
        player.handlePlayPauseClick();
    };

    useEffect(() => {
        if (externalId) {
            playerRef.current = new YTPlayer('#YTPlayer', {
                captions: false,
                controls: false,
                modestBranding: true,
                timeupdateFrequency: 100,
                playsInline: true,
                fullscreen: false,
                annotations: false,
                rel: 0,
                autoplay: false,
                related: false,
                disablekb: 0,
                iv_load_policy: 3,
                showinfo: 0,
                enablejsapi: 1,
            });
            onPlayerReady();

            return () => {
                playerRef.current?.destroy();
                player.setIsLoaded(false);
                player.setWasPlayed(false);
            };
        }
    }, [externalId]);

    useEffect(() => {
        const currentProgress = Math.floor((currentTime / duration) * 100);

        if (currentProgress > fetchedProgressRef.current) {
            setProgress(currentProgress);
        }
    }, [currentTime, duration]);

    useEffect(() => {
        if (progress && progress > fetchedProgressRef.current) {
            fetchedProgressRef.current = progress;
            sendProgress(progress);
        }
    }, [progress, fetchedProgressRef]);

    useEffect(() => {
        setProgress(null);
        fetchedProgressRef.current = 0;

        getProgress();
    }, [trackId]);

    useEffect(() => {
        let timeoutId;

        const handleMouseMove = () => {
            setIsCursorIdle(false);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setIsCursorIdle(true), 3000);
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div
            className={`${cl.wrapper} ${isCursorIdle ? cl.idle : ''}`}
            onClick={handleVideoClick}
        >
            {externalId ? <div className={cl.player} id="YTPlayer" /> : null}
            {!isLoaded && (
                <Loader
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 3,
                    }}
                />
            )}
            {!isPlaying && (
                <div
                    className={wasPlayed ? cl.overlay : cl.firstOverlay}
                    onClick={() => playerRef.current.play()}
                >
                    {!wasPlayed && (
                        <>
                            {externalId && (
                                <img
                                    className={cl.fakePreview}
                                    src={
                                        isLoaded
                                            ? `https://img.youtube.com/vi/${externalId}/0.jpg`
                                            : ''
                                    }
                                    alt={externalId}
                                />
                            )}

                            <div className={cl.firstPlayButton}>
                                <IconPlay />
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={cl.controls} ref={controlsRef}>
                <UserPlayerControls
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            </div>
        </div>
    );
};

export default observer(UserPlayer);
