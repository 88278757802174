import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import languagesStore from '../../../../store/interface';
import learnSituationStore from '../../store';
import userDialoguesStore from '../../../UserPlatform/pages/UserDialogues/store/userDialogues';
import userStore from '../../../UserPlatform/store/userStore';
import {
    Button,
    CloseButton,
    FilledRange,
    Label,
    Loader,
    MicroButton,
    ToggleButton,
} from '../../../../UI';
import { GENDERS } from '../../../../pages/SituationsAdministration/data/constants';
import { LearningModes } from '../../data';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import { MediaPlayBackOptions } from '../../../../data/common';
import cl from './SelectLearnSituationParams.module.css';

const FinishedDialogueProgress = {
    listeningCompleted: 1,
    puzzleCompleted: 1,
    speakingCompleted: 1,
    translationCompleted: 1,
};

const SelectLearnSituationParams = ({
    situation,
    progress,
    isLoading,
    isHistory,
}) => {
    const { t } = useTranslation();

    const [isSettings, setIsSettings] = useState(false);
    const { lang } = languagesStore;
    const { gender, modality, mode, playbackSpeed } = learnSituationStore;

    const toggleSettings = () => setIsSettings(!isSettings);

    const handlePlaybackChange = (e) => {
        learnSituationStore.setPlaybackSpeed(e.target.value);
    };

    const getModeStyle = (m) => {
        if (!progress || !progress[m]) return {};

        const currentProgress = progress[m] || 0;
        const fillWidth = Math.floor(currentProgress * 100 + 3);

        return {
            background: `linear-gradient(to right, var(--orange-light) ${fillWidth}%, var(--orange-transparent) ${fillWidth}%)`,
            border: m === mode ? '1px solid  var(--orange)' : 'none',
        };
    };

    const handleModeChange = (newMode) => {
        if (mode === newMode) return;

        learnSituationStore.setStatementIndex(0);
        learnSituationStore.setMode(newMode);
    };

    const [removeFavDialogue, isSituationLoading] = useFetching(async () => {
        userDialoguesStore.setCurrentSituation({});
        await ProgressService.sendSituationProgress({
            situationId: situation.id,
            lang,
            favourite: false,
            ...FinishedDialogueProgress,
        });
        userStore.removeFavDialogue({
            ...situation,
            ...FinishedDialogueProgress,
        });
        userStore.setActiveChapter({});
        userDialoguesStore.setCurrentSituation({});
    });

    useEffect(() => {
        setIsSettings(false);
    }, [situation]);

    if (isLoading)
        return (
            <div className={cl.situationParamsContainer}>
                <Loader style={{ margin: 'auto', height: '100%' }} />
            </div>
        );

    return (
        <div className={cl.situationParamsContainer}>
            <div>
                <p className={cl.title}>{situation.title}</p>
                <div className={cl.situationParams}>
                    <p>{situation.categoryTitle}</p>
                    <p>
                        {`${situation.level[0]}-${situation.level.slice(-1)}`}
                    </p>
                    <p>
                        {t(`user_view.dialogue_tone_${modality.toLowerCase()}`)}
                    </p>
                </div>
                <MicroButton
                    className={cl.settingsButton}
                    size={'regular'}
                    variant={'grey'}
                    icon={<IconSettings className={cl.iconSettings} />}
                    onClick={toggleSettings}
                />
                <div
                    className={`${cl.settingsContainer} ${isSettings ? '' : cl.hidden}`}
                >
                    <div className={cl.settingsInner}>
                        <div>
                            <Label text={t('user_view.your_voice')} />
                            <div className={cl.packSizeSelector}>
                                <ToggleButton
                                    value={gender}
                                    onChange={learnSituationStore.setGender}
                                    options={GENDERS}
                                    isGrid
                                    variant={'filled'}
                                />
                            </div>
                        </div>
                        <div>
                            <Label
                                text={t('glossary_settings.playback_speed')}
                            />
                            <FilledRange
                                value={playbackSpeed}
                                min={MediaPlayBackOptions[0]}
                                step={
                                    MediaPlayBackOptions[1] -
                                    MediaPlayBackOptions[0]
                                }
                                max={
                                    MediaPlayBackOptions[
                                        MediaPlayBackOptions.length - 1
                                    ]
                                }
                                color={`var(--orange)`}
                                onChange={handlePlaybackChange}
                            />
                        </div>
                    </div>
                    <CloseButton
                        top={16}
                        right={16}
                        onClick={() => setIsSettings(false)}
                    />
                </div>
            </div>

            <div className={cl.modesContainer}>
                <p>{t('user_view.select_dialogue_mode')} </p>
                <div className={cl.modesList}>
                    {LearningModes.map((m) => (
                        <Button
                            style={getModeStyle(m)}
                            variant={'lightOrange'}
                            className={cl.mode}
                            key={m}
                            onClick={() => handleModeChange(m)}
                            text={t(`learn_dialogue.${m}`)}
                            icon={<IconArrow className={cl.modeArrow} />}
                        />
                    ))}
                </div>
            </div>

            <Button
                variant={'grey'}
                className={`${cl.completeButton} ${isHistory ? cl.hidden : ''}`}
                text={t(
                    isHistory
                        ? 'user_view.dialogue_completed'
                        : 'user_view.mark_dialogue_as_complete'
                )}
                onClick={removeFavDialogue}
                isLoading={isSituationLoading}
            />
        </div>
    );
};

export default observer(SelectLearnSituationParams);
