import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import exercisesStore from '../../store/exercisesStore';
import languagesStore from '../../../../store/interface';
import lessonsStore from '../../../../features/LessonsKanban/store/lessonsStore';
import {
    onboardingStore,
    OnboardingKeys,
} from '../../../../features/Onboarding';
import UserService from '../../../../api/UserService';
import ProgressService from '../../../../api/ProgressService';
import { LanguageLevels } from '../../../../data/common';
import { Button, Input } from '../../../../UI';
import { ReactComponent as IconClose } from '../../../../assets/svg/lessons-cross_white.svg';
import { ReactComponent as IconFilter } from '../../../../assets/svg/lessons-filter.svg';
import { MockOnboardingStudentId } from '../../../TeacherStudents/data';
import cl from './StudentsSelect.module.css';

const StudentsSelect = ({ withReset }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;

    const ref = useRef();

    const { currentStudent } = exercisesStore;
    const { filterByStudent } = lessonsStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.StudentSelect
    );

    const [search, setSearch] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [studentOptions, setStudentsOptions] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const [getStudents] = useFetching(async () => {
        if (isOnboarding) return;
        const { data } = await UserService.getStudents();
        const options = data.items.map((s) => ({
            id: s.id,
            name: s.name?.length ? s.name : t('exercises.unnamed_student'),
            level: LanguageLevels[s.level],
            nativeLang: s.nativeLang,
            targetLang: s.lang,
        }));

        setStudentsOptions(options.filter((o) => o.targetLang === lang));
    });

    const [getStudentChapters, chaptersLoading] = useFetching(async () => {
        const userId = currentStudent?.id;

        const { data: trackData } = await ProgressService.getStudentTracks({
            userId,
            lang,
        });

        const { data: exerciseData } =
            await ProgressService.getStudentExercises({
                userId,
                lang,
            });

        const { data: situationData } =
            await ProgressService.getStudentSituations({
                userId,
                lang,
            });

        const { data: wordData } = await ProgressService.getStudentDictionaries(
            {
                userId,
                lang,
            }
        );

        const { data: lessonsData } = await ProgressService.getStudentLessons({
            userId,
            lang,
            status: 'unfinished',
        });

        exercisesStore.setCurrentStudentChapters({
            situations: situationData?.items ?? [],
            tracks: trackData?.items ?? [],
            exercises: exerciseData?.items ?? [],
            dictionaries: wordData?.items ?? [],
            words: [],
        });
        lessonsStore.setCurrentStudentLessons(lessonsData?.items ?? []);
    });

    const handleOptionClick = (sObj) => {
        exercisesStore.setCurrentStudent(sObj);
        if (withReset) {
            lessonsStore.setIsResetStudent(true);
        } else {
            setTimeout(() => {
                lessonsStore.setDifficulty(sObj.level);
            });
        }
        if (onboardingStore.isOnboarding(OnboardingKeys.AwaitStudentSelect)) {
            onboardingStore.moveToNextComponent();
        }
        setExpanded(false);
    };

    const handleResetClick = (e) => {
        e.stopPropagation();
        if (!currentStudent?.id) return;
        exercisesStore.setCurrentStudent(null);
        lessonsStore.setCurrentStudentLessons([]);
        setExpanded(false);
    };

    const handleFilterClick = (e) => {
        e.stopPropagation();
        lessonsStore.toggleFilterByStudent();
    };

    const getDisplayedValue = () => {
        if (currentStudent?.id) {
            const current =
                studentOptions?.find((s) => s.id === currentStudent?.id) ?? {};
            return (
                <>
                    <p className={cl.name}>{current.name}</p>

                    <div
                        className={cl.filterButton}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Button
                            variant={'dark'}
                            size={'tiny'}
                            icon={<IconClose className={cl.iconClose} />}
                            onClick={handleResetClick}
                        />
                    </div>
                    {!withReset && (
                        <div
                            className={cl.filterButton}
                            style={{ marginLeft: 6 }}
                        >
                            <Button
                                size={'tiny'}
                                variant={filterByStudent ? 'purple' : 'grey'}
                                icon={
                                    <IconFilter
                                        className={
                                            filterByStudent
                                                ? cl.white
                                                : cl.black
                                        }
                                    />
                                }
                                onClick={handleFilterClick}
                            />
                        </div>
                    )}
                </>
            );
        }
        return t('exercises.select_student');
    };

    const getClassName = (sObj) => {
        const classNames = [cl.optionCont];
        const { difficulty, lang } = exercisesStore;

        if (currentStudent?.id === sObj.id) classNames.push(cl.active);

        if (difficulty !== sObj.level || lang !== sObj.targetLang) {
            classNames.push(cl.wrong);
        }

        return classNames.join(' ');
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        getStudents();
    }, []);

    useEffect(() => {
        if (isOnboarding) {
            setExpanded(true);
            setStudentsOptions([
                {
                    id: MockOnboardingStudentId,
                    name: t('teacher_onboarding.temporary_student_name'),
                    level: 'A1',
                    nativeLang:
                        nativeLang === lang
                            ? lang === 'en'
                                ? 'de'
                                : 'en'
                            : nativeLang,
                    targetLang: lang,
                },
            ]);
        }
    }, [isOnboarding]);

    useEffect(() => {
        if (currentStudent) {
            getStudentChapters();
        }
    }, [currentStudent]);

    useEffect(() => {
        setFiltered(
            search
                ? studentOptions.filter((s) =>
                      s.name?.toLowerCase()?.includes(search.toLowerCase())
                  )
                : studentOptions
        );
    }, [studentOptions, search]);

    useEffect(() => {
        if (!currentStudent && !chaptersLoading) {
            exercisesStore.setCurrentStudentChapters({});
            lessonsStore.setCurrentStudentLessons([]);
        }
    }, [chaptersLoading, currentStudent]);

    useEffect(() => setSearch(''), [expanded]);

    useEffect(() => {
        lessonsStore.setCurrentStudentLessonsLoading(chaptersLoading);
    }, [chaptersLoading]);

    return (
        <div
            className={`${cl.studentsSelect} ${currentStudent ? cl.active : ''}`}
            onClick={() => setExpanded(!expanded)}
            ref={ref}
        >
            {!expanded ? (
                <>
                    <div className={cl.arrowDown} />
                    {
                        <div
                            className={cl.optionCont}
                            style={{ background: 'none', padding: 0 }}
                        >
                            {getDisplayedValue()}
                        </div>
                    }
                </>
            ) : (
                <>
                    <div className={cl.arrowUp} />
                    <div
                        className={cl.optionCont}
                        style={{ background: 'none', padding: 0 }}
                    >
                        {getDisplayedValue()}
                    </div>
                    {studentOptions.length ? (
                        <div className={cl.options}>
                            <Input
                                autoFocus
                                value={search}
                                onChange={setSearch}
                                variant={'filled'}
                                stopPropagation
                                placeholder={t('library.search')}
                            />
                            {filtered.map((sObj) => (
                                <div
                                    className={getClassName(sObj)}
                                    key={sObj.id}
                                    onClick={() => handleOptionClick(sObj)}
                                >
                                    <p>{sObj.name}</p>
                                    <div className={cl.level}>
                                        {sObj.level ?? '?'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p
                            className={cl.emptyList}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {t('exercises.empty_students_list')}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default observer(StudentsSelect);
