import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconClose } from '../../assets/svg/lessons-cross_white.svg';
import { Button, Tooltip } from '../';
import { ExerciseIcon } from '../../pages/TeacherContent/views/ExercisesView/components';
import cl from './Select.module.css';

const OptionsBottomOffset = 4;

const Select = ({
    value,
    variant,
    size,
    label,
    options = [],
    onChange,
    style,
    height,
    hideReset,
    ignoreOverflow,
    className,
    disabled,
    icon,
    tooltip,
    withExerciseIcons = false,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [displayAbove, setDisplayAbove] = useState(false);

    const ref = useRef();

    function handleSelectClick(e) {
        const elClasses = e.target.classList;
        if (
            (!elClasses.contains(cl.primarySelect) &&
                !elClasses.contains(cl.arrowUp) &&
                !elClasses.contains(cl.arrowDown) &&
                !elClasses.contains(cl.value)) ||
            !options?.length ||
            disabled
        ) {
            return;
        }
        setExpanded(!expanded);
    }

    const handleOptionClick = (value) => {
        onChange(value);
        setExpanded(false);
    };

    const getActive = () => options.find((o) => o.value === value);

    const handleResetButton = (e) => {
        e.stopPropagation();
        setExpanded(false);
        onChange('');
    };

    const renderResetButton = () => {
        if (!getActive() || hideReset) return null;
        return (
            <div className={cl.resetButton}>
                <Button
                    variant={'dark'}
                    size={'tiny'}
                    icon={<IconClose style={{ width: 22 }} />}
                    onClick={handleResetButton}
                />
            </div>
        );
    };

    const getDisplayedValue = () => {
        if (!options.length) <div className={cl.value}>{''}</div>;
        if (expanded) return <div className={cl.value}>{label}</div>;

        const current = getActive();
        return current?.name ? (
            <div className={`${cl.value} ${cl.active}`}>{current.name}</div>
        ) : (
            <div className={cl.value}>{label}</div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        if (expanded) {
            const rect = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const optionsHeight = height || 200;

            setDisplayAbove(rect.bottom + optionsHeight > windowHeight);

            if (ignoreOverflow) {
                const options = ref.current.querySelector(`.${cl.options}`);
                if (options) {
                    options.style.position = 'fixed';
                    options.style.top = displayAbove
                        ? `${rect.top - optionsHeight}px`
                        : `${rect.bottom + OptionsBottomOffset}px`;
                    options.style.left = `${rect.left}px`;
                    options.style.width = `${rect.width}px`;
                    options.style.zIndex = 1000;
                }
            }
        }
    }, [expanded, height, ignoreOverflow, displayAbove]);

    return (
        <div
            className={`${cl.primarySelect} ${expanded ? cl.expanded : ''} ${
                cl[variant]
            } ${size ? cl[size] : ''} ${!options?.length ? cl.disabled : ''} ${
                disabled ? cl.disabled : ''
            } ${className ? className : ''}`}
            style={style}
            onClick={handleSelectClick}
            ref={ref}
        >
            {icon}
            {getDisplayedValue()}
            {renderResetButton()}
            {!expanded ? (
                <div className={cl.arrowDown} />
            ) : (
                <>
                    <div className={cl.arrowUp} />
                    <div
                        className={`${cl.options} ${displayAbove ? cl.up : cl.down}`}
                        style={height ? { height } : {}}
                    >
                        {options.map((o, i) => (
                            <div
                                className={cl.optionCont}
                                key={o.value}
                                onClick={() => handleOptionClick(o.value)}
                            >
                                {withExerciseIcons ? (
                                    <ExerciseIcon
                                        type={o.value}
                                        additionalClass={cl.exerciseIcon}
                                    />
                                ) : null}
                                <p
                                    className={`${cl.option} ${
                                        value === o.value ? cl.active : ''
                                    }`}
                                >
                                    {o.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {disabled && tooltip && (
                <Tooltip
                    className={cl.tooltip}
                    variant={'warning'}
                    text={tooltip}
                    tailPosition={'bottom'}
                />
            )}
        </div>
    );
};

export default Select;
