import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import dictionaryStore from '../../store/dictionaryStore';
import { useTranslation } from 'react-i18next';
import DictionaryService from '../../../../api/DictionaryService';
import LANGUAGE_OPTIONS from '../../helpers/languageOptions';
import {
    FileUploader,
    LabeledSelect,
    Loader,
    PrimaryButton,
} from '../../../../UI';
import { ChunkSize, DictionaryViews } from '../../data/constants';
import { FileTypes } from '../../../../data/common';
import cl from './LoadFile.module.css';

const reader = new FileReader();

const LoadFile = () => {
    const { t } = useTranslation();

    const [modal, setModal] = useState(false);
    const [status, setStatus] = useState('');

    const { endOfFile, fileLanguage, processedCount, totalCount } =
        dictionaryStore;

    const processFile = (file) => {
        let abortProcess = false;

        if (!file) return;

        const abort = () => {
            abortProcess = true;
        };

        reader.onload = async (e) => {
            const words = e.target.result.trim().split('\n');
            dictionaryStore.setTotalCount(words.length);

            const chunk = words.slice(0, ChunkSize);
            await processChunk(chunk);
            dictionaryStore.incrementProcessedCount(chunk.length);

            const remainingWords = words.slice(ChunkSize);
            if (remainingWords.length > 0 && !abortProcess) {
                setTimeout(() => readNextChunk(remainingWords), 0);
            } else {
                abortProcess
                    ? dictionaryStore.abortFileLoading()
                    : dictionaryStore.setEndOfFile(true);
            }
        };

        const readNextChunk = async (words) => {
            const nextChunk = words.slice(0, ChunkSize);
            await processChunk(nextChunk);
            dictionaryStore.incrementProcessedCount(nextChunk.length);

            const remainingWords = words.slice(ChunkSize);
            if (remainingWords.length > 0 && !abortProcess) {
                setTimeout(() => readNextChunk(remainingWords), 0);
            } else {
                abortProcess
                    ? dictionaryStore.abortFileLoading()
                    : dictionaryStore.setEndOfFile(true);
                setStatus('');
            }
        };

        const processChunk = async (words) => {
            const message = await DictionaryService.addWordsBatch({
                words,
                sourceLang: fileLanguage,
            });
            setStatus(message);
        };
        reader.readAsText(file);

        return abort;
    };

    const handleFileSave = (file) => {
        const callback = processFile(file);
        dictionaryStore.setAbortCallback(() => callback);
    };

    const openCurrentQueue = () => {
        dictionaryStore.setLanguage(fileLanguage);
        dictionaryStore.setView(DictionaryViews.queue);
        dictionaryStore.abortFileLoading();
    };

    return (
        <>
            <FileUploader
                type={FileTypes.Text}
                visible={modal}
                setVisible={setModal}
                onSave={handleFileSave}
            />
            {totalCount === 0 ? (
                <div className={cl.controls}>
                    <LabeledSelect
                        label={t('dictionary_administration.source_lang')}
                        value={fileLanguage}
                        useNames={true}
                        isMultiSelect={false}
                        onChange={dictionaryStore.setFileLanguage}
                        langsSelector={true}
                        options={LANGUAGE_OPTIONS}
                        style={{ width: '100%', minWidth: 150 }}
                    />
                    <PrimaryButton
                        text={t('dictionary_administration.select_file')}
                        variant={'dark'}
                        onClick={() => setModal(true)}
                    />
                </div>
            ) : endOfFile ? (
                <div className={cl.loadingInfo}>
                    <p>{t('dictionary_administration.finish_alert')}</p>
                    <p>{`${t(
                        'dictionary_administration.total_loaded'
                    )}: ${totalCount}`}</p>
                    <p>{t('dictionary_administration.finish_subalert')}</p>
                    <div className={cl.buttons}>
                        <PrimaryButton
                            text={t('buttons.close')}
                            onClick={dictionaryStore.abortFileLoading}
                        />
                        <PrimaryButton
                            text={t('dictionary_administration.queue_btn')}
                            variant={'dark'}
                            onClick={openCurrentQueue}
                        />
                    </div>
                </div>
            ) : (
                <div className={cl.loadingInfo}>
                    <p>{t('dictionary_administration.loading_process')}</p>
                    <Loader style={{ opacity: 0.5 }} />
                    <p>{`${t(
                        'dictionary_administration.processed'
                    )}: ${processedCount}/${totalCount}`}</p>
                    {status && (
                        <p>{`${t('dictionary_administration.chunk_state')}: ${status}`}</p>
                    )}
                    <PrimaryButton
                        text={t('dictionary_administration.abort')}
                        variant={'red'}
                        onClick={dictionaryStore.abortFileLoading}
                    />
                </div>
            )}
        </>
    );
};

export default observer(LoadFile);
