import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AddChapterInfo } from '..';
import { CloseButton, Modal } from '../../../../UI';
import { iconMap, titleMap } from '../../views/ExercisesView/data/constants';
import cl from './AddChapterModal.module.css';

const AddChapterModal = ({
    visible,
    setVisible,
    isOwn = false,
    type,
    children,
}) => {
    const { t } = useTranslation();
    const renderTitle = () => {
        const title = isOwn
            ? titleMap[type]
            : `teacher_onboarding.select_${type}`;

        return (
            <div className={cl.titleWithIcon}>
                {iconMap[type]}
                {t(title)}
            </div>
        );
    };

    return (
        <Modal className={cl.modal} visible={visible} setVisible={setVisible}>
            <div className={cl.modalHeader}>
                {renderTitle()}
                <CloseButton onClick={() => setVisible(false)} />
            </div>
            {children}
            {visible && <AddChapterInfo type={type} />}
        </Modal>
    );
};

export default observer(AddChapterModal);
