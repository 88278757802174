import { PartsOfSpeech } from '../../../../../features/LessonsKanban/data/constants';

export const DictionaryCodes = {
    NotFound: '044',
    InvalidWord: '045',
    NoTranslation: '046',
    TranslationError: '048',
    Ok: 200,
    Created: 201,
    TooEarly: 425,
};

export const DefaultWordsParams = {
    title: '',
    topic: '',
    level: '',
    wordsAmount: 5,
    partOfSpeech: PartsOfSpeech.Any,
};

export const WordsRange = {
    Min: 5,
    Max: 25,
    Step: 1,
};

export const WordStatuses = {
    Queued: 'queued',
    Pending: 'pending',
    Ready: 'ready',
    Invalid: 'invalid',
};

export const ServerWordStatuses = {
    Ok: 'ok',
};

export const CheckWordStatusInterval = 5000;

export const ProcessedWordsKey = 'dictionaryProcessedWords';

export const UserWordsCategory = 'userWordsCategory';

export const DictionaryServerErrors = {
    NotFound: 'not_found',
    InvalidWord: 'invalid',
    TranslationMissing: 'translation_missing',
    Generating: 'generating',
    TranslationFailed: 'translation_failed',
};

export const mapDictionaryServerErrors = {
    '044': DictionaryServerErrors.NotFound,
    '045': DictionaryServerErrors.InvalidWord,
    '046': DictionaryServerErrors.TranslationMissing,
    '047': DictionaryServerErrors.Generating,
    '048': DictionaryServerErrors.TranslationFailed,
};
