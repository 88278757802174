import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CloseButton, Modal } from '../../../../UI';
import cl from './DeleteConfirmation.module.css';

const DeleteConfirmation = ({
    visible,
    title,
    subtitle,
    setVisible,
    onReset,
    onDelete,
    isSave,
    style,
    variant,
}) => {
    const { t } = useTranslation();

    const closeModal = () => setVisible(false);

    return (
        <Modal
            className={cl.confirmModal}
            visible={visible}
            setVisible={setVisible}
            style={style}
        >
            <p className={cl.title}>{title}</p>
            <p className={cl.subtitle}>
                {subtitle ||
                    t(
                        isSave
                            ? 'exercises.update_exercise_subtitle'
                            : 'exercises.delete_lesson_confirmation_subtitle'
                    )}
            </p>
            <div className={cl.buttons}>
                <Button
                    text={t(isSave ? 'buttons.save' : 'buttons.delete')}
                    style={{ marginRight: 'auto' }}
                    variant={variant || (isSave ? 'dark' : 'red')}
                    onClick={onDelete}
                />
                {onReset && (
                    <Button
                        text={t('buttons.reset')}
                        variant={'red'}
                        onClick={onReset}
                    />
                )}
                <Button
                    text={t('buttons.cancel')}
                    variant={'grey'}
                    onClick={closeModal}
                />
            </div>
            <CloseButton onClick={closeModal} />
        </Modal>
    );
};

export default DeleteConfirmation;
