import axios from 'axios';

const URL = '/v1/exercises';

export default class ExerciseService {
    static async getExercises({
        offset,
        limit,
        searchString,
        lang,
        type,
        mediaType,
        difficulty,
    }) {
        const params = new URLSearchParams({ offset, limit });

        const optional = {
            searchString,
            lang,
            type,
            mediaType,
            difficulty,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const exercises = await axios.get(
            `${URL}/exercises?${params.toString()}`
        );

        return exercises;
    }

    static async getExercise({ id }) {
        const exercise = await axios.get(`${URL}/exercises/${id}`);
        return exercise;
    }

    static async updateExercise({
        id,
        title,
        data,
        description,
        difficulty,
        categoryId,
        text,
    }) {
        const body = { id, description };

        const optional = {
            title,
            data,
            difficulty,
            categoryId,
            text,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                body[param] = optional[param];
            }
        });

        const response = await axios.patch(`${URL}/exercises/${id}`, body);

        return response;
    }

    static async createExercise({
        type,
        mediaType,
        prompt,
        grammarTopic,
        vocabularyTopic,
        grammarRule,
        commonMistakes,
        text,
        sentencesAmount,
        difficulty,
        lang,
        trackId,
        trackInterval,
        vocabulary,
        title,
        description,
        voiceId,
    }) {
        const response = await axios.post(`${URL}/exercises/generate`, {
            type,
            mediaType,
            prompt,
            grammarTopic,
            grammarRule,
            commonMistakes,
            vocabularyTopic,
            text,
            sentencesAmount,
            difficulty,
            lang,
            trackId,
            trackInterval,
            vocabulary,
            title,
            description,
            voiceId,
        });
        return response;
    }

    static async generateText({
        grammarTopic,
        grammarRule,
        textTopic,
        genre,
        lang,
        difficulty,
        vocabulary,
        sentencesAmount,
    }) {
        const response = await axios.post(`${URL}/exercises/generate-text`, {
            grammarTopic,
            grammarRule,
            textTopic,
            genre,
            lang,
            difficulty,
            vocabulary,
            sentencesAmount,
        });

        return response;
    }

    static async generateWords({
        topic,
        difficulty,
        lang,
        wordsAmount,
        partOfSpeech,
    }) {
        const response = await axios.post(`${URL}/exercises/generate-words`, {
            topic,
            difficulty,
            lang,
            wordsAmount,
            partOfSpeech,
        });

        return response;
    }

    static async keepExercise({ id }) {
        const response = await axios.post(`${URL}/exercises/keep/${id}`, {});

        return response;
    }

    static async cloneExercise({ id }) {
        const response = await axios.post(`${URL}/exercises/clone/${id}`, {});

        return response;
    }

    static async deleteExercise({ id }) {
        const response = await axios.delete(`${URL}/exercises/${id}`);
        return response;
    }

    static async removeExercise({ id }) {
        const response = await axios.delete(`${URL}/exercises/remove/${id}`);

        return response;
    }

    static async getSavedExercises({
        offset,
        limit,
        searchString,
        lang,
        type,
        mediaType,
        difficulty,
        unassigned,
    }) {
        const params = new URLSearchParams({ offset, limit, lang });

        const optional = {
            searchString,
            type,
            mediaType,
            difficulty,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        if (unassigned) {
            params.append('unassigned', unassigned);
        }

        const exercises = await axios.get(
            `${URL}/exercises/get-saved?${params.toString()}`
        );
        return exercises;
    }

    static async getLessons({
        offset,
        limit,
        searchString,
        lang,
        grammarTopic,
        vocabularyTopic,
        basic,
    }) {
        const params = new URLSearchParams({ offset, limit, lang });

        const optional = {
            searchString,
            grammarTopic,
            vocabularyTopic,
            basic,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const lessons = await axios.get(`${URL}/lessons?${params.toString()}`);

        return lessons;
    }

    static async getSavedLessons({ offset, limit, searchString, lang }) {
        const params = new URLSearchParams({ offset, limit, lang });

        const optional = {
            searchString,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const lessons = await axios.get(
            `${URL}/lessons/get-saved?${params.toString()}`
        );

        return lessons;
    }

    static async getLesson({ id }) {
        const response = await axios.get(`${URL}/lessons/${id}`);
        return response;
    }

    static async createLesson({
        title,
        lang,
        description,
        exam,
        file,
        difficulty,
        order,
        age,
        isPrivate,
        grammarTopic,
        vocabularyTopic,
    }) {
        const response = await axios.post(`${URL}/lessons`, {
            title,
            lang,
            description,
            exam,
            file,
            order,
            difficulty,
            age,
            isPrivate,
            grammarTopic,
            vocabularyTopic,
        });
        return response;
    }

    static async keepLesson({ id }) {
        const response = await axios.post(`${URL}/lessons/keep/${id}`, {});

        return response;
    }

    static async updateLesson({ id, picture, pdf, ...params }) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (value !== null && value !== undefined && value !== '') {
                formData.append(key, value);
            }
        }
        if (picture) {
            formData.append('pictures', picture, picture.name);
            formData.append('pictures', new Blob(), 'eof');
        }
        if (pdf) {
            formData.append('pdfs', pdf, pdf.name);
            formData.append('pdfs', new Blob(), 'eof');
        }

        const response = await axios.patch(`${URL}/lessons/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response;
    }

    static async updateLessonOrder({ id, order }) {
        const response = await axios.patch(
            `${URL}/lessons/${id}/update-order`,
            {
                order,
            }
        );

        return response;
    }

    static async deleteLesson({ id }) {
        const response = await axios.delete(`${URL}/lessons/${id}`);

        return response;
    }

    static async removeLessonFromSaved({ id }) {
        const response = await axios.delete(`${URL}/lessons/remove/${id}`);

        return response;
    }

    static async addChapterToLesson({
        lessonId,
        chapterId,
        chapterType,
        order,
        title,
    }) {
        const response = await axios.post(
            `${URL}/lessons/${lessonId}/add-chapter`,
            {
                chapterId,
                chapterType,
                order,
                title,
            }
        );
        return response;
    }

    static async deleteChapterFromLesson({ lessonId, chapterId }) {
        const response = await axios.delete(
            `${URL}/lessons/${lessonId}/remove-chapter`,
            {
                data: {
                    chapterId,
                },
            }
        );
        return response;
    }

    static async getTeacherLessons({ id }) {
        const lessons = await axios.get(`${URL}/lessons/teacher-lessons/${id}`);

        return lessons;
    }

    static async cloneLesson({ id }) {
        const lessons = await axios.post(`${URL}/lessons/clone/${id}`);

        return lessons;
    }

    static async verifyExercise({ id, verified }) {
        const response = await axios.patch(`${URL}/exercises/verify/${id}`, {
            verified,
        });

        return response;
    }

    static async getTeachers({
        lang,
        difficulty,
        age,
        searchString,
        teacherSearchString,
    }) {
        const params = new URLSearchParams({ lang });

        const optional = {
            difficulty,
            age,
            searchString,
            teacherSearchString,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const teachers = await axios.get(
            `${URL}/lessons/teachers?${params.toString()}`
        );

        return teachers;
    }

    static async getGrammarOptions({ lang, searchString }) {
        let query = `?lang=${lang}`;
        if (searchString) query += `&searchString=${searchString}`;
        const lessons = await axios.get(`${URL}/grammar${query}`);

        return lessons;
    }

    static async getVocabularyOptions({ lang, searchString }) {
        let query = `?lang=${lang}`;
        if (searchString) query += `&searchString=${searchString}`;
        const lessons = await axios.get(`${URL}/vocabulary${query}`);

        return lessons;
    }
}
