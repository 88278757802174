import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import lessonsStore from '../../store/lessonsStore';
import wizardStore from './store/wizardStore';
import { Button, CloseButton, Loader, Modal } from '../../../../UI';
import {
    AddChapter,
    CreateLesson,
    DialogueWizard,
    ExerciseWizard,
    VideoWizard,
    WordPackWizard,
} from './components';
import { Chapters } from '../../data/constants';
import { ExerciseTypes } from './components/CreateLesson/CreateLesson';
import { ReactComponent as IconAdd } from '../../../../assets/svg/lessons-add.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import { GrammarExerciseTypes } from '../../../../pages/TeacherContent/data/constants';
import cl from './LessonWizard.module.css';

export const WizardViews = {
    CreateLesson: 'lesson',
    WordPack: Chapters.Dictionary,
    Exercise: Chapters.Exercise,
    Video: Chapters.Track,
    Dialogue: Chapters.Situation,
    AddChapter: 'add_chapter',
};

const WizardComponentMap = {
    [WizardViews.CreateLesson]: CreateLesson,
    [WizardViews.WordPack]: WordPackWizard,
    [WizardViews.Video]: VideoWizard,
    [WizardViews.Dialogue]: DialogueWizard,
    [WizardViews.Exercise]: ExerciseWizard,
    [WizardViews.AddChapter]: AddChapter,
};

export const WizardSteps = [
    {
        view: WizardViews.CreateLesson,
        steps: ['create'],
    },
    {
        view: WizardViews.WordPack,
        steps: ['select', 'generate'],
    },
    {
        view: WizardViews.Exercise,
        steps: ['select', 'type', 'kind', 'source', 'generate'],
    },
    {
        view: WizardViews.Video,
        steps: ['select'],
    },
    {
        view: WizardViews.Dialogue,
        steps: ['select', 'generate'],
    },
    {
        view: WizardViews.AddChapter,
        steps: ['select'],
    },
];

const LessonWizard = () => {
    const { t } = useTranslation();

    const {
        currentViewObj,
        currentStepIndex,
        isWizardVisible,
        exerciseType,
        exerciseKind,
    } = wizardStore;
    const { currentLesson, isClonePreview } = lessonsStore;

    const currentStep = currentViewObj.steps[currentStepIndex];

    const [addChapter, isLoading] = useFetching(
        async ({ chapterId, chapterType, title }) => {
            wizardStore.setWizardVisible(false);
            await ExerciseService.addChapterToLesson({
                title,
                lessonId: currentLesson.id,
                chapterId,
                chapterType,
                order: 0,
            });

            const { data } = await ExerciseService.getLesson({
                id: currentLesson.id,
            });

            lessonsStore.updateLesson({
                ...currentLesson,
                chapters: data.chapters,
            });

            const newChapter = data.chapters.find((c) => c.id === chapterId);
            lessonsStore.setCurrentChapter(newChapter);
        }
    );

    const getTitle = () => {
        if (currentViewObj.view === WizardViews.Exercise) {
            if (['source', 'generate'].includes(currentStep)) {
                return `${t(`lesson_wizard.${exerciseType}_exercise`)} / ${t(`exercises.${exerciseKind}`)}: ${t(`lesson_wizard.exercise_${currentStep}`)}`;
            }
            if (currentStep === 'kind') {
                return t(
                    `lesson_wizard.${exerciseType}_${currentViewObj.view}_${currentStep}_title`
                );
            }
        }
        return t(`lesson_wizard.${currentViewObj.view}_${currentStep}_title`);
    };

    const renderTitle = () => {
        const isFirst = ['select', 'create'].includes(currentStep);

        return (
            <p
                className={`${isFirst ? cl.title : cl.shiftedTitle} ${cl[currentViewObj.view]}`}
            >
                {getTitle()}
            </p>
        );
    };

    const renderCurrentStep = () => {
        if (isLoading) return <Loader style={{ margin: 'auto' }} />;
        const Component = WizardComponentMap[currentViewObj.view];
        return Component ? <Component onAdd={addChapter} /> : null;
    };
    const handleBackPageArrow = () => {
        if (
            currentViewObj.view === WizardViews.Exercise &&
            exerciseType === ExerciseTypes.Grammar &&
            exerciseKind === GrammarExerciseTypes.StatementsTransformation
        ) {
            wizardStore.prevStep();
        }
        wizardStore.prevStep();
    };

    const renderBackArrow = () => {
        const isFirst = ['select', 'create'].includes(currentStep);

        if (isFirst) return null;
        return (
            <IconArrow className={cl.backArrow} onClick={handleBackPageArrow} />
        );
    };
    return (
        <>
            <Button
                text={t('exercises.add_lesson')}
                onClick={() => wizardStore.setWizardVisible(true)}
                variant={'purple'}
                icon={<IconAdd className={cl.plusIcon} />}
            />
            {isWizardVisible && (
                <Modal
                    className={`${cl.wizardModal} ${isClonePreview ? cl.hidden : ''}`}
                    visible={isWizardVisible}
                    setVisible={wizardStore.setWizardVisible}
                    style={{ zIndex: 20 }}
                    hideOverlay={isClonePreview}
                >
                    {renderBackArrow()}
                    {renderTitle()}
                    <div className={cl.modalInner}>{renderCurrentStep()}</div>

                    <CloseButton
                        onClick={() => wizardStore.setWizardVisible(false)}
                    />
                </Modal>
            )}
        </>
    );
};

export default observer(LessonWizard);
