import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import { Button, CloseButton, Modal } from '../../../../../../UI';
import Lesson from '../../../Lesson/Lesson';
import { Chapters } from '../../../../data/constants';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import cl from './AvailableLessons.module.css';

const AvailableLessons = ({ currentParams, processLesson }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { lessons } = lessonsStore;

    const { difficulty, grammarTopic, vocabularyTopic } = currentParams;

    const [showAvailableLessons, setShowAvailableLessons] = useState(false);
    const [availableLessons, setAvailableLessons] = useState([]);

    const handleLessonPreview = (lObj) => {
        lessonsStore.setCurrentLesson(lObj);
        lessonsStore.setIsClonePreview(true);
    };

    const [getAvailableLessons, availableLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getLessons({
            offset: 0,
            limit: 100,
            lang,
            grammarTopic,
            vocabularyTopic,
        });

        if (!data?.items) {
            setAvailableLessons([]);
            return;
        }

        const availableLessons = await Promise.all(
            data.items.map(async (lesson) => {
                const lessonData = await ExerciseService.getLesson({
                    id: lesson.id,
                });
                return { ...lesson, ...lessonData.data };
            })
        );

        const withoutExisting = availableLessons.filter(
            (lesson) =>
                !lessons.some((existing) => existing.id === lesson.id) &&
                lesson.chapters.length
        );

        setAvailableLessons(withoutExisting);
    });

    useEffect(() => {
        if (grammarTopic || vocabularyTopic) {
            getAvailableLessons();
        }
    }, [difficulty, grammarTopic, vocabularyTopic]);

    return (
        <>
            <Modal
                className={cl.lessonsModal}
                visible={showAvailableLessons}
                setVisible={setShowAvailableLessons}
            >
                <p className={cl.title}>
                    {t('lesson_wizard.select_ready_made_lesson')}
                </p>
                <p className={cl.subtitle}>
                    {t('lesson_wizard.select_ready_made_lesson_subtitle')}
                </p>
                <div className={cl.lessonsList}>
                    {availableLessons.map((lObj) => (
                        <Lesson
                            key={lObj.id}
                            lessonObj={lObj}
                            onSelect={handleLessonPreview}
                        />
                    ))}
                </div>
                <CloseButton onClick={() => setShowAvailableLessons(false)} />
            </Modal>
            {availableLessons.length !== 0 && (
                <Button
                    icon={<IconStars />}
                    variant={Chapters.Track}
                    onClick={() => setShowAvailableLessons(true)}
                    isLoading={availableLoading}
                    text={`${t('lesson_wizard.find_lessons')} (${availableLessons.length})`}
                />
            )}
        </>
    );
};

export default observer(AvailableLessons);
