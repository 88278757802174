import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import toastStore from './store/toast';
import { CloseButton } from '../../UI';
import { ReactComponent as IconCheck } from '../../assets/svg/lessons-check.svg';
import { ReactComponent as IconError } from '../../assets/svg/icon-close.svg';
import { ReactComponent as IconWarning } from '../../assets/svg/icon-warning.svg';
import { ReactComponent as IconInfo } from '../../assets/svg/exercise-media-info.svg';
import cl from './Toast.module.css';

const icons = {
    success: <IconCheck className={cl.successIcon} />,
    error: <IconError className={cl.errorIcon} />,
    info: <IconInfo className={cl.infoIcon} />,
    warning: <IconWarning className={cl.warningIcon} />,
};

const Toast = () => {
    const { t } = useTranslation();

    return (
        <AnimatePresence>
            {toastStore.isVisible && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className={`${cl.toast} ${cl[toastStore.variant]}`}
                >
                    <div className={cl.toastContent}>
                        <div className={cl.iconContainer}>
                            {icons[toastStore.variant]}
                        </div>
                        <div className={cl.toastText}>{t(toastStore.text)}</div>
                        <CloseButton
                            onClick={toastStore.hideToast}
                            top={12}
                            right={12}
                            size={12}
                        />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default observer(Toast);
