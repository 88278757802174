import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import guestOnboarding from '../../store/guestOnboarding';
import Player from '../../../Player/Player';
import { PonyPlayer } from '../../../../features/UserPlatform/components';
import InfoModal from '../InfoModal/InfoModal';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import cl from './DemoVideoPreview.module.css';

const DemoVideoPreview = ({ video, className, isStudent }) => {
    const { t } = useTranslation();

    const onboardingKey = guestOnboarding.isOnboarding(Chapters.Track);

    return (
        <div className={`${cl.previewContainer} ${className}`}>
            {!onboardingKey &&
                (isStudent ? (
                    <PonyPlayer trackId={video.id} />
                ) : (
                    <Player track={video} isGuest />
                ))}
            <InfoModal
                delay={200}
                onboardingKey={onboardingKey}
                variant={Chapters.Track}
                title={t('teacher_tutorial.videos_info')}
                subtitle={t('teacher_tutorial.videos_info_subtitle')}
            />
        </div>
    );
};

export default observer(DemoVideoPreview);
