import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SmallLoader } from '../../../../../../UI';
import toHoursAndMinutes from '../../../../../../utils/toHoursAndMinutes';
import getTopCenterAndOffset from '../../../../../../utils/getTopCenterAndOffset';
import { ReactComponent as IconGoNext } from '../../../../../../assets/svg/player-forward.svg';
import { ReactComponent as IconGoPrev } from '../../../../../../assets/svg/player-rewind.svg';
import { ReactComponent as IconPlay } from '../../../../../../assets/svg/player-play.svg';
import { ReactComponent as IconPause } from '../../../../../../assets/svg/player-pause.svg';
import cl from './ExerciseAudioPlayer.module.css';

const ExerciseAudioPlayer = ({ id }) => {
    const { t } = useTranslation();

    const [isValidAudio, setIsValidAudio] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);

    const audioRef = useRef(null);
    const intervalRef = useRef();

    const url = `/filedump/exercises/${id}.mp3`;
    const percent = (progress / duration) * 100;

    const checkAudioAvailability = async () => {
        try {
            const response = await fetch(`${url}?nocache=${Date.now()}`, {
                method: 'GET',
            });
            if (response.ok) {
                setIsValidAudio(true);
            } else {
                setIsValidAudio(false);
            }
        } catch (error) {
            setIsValidAudio(false);
        }
    };

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        setProgress(audioRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const rewind = () => {
        audioRef.current.currentTime -= 10;
    };

    const forward = () => {
        audioRef.current.currentTime += 10;
    };

    const handleProgressChange = (e) => {
        const { clientX, target } = e;
        const { left, width } = getTopCenterAndOffset(target);

        const clickPercent = ((clientX - left) / width) * 100;
        const newTime = (duration * clickPercent) / 100;

        audioRef.current.currentTime = newTime;
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
        setProgress(0);
    };

    useEffect(() => {
        clearInterval(intervalRef.current);
        if (!isValidAudio) {
            checkAudioAvailability();
            intervalRef.current = setInterval(() => {
                checkAudioAvailability();
            }, 3000);
        }
        return () => clearInterval(intervalRef.current);
    }, [id, isValidAudio]);

    useEffect(() => {
        const audioElement = audioRef.current;
        audioElement?.addEventListener('ended', handleAudioEnd);

        return () => {
            audioElement?.removeEventListener('ended', handleAudioEnd);
        };
    }, []);

    useEffect(() => {
        setIsValidAudio(false);
    }, [id]);

    if (!isValidAudio)
        return (
            <div className={cl.loaderContainer}>
                <SmallLoader className={cl.loader} size={12} />
                <p className={cl.loaderText}>
                    {t('exercises.audio_is_not_ready')}
                </p>
            </div>
        );

    return (
        <div className={cl.controlsCont}>
            <audio
                ref={audioRef}
                src={url}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
            />
            <div className={cl.trackLineWrapper}>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(progress)}
                </span>
                <div className={cl.trackLine} onClick={handleProgressChange}>
                    <div
                        className={cl.trackProgress}
                        style={{ width: `${percent}%` }}
                    />
                    <div
                        className={cl.dragger}
                        style={{ left: `${percent}%` }}
                    />
                </div>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(duration)}
                </span>
            </div>
            <div className={cl.playPauseBtnsCont}>
                <button className={cl.button} onClick={rewind}>
                    <IconGoPrev />
                </button>
                <button className={cl.button} onClick={togglePlay}>
                    {isPlaying ? <IconPause /> : <IconPlay />}
                </button>
                <button className={cl.button} onClick={forward}>
                    <IconGoNext />
                </button>
            </div>
        </div>
    );
};

export default observer(ExerciseAudioPlayer);
