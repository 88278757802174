import React from 'react';
import { ReactComponent as IconAudio } from '../../../../../../assets/svg/exercise-media-audio.svg';
import { ReactComponent as IconGrammar } from '../../../../../../assets/svg/exercise-media-grammar.svg';
import { ReactComponent as IconInfo } from '../../../../../../assets/svg/exercise-media-info.svg';
import { ReactComponent as IconLexical } from '../../../../../../assets/svg/exercise-media-lexical.svg';
import { ReactComponent as IconText } from '../../../../../../assets/svg/exercise-media-text.svg';
import { ReactComponent as IconVideo } from '../../../../../../assets/svg/exercise-media-video.svg';
import { ExerciseMediaTypes } from '../../../../data/constants';
import cl from './ExerciseMediaIcon.module.css';

const ExerciseMediaIcon = ({ mediaType, style }) => {
    switch (mediaType) {
        case ExerciseMediaTypes.Audio:
            return <IconAudio className={cl.icon} style={style} />;
        case ExerciseMediaTypes.Grammar:
            return <IconGrammar className={cl.iconSmall} style={style} />;
        case ExerciseMediaTypes.Info:
            return <IconInfo className={cl.icon} style={style} />;
        case ExerciseMediaTypes.Lexical:
            return <IconLexical className={cl.icon} style={style} />;
        case ExerciseMediaTypes.Text:
            return <IconText className={cl.icon} style={style} />;
        case ExerciseMediaTypes.Video:
            return <IconVideo className={cl.iconSmall} style={style} />;
        default:
            return null;
    }
};

export default ExerciseMediaIcon;
