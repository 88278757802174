import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { motion, useInView } from 'framer-motion';
import { Typography } from '..';
import { ReactComponent as Background } from '../../../../assets/svg/teacher_tutorial_background.svg';
import LanguagesBar from '../LanguagesBar/LanguagesBar';
import cl from './FirstSection.module.css';

const FirstSection = () => {
    const { t } = useTranslation();
    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, { once: true, margin: '-100px' });

    return (
        <motion.div
            ref={sectionRef}
            className={cl.firstSection}
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: 'easeOut' }}
        >
            <motion.div
                className={cl.backgroundContainer}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={isInView ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.9, ease: 'easeOut' }}
            >
                <Background className={cl.backgroundImage} />
            </motion.div>

            <div className={cl.sectionInner}>
                <motion.div
                    initial={{ opacity: 0, y: 70 }}
                    animate={isInView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.7, ease: 'easeOut', delay: 0.2 }}
                >
                    <Typography
                        variant="h1"
                        className={cl.mainTitle}
                        dangerouslySetInnerHTML={t('teacher_tutorial.title')}
                    />
                </motion.div>

                <motion.div
                    className={cl.subTitleSection}
                    initial={{ opacity: 0, y: 70 }}
                    animate={isInView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.7, ease: 'easeOut', delay: 0.4 }}
                >
                    <div className={cl.subTitleSelection}>
                        <p>{t('teacher_tutorial.sub_title')}</p>
                        <p className={cl.selected}>A1 - C1</p>
                    </div>
                </motion.div>
            </div>

            <motion.div
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.7, ease: 'easeOut', delay: 0.6 }}
            >
                <LanguagesBar />
            </motion.div>
        </motion.div>
    );
};

export default observer(FirstSection);
