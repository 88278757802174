import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import ProgressService from '../../../../api/ProgressService';
import TrackService from '../../../../api/TrackService';
import { authStore } from '../../../../App';
import exercisesPlayer from '../../../../pages/TeacherContent/store/exercisesPlayer';
import userStore from '../../../UserPlatform/store/userStore';
import {
    ExerciseAudioPlayer,
    ExercisePlayer,
    ExercisePlayerControls,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { Button, Loader } from '../../../../UI';
import UserSummaryPreview from '../UserSummaryPreview/UserSummaryPreview';
import UserSentenceOrderPreview from '../UserSentenceOrderPreview/UserSentenceOrderPreview';
import UserAdvancedQuizPreview from '../UserAdvancedQuizPreview/UserAdvancedQuizPreview';
import UserSubstitutionPreview from '../UserSubstitutionPreview/UserSubstitutionPreview';
import UserQuizPreview from '../UserQuizPreview/UserQuizPreview';
import UserWordsOrderPreview from '../UserWordsOrderPreview/UserWordsOrderPreview';
import UserMultichoicePreview from '../UserMultichoicePreview/UserMultichoicePreview';
import UserClozePreview from '../UserClozePreview/UserClozePreview';
import UserFreeFormQuestionsPreview from '../UserFreeFormQuestionsPreview/UserFreeFormQuestionsPreview';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { deepEqual } from '../../../../utils/deepEqual';
import { TicksInSecond } from '../../../../data/common';
import { StudentExerciseStatuses } from '../../../LessonsKanban/data/constants';
import {
    ExerciseMediaTypes,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../pages/TeacherContent/data/constants';
import cl from './UserExercisePreview.module.css';

const mapExerciseComponents = {
    [MediaExerciseTypes.Summary]: UserSummaryPreview,
    [MediaExerciseTypes.SentencesOrder]: UserSentenceOrderPreview,
    [MediaExerciseTypes.AdvancedQuiz]: UserAdvancedQuizPreview,
    [GrammarExerciseTypes.Substitution]: UserSubstitutionPreview,
    [MediaExerciseTypes.Quiz]: UserQuizPreview,
    [GrammarExerciseTypes.WordsOrder]: UserWordsOrderPreview,
    [GrammarExerciseTypes.Multichoice]: UserMultichoicePreview,
    [GrammarExerciseTypes.Cloze]: UserClozePreview,
    [MediaExerciseTypes.Presentation]: UserFreeFormQuestionsPreview,
    [MediaExerciseTypes.FreeFormQuestions]: UserFreeFormQuestionsPreview,
    [GrammarExerciseTypes.StatementsTransformation]:
        UserFreeFormQuestionsPreview,
};

const UserExercisePreview = ({ exerciseId }) => {
    const { t } = useTranslation();

    const { userTeachers } = authStore;

    const isFav = userStore.favExercises.some((eObj) => eObj.id === exerciseId);

    const results = userStore.getExerciseProgress(exerciseId);
    const comment = userStore.getExerciseTeacherComment(exerciseId);

    const [exerciseObj, setExerciseObj] = useState({});

    const [getExerciseData, dataLoading] = useFetching(async () => {
        if (!exerciseId) return;
        const { data } = await ExerciseService.getExercise({ id: exerciseId });
        if (data.trackId && data.trackInterval) {
            await getTrack({
                trackId: data.trackId,
                trackInterval: data.trackInterval,
            });
        } else {
            exercisesPlayer.resetMediaParams();
        }
        setExerciseObj(data);
    }, true);

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    // const [toggleExerciseStatus, isToggleLoading] = useFetching(async () => {
    //     if (isFav) {
    //         await ProgressService.updateExerciseStatus({
    //             exerciseId: exerciseId,
    //             status: StudentExerciseStatuses.Done,
    //         });
    //         userStore.removeFavExercise(exerciseObj.id);
    //         userStore.setActiveChapter({});
    //     } else {
    //         await ProgressService.updateExerciseStatus({
    //             exerciseId: exerciseId,
    //             status: StudentExerciseStatuses.Unfinished,
    //         });
    //         userStore.addFavExercise(exerciseObj);
    //         userExercisesStore.setCurrentExercise({});
    //     }
    // });

    const [markExerciseAsDone, isDoneLoading] = useFetching(async () => {
        const status = userTeachers.length
            ? StudentExerciseStatuses.AwaitingEvaluation
            : StudentExerciseStatuses.Done;
        await ProgressService.updateExerciseStatus({
            exerciseId: exerciseObj.id,
            status,
        });

        userStore.removeFavExercise(exerciseObj.id);
    });

    const handleResultsUpdate = (newResults) => {
        if (deepEqual(results, newResults)) return;

        userStore.updateExerciseProgress({
            exerciseId: exerciseObj.id,
            data: newResults,
        });
    };

    const isExerciseHaveAnswers = () => {
        if (
            [ExerciseMediaTypes.Audio, ExerciseMediaTypes.Video].includes(
                exerciseObj.mediaType
            )
        )
            return true;
        return ![
            MediaExerciseTypes.FreeFormQuestions,
            GrammarExerciseTypes.StatementsTransformation,
            MediaExerciseTypes.Presentation,
        ].includes(exerciseObj.type);
    };

    const getMediaPreview = () => {
        if (exerciseObj.mediaType === ExerciseMediaTypes.Video) {
            return (
                <div className={cl.videoContainer} key={exerciseObj.trackId}>
                    <ExercisePlayer isPreview={true} />
                    <ExercisePlayerControls mode={'video'} isPreview={true} />
                </div>
            );
        }
        if (exerciseObj.mediaType === ExerciseMediaTypes.Audio) {
            return (
                <div key={exerciseObj.trackId}>
                    <ExerciseAudioPlayer id={exerciseObj.id} />
                </div>
            );
        }
        if (exerciseObj.text) {
            return (
                <div>
                    <QuestionsLabel label={'gap_word_select'} />
                    <div className={cl.exerciseText}>{exerciseObj.text}</div>
                </div>
            );
        }

        return null;
    };

    const getPreview = () => {
        const Component = mapExerciseComponents[exerciseObj.type] || null;

        if (!Component) return <></>;

        return (
            <Component
                exerciseObj={exerciseObj}
                results={results}
                setResults={handleResultsUpdate}
                isCompleted={!isFav}
            />
        );
    };

    useEffect(() => {
        setExerciseObj({});
        if (!exerciseId) return;
        getExerciseData();
    }, [exerciseId]);

    if (dataLoading || trackLoading)
        return <Loader style={{ margin: 'auto' }} />;

    return (
        <div className={cl.preview}>
            <div className={cl.container}>
                <div className={cl.titleContainer}>
                    <p className={cl.exerciseTitle}>{exerciseObj.title}</p>
                </div>
                <div className={cl.previewContainer}>
                    <p className={cl.exerciseDescription}>
                        {exerciseObj.description}
                    </p>
                    {getMediaPreview()}
                    <div className={isFav ? '' : cl.locked}>{getPreview()}</div>

                    {isFav ? (
                        <Button
                            variant={'grey'}
                            className={`${cl.completeButton}`}
                            text={t('user_view.finish_exercise')}
                            onClick={markExerciseAsDone}
                            isLoading={isDoneLoading}
                        />
                    ) : (
                        <>
                            <p className={cl.completedText}>
                                {t('user_view.exercise_is_completed')}
                            </p>
                            {comment && (
                                <div className={cl.teacherFeedback}>
                                    <p>
                                        {`${t(
                                            'user_view.exercise_teacher_feedback'
                                        )}${comment}`}
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(UserExercisePreview);
