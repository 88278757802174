import React, { useEffect, useState } from 'react';
import { ReactComponent as IconAdd } from '../../../../assets/svg/user-icon-add.svg';
import { ReactComponent as IconBin } from '../../../../assets/svg/user-icon-bin.svg';
import { ReactComponent as IconCheck } from '../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconCross } from '../../../../assets/svg/icon-cross.svg';
import { ReactComponent as IconFolder } from '../../../../assets/svg/user-icon-folder.svg';
import { ReactComponent as IconStar } from '../../../../assets/svg/user-icon-star.svg';
import { ReactComponent as IconStarDashed } from '../../../../assets/svg/user-icon-star-dashed.svg';
import { ReactComponent as IconTimer } from '../../../../assets/svg/user-icon-timer.svg';
import { SmallLoader } from '../../../../UI';
import cl from './ChapterActionButton.module.css';

const getIcon = (iconType) => {
    switch (iconType) {
        case 'add':
            return <IconAdd />;
        case 'bin':
            return <IconBin />;
        case 'check':
            return <IconCheck className={cl.iconCheck} />;
        case 'cross':
            return <IconCross />;
        case 'folder':
            return <IconFolder />;
        case 'star':
            return <IconStar />;
        case 'star_dashed':
            return <IconStarDashed />;
        case 'timer':
            return <IconTimer />;

        default:
            return null;
    }
};

const ChapterActionButton = ({
    title,
    color,
    icon,
    onClick,
    isLoading,
    isDisabled,
    variant,
}) => {
    const [isPressed, setIsPressed] = useState(false);
    const [isLoader, setLoader] = useState(false);

    const svg = getIcon(icon);

    const classNames = [cl.button, cl[color]];
    if (isPressed) classNames.push(cl.pressed);
    if (isDisabled) classNames.push(cl.disabled);
    if (variant) classNames.push(cl[variant]);

    // const getTitle = () => {
    // switch (color) {
    //     case 'orange':
    //         return t('user_view.chapter_button_remove_title');
    //     case 'purple':
    //         return t('user_view.chapter_button_add_title');
    //     default:
    //         return null;
    // }
    // };

    useEffect(() => {
        if (isLoading) {
            setLoader(true);
        } else {
            setTimeout(() => {
                setLoader(false);
            }, 100);
        }
    }, [isLoading]);

    return (
        <button
            title={title}
            className={classNames.join(' ')}
            onClick={isDisabled ? () => {} : onClick}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onMouseLeave={() => setIsPressed(false)}
            onTouchStart={() => setIsPressed(true)}
            onTouchEnd={() => setIsPressed(false)}
        >
            {isLoader ? <SmallLoader size={12} color={'var(--white'} /> : svg}
        </button>
    );
};

export default ChapterActionButton;
