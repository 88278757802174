import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesPlayer from '../../../../../../pages/TeacherContent/store/exercisesPlayer';
import exercisesStore from '../../../../../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import SituationsService from '../../../../../../api/SituationsService';
import TrackService from '../../../../../../api/TrackService';
import {
    ExerciseMediaTypes,
    getExerciseTypesByMedia,
    GrammarExerciseTypes,
    LexicalExerciseTypes,
    MaximumMediaCharactersAmount,
    MediaExerciseTypes,
    MediaTypes,
    QuestionsRange,
    SummaryQuestionsRange,
    TextGenresByLevel,
    TextRange,
} from '../../../../../../pages/TeacherContent/data/constants';
import {
    Button,
    Checkbox,
    FilledRange,
    Input,
    Label,
    Loader,
    Modal,
    Select,
    ToggleButton,
    Tooltip,
    Tree,
} from '../../../../../../UI';
import { SelectVideoModal } from '../../../../../../pages/TeacherContent/components';
import {
    ExerciseIcon,
    ExercisePlayer,
    ExercisePlayerControls,
    SelectExerciseModal,
} from '../../../../../../pages/TeacherContent/views/ExercisesView/components';
import { Chapters, SynthesisVoiceIds } from '../../../../data/constants';
import { LanguageLevels, TicksInSecond } from '../../../../../../data/common';
import toHoursAndMinutes from '../../../../../../utils/toHoursAndMinutes';
import { WizardViews } from '../../LessonWizard';
import { ManageWordPackModal, SelectContentButtons } from '..';
import { PresentationTypes } from '../../../../../../pages/TeacherContent/views/ExercisesView/data/constants';
import { SelectSituationModal } from '../../../../../../pages/TeacherContent/views/SituationsView/components';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import { ReactComponent as IconText } from '../../../../../../assets/svg/lessons-text_alt.svg';
import { ReactComponent as IconVideo } from '../../../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconArrow } from '../../../../../../assets/svg/lessons-arrow.svg';
import { ReactComponent as IconDialogue } from '../../../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconYouTube } from '../../../../../../assets/svg/youtube-logo.svg';
import { ReactComponent as IconTypeText } from '../../../../../../assets/svg/exercise-media-text.svg';
import { ReactComponent as IconTypeVideo } from '../../../../../../assets/svg/exercise-media-video.svg';
import { ReactComponent as IconTypeGrammar } from '../../../../../../assets/svg/exercise-media-grammar.svg';
import { ReactComponent as IconTypeInfo } from '../../../../../../assets/svg/exercise-media-info.svg';
import { ReactComponent as IconTypeLexical } from '../../../../../../assets/svg/exercise-media-lexical.svg';
import { ReactComponent as IconTypeAudio } from '../../../../../../assets/svg/exercise-media-audio.svg';
import cl from './ExerciseWizard.module.css';

export const AvailableLanguageLevels = ['A1', 'A2', 'B1', 'B2', 'C1'];

const TextSourceTypes = {
    AI: 'ai',
    Own: 'own',
    Dialogue: 'dialogue',
};

const VideoSourceTypes = {
    Own: 'own',
    Library: 'library',
    YouTube: 'youtube',
};

const ExerciseTypeIcon = ({ type }) => {
    switch (type) {
        case ExerciseMediaTypes.Grammar:
            return <IconTypeGrammar className={cl.pathIcon} />;
        case ExerciseMediaTypes.Lexical:
            return <IconTypeLexical className={cl.pathIcon} />;
        case ExerciseMediaTypes.Info:
            return <IconTypeInfo className={cl.pathIcon} />;
        case ExerciseMediaTypes.Audio:
            return <IconTypeAudio className={cl.pathIcon} />;
        case ExerciseMediaTypes.Text:
            return <IconTypeText className={cl.pathIcon} />;
        case ExerciseMediaTypes.Video:
            return <IconTypeVideo className={cl.pathIcon} />;
        default:
            return null;
    }
};

const TextSourceIcon = ({ source }) => {
    switch (source) {
        case TextSourceTypes.AI:
            return <IconStars className={cl.strokeIcon} />;
        case TextSourceTypes.Own:
            return <IconText className={cl.strokeIcon} />;
        case TextSourceTypes.Dialogue:
            return <IconDialogue className={cl.strokeIcon} />;
        default:
            return null;
    }
};

const VideoSourceIcon = ({ source }) => {
    switch (source) {
        case VideoSourceTypes.Own:
            return <IconVideo className={cl.videoIcon} />;
        case VideoSourceTypes.Library:
            return <IconVideo className={cl.videoIcon} />;
        case VideoSourceTypes.YouTube:
            return <IconYouTube className={cl.ytIcon} />;
        default:
            return null;
    }
};

const SelectExercise = observer(({ onSelect }) => {
    const { lang } = languagesStore;

    const { currentLesson } = lessonsStore;
    const { difficulty, grammarTopic, vocabularyTopic } = currentLesson;

    const [isOwn, setIsOwn] = useState(false);
    const [isModal, setModal] = useState(false);

    const [allExercisesCount, setAllExercisesCount] = useState(null);
    const [favExercisesCount, setFavExercisesCount] = useState(null);

    const [getExercises, isLoading] = useFetching(async () => {
        const { data: allExercises } = await ExerciseService.getExercises({
            offset: 0,
            limit: 1,
            lang,
            difficulty,
        });
        const { data: favExercises } = await ExerciseService.getSavedExercises({
            offset: 0,
            limit: 1,
            lang,
            difficulty,
        });

        setAllExercisesCount(allExercises.total || 0);
        setFavExercisesCount(favExercises.total || 0);
    });

    const [addExercise, addLoading] = useFetching(async ({ id }) => {
        if (!isOwn) {
            ExerciseService.cloneExercise({ id }).then(() =>
                exercisesStore.refresh()
            );
        }
        const { data } = await ExerciseService.getExercise({
            id,
        });
        onSelect({
            id,
            title: data.title,
        });
    });

    const handleSelectClick = () => {
        setIsOwn(false);
        setTimeout(() => {
            setModal(true);
        });
    };

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            setModal(true);
        });
    };

    useEffect(() => {
        getExercises();
    }, [difficulty]);

    useEffect(() => {
        if (!currentLesson) return;

        wizardStore.setExerciseParams({
            difficulty,
            grammarTopic,
            vocabularyTopic,
        });
    }, [currentLesson]);

    return (
        <>
            <SelectContentButtons
                variant={Chapters.Exercise}
                onOwnClick={handleOwnClick}
                onLibraryClick={handleSelectClick}
                onCreateClick={wizardStore.nextStep}
                ownCount={favExercisesCount}
                libraryCount={allExercisesCount}
            />

            <SelectExerciseModal
                visible={isModal}
                setVisible={setModal}
                onClick={addExercise}
                // TODO: pass already added exercises packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
                presetDifficulty={LanguageLevels[difficulty]}
            />
        </>
    );
});

const ExerciseType = observer(() => {
    const { t } = useTranslation();
    const { currentLesson } = lessonsStore;

    const isTypeDisabled = (type) => {
        if ([ExerciseMediaTypes.Info].includes(type)) return true;
        return false;
    };

    const renderExercises = (type) => {
        const exerciseKinds = Object.values(getExerciseTypesByMedia(type));

        return (
            <span>
                {exerciseKinds.map((type) => t(`exercises.${type}`)).join(', ')}
            </span>
        );
    };

    const handleTypeSelect = (type) => {
        if (isTypeDisabled(type)) return;

        wizardStore.setExerciseType(type);
        wizardStore.nextStep();

        if (!currentLesson) wizardStore.nextStep();
    };

    return (
        <div className={cl.exerciseCardContainer}>
            {Object.values(ExerciseMediaTypes).map((type) => (
                <div
                    className={`${cl.basicCard} ${isTypeDisabled(type) ? cl.disabled : ''}`}
                    key={type}
                    onClick={() => handleTypeSelect(type)}
                >
                    <ExerciseTypeIcon type={type} />
                    <div className={cl.basicCardText}>
                        <p>{t(`lesson_wizard.${type}_exercise`)}</p>
                        {renderExercises(type)}
                    </div>
                </div>
            ))}
        </div>
    );
});

const ExerciseKind = observer(() => {
    const { t } = useTranslation();

    const { currentLesson } = lessonsStore;

    const { exerciseType, exerciseParams, grammarOptions, verifiedTypesInfo } =
        wizardStore;

    const { grammarTopic } = exerciseParams;

    const currentGrammar = grammarOptions
        .flatMap((o) => o.topics)
        .find((tObj) => tObj.topic === grammarTopic);

    const availableTypes = currentGrammar ? currentGrammar.availableTypes : [];

    const exerciseKinds = Object.values(getExerciseTypesByMedia(exerciseType));

    const isLexical = exerciseType === ExerciseMediaTypes.Lexical;
    const isGrammar = exerciseType === ExerciseMediaTypes.Grammar;

    const getExtraClassName = (current) => {
        if (
            (exerciseType === ExerciseMediaTypes.Lexical && current) ===
            LexicalExerciseTypes.Cloze
        )
            return cl.disabled;

        if (exerciseType !== ExerciseMediaTypes.Grammar || !currentLesson)
            return '';

        return availableTypes.some((kind) => kind === current)
            ? cl.supported
            : '';
    };

    const handleKindSelect = (kind) => {
        if (isLexical && kind === LexicalExerciseTypes.Cloze) return;
        wizardStore.setExerciseKind(kind);
        wizardStore.nextStep();

        if (
            isGrammar &&
            kind === GrammarExerciseTypes.StatementsTransformation
        ) {
            wizardStore.nextStep();
        }
    };

    const renderExerciseCard = (kind, mediaType) => {
        return (
            <div
                className={`${cl.basicCard} ${getExtraClassName(kind)}`}
                key={kind}
                onClick={() => handleKindSelect(kind)}
            >
                <ExerciseIcon type={kind} additionalClass={cl.modalIcon} />
                <div className={cl.basicCardText}>
                    <p>{t(`exercises.${kind}`)}</p>
                    <span>
                        {t(
                            `exercises.${kind}${
                                mediaType === ExerciseMediaTypes.Text
                                    ? '_text'
                                    : mediaType === ExerciseMediaTypes.Audio
                                      ? '_audio'
                                      : ''
                            }_desc`
                        )}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                className={
                    exerciseKinds.length === 2
                        ? cl.exerciseCardContainerAlt
                        : cl.exerciseCardContainer
                }
            >
                {exerciseKinds.map((kind) =>
                    renderExerciseCard(kind, exerciseType)
                )}
            </div>
            <Modal
                visible={isGrammar && currentLesson && !verifiedTypesInfo}
                setVisible={() => wizardStore.setVerifiedTypesInfo(true)}
                className={cl.intro}
            >
                <p className={cl.title}>
                    {t('lesson_wizard.verified_types_title')}
                </p>
                <div className={cl.subtitle}>
                    {t('lesson_wizard.verified_types_subtitle')}
                </div>

                <Button
                    variant={Chapters.Exercise}
                    text={t('teacher_onboarding.close_button')}
                    onClick={() => wizardStore.setVerifiedTypesInfo(true)}
                />
            </Modal>
        </>
    );
});

const TextSource = observer(() => {
    const { t } = useTranslation();
    const { exerciseParams, exerciseType, selectSituation } = wizardStore;
    const { difficulty } = exerciseParams;
    const isAudio = exerciseType === ExerciseMediaTypes.Audio;

    const currentSourceTypes = isAudio
        ? [TextSourceTypes.AI, TextSourceTypes.Own]
        : Object.values(TextSourceTypes);

    const handleSourceSelect = (source) => {
        wizardStore.setTextSource(source);
        if (source === TextSourceTypes.Dialogue) {
            wizardStore.setSelectSituation(true);
        } else {
            wizardStore.nextStep();
        }
    };

    const handleDialogueSelect = ({ id }) => {
        setTimeout(() => {
            wizardStore.setExerciseParams({
                ...exerciseParams,
                dialogueId: id,
            });
            wizardStore.nextStep();
        }, 100);
    };

    return (
        <div className={cl.exerciseTypeContainer}>
            {Object.values(currentSourceTypes).map((s) => (
                <div
                    key={s}
                    className={cl.basicCard}
                    onClick={() => handleSourceSelect(s)}
                >
                    <TextSourceIcon
                        source={s}
                        additionalClass={cl.sourceIcon}
                    />
                    <div className={cl.basicCardText}>
                        <p>{t(`lesson_wizard.${s}_text`)}</p>
                        <span>{t(`lesson_wizard.${s}_text_description`)}</span>
                    </div>
                </div>
            ))}
            <SelectSituationModal
                visible={selectSituation}
                setVisible={wizardStore.setSelectSituation}
                onClick={handleDialogueSelect}
                isOnboarding={false}
                isOwn={true}
                presetDifficulty={LanguageLevels[difficulty]}
            />
        </div>
    );
});

const GenerateByText = observer(({ onGenerate }) => {
    const { t } = useTranslation();

    const { currentLesson } = lessonsStore;

    const { lang } = languagesStore;
    const {
        textSource,
        exerciseKind,
        exerciseType,
        wordPack,
        grammarOptions,
        vocabularyOptions,
        extraStep,
        exerciseParams,
    } = wizardStore;

    const { difficulty, dialogueId, genre } = exerciseParams;

    const isGrammar = exerciseType === ExerciseMediaTypes.Grammar;
    const isLexical = exerciseType === ExerciseMediaTypes.Lexical;
    const isAudio = exerciseType === ExerciseMediaTypes.Audio;
    const isText = exerciseType === ExerciseMediaTypes.Text;

    const isTransformation =
        exerciseKind === GrammarExerciseTypes.StatementsTransformation;

    const isWordsOrder = exerciseKind === GrammarExerciseTypes.WordsOrder;
    const isSummary = exerciseKind === MediaExerciseTypes.Summary;
    const isPresentation = exerciseKind === MediaExerciseTypes.Presentation;
    const isAiText = textSource === TextSourceTypes.AI;
    const isOwnText = [TextSourceTypes.Own, TextSourceTypes.Dialogue].includes(
        textSource
    );
    const [isLastMoment, setLastMoment] = useState(false);

    const isExtraRange =
        Object.values(MediaExerciseTypes).includes(exerciseKind) &&
        !isPresentation;

    const textParams = isTransformation ? QuestionsRange : TextRange;
    const rangeParams = isSummary ? SummaryQuestionsRange : QuestionsRange;

    const presentationOptions = Object.values(PresentationTypes).map(
        (type) => ({
            name: t(`presentation_options.${type}_title`),
            value: t(`presentation_options.${type}_text_description`),
        })
    );

    const [text, setText] = useState(isOwnText ? '' : null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [sentencesAmount, setSentencesAmount] = useState(textParams.Default);
    const [entitesAmount, setEntitiesAmount] = useState(
        isSummary ? SummaryQuestionsRange.Default : QuestionsRange.Default
    );
    const [formErrors, setFormErrors] = useState({});
    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredVocabulary, setFilteredVocabulary] =
        useState(vocabularyOptions);

    const [showText, setShowText] = useState(isOwnText ? true : false);
    const [showMore, setShowMore] = useState(false);

    const [generateExercise, isExerciseLoading] = useFetching(async () => {
        setLastMoment(false);
        const currentGrammar = grammarOptions
            .flatMap((o) => o.topics)
            .find((tObj) => tObj.topic === exerciseParams.grammarTopic);

        const targetVocabulary = isOwnText
            ? []
            : wizardStore.getPlainSelectedWords();

        let exerciseText = text;

        if (!exerciseText) {
            const { data: textData } = await ExerciseService.generateText({
                lang,
                difficulty,
                grammarTopic:
                    isText || isAudio
                        ? ''
                        : (currentGrammar?.prompt ??
                          exerciseParams.grammarTopic),
                grammarRule:
                    isText || isAudio
                        ? ''
                        : (currentGrammar?.grammarRule ?? ''),
                textTopic: exerciseParams.vocabularyTopic,
                genre: exerciseParams.genre,
                vocabulary: targetVocabulary,
                sentencesAmount: sentencesAmount,
            });

            setText(textData.text);
            exerciseText = textData.text;
        }

        wizardStore.setExtraStep(true);

        if (showText) return;
        setLastMoment(false);

        const voiceId = isAudio ? getRandomVoiceId() : null;

        const { data } = await ExerciseService.createExercise({
            type: exerciseKind,
            mediaType: exerciseType,
            prompt: currentGrammar?.prompt ?? exerciseParams.grammarTopic,
            grammarRule:
                isText || isAudio ? '' : (currentGrammar?.grammarRule ?? ''),
            grammarTopic: isText || isAudio ? '' : exerciseParams.grammarTopic,
            vocabularyTopic: exerciseParams.vocabularyTopic,
            commonMistakes:
                isText || isAudio ? '' : currentGrammar?.commonMistakes,
            text: exerciseText,
            sentencesAmount: entitesAmount,
            difficulty,
            lang,
            vocabulary: targetVocabulary,
            title,
            description,
            voiceId,
        });
        setLastMoment(false);
        if (data) {
            onGenerate(data);
        }
    });

    const [getSituationText, isSituationLoading] = useFetching(async () => {
        const { data: infoData } = await SituationsService.getSituationInfo({
            id: dialogueId,
            language: lang.toUpperCase(),
        });
        const { info } = infoData;
        const modality = info[0]?.modality;
        const gender = info[0]?.gender;
        const { data } = await SituationsService.getSituation({
            id: dialogueId,
            language: lang.toUpperCase(),
            gender,
            modality,
            targetLanguage: lang.toUpperCase(),
        });

        const { statements } = data;
        if (!statements?.length) return;

        setText(statements.map((s) => `- ${s.phrase}`).join('\n'));
    });

    const [generateTextExercise, isTextExerciseLoading] = useFetching(
        async () => {
            const currentGrammar = grammarOptions
                .flatMap((o) => o.topics)
                .find((tObj) => tObj.topic === exerciseParams.grammarTopic);

            const targetVocabulary = isOwnText
                ? []
                : wizardStore.getPlainSelectedWords();

            const voiceId = isAudio ? getRandomVoiceId() : null;

            const { data } = await ExerciseService.createExercise({
                type: exerciseKind,
                mediaType: exerciseType,
                prompt: currentGrammar?.prompt ?? exerciseParams.grammarTopic,
                grammarRule:
                    isText || isAudio
                        ? ''
                        : (currentGrammar?.grammarRule ?? ''),
                grammarTopic:
                    isText || isAudio ? '' : exerciseParams.grammarTopic,
                vocabularyTopic: exerciseParams.vocabularyTopic,
                commonMistakes:
                    isText || isAudio ? '' : currentGrammar?.commonMistakes,
                text,
                sentencesAmount:
                    isTransformation || isWordsOrder
                        ? sentencesAmount
                        : entitesAmount,
                difficulty,
                lang,
                voiceId,
                vocabulary: targetVocabulary,
                title,
                description,
            });
            setLastMoment(false);
            if (data) {
                onGenerate(data);
            }
        }
    );

    const handleGenerateExercise = (callback) => {
        if (isExerciseLoading || isTextExerciseLoading) return;
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                grammarTopic:
                    isGrammar && !isWordsOrder && !exerciseParams.grammarTopic,
                vocabularyTopic:
                    (isAiText || (isLexical && !isOwnText)) &&
                    !exerciseParams.vocabularyTopic,
                title: !title && isPresentation,
                description: !description && isPresentation,
                text: typeof text === 'string' && !text.length,
                genre:
                    !isTransformation &&
                    !isWordsOrder &&
                    typeof text !== 'string' &&
                    !exerciseParams.genre,
                vocabulary:
                    isLexical &&
                    !isOwnText &&
                    !wizardStore.getPlainSelectedWords()?.length,
                difficulty: typeof difficulty !== 'number',
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) {
                console.error('error in exercise wizard form');
                console.error(
                    'missing params:',
                    Object.entries(errors)
                        .filter(([_error, flag]) => flag)
                        .map(([error]) => error)
                        ?.join(', ')
                );
                return;
            }
            callback();
        });
    };

    const getRandomVoiceId = () =>
        SynthesisVoiceIds[Math.floor(Math.random() * SynthesisVoiceIds.length)];

    const handleTextRangeInput = (e) => {
        setSentencesAmount(e.target.value);
    };

    const handleEntitiesRangeInput = (e) => {
        setEntitiesAmount(e.target.value);
    };

    const getCurrentGenres = () => {
        const level =
            typeof exerciseParams.difficulty === 'number'
                ? LanguageLevels[exerciseParams.difficulty]
                : LanguageLevels[0];
        return TextGenresByLevel[level];
    };

    const getRandomTextGenre = () => {
        const currentGenres = getCurrentGenres();
        return currentGenres[Math.floor(Math.random() * currentGenres.length)];
    };

    const getGenreOptions = () => {
        return getCurrentGenres().map((genre) => ({
            value: genre,
            name: t(`exercises.${genre}`),
        }));
    };

    const handleInputChange = (key, value) => {
        setFormErrors({});
        wizardStore.setExerciseParams({ ...exerciseParams, [key]: value });
    };

    const getExerciseProgressDuration = () => {
        if (!(isExerciseLoading || isTextExerciseLoading)) return null;

        const count = sentencesAmount;
        const timeout = Math.min(Math.max(count * 500, 3000), 15000);

        if (isExerciseLoading) {
            if (showText) return timeout;
            return count * 1500;
        }

        if (isTextExerciseLoading) return count * 900;

        return null;
    };

    const getButtonText = () => {
        if (showText && isExerciseLoading)
            return isLastMoment
                ? 'exercises.generating_last_moment'
                : 'exercises.text_is_generating';

        if (isExerciseLoading || isTextExerciseLoading)
            return isLastMoment
                ? 'exercises.generating_last_moment'
                : 'exercises.exercise_is_generating';

        return 'exercises.generate_exercise';
    };

    useEffect(() => {
        const progressDuration = getExerciseProgressDuration();
        if (progressDuration !== null) {
            const timeout = setTimeout(() => {
                setLastMoment(true);
            }, progressDuration - 500);

            return () => clearTimeout(timeout);
        }
    }, [isExerciseLoading, isTextExerciseLoading, sentencesAmount, showText]);

    useEffect(() => {
        if (dialogueId && textSource === TextSourceTypes.Dialogue) {
            getSituationText();
        }
    }, [dialogueId, textSource]);

    useEffect(() => {
        setTitle('');
        setDescription('');
    }, [exerciseKind]);

    useEffect(() => {
        if (isGrammar && !exerciseParams.grammarTopic) setShowMore(true);
        if (!currentLesson) {
            setShowMore(true);
        }
    }, [exerciseParams, currentLesson, exerciseType]);

    useEffect(() => {
        setFormErrors({});
    }, [wordPack]);

    useEffect(() => {
        handleInputChange('dialogueId', '');
    }, []);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics?.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            ?.filter((g) => g.topics?.length);
        setFilteredGrammar(currentGrammar);

        const currentVocabulary = vocabularyOptions
            .map((g) => ({
                ...g,
                topics: g.topics?.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            ?.filter((g) => g.topics?.length);
        setFilteredVocabulary(currentVocabulary);
    }, [grammarOptions, vocabularyOptions, difficulty]);

    useEffect(() => {
        setLastMoment(false);

        if (
            !currentLesson &&
            exerciseType === ExerciseMediaTypes.Grammar &&
            exerciseKind
        ) {
            setFilteredGrammar(() =>
                filteredGrammar.map((cObj) => ({
                    ...cObj,
                    topics: cObj.topics?.filter((tObj) =>
                        tObj.availableTypes.includes(exerciseKind)
                    ),
                }))
            );
        }
    }, [currentLesson, exerciseType, exerciseKind]);

    useEffect(() => {
        if (
            !extraStep &&
            ![TextSourceTypes.Own, TextSourceTypes.Dialogue].includes(
                textSource
            )
        ) {
            setShowText(isAudio ? true : false);
            setText();
        }
    }, [extraStep, textSource]);

    useEffect(() => {
        if (currentLesson) return;

        const newGenre =
            (genre && getCurrentGenres().includes(genre)) ||
            typeof difficulty !== 'number'
                ? exerciseParams.genre
                : getRandomTextGenre();

        wizardStore.setExerciseParams({
            ...wizardStore.exerciseParams,
            grammarTopic: '',
            vocabularyTopic: '',
            genre: newGenre,
        });
    }, [currentLesson, difficulty]);

    if (showText && typeof text === 'string')
        return (
            <>
                {(isGrammar ||
                    isPresentation ||
                    !currentLesson ||
                    isExtraRange) && (
                    <div className={cl.textParamsContainer}>
                        {isExtraRange && (
                            <div>
                                <Label
                                    text={t(
                                        exerciseKind ===
                                            MediaExerciseTypes.SentencesOrder
                                            ? 'exercises.sentence_count'
                                            : exerciseKind ===
                                                MediaExerciseTypes.Summary
                                              ? 'exercises.summary_count'
                                              : 'exercises.question_count'
                                    )}
                                />
                                <FilledRange
                                    color={'var(--blue)'}
                                    value={entitesAmount ?? rangeParams.Default}
                                    min={rangeParams.Min}
                                    step={rangeParams.Step}
                                    max={rangeParams.Max}
                                    onChange={handleEntitiesRangeInput}
                                />
                            </div>
                        )}
                        {isGrammar && !isWordsOrder && (
                            <div className={cl.wideSelect}>
                                <Label
                                    text={t('lesson_wizard.grammar_topic')}
                                    isError={formErrors['grammarTopic']}
                                    isRequired
                                />
                                <Tree
                                    label={t('lesson_wizard.grammar_topic')}
                                    variant={
                                        formErrors['grammarTopic']
                                            ? 'errorSmall'
                                            : 'grey'
                                    }
                                    value={exerciseParams.grammarTopic}
                                    onChange={(value) =>
                                        handleInputChange('grammarTopic', value)
                                    }
                                    options={filteredGrammar}
                                    ignoreOverflow
                                    height={320}
                                />
                            </div>
                        )}
                        {isPresentation && (
                            <>
                                <div className={cl.toggleContainer}>
                                    <Label
                                        text={t('exercises.exercise_title')}
                                        isError={formErrors['title']}
                                        isRequired
                                    />
                                    <Input
                                        variant={
                                            formErrors['title']
                                                ? 'erroredSmall'
                                                : 'outlinedSmall'
                                        }
                                        placeholder={t('exercises.enter_title')}
                                        value={title}
                                        onChange={setTitle}
                                    />
                                </div>
                                <div className={cl.toggleContainer}>
                                    <Label
                                        text={t('exercises.presentation_task')}
                                        isError={formErrors['description']}
                                        isRequired
                                    />
                                    <Select
                                        style={{ width: '100%' }}
                                        label={t('exercises.select_value')}
                                        value={description}
                                        onChange={setDescription}
                                        variant={'grey'}
                                        options={presentationOptions}
                                        height={200}
                                        hideReset
                                    />
                                </div>
                            </>
                        )}
                        {!currentLesson && (
                            <div className={cl.levelContainer}>
                                <Label
                                    text={t('users.level')}
                                    isError={formErrors['difficulty']}
                                    isRequired
                                />
                                <ToggleButton
                                    className={cl.levelToggle}
                                    options={LanguageLevels}
                                    availableOptions={AvailableLanguageLevels}
                                    disabled
                                    value={LanguageLevels[difficulty]}
                                    onChange={(value) =>
                                        handleInputChange(
                                            'difficulty',
                                            LanguageLevels.indexOf(value)
                                        )
                                    }
                                    variant={
                                        formErrors['difficulty']
                                            ? 'error'
                                            : 'outlined'
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}

                {isLexical && !isOwnText && (
                    <ManageWordPackModal
                        variant={Chapters.Exercise}
                        isError={formErrors['vocabulary']}
                        globalMode
                        type={
                            exerciseType === ExerciseMediaTypes.Lexical
                                ? 'lexical'
                                : 'exercise'
                        }
                    />
                )}
                <div className={cl.textEditorContainer}>
                    <Label
                        text={t('exercises.gap_word_select')}
                        isError={formErrors['text']}
                        isRequired
                    />
                    {isSituationLoading ? (
                        <Loader className={cl.dialogueLoader} />
                    ) : (
                        <textarea
                            className={cl.textEditor}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            placeholder={t(
                                'lesson_wizard.own_text_placeholder'
                            )}
                        />
                    )}
                </div>

                <Button
                    text={t(getButtonText())}
                    variant={Chapters.Exercise}
                    onClick={() => handleGenerateExercise(generateTextExercise)}
                    progressDuration={getExerciseProgressDuration()}
                />
            </>
        );

    return (
        <>
            {showMore ? (
                <div className={cl.moreParamsContainer}>
                    <div className={cl.verticalContainer}>
                        {!isTransformation && !isWordsOrder && isExtraRange && (
                            <div>
                                <Label
                                    text={t('exercises.genre')}
                                    isError={formErrors['genre']}
                                    isRequired
                                />
                                <Select
                                    className={cl.wideSelect}
                                    label={t('exercises.genre')}
                                    variant={
                                        formErrors['genre']
                                            ? 'errorSmall'
                                            : 'grey'
                                    }
                                    value={exerciseParams.genre}
                                    options={getGenreOptions()}
                                    onChange={(genre) =>
                                        handleInputChange('genre', genre)
                                    }
                                    ignoreOverflow
                                    style={{ width: '100%' }}
                                    disabled={difficulty === ''}
                                    tooltip={t(
                                        'lesson_wizard.grammar_topic_select_level'
                                    )}
                                />
                            </div>
                        )}
                        {isPresentation && (
                            <div className={cl.flexContainer}>
                                <div className={cl.toggleContainer}>
                                    <Label
                                        text={t('exercises.exercise_title')}
                                        isError={formErrors['title']}
                                        isRequired
                                    />
                                    <Input
                                        variant={
                                            formErrors['title']
                                                ? 'erroredSmall'
                                                : 'outlinedSmall'
                                        }
                                        placeholder={t('exercises.enter_title')}
                                        value={title}
                                        onChange={setTitle}
                                    />
                                </div>
                                <div className={cl.toggleContainer}>
                                    <Label
                                        text={t('exercises.presentation_task')}
                                        isError={formErrors['description']}
                                        isRequired
                                    />
                                    <Select
                                        style={{ width: '100%' }}
                                        label={t('exercises.select_value')}
                                        value={description}
                                        onChange={setDescription}
                                        variant={'grey'}
                                        options={presentationOptions}
                                        height={200}
                                        hideReset
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <Label
                                text={t('lesson_wizard.lexical_topic')}
                                isError={formErrors['vocabularyTopic']}
                                isRequired
                            />
                            <Tree
                                label={t('lesson_wizard.lexical_topic')}
                                variant={
                                    formErrors['vocabularyTopic']
                                        ? 'errorSmall'
                                        : 'grey'
                                }
                                value={exerciseParams.vocabularyTopic}
                                onChange={(value) =>
                                    handleInputChange('vocabularyTopic', value)
                                }
                                options={filteredVocabulary}
                                hideReset
                                ignoreOverflow
                                height={350}
                                disabled={difficulty === ''}
                                tooltip={t(
                                    'lesson_wizard.grammar_topic_select_level'
                                )}
                            />
                        </div>
                        {isGrammar && (
                            <div>
                                <Label
                                    text={t('lesson_wizard.grammar_topic')}
                                    isError={formErrors['grammarTopic']}
                                    isRequired={isGrammar}
                                />
                                <Tree
                                    label={t('lesson_wizard.grammar_topic')}
                                    variant={
                                        formErrors['grammarTopic']
                                            ? 'errorSmall'
                                            : 'grey'
                                    }
                                    value={exerciseParams.grammarTopic}
                                    onChange={(value) =>
                                        handleInputChange('grammarTopic', value)
                                    }
                                    options={filteredGrammar}
                                    ignoreOverflow
                                    height={320}
                                    disabled={difficulty === ''}
                                    tooltip={t(
                                        'lesson_wizard.grammar_topic_select_level'
                                    )}
                                />
                            </div>
                        )}
                        <ManageWordPackModal
                            variant={Chapters.Exercise}
                            isError={formErrors['vocabulary']}
                            globalMode
                            type={
                                exerciseType === ExerciseMediaTypes.Lexical
                                    ? 'lexical'
                                    : 'exercise'
                            }
                        />
                    </div>
                    <div className={cl.verticalContainer}>
                        {!currentLesson && (
                            <div>
                                <Label
                                    text={t('users.level')}
                                    isError={formErrors['difficulty']}
                                    isRequired
                                />
                                <ToggleButton
                                    className={cl.levelToggle}
                                    options={LanguageLevels}
                                    availableOptions={AvailableLanguageLevels}
                                    disabled
                                    value={LanguageLevels[difficulty]}
                                    onChange={(value) =>
                                        handleInputChange(
                                            'difficulty',
                                            LanguageLevels.indexOf(value)
                                        )
                                    }
                                    variant={
                                        formErrors['difficulty']
                                            ? 'error'
                                            : 'outlined'
                                    }
                                />
                            </div>
                        )}
                        {!isTransformation &&
                            !isWordsOrder &&
                            !isExtraRange && (
                                <div>
                                    <Label
                                        text={t('exercises.genre')}
                                        isError={formErrors['genre']}
                                        isRequired
                                    />
                                    <Select
                                        className={cl.wideSelect}
                                        label={t('exercises.genre')}
                                        variant={
                                            formErrors['genre']
                                                ? 'errorSmall'
                                                : 'grey'
                                        }
                                        value={exerciseParams.genre}
                                        options={getGenreOptions()}
                                        onChange={(genre) =>
                                            handleInputChange('genre', genre)
                                        }
                                        ignoreOverflow
                                        style={{ width: '100%' }}
                                        disabled={difficulty === ''}
                                        tooltip={t(
                                            'lesson_wizard.grammar_topic_select_level'
                                        )}
                                    />
                                </div>
                            )}
                        <div>
                            <Label
                                text={t(
                                    isTransformation
                                        ? 'exercises.statements_count'
                                        : 'exercises.text_sentence_count'
                                )}
                            />
                            <FilledRange
                                color={'var(--blue)'}
                                value={sentencesAmount}
                                min={textParams.Min}
                                step={textParams.Step}
                                max={textParams.Max}
                                onChange={handleTextRangeInput}
                            />
                        </div>
                        {isExtraRange && (
                            <div>
                                <Label
                                    text={t(
                                        exerciseKind ===
                                            MediaExerciseTypes.SentencesOrder
                                            ? 'exercises.sentence_count'
                                            : exerciseKind ===
                                                MediaExerciseTypes.Summary
                                              ? 'exercises.summary_count'
                                              : 'exercises.question_count'
                                    )}
                                />
                                <FilledRange
                                    color={'var(--blue)'}
                                    value={entitesAmount ?? rangeParams.Default}
                                    min={rangeParams.Min}
                                    step={rangeParams.Step}
                                    max={rangeParams.Max}
                                    onChange={handleEntitiesRangeInput}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    {isPresentation && (
                        <div className={cl.flexContainer}>
                            <div className={cl.toggleContainer}>
                                <Label
                                    text={t('exercises.exercise_title')}
                                    isError={formErrors['title']}
                                    isRequired
                                />
                                <Input
                                    variant={
                                        formErrors['title']
                                            ? 'erroredSmall'
                                            : 'outlinedSmall'
                                    }
                                    placeholder={t('exercises.enter_title')}
                                    value={title}
                                    onChange={setTitle}
                                />
                            </div>
                            <div className={cl.toggleContainer}>
                                <Label
                                    text={t('exercises.presentation_task')}
                                    isError={formErrors['description']}
                                    isRequired
                                />
                                <Select
                                    style={{ width: '100%' }}
                                    label={t('exercises.select_value')}
                                    value={description}
                                    onChange={setDescription}
                                    variant={'grey'}
                                    options={presentationOptions}
                                    height={200}
                                    hideReset
                                />
                            </div>
                        </div>
                    )}
                    <div className={cl.flexContainer}>
                        {!isTransformation && !isWordsOrder && (
                            <div>
                                <Label
                                    text={t('exercises.genre')}
                                    isError={formErrors['genre']}
                                    isRequired
                                />
                                <Select
                                    className={cl.wideSelect}
                                    label={t('exercises.genre')}
                                    variant={
                                        formErrors['genre']
                                            ? 'errorSmall'
                                            : 'grey'
                                    }
                                    value={exerciseParams.genre}
                                    options={getGenreOptions()}
                                    onChange={(genre) =>
                                        handleInputChange('genre', genre)
                                    }
                                    ignoreOverflow
                                    style={{ width: '100%' }}
                                    disabled={difficulty === ''}
                                    tooltip={t(
                                        'lesson_wizard.grammar_topic_select_level'
                                    )}
                                />
                            </div>
                        )}
                        <div>
                            <Label
                                text={t(
                                    isTransformation
                                        ? 'exercises.statements_count'
                                        : 'exercises.text_sentence_count'
                                )}
                            />
                            <FilledRange
                                color={'var(--blue)'}
                                value={sentencesAmount}
                                min={textParams.Min}
                                step={textParams.Step}
                                max={textParams.Max}
                                onChange={handleTextRangeInput}
                            />
                        </div>
                    </div>
                    {isLexical && !isOwnText && (
                        <ManageWordPackModal
                            variant={Chapters.Exercise}
                            isError={formErrors['vocabulary']}
                            globalMode
                            type={
                                exerciseType === ExerciseMediaTypes.Lexical
                                    ? 'lexical'
                                    : 'exercise'
                            }
                        />
                    )}
                    {currentLesson && (
                        <div>
                            <Label
                                text={t('lesson_wizard.text_exercise_info')}
                            />
                            <Label
                                text={t(
                                    `lesson_wizard.exercise_generate_subtitle`
                                )}
                            />
                        </div>
                    )}
                </>
            )}
            {!(isTransformation || isWordsOrder) && (
                <Checkbox
                    className={cl.showMoreCheckbox}
                    color={Chapters.Exercise}
                    variant={'checkmark'}
                    value={showText}
                    onChange={() => setShowText(!showText)}
                    label={t('lesson_wizard.show_text')}
                />
            )}

            <div
                className={
                    isTransformation
                        ? cl.transformationControls
                        : cl.flexContainer
                }
            >
                {currentLesson && (
                    <div
                        className={cl.expandButton}
                        onClick={() => setShowMore(!showMore)}
                    >
                        {t(
                            showMore
                                ? 'lesson_wizard.show_less'
                                : 'lesson_wizard.show_more'
                        )}
                        <IconArrow />
                    </div>
                )}

                <Button
                    text={t(getButtonText())}
                    variant={Chapters.Exercise}
                    onClick={() =>
                        handleGenerateExercise(
                            isTransformation || isWordsOrder
                                ? generateTextExercise
                                : generateExercise
                        )
                    }
                    progressDuration={getExerciseProgressDuration()}
                />
            </div>
        </>
    );
});

const VideoSource = observer(() => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);
    const [isOwn, setIsOwn] = useState(false);

    const handleSourceSelect = (source) => {
        setIsOwn(source === VideoSourceTypes.Own);
        setTimeout(() => {
            setVisible(true);
        });
    };

    const handleVideoSelect = (video) => {
        wizardStore.setCurrentVideo(video);
        setVisible(false);
        wizardStore.nextStep();
    };

    return (
        <>
            <div className={cl.exerciseTypeContainer}>
                {Object.values(VideoSourceTypes).map((s) => (
                    <div
                        className={`${cl.basicCard} ${s === VideoSourceTypes.YouTube ? cl.disabled : ''}`}
                        key={s}
                        onClick={() =>
                            s !== VideoSourceTypes.YouTube &&
                            handleSourceSelect(s)
                        }
                    >
                        <VideoSourceIcon
                            source={s}
                            additionalClass={cl.sourceIcon}
                        />
                        <div className={cl.basicCardText}>
                            <p>{t(`lesson_wizard.${s}_video`)}</p>
                            <span>
                                {t(`lesson_wizard.${s}_video_description`)}
                            </span>
                        </div>
                        {s === VideoSourceTypes.YouTube && (
                            <Tooltip
                                className={cl.tooltip}
                                variant={'warning'}
                                text={t('lesson_wizard.unsupported_yt_video')}
                                tailPosition={'right'}
                            />
                        )}
                    </div>
                ))}
            </div>

            <SelectVideoModal
                visible={visible}
                setVisible={setVisible}
                onClick={handleVideoSelect}
                isOwn={isOwn}
            />
        </>
    );
});

const GenerateByVideo = observer(({ onGenerate }) => {
    const { t } = useTranslation();

    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { trackText } = exercisesStore;
    const {
        currentVideo,
        exerciseKind,
        exerciseType,
        wordPack,
        grammarOptions,
        extraStep,
        exerciseParams,
    } = wizardStore;

    const isSummary = exerciseKind === MediaExerciseTypes.Summary;
    const isPresentation = exerciseKind === MediaExerciseTypes.Presentation;

    const rangeParams = isSummary ? SummaryQuestionsRange : QuestionsRange;

    const currentGrammar = grammarOptions
        .flatMap((o) => o.topics)
        .find((tObj) => tObj.topic === exerciseParams.grammarTopic);

    const [videoData, setVideoData] = useState({});

    const [entitesAmount, setEntitiesAmount] = useState(
        isSummary ? SummaryQuestionsRange.Default : QuestionsRange.Default
    );

    const [formErrors, setFormErrors] = useState({});

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [isLastMoment, setLastMoment] = useState(false);

    const presentationOptions = Object.values(PresentationTypes).map(
        (type) => ({
            name: t(`presentation_options.${type}_title`),
            value: t(
                `presentation_options.${type}_${exerciseType}_description`
            ),
        })
    );

    const handleGenerateExercise = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                title: !title && isPresentation,
                description: !description && isPresentation,
                difficulty: typeof exerciseParams.difficulty !== 'number',
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            generateExercise();
        });
    };

    const [generateExercise, isLoading] = useFetching(async () => {
        setLastMoment(false);
        const withInterval = ![
            MediaTypes.Text,
            MediaTypes.GeneratedText,
        ].includes(exerciseKind);

        const { items } = wordPack;
        const vocabulary = items ? items.map((wObj) => wObj.word) : [];

        const { data } = await ExerciseService.createExercise({
            title,
            description,
            type: exerciseKind,
            mediaType: exerciseType,
            prompt: currentGrammar?.prompt ?? exerciseParams.grammarTopic,
            sentencesAmount: entitesAmount,
            difficulty: exerciseParams.difficulty,
            lang,
            vocabulary,
            text: exercisesStore.trackText,
            trackId: currentVideo.id,
            grammarTopic: exerciseParams.grammarTopic,
            vocabularyTopic: exerciseParams.vocabularyTopic,
            trackInterval: withInterval
                ? exercisesPlayer.currentRange.map((r) => r / TicksInSecond)
                : null,
        });

        setLastMoment(false);
        if (data) {
            onGenerate(data);
        }
    });

    const [getVideo, videoLoading] = useFetching(async () => {
        const { data } = await TrackService.getTrackSentences({
            id: currentVideo.id,
            lang,
        });
        const maxDuration = getMax(data.sentences);

        exercisesPlayer.setTrackData({ ...data, duration: maxDuration });
        setVideoData({ ...data, duration: maxDuration });
        exercisesStore.setTrackText(
            data?.sentences.map((s) => s.text).join(' ') ?? ''
        );
    });

    const handleRangeInput = (e) => {
        setEntitiesAmount(e.target.value);
    };

    const getMax = (sentences) => {
        let totalLength = 0;
        let max = exercisesPlayer.trackLength;

        for (const t of sentences) {
            const segmentLength = t.text.length;

            if (totalLength + segmentLength > MaximumMediaCharactersAmount) {
                break;
            }

            totalLength += segmentLength;
            max = t.offset;
        }
        return max / TicksInSecond;
    };

    useEffect(() => {
        setFormErrors({});
    }, [title, description]);

    useEffect(() => {
        getVideo();
    }, [currentVideo]);

    useEffect(() => {
        const range = exercisesPlayer.currentRange;
        if (!range?.length) {
            exercisesStore.setTrackText(
                exercisesPlayer.trackSentences?.map((t) => t.text).join(' ')
            );
            return;
        }

        const segment = exercisesPlayer.trackSentences?.filter(
            (t) => t.offset >= range[0] && t.offset <= range[1]
        );
        exercisesStore.setTrackText(segment?.map((t) => t.text).join(' '));
    }, [exercisesPlayer.currentRange]);

    const isRange = () =>
        ![MediaExerciseTypes.Presentation].includes(exerciseKind);

    const handleInputChange = (key, value) => {
        wizardStore.setExerciseParams({ ...exerciseParams, [key]: value });
    };

    const getExerciseProgressDuration = () => {
        if (!isLoading) return null;

        const wordsAmount = exercisesStore.trackText?.split(' ').length;
        const timeout = wordsAmount * 10 + entitesAmount * 500;

        return Math.min(Math.max(timeout, 3000), 15000);
    };

    const getButtonText = () => {
        return isLoading
            ? isLastMoment
                ? 'exercises.generating_last_moment'
                : 'exercises.exercise_is_generating'
            : 'exercises.generate_exercise';
    };

    useEffect(() => {
        const progressDuration = getExerciseProgressDuration();
        if (progressDuration !== null) {
            const timeout = setTimeout(() => {
                setLastMoment(true);
            }, progressDuration - 500);

            return () => clearTimeout(timeout);
        }
    }, [isLoading, entitesAmount]);

    if (videoLoading)
        return <Loader className={cl.loader} style={{ margin: 'auto' }} />;

    return (
        <>
            <div
                className={`${cl.labeledContainer} ${extraStep ? cl.hidden : ''}`}
            >
                <div className={cl.videoPreviewContainer}>
                    <Label text={t('lesson_wizard.current_video_info')} />
                    <div className={cl.videoPreview}>
                        <div className={cl.imageContainer}>
                            <img
                                src={videoData.image?.md}
                                alt={videoData.title}
                            />
                        </div>
                        <div className={cl.cardInner}>
                            <p className={cl.cardTitle}>{videoData.title}</p>
                            <div className={cl.videoInfoContainer}>
                                {[
                                    LanguageLevels[videoData.difficulty],
                                    toHoursAndMinutes(videoData.duration),
                                    videoData.lang,
                                ].map((p, i) => (
                                    <p className={cl.cardLabel} key={i}>
                                        {p}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {isPresentation && (
                    <div className={cl.extraParamsContainer}>
                        <div className={cl.toggleContainer}>
                            <Label
                                text={t('exercises.exercise_title')}
                                isError={formErrors['title']}
                            />
                            <Input
                                variant={
                                    formErrors['title']
                                        ? 'erroredSmall'
                                        : 'outlinedSmall'
                                }
                                placeholder={t('exercises.enter_title')}
                                value={title}
                                onChange={setTitle}
                            />
                        </div>
                        <div className={cl.toggleContainer}>
                            <Label
                                text={t('exercises.presentation_task')}
                                isError={formErrors['description']}
                            />
                            <Select
                                style={{ width: '100%' }}
                                label={t('exercises.select_value')}
                                value={description}
                                onChange={setDescription}
                                variant={'grey'}
                                options={presentationOptions}
                                height={200}
                                hideReset
                                ignoreOverflow
                            />
                        </div>
                    </div>
                )}

                <div className={cl.flexContainerBottom}>
                    <div>
                        <Label text={t('lesson_wizard.edit_video_label')} />
                        <Button
                            className={cl.wideSelect}
                            variant={Chapters.Track}
                            text={t('lesson_wizard.edit_video')}
                            onClick={() => wizardStore.setExtraStep(true)}
                        />
                    </div>
                    {!currentLesson && (
                        <div>
                            <Label
                                text={t('users.level')}
                                isError={formErrors['difficulty']}
                                isRequired
                            />
                            <ToggleButton
                                className={cl.levelToggle}
                                options={LanguageLevels}
                                availableOptions={AvailableLanguageLevels}
                                disabled
                                value={
                                    LanguageLevels[exerciseParams.difficulty]
                                }
                                onChange={(value) =>
                                    handleInputChange(
                                        'difficulty',
                                        LanguageLevels.indexOf(value)
                                    )
                                }
                                variant={'outlined'}
                            />
                        </div>
                    )}
                    {isRange() && (
                        <div>
                            <Label
                                text={t(
                                    exerciseKind ===
                                        MediaExerciseTypes.SentencesOrder
                                        ? 'exercises.sentence_count'
                                        : exerciseKind ===
                                            MediaExerciseTypes.Summary
                                          ? 'exercises.summary_count'
                                          : 'exercises.question_count'
                                )}
                            />
                            <FilledRange
                                color={'var(--blue)'}
                                value={entitesAmount ?? rangeParams.Default}
                                min={rangeParams.Min}
                                step={rangeParams.Step}
                                max={rangeParams.Max}
                                onChange={handleRangeInput}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div
                className={`${cl.mediaEditor} ${extraStep ? '' : cl.hidden} ${isPresentation ? cl.small : ''}`}
                key={currentVideo}
            >
                {isPresentation && (
                    <div className={cl.extraParamsContainer}>
                        <div className={cl.toggleContainer}>
                            <Label
                                text={t('exercises.exercise_title')}
                                isError={formErrors['title']}
                                isRequired
                            />
                            <Input
                                variant={
                                    formErrors['title']
                                        ? 'erroredSmall'
                                        : 'outlinedSmall'
                                }
                                placeholder={t('exercises.enter_title')}
                                value={title}
                                onChange={setTitle}
                            />
                        </div>
                        <div className={cl.toggleContainer}>
                            <Label
                                text={t('exercises.presentation_task')}
                                isError={formErrors['description']}
                                isRequired
                            />
                            <Select
                                style={{ width: '100%' }}
                                label={t('exercises.select_value')}
                                value={description}
                                onChange={setDescription}
                                variant={'grey'}
                                options={presentationOptions}
                                height={200}
                                hideReset
                                ignoreOverflow
                            />
                        </div>
                    </div>
                )}
                <div className={cl.mediaEditContainer}>
                    <ExercisePlayer isPreview={false} />
                    <div className={cl.textContainer}>
                        <p>{trackText}</p>
                    </div>
                </div>
                <div className={cl.controlsContainer}>
                    <ExercisePlayerControls
                        mode={'video'}
                        isPreview={false}
                        limitDuration={!isPresentation}
                    />
                </div>
            </div>
            <Button
                text={t(getButtonText())}
                variant={Chapters.Exercise}
                onClick={handleGenerateExercise}
                progressDuration={getExerciseProgressDuration()}
            />
        </>
    );
});

const SelectBasicParams = observer(() => {
    const { t } = useTranslation();

    const { grammarOptions, vocabularyOptions, exerciseParams, exerciseType } =
        wizardStore;
    const { difficulty, grammarTopic, vocabularyTopic } = exerciseParams;

    const [formErrors, setFormErrors] = useState({});
    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredVocabulary, setFilteredVocabulary] =
        useState(vocabularyOptions);

    const isGrammar = exerciseType === ExerciseMediaTypes.Grammar;

    const handleNextStep = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof difficulty !== 'number',
                vocabularyTopic: !vocabularyTopic,
                grammarTopic: isGrammar && !grammarTopic,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            wizardStore.nextStep();
        });
    };

    const handleInputChange = (key, value) => {
        wizardStore.setExerciseParams({ ...exerciseParams, [key]: value });
    };

    useEffect(() => {
        setFormErrors({});
    }, [difficulty, grammarTopic, vocabularyTopic]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredGrammar(currentGrammar);

        const currentVocabulary = vocabularyOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredVocabulary(currentVocabulary);
    }, [grammarOptions, vocabularyOptions, difficulty]);

    return (
        <>
            <div>
                <Label
                    text={t('users.level')}
                    isError={formErrors['difficulty']}
                    isRequired
                />
                <ToggleButton
                    className={cl.levelToggle}
                    options={LanguageLevels}
                    availableOptions={AvailableLanguageLevels}
                    disabled
                    value={LanguageLevels[difficulty]}
                    onChange={(value) =>
                        handleInputChange(
                            'difficulty',
                            LanguageLevels.indexOf(value)
                        )
                    }
                    variant={formErrors['difficulty'] ? 'error' : 'outlined'}
                />
            </div>
            <div>
                <Label
                    text={t('exercises.select_lexical_topic')}
                    isError={formErrors['vocabularyTopic']}
                    isRequired
                />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    variant={
                        formErrors['vocabularyTopic'] ? 'errorSmall' : 'grey'
                    }
                    value={vocabularyTopic}
                    onChange={(value) =>
                        handleInputChange('vocabularyTopic', value)
                    }
                    options={filteredVocabulary}
                    hideReset
                    ignoreOverflow
                    height={240}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            {isGrammar && (
                <div>
                    <Label
                        text={t('exercises.select_grammar_topic')}
                        isError={formErrors['grammarTopic']}
                        isRequired
                    />
                    <Tree
                        label={t('lesson_wizard.grammar_topic')}
                        variant={
                            formErrors['grammarTopic'] ? 'errorSmall' : 'grey'
                        }
                        value={grammarTopic}
                        onChange={(value) =>
                            handleInputChange('grammarTopic', value)
                        }
                        options={filteredGrammar}
                        ignoreOverflow
                        height={180}
                        disabled={difficulty === ''}
                        tooltip={t('lesson_wizard.grammar_topic_select_level')}
                    />
                </div>
            )}
            <Button
                style={{ marginTop: 'auto' }}
                text={t('buttons.next')}
                variant={Chapters.Exercise}
                onClick={handleNextStep}
            />
        </>
    );
});

const ExerciseWizard = ({ onAdd }) => {
    const [view, step] = wizardStore.getCurrentViewAndStep();

    const { exerciseType } = wizardStore;
    const { currentLesson } = lessonsStore;

    const handleExerciseAdd = (data) => {
        if (currentLesson) {
            const { id, title } = data;
            onAdd({ chapterType: Chapters.Exercise, chapterId: id, title });
        } else {
            onAdd(data);
        }
    };

    useEffect(() => {
        if (!currentLesson && step === 'select') {
            wizardStore.resetExerciseParams();
        }
    }, [currentLesson, step]);

    if (!view || view !== WizardViews.Exercise) return null;

    switch (step) {
        case 'select':
            return currentLesson ? (
                <SelectExercise onSelect={handleExerciseAdd} />
            ) : (
                <ExerciseType />
            );
        case 'type':
            return currentLesson ? <ExerciseType /> : <SelectBasicParams />;
        case 'kind':
            return <ExerciseKind />;
        case 'source':
            return exerciseType === ExerciseMediaTypes.Video ? (
                <VideoSource />
            ) : (
                <TextSource />
            );
        case 'generate':
            return exerciseType === ExerciseMediaTypes.Video ? (
                <GenerateByVideo onGenerate={handleExerciseAdd} />
            ) : (
                <GenerateByText onGenerate={handleExerciseAdd} />
            );

        default:
            return null;
    }
};

export default observer(ExerciseWizard);
