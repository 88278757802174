import React, { useRef } from 'react';
import { t } from 'i18next';
import { motion, useInView } from 'framer-motion';
import Typography from '../Typography/Typography';
import ProfitCard from '../ProfitCard/ProfitCard';
import cl from './ProfitSection.module.css';
import LanguageBubble from '../LanguageBubble/LanguageBubble';

const ProfitSection = () => {
    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, { once: true, margin: '-300px' });

    return (
        <div ref={sectionRef} className={cl.profitSectionContainer}>
            <motion.div
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                <Typography
                    variant="h2"
                    className={cl.title}
                    dangerouslySetInnerHTML={t(
                        'teacher_tutorial.profit_section_title'
                    )}
                />
                <Typography
                    variant="h3"
                    className={cl.subtitle}
                    dangerouslySetInnerHTML={t(
                        'teacher_tutorial.profit_section_subtitle'
                    )}
                />
            </motion.div>

            <LanguageBubble language="es" className={cl.logoES} animate={{}} />

            <motion.div
                className={cl.cardsBlock}
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={{
                    hidden: { opacity: 0 },
                    visible: {
                        opacity: 1,
                        transition: { staggerChildren: 0.2 },
                    },
                }}
            >
                {[0, 1, 2, 3].map((index) => (
                    <motion.div
                        className={cl.cardWrapper}
                        key={index}
                        variants={{
                            hidden: { opacity: 0, y: 70 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        <ProfitCard index={index} />
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default ProfitSection;
