import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import situationsStore from './store/situationsStore';
import SituationsService from '../../api/SituationsService';
import LanguagesService from '../../api/LanguagesService';
import {
    SituationCategory,
    SituationCategoryCard,
    Situation,
    SearchBar,
    QueueList,
} from './components';
import { useFetching } from '../../hooks/useFetching';
import { List, PageWrapper } from '../../UI';

const SituationsAdministration = () => {
    const {
        language,
        searchString,
        level,
        categories,
        currentCategory,
        currentSituation,
        isQueue,
        wasChanged,
    } = situationsStore;

    const intervalRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [getLanguages] = useFetching(async () => {
        const { data } = await LanguagesService.getAllLanguages({
            ready: null,
        });
        situationsStore.setLanguageOptions(data);
    });

    const getCategories = async () => {
        setIsLoading(true);
        const categoriesRes = await SituationsService.getCategories({
            language,
        });
        const availableCategoriesRes =
            await SituationsService.getAvailableCategories({
                language,
            });
        const categories = categoriesRes.data.filter(
            (c, index, self) =>
                !self.slice(0, index).some((item) => item.id === c.id)
        );
        const mappedCategories = categories.map((c) => ({
            ...c,
            situations:
                availableCategoriesRes.data.find((a) => a.id === c.id)
                    ?.situations || [],
        }));
        setIsLoading(false);
        setCategoriesData(mappedCategories);
    };

    const setCategoriesData = (categories) => {
        situationsStore.setCategories(categories);
        if (situationsStore.currentCategory) {
            const newCategory = categories.find(
                (c) => c.id === situationsStore.currentCategory.id
            );
            situationsStore.setCurrentCategory(newCategory);
        }
        if (situationsStore.currentSituation) {
            const newSituation = categories
                .flatMap((c) => c.situations)
                .find((c) => c.id === situationsStore.currentSituation.id);
            if (newSituation) {
                situationsStore.setCurrentSituation({
                    ...newSituation,
                    situationInfoId:
                        situationsStore.currentSituation.situationInfoId,
                    statements: situationsStore.currentSituation.statements,
                });
            } else {
                situationsStore.setCurrentSituation({
                    ...situationsStore.currentSituation,
                    info: [],
                });
            }
        }
    };

    const renderCategories = () => {
        return (
            <List>
                {filteredCategories.map((cat) => (
                    <SituationCategoryCard
                        key={cat.id}
                        filtered={cat}
                        category={categories.find((c) => c.id === cat.id)}
                        style={
                            cat.situations.length
                                ? {}
                                : { filter: 'grayscale(1)' }
                        }
                    />
                ))}
            </List>
        );
    };

    useEffect(() => {
        getLanguages();
    }, []);

    useEffect(() => {
        getCategories();
    }, [language]);

    useEffect(() => {
        if (!searchString && !level) {
            setFilteredCategories(categories);
        } else {
            setFilteredCategories(
                categories
                    .map((c) => ({
                        ...c,
                        situations: c.situations.filter(
                            (s) =>
                                s.title
                                    .toLowerCase()
                                    .includes(searchString.toLowerCase()) &&
                                (level ? s.level.includes(level) : true)
                        ),
                    }))
                    .filter((c) => c.situations.length)
            );
        }
    }, [searchString, level, categories]);

    useEffect(() => {
        if (wasChanged) {
            getCategories();
            setTimeout(() => {
                situationsStore.setWasChanged(false);
            });
        }
    }, [wasChanged]);

    useEffect(() => {
        clearInterval(intervalRef.current);
        if (!currentSituation && !wasChanged) {
            intervalRef.current = setInterval(() => {
                getCategories();
            }, 3000);
        }
        return () => clearInterval(intervalRef.current);
    }, [currentSituation, currentCategory, wasChanged, language]);

    return (
        <PageWrapper>
            {isQueue ? (
                <QueueList />
            ) : currentSituation ? (
                <Situation />
            ) : situationsStore.currentCategory ? (
                <SituationCategory
                    isLoading={isLoading}
                    onRefresh={getCategories}
                />
            ) : (
                <>
                    <SearchBar
                        isLoading={isLoading}
                        onRefresh={getCategories}
                    />
                    {renderCategories()}
                </>
            )}
        </PageWrapper>
    );
};

export default observer(SituationsAdministration);
