import React from 'react';
import { ReactComponent as IconWordsOrder } from '../../../../../../assets/svg/exercise-icon-words.svg';
import { ReactComponent as IconStatementsTransformation } from '../../../../../../assets/svg/exercise-icon-transformation.svg';
import { ReactComponent as IconSentencesOrder } from '../../../../../../assets/svg/exercise-icon-sentences.svg';
import { ReactComponent as IconQuiz } from '../../../../../../assets/svg/exercise-icon-quiz.svg';
import { ReactComponent as IconAdvancedQuiz } from '../../../../../../assets/svg/exercise-icon-true-false.svg';
import { ReactComponent as IconSummary } from '../../../../../../assets/svg/exercise-icon-summary.svg';
import { ReactComponent as IconPresentation } from '../../../../../../assets/svg/exercise-icon-writing.svg';
import { ReactComponent as IconFreeFormQuestions } from '../../../../../../assets/svg/exercise-icon-questions.svg';
import { ReactComponent as IconGaps } from '../../../../../../assets/svg/exercise-icon-gaps.svg';
import { ReactComponent as IconCloze } from '../../../../../../assets/svg/exercise-icon-cloze.svg';
import { ReactComponent as IconMultichoice } from '../../../../../../assets/svg/exercise-icon-multichoice.svg';

import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import cl from './ExerciseIcon.module.css';

const ExerciseIcon = ({ type, additionalClass }) => {
    switch (type) {
        case GrammarExerciseTypes.Cloze:
            return (
                <IconCloze className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case GrammarExerciseTypes.Substitution:
            return (
                <IconGaps className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case GrammarExerciseTypes.Multichoice:
            return (
                <IconMultichoice
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case GrammarExerciseTypes.WordsOrder:
            return (
                <IconWordsOrder
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case GrammarExerciseTypes.StatementsTransformation:
            return (
                <IconStatementsTransformation
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.SentencesOrder:
            return (
                <IconSentencesOrder
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Quiz:
            return (
                <IconQuiz className={`${cl.icon} ${additionalClass ?? ''}`} />
            );
        case MediaExerciseTypes.AdvancedQuiz:
            return (
                <IconAdvancedQuiz
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Summary:
            return (
                <IconSummary
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.Presentation:
            return (
                <IconPresentation
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        case MediaExerciseTypes.FreeFormQuestions:
            return (
                <IconFreeFormQuestions
                    className={`${cl.icon} ${additionalClass ?? ''}`}
                />
            );
        default:
            return null;
    }
};

export default ExerciseIcon;
