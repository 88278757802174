import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import { PageWrapper } from '../../../../UI';
import { LessonsKanban } from '../../../../pages';
import cl from './UserLibrary.module.css';

const UserLibrary = () => {
    const { user } = authStore;
    const { level } = user;

    return (
        <PageWrapper>
            <LessonsKanban isUser presetDifficulty={level} />
        </PageWrapper>
    );
};

export default observer(UserLibrary);
