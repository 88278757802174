import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../App';
import { useFetching } from '../../hooks/useFetching';
import ExerciseService from '../../api/ExerciseService';
import languagesStore from '../../store/interface';
import teachersStore from './store/teachersStore';
import { TeachersGrid, TeachersSearchBar } from './components';
import LessonsKanban from '../../features/LessonsKanban/LessonsKanban';
import {
    onboardingStore,
    OnboardingModal,
    OnboardingKeys,
} from '../../features/Onboarding';
import { ViewTitle } from '../../UI';

const Teachers = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { currentTeacher, searchString, teacherSearchString } = teachersStore;

    const [teachers, setTeachers] = useState([]);

    const [getTeachers, isLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getTeachers({
            lang,
            searchString,
            teacherSearchString,
        });
        const items = data?.items ?? [];
        setTeachers(items.filter((t) => t.id !== authStore.user?.id));
    });

    useEffect(() => {
        getTeachers();
    }, [searchString, teacherSearchString]);

    useEffect(
        () => () => {
            teachersStore.setCurrentTeacher(null);
        },
        []
    );

    return (
        <>
            {currentTeacher ? (
                <LessonsKanban />
            ) : (
                <>
                    <ViewTitle text={t('navbar.teachers')} />
                    <TeachersSearchBar />
                    <TeachersGrid items={teachers} />
                </>
            )}
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.TeachersInfo
                )}
                title={t('teacher_onboarding.teachers_info')}
                subtitle={t('teacher_onboarding.teachers_info_subtitle')}
                delay={500}
            />
        </>
    );
};

export default observer(Teachers);
