import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconFolder } from '../../../../assets/svg/lessons-folder.svg';
import { MediaExerciseTypes } from '../../../TeacherContent/data/constants';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import { mapChapterData } from '../../../TeacherContent/helpers/mapChapterData';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
} from '../../../TeacherContent/views/ExercisesView/components';
import { SmallLoader } from '../../../../UI';
import cl from './DemoChapterCard.module.css';

const DemoChapterCard = ({ type, chapter, isActive, onClick }) => {
    const { t } = useTranslation();

    const [mappedChapter, setMappedChapter] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const { title, preview, params = [] } = mappedChapter;

    const getChapterType = () => {
        if (chapter.trackId && chapter.trackInterval) return 'video';
        if (Object.values(MediaExerciseTypes).includes(chapter.exerciseType))
            return 'text';
        if (chapter.text) return 'text';
        if (chapter.lexical) return 'lexical';
        return chapter.exerciseType || chapter.type;
    };

    const handleChapterClick = () => {
        onClick(chapter);
    };

    const renderWordCount = () => {
        const count = chapter?.wordsCount;

        return Number.isNaN(count) ? '' : count;
    };

    useEffect(() => {
        setMappedChapter(mapChapterData({ ...chapter }, type, t));
    }, [chapter]);

    return (
        <div
            className={`${cl.chapterCard} ${cl[type]} ${isActive ? cl.active : ''}`}
            onClick={handleChapterClick}
        >
            <div className={cl.imageContainer}>
                {preview && (
                    <>
                        {!isLoaded && (
                            <SmallLoader
                                size={12}
                                color={'var(--border-light-gray)'}
                            />
                        )}
                        <img
                            src={preview}
                            alt={title}
                            onLoad={() => setIsLoaded(true)}
                        />
                    </>
                )}
                {type === Chapters.Dictionary && (
                    <>
                        <div className={cl.folder}>
                            <IconFolder />
                        </div>
                        <p className={cl.count}>{renderWordCount()}</p>
                    </>
                )}
                {type === Chapters.Exercise && (
                    <>
                        <ExerciseIcon
                            type={chapter.exerciseType || chapter.type}
                        />
                        <ExerciseMediaIcon mediaType={chapter.mediaType} />
                    </>
                )}
            </div>
            <div className={cl.cardInner}>
                <p className={cl.title}>{title}</p>
                <div className={cl.infoContainer}>
                    {params.map((p, i) => (
                        <p className={`${cl.label} ${cl[type]}`} key={i}>
                            {p}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(DemoChapterCard);
