import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import player from '../../../../store/player';
import userStore from '../../store/userStore';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import { TranslationDisplay, TextDisplay, UserPlayer } from '..';
import { CloseButton } from '../../../../UI';
import { Roles } from '../../../../data/common';
import cl from './PonyPlayer.module.css';
import { useLocation } from 'react-router-dom';

const PonyPlayer = ({ trackId, onClose }) => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const { language: lang } = i18n;

    const isUser = authStore.user.role === Roles.User;

    const { externalId, wasPlayed } = player;

    const { clickedWords, chapterCloseModal, pendingEvent } = userStore;

    const isToDo = location.pathname.includes('todo');
    const isHistory = location.pathname.includes('history');

    const playerContainerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [getTrack] = useFetching(async ({ id }) => {
        const { data } = await TrackService.getTrackSentences({ id, lang });
        if (data && data.externalId !== externalId) {
            player.destroy();
        }
        player.parseTrackData(data);
    });

    const handleClickOutside = (e) => {
        if (
            playerContainerRef.current &&
            !playerContainerRef.current.contains(e.target) &&
            !chapterCloseModal &&
            wasPlayed &&
            (clickedWords.length || !(isToDo || isHistory))
        ) {
            e.preventDefault();
            if (!pendingEvent) {
                userStore.setPendingEvent(e);
                player.playerRef?.pause();
            }
            userStore.setChapterCloseModal(true);
        }
    };

    useEffect(() => {
        getTrack({ id: trackId });
    }, [trackId]);

    useEffect(() => {
        if (!chapterCloseModal && isUser) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        chapterCloseModal,
        clickedWords,
        pendingEvent,
        isUser,
        wasPlayed,
        isToDo,
        isHistory,
    ]);

    return (
        <div className={cl.trackPlayer} key={trackId} ref={playerContainerRef}>
            <TranslationDisplay currentIndex={currentIndex} />
            <UserPlayer
                trackId={trackId}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                isToDo={isToDo}
            />
            <TextDisplay
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            />
            {onClose && (
                <CloseButton className={cl.closeButton} onClick={onClose} />
            )}
        </div>
    );
};

export default observer(PonyPlayer);
