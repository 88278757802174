import React from 'react';
import { t } from 'i18next';
import { ReactComponent as Check } from '../../../../assets/svg/icon-check.svg';
import { ReactComponent as Dash } from '../../../../assets/svg/icon-dash.svg';

import cl from './CostCard.module.css';

const featuresByRole = {
    student: [
        { name: 'costcard_function_do_exercises', free: true, premium: true },
        {
            name: 'costcard_function_library',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_watch_video',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_do_dialogues',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_do_dictionaries',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_add_selected_to_dictionaries_video',
            free: false,
            premium: true,
        },
    ],
    teacher: [
        {
            name: 'costcard_function_library',
            free: true,
            premium: true,
        },
        {
            name: 'costcard_function_gen_exercise',
            free: true,
            premium: true,
        },
        {
            name: 'costcard_function_gen_dialogues',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_gen_words',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_import_video',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_create_lessons',
            free: false,
            premium: true,
        },
        {
            name: 'costcard_function_manage_progress',
            free: false,
            premium: true,
        },
    ],
};

const CostCard = ({ role }) => {
    const features = featuresByRole[role];

    return (
        <div className={cl.cardContainer}>
            <p className={cl.role}>
                {t(`teacher_tutorial.${role}_card_title`)}
            </p>
            <table className={cl.featureTable}>
                <thead>
                    <tr>
                        <th>{`${t(role === 'teacher' ? 'teacher_tutorial.costacard_features_teachers' : 'teacher_tutorial.costcard_features')}:`}</th>
                        <th className={cl.iconCell}>
                            {t(`teacher_tutorial.costcard_${role}_free`)}
                        </th>
                        <th className={cl.iconCell}>
                            {t(`teacher_tutorial.costcard_${role}_premium`)}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {features.map((feature, index) => (
                        <tr key={index}>
                            <td>{t(`teacher_tutorial.${feature.name}`)}</td>
                            <td className={cl.iconCell}>
                                {feature.free ? (
                                    <Check className={cl.icon} />
                                ) : (
                                    <Dash className={cl.icon} />
                                )}
                            </td>
                            <td className={cl.iconCell}>
                                {feature.premium ? (
                                    <Check className={cl.icon} />
                                ) : (
                                    <Dash className={cl.icon} />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CostCard;
