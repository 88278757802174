import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../App';
import languagesStore from '../../store/interface';
import { onboardingStore } from '../../features/Onboarding';
import UserService from '../../api/UserService';
import { useFetching } from '../../hooks/useFetching';
import { Loader, PageWrapper } from '../../UI';
import { Button, Input, Label, Select, ToggleButton } from '../../UI';
import { LanguageLevels, Roles } from '../../data/common';
import { ROUTES } from '../../router';
import { ReactComponent as IconLogout } from '../../assets/svg/icon-logout.svg';
import { ReactComponent as IconBin } from '../../assets/svg/user-icon-bin.svg';

import { AvailableLanguageLevels } from '../../features/LessonsKanban/components/LessonWizard/components/ExerciseWizard/ExerciseWizard';
import cl from './Profile.module.css';

const ProfileViews = {
    Profile: 'profile',
    Learn: 'learn',
    Subscription: 'subscription',
};

const Profile = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const { availableLanguages, lang, languages, nativeLang } = languagesStore;

    const { user, userTeachers } = authStore;
    const { level, role } = user;

    const [activeView, setActiveView] = useState(ProfileViews.Profile);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sex, setSex] = useState('');

    const [logout, logoutLoading] = useFetching(async () => {
        await UserService.logout();
    });

    const [updateUser, isLoading] = useFetching(
        async ({ lang, level, nativeLang }) => {
            const { id } = user;
            const res = await UserService.updateUser(
                lang || nativeLang
                    ? { id, lang, nativeLang }
                    : typeof level === 'number'
                      ? { id, level }
                      : { id, name, sex }
            );

            if (res && res.status === 204) {
                authStore.setUser(
                    lang
                        ? { ...user, lang }
                        : nativeLang
                          ? { ...user, nativeLang }
                          : typeof level === 'number'
                            ? { ...user, level }
                            : { ...user, name, sex }
                );
            }
        }
    );

    const [unfollowTeacher] = useFetching(async (id) => {
        await UserService.unfollowTeacher({ id });

        authStore.deleteUserTeacher(id);
    });

    useEffect(() => {
        setName(user.name ?? '');
        setEmail(user.email ?? '');
        setSex(user.sex ?? '');
    }, [user]);

    const resetOnboarding = () => {
        onboardingStore.setIsCompleted(false);
        navigate(ROUTES.students);
    };

    const hasChanged = () => {
        if (name && name !== user.name) return true;
        if (sex && sex !== user.sex) return true;
        return false;
    };

    const handleLogout = async () => {
        await logout();
        authStore.logout(navigate);
    };

    const getCurrentLangName = () =>
        languages.find((l) => l.value === lang)?.name ?? '';

    const getCurrentInterfaceLangName = () =>
        languages.find((l) => l.value === i18n.language)?.name ?? '';

    const getCurrentNativeLangName = () =>
        languages.find((l) => l.value === nativeLang)?.name ?? '';

    const handleLangChange = (lang) => {
        updateUser({ lang });
    };

    const handleNativeLangChange = (nativeLang) => {
        updateUser({ nativeLang });
    };

    const handleViewSelect = (view) => {
        if (view === activeView) return;
        setActiveView(view);
    };

    const handleDiffcultyChange = (difficulty) => {
        updateUser({ level: LanguageLevels.indexOf(difficulty) });
    };

    const renderView = () => {
        switch (activeView) {
            case ProfileViews.Profile:
                return (
                    <div className={cl.viewInner}>
                        <div className={cl.horizontalCont}>
                            <div className={cl.inputCont}>
                                <Label text={t('profile.name')} />
                                <Input
                                    variant={'outlinedSmall'}
                                    value={name}
                                    onChange={setName}
                                />
                            </div>
                            <div className={cl.inputCont}>
                                <Label text={t('email')} />
                                <Input
                                    variant={'outlinedSmall'}
                                    value={email}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className={cl.horizontalCont}>
                            <div className={cl.inputCont}>
                                <Label
                                    text={t(
                                        role === Roles.User
                                            ? 'users.lang'
                                            : 'situations.language'
                                    )}
                                />
                                <Select
                                    className={cl.select}
                                    label={getCurrentLangName()}
                                    variant={'outlinedSmall'}
                                    value={lang}
                                    options={availableLanguages}
                                    onChange={handleLangChange}
                                    hideReset
                                    height={200}
                                />
                            </div>

                            <div className={cl.levelCont}>
                                <Label text={t('users.level')} />
                                <ToggleButton
                                    className={cl.levelToggle}
                                    options={LanguageLevels}
                                    availableOptions={AvailableLanguageLevels}
                                    disabled
                                    value={LanguageLevels[level]}
                                    onChange={handleDiffcultyChange}
                                    variant={'outlined'}
                                />
                            </div>
                        </div>

                        <div className={cl.inputCont}>
                            <Label text={t('users.interface_lang')} />
                            <Select
                                className={cl.select}
                                label={getCurrentInterfaceLangName()}
                                variant={'outlinedSmall'}
                                value={i18n.language}
                                options={availableLanguages}
                                onChange={languagesStore.setInterfaceLang}
                                hideReset
                                height={200}
                            />
                        </div>
                        <div className={cl.inputCont}>
                            <Label
                                text={t(
                                    role === Roles.Teacher
                                        ? 'users.student_lang'
                                        : 'users.native'
                                )}
                            />
                            <Select
                                className={cl.select}
                                label={getCurrentNativeLangName()}
                                variant={'outlinedSmall'}
                                value={nativeLang}
                                options={languages.filter(
                                    (lObj) => lObj.value !== lang
                                )}
                                onChange={handleNativeLangChange}
                                hideReset
                                height={200}
                            />
                        </div>

                        <div className={cl.horizontalCont}>
                            {hasChanged() && (
                                <Button
                                    text={t('buttons.save_changes')}
                                    variant={'purple'}
                                    onClick={updateUser}
                                />
                            )}
                        </div>
                    </div>
                );
            case ProfileViews.Learn:
                return (
                    <div className={cl.viewInner}>
                        <div>
                            <Label text={t('profile.my_teachers')} />
                            {userTeachers?.length ? (
                                <div className={cl.teachersList}>
                                    {userTeachers.map((tObj) => (
                                        <div
                                            className={cl.teacherCard}
                                            key={tObj.id}
                                        >
                                            <p>{tObj.name}</p>
                                            <IconBin
                                                onClick={() =>
                                                    unfollowTeacher(tObj.id)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className={cl.emptyAlert}>
                                    {t('profile.no_active_teachers')}
                                </p>
                            )}
                        </div>
                    </div>
                );
            case ProfileViews.Subscription:
                return <></>;
            default:
                return <></>;
        }
    };

    return (
        <PageWrapper additionalClass={cl.profileWrapper}>
            {isLoading && (
                <Loader
                    style={{
                        position: 'absolute',
                        zIndex: 3,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
            <div className={cl.profile}>
                <div className={cl.aside}>
                    {Object.values(ProfileViews).map((view) => (
                        <Button
                            key={view}
                            className={cl.button}
                            variant={view === activeView ? 'grey' : 'white'}
                            text={t(`profile.${view}_view`)}
                            onClick={() => handleViewSelect(view)}
                            isDisabled={view === ProfileViews.Subscription}
                        />
                    ))}
                    {role === Roles.Teacher && (
                        <Button
                            className={cl.button}
                            variant={'grey'}
                            text={t('teacher_onboarding.reset_onboarding')}
                            onClick={resetOnboarding}
                        />
                    )}
                    <Button
                        className={cl.button}
                        icon={
                            logoutLoading ? null : (
                                <IconLogout className={cl.logoutIcon} />
                            )
                        }
                        variant={'red'}
                        text={t('buttons.logout')}
                        onClick={handleLogout}
                        isLoading={logoutLoading}
                    />
                </div>
                <div className={cl.viewBlock}>
                    <p className={cl.viewTitle}>
                        {t(`profile.${activeView}_view`)}
                    </p>
                    {renderView()}
                </div>
            </div>
        </PageWrapper>
    );
};

export default observer(Profile);
