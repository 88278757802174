import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chapters } from '../../../../data/constants';
import { ReactComponent as IconStars } from '../../../../../../assets/svg/lessons-stars.svg';
import { ReactComponent as IconFav } from '../../../../../../assets/svg/icon-fav.svg';
import { ReactComponent as IconLibrary } from '../../../../../../assets/svg/icon-library.svg';
import cl from './SelectContentButtons.module.css';

const mapTextKeysByChapter = {
    [Chapters.Dictionary]: [
        'teacher_navbar.dictionaries',
        'teacher_onboarding.select_words',
        'exercises.add_new_words',
    ],
    [Chapters.Track]: [
        'teacher_navbar.tracks',
        'teacher_onboarding.select_track',
        'exercises.add_new_track',
    ],
    [Chapters.Exercise]: [
        'teacher_navbar.exercises',
        'teacher_onboarding.select_exercise',
        'exercises.add_new_exercise',
    ],
    [Chapters.Situation]: [
        'teacher_navbar.situations',
        'teacher_onboarding.select_situation',
        'exercises.add_new_situation',
    ],
};

const SelectContentButtons = ({
    variant,
    onOwnClick,
    onLibraryClick,
    onCreateClick,
    ownCount,
    libraryCount,
}) => {
    const { t } = useTranslation();

    const textKeys = mapTextKeysByChapter[variant];

    return (
        <div className={`${cl.cardContainer} ${cl[variant]}`}>
            <div className={cl.card} onClick={onOwnClick}>
                <div className={cl.iconContainer}>
                    <IconFav className={cl.strokeIcon} />
                    <span className={cl.count}>{ownCount}</span>
                </div>
                <div className={cl.cardText}>
                    <p>{t(textKeys[0])}</p>
                    <span>{t(`lesson_wizard.${variant}_own_subtitle`)}</span>
                </div>
            </div>
            <div className={cl.card} onClick={onLibraryClick}>
                <div className={cl.iconContainer}>
                    <IconLibrary className={cl.fillIcon} />
                    <span className={cl.count}>{libraryCount}</span>
                </div>
                <div className={cl.cardText}>
                    <p>{t(textKeys[1])}</p>
                    <span>
                        {t(`lesson_wizard.${variant}_library_subtitle`)}
                    </span>
                </div>
            </div>
            <div
                className={`${cl.card} ${onCreateClick ? '' : cl.disabled}`}
                onClick={onCreateClick}
            >
                <IconStars className={cl.strokeIcon} />
                <div className={cl.cardText}>
                    <p> {t(textKeys[2])}</p>
                    <span>{t(`lesson_wizard.${variant}_create_subtitle`)}</span>
                </div>
            </div>
        </div>
    );
};

export default SelectContentButtons;
