import React, { useEffect, useState } from 'react';
import { useFetching } from '../../../../../../hooks/useFetching';
import { observer } from 'mobx-react-lite';
import ProgressService from '../../../../../../api/ProgressService';
import TrackService from '../../../../../../api/TrackService';
import exercisesStore from '../../../../../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import { SelectVideoModal } from '../../../../../../pages/TeacherContent/components';
import { LanguageLevels } from '../../../../../../data/common';
import { Chapters } from '../../../../data/constants';
import SelectContentButtons from '../SelectContentButtons/SelectContentButtons';

const VideoWizard = ({ onAdd }) => {
    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;

    const { difficulty } = currentLesson;

    const [isModal, setModal] = useState(false);
    const [isOwn, setIsOwn] = useState(false);

    const [allTracksCount, setAllTracksCount] = useState(null);
    const [favTracksCount, setFavTracksCount] = useState(null);

    const [getTracks, isLoading] = useFetching(async () => {
        const params = {
            offset: 0,
            limit: 50,
            lang,
            difficulty,
            processed: null,
            recognized: true,
        };

        const { data: allTracks } = await TrackService.getTracks(params);
        const { data: favTracks } = await TrackService.getFavorites(params);

        setAllTracksCount(allTracks.total || 0);
        setFavTracksCount(favTracks.total || 0);
    });

    const handleSelectClick = () => {
        setIsOwn(false);
        setTimeout(() => {
            setModal(true);
        });
    };

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            setModal(true);
        });
    };

    const addVideo = async ({ id, title }) => {
        if (!isOwn) {
            ProgressService.addTrack({ trackId: id, language: lang }).then(() =>
                exercisesStore.refresh()
            );
        }
        onAdd({ chapterId: id, title, chapterType: Chapters.Track });
    };

    useEffect(() => {
        getTracks();
    }, []);

    return (
        <>
            <SelectContentButtons
                variant={Chapters.Track}
                onOwnClick={handleOwnClick}
                onLibraryClick={handleSelectClick}
                ownCount={favTracksCount}
                libraryCount={allTracksCount}
            />
            <SelectVideoModal
                visible={isModal}
                setVisible={setModal}
                onClick={addVideo}
                // TODO: pass already added categoriees packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
                presetDifficulty={LanguageLevels[difficulty]}
            />
        </>
    );
};

export default observer(VideoWizard);
