import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shuffleArray from '../../../../utils/shuffleArray';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './MultichoicePreview.module.css';

const MultichoicePreview = ({
    exerciseObj,
    results,
    showAnswers,
    isPdf = false,
    isPreview = false,
}) => {
    const { t } = useTranslation();

    const { sentences } = exerciseObj.data;

    const wordRef = useRef();
    const tooltipRef = useRef();
    const testRef = useRef();

    const [words, setWords] = useState([]);
    const [dropdownAnswers, setDropdownAnswers] = useState([]);
    const [currentDropdown, setCurrentDropdown] = useState(null);
    const [displayAbove, setDisplayAbove] = useState(false);

    const renderWord = (w) => {
        if (!w?.options) return w.word;

        const classNames = [cl.dropdown];
        if (currentDropdown === w.id) classNames.push(cl.selected);
        if (results || showAnswers) {
            classNames.push(cl.results);
            const isCorrect =
                dropdownAnswers.find((a) => a.word.id === w.id)?.answer ===
                w.word;
            classNames.push(isCorrect ? cl.correct : cl.wrong);
        }

        if (isPdf) {
            return (
                <span className={cl.pdfOptions}>
                    {`${w?.options?.join(', ')}`}
                </span>
            );
        }

        return (
            <span
                key={w.id}
                className={classNames.join(' ')}
                onClick={results ? () => {} : () => handleWordClick(w.id)}
                ref={currentDropdown === w.id ? tooltipRef : null}
            >
                <span className={cl.dropdownIcon}></span>
                {dropdownAnswers.find((a) => a.word.id === w.id)?.answer}
                <div
                    className={cl.dropdownTooltip}
                    ref={currentDropdown === w.id ? testRef : null}
                >
                    <div className={cl.optionsCont}>
                        {w?.options
                            .filter((o) => o.length)
                            .map((o, i) => (
                                <span
                                    key={`${o}${i}`}
                                    onClick={() => handleOptionClick(w, o)}
                                >
                                    {o}
                                </span>
                            ))}
                    </div>
                </div>
            </span>
        );
    };

    const handleWordClick = (id) => {
        setCurrentDropdown(currentDropdown === id ? null : id);
    };

    const handleOptionClick = (word, option) => {
        const newAnswers = dropdownAnswers.map((a) => {
            if (a.word.id === word.id) {
                return { ...a, answer: option };
            }
            return a;
        });
        setDropdownAnswers(newAnswers);
    };

    const displayAnswers = () => {
        setDropdownAnswers(
            dropdownAnswers.map((a) => ({
                ...a,
                answer: a.word.word,
            }))
        );
    };

    const resetAnswers = () => {
        if (dropdownAnswers.some((w) => w.answer)) {
            setDropdownAnswers(
                dropdownAnswers.map((w) => ({ ...w, answer: '' }))
            );
        }
    };

    const handleClickOutside = (e) => {
        if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
            setCurrentDropdown(null);
        }
    };

    const getAnswerById = (userAnswers, id) => {
        for (let i = 0; i < userAnswers.length; i++) {
            if (userAnswers[i][id] !== undefined) {
                return userAnswers[i][id];
            }
        }
        return null;
    };

    const positionTooltip = () => {
        if (testRef.current && wordRef.current) {
            const rect = wordRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const tooltipHeight = testRef.current.offsetHeight || 200;

            setDisplayAbove(rect.bottom + tooltipHeight > windowHeight);

            testRef.current.style.position = 'fixed';
            testRef.current.style.top = displayAbove
                ? `${rect.top - tooltipHeight}px`
                : `${rect.bottom}px`;
            testRef.current.style.left = `${rect.left}px`;
            testRef.current.style.zIndex = 1000;
        }
    };

    useEffect(() => {
        positionTooltip();
    }, [currentDropdown, displayAbove]);

    useEffect(() => {
        if (results) return;
        showAnswers ? displayAnswers() : resetAnswers();
    }, [showAnswers]);

    useEffect(() => {
        const flatWords = sentences?.map((s) => s.words).flat() ?? [];
        const dropdownWords = flatWords
            .filter((w) => w?.options)
            .map((w) => ({ word: w, answer: '' }));
        setWords(
            flatWords.map((w) =>
                w?.options
                    ? { ...w, options: shuffleArray(w?.options ?? []) }
                    : w
            )
        );
        if (!results?.userAnswers) {
            setDropdownAnswers(dropdownWords);
            return;
        }
        const { userAnswers } = results;
        setDropdownAnswers(
            dropdownWords.map((a) => ({
                ...a,
                answer: getAnswerById(userAnswers, a.word.id),
            }))
        );
    }, [results]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tooltipRef]);

    const getLabel = () => {
        if (results) return 'student_answers';
        if (isPdf) return 'multichoice_pdf_info';
        if (isPreview) return 'multichoice_preview_info';
        return 'text';
    };

    return (
        words.length !== 0 && (
            <>
                <QuestionsLabel
                    label={getLabel()}
                    variant={isPreview ? 'demo' : ''}
                />
                <div className={cl.exerciseText}>
                    {words.map((w) => (
                        <span
                            ref={currentDropdown === w.id ? wordRef : null}
                            className={cl.wordContainer}
                            key={w.id}
                        >
                            {renderWord(w)}
                            {w.endChar && <span>{w.endChar}</span>}
                        </span>
                    ))}
                </div>
            </>
        )
    );
};

export default MultichoicePreview;
