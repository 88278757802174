import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/svg/arrow_left.svg';
import { ROUTES } from '../../router';
import cl from './BackPageArrow.module.css';

const ROUTES_WITH_BACK_BUTTON = [
    ROUTES.add_track,
    ROUTES.profile,
    ROUTES.listen,
];

const BackPageArrow = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const handleNavLinkClick = () => {
        navigate(-1);
    };

    const isArrowVisible = () => {
        if (location.pathname.length <= 1) return false;
        if (location.pathname.includes(ROUTES.users) && id) return true;
        return ROUTES_WITH_BACK_BUTTON.some((r) =>
            location.pathname.includes(r)
        );
    };

    return (
        <div
            onClick={handleNavLinkClick}
            className={`${cl.arrow} ${isArrowVisible() ? cl.visible : ''}`}
        >
            <ArrowLeft />
        </div>
    );
};

export default BackPageArrow;
