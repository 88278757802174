import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import userWordsStore from '../../../UserPlatform/store/userWords';
import { Button } from '../../../../UI';
import { ReactComponent as IconSound } from '../../../../assets/svg/icon-sound.svg';
import cl from './KeyboardCard.module.css';

const KeyboardCard = ({ wordObj, mode, onComplete }) => {
    const audioMode = mode === 'audio';
    const textMode = mode === 'text';

    const { playbackSpeed } = userWordsStore;

    const [isCorrect, setIsCorrect] = useState(null);
    const [showTranslation, setShowTranslation] = useState(textMode);
    const [keyboardInput, setKeyboardInput] = useState('');
    const [audio] = useState(new Audio());

    const { word, translations, voicePath } = wordObj;

    const handleCheck = () => {
        if (keyboardInput.toLowerCase() === word.toLowerCase()) {
            setIsCorrect(true);
            setShowTranslation(true);
        } else {
            setIsCorrect(false);
        }
    };

    const handleKeyDown = (e) => {
        setIsCorrect(null);
        if (e.key === 'Backspace') {
            setKeyboardInput((prev) => prev.slice(0, -1));
        } else if (/^[a-zA-Z0-9 ]$/.test(e.key)) {
            setKeyboardInput((prev) => (prev + e.key).slice(0, word.length));
        }
    };

    const handleAudioClick = () => {
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    useEffect(() => {
        if (voicePath) {
            audio.pause();
            audio.src = voicePath;
            audio.play();
        } else {
            audio.src = '';
        }
    }, [voicePath]);

    useEffect(() => {
        setKeyboardInput([]);
        setIsCorrect(null);
        setShowTranslation(textMode);
    }, [word, mode, textMode]);

    useEffect(() => {
        if (keyboardInput.length === word.length) {
            handleCheck();
        }
    }, [keyboardInput]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (isCorrect) {
            audio.play();

            audio.addEventListener('ended', onComplete);

            return () => {
                audio.removeEventListener('ended', onComplete);
            };
        }
    }, [isCorrect]);

    return (
        <div className={cl.keyboardCard}>
            <div
                className={`${cl.answerCont} ${
                    isCorrect !== null
                        ? isCorrect
                            ? cl.correct
                            : cl.wrong
                        : ''
                }`}
            >
                {Array(word.length)
                    .fill('')
                    .map((_t, i) => (
                        <span className={cl.letter} key={i}>
                            {keyboardInput[i]}
                        </span>
                    ))}
            </div>
            <div style={{ marginBottom: textMode ? 'auto' : '' }}>
                <p
                    className={`${cl.translationText} ${showTranslation ? '' : cl.blur}`}
                    onClick={() => setShowTranslation(!showTranslation)}
                >
                    {translations?.join(', ')}
                </p>
            </div>
            {audioMode && (
                <Button
                    className={cl.playButton}
                    icon={<IconSound />}
                    variant={'grey'}
                    onClick={handleAudioClick}
                />
            )}
        </div>
    );
};

export default observer(KeyboardCard);
