import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '..';
import { CloseButton } from '../../UI';
import { FileTypes } from '../../data/common';
import cl from './FileUploader.module.css';

const FileUploader = ({
    type,
    visible,
    setVisible,
    inputKey,
    onSave,
    isLessons,
}) => {
    const { t } = useTranslation();

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files) {
            onSave(e.dataTransfer.files[0]);
            setVisible(false);
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files[0]) {
            onSave(e.target.files[0]);
            setVisible(false);
        }
    };

    const getModalTitle = () => {
        switch (type) {
            case FileTypes.Audio:
                return t('exercises.file_modal_title');
            case FileTypes.Text:
                return t('dictionary_administration.upload_file');
            case FileTypes.Image:
                return t('exercises.file_modal_title');
            case FileTypes.Pdf:
                return t('exercises.file_modal_title');
            default:
                return '';
        }
    };

    const getModalSubtitle = () => {
        switch (type) {
            case FileTypes.Audio:
                return t('exercises.file_modal_subtitle');
            case FileTypes.Text:
                return t('dictionary_administration.upload_file_subtitle');
            case FileTypes.Image:
                return t('exercises.file_modal_subtitle');
            case FileTypes.Pdf:
                return t('exercises.file_modal_subtitle');
            default:
                return '';
        }
    };

    const getAccept = () => {
        switch (type) {
            case FileTypes.Text:
                return 'text/plain';
            case FileTypes.Pdf:
                return 'pdf';
            case FileTypes.Audio:
            case FileTypes.Image:
            default:
                return `${type}/*`;
        }
    };

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            className={isLessons ? cl.lessonModal : ''}
        >
            {visible && (
                <div
                    className={`${cl.fileModal} ${isLessons ? cl.lessons : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <p className={cl.uploadTitle}>{getModalTitle()}</p>
                    <p className={cl.uploadSubtitle}>{getModalSubtitle()}</p>
                    <span className={cl.uploadIcon} />
                    <div className={cl.inputCont}>
                        <p className={cl.uploadSubtitle}>
                            {t('exercises.file_modal_button_text')}
                        </p>
                        <label className={cl.customUploadButton}>
                            {t('exercises.upload_button')}
                            <input
                                type="file"
                                accept={getAccept()}
                                key={inputKey}
                                onChange={handleFileSelect}
                            />
                        </label>
                    </div>
                    <CloseButton onClick={() => setVisible(false)} />
                </div>
            )}
        </Modal>
    );
};

export default FileUploader;
