import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import getTopCenterAndOffset from '../../../../utils/getTopCenterAndOffset';
import toHoursAndMinutes from '../../../../utils/toHoursAndMinutes';
import player from '../../../../store/player';
import { ReactComponent as IconGoNext } from '../../../../assets/svg/player-forward.svg';
import { ReactComponent as IconGoPrev } from '../../../../assets/svg/player-rewind.svg';
import { ReactComponent as IconPlay } from '../../../../assets/svg/player-play.svg';
import { ReactComponent as IconPause } from '../../../../assets/svg/player-pause.svg';
import { ReactComponent as IconPauseSwitch } from '../../../../assets/svg/player-switch.svg';
import { TicksInSecond } from '../../../../data/common';
import cl from './UserPlayerControls.module.css';

const HALF_SECOND = TicksInSecond / 2;
const SPEED_OPTIONS = ['0.7x', '0.8x', '0.9x', '1x'];

const UserPlayerControls = ({ currentIndex, setCurrentIndex }) => {
    const { t } = useTranslation();

    const speedPickerRef = useRef();
    const debounceTimer = useRef();

    const [isDebounce, setIsDebounce] = useState(false);
    const [speedPicker, setSpeedPicker] = useState(false);

    const [isPrevAvaliable, setIsPrevAvaliable] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    const toggleSpeedPicker = () => {
        setSpeedPicker(!speedPicker);
    };

    const togglePauseAfterEach = () => {
        player.setPauseAfterEach(!player.pauseAfterEach);
    };

    const percent = (player.currentTime / player.duration) * 100;

    const debounceClick = () => {
        setIsDebounce(true);
        clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(() => {
            setIsDebounce(false);
        }, 100);
    };

    const handlePrevNextSentenceButton = (direction) => {
        if (isDebounce) return;
        debounceClick();
        const { sentences, currentSentenceObj } = player;
        const nextIndex =
            direction === 'prev' ? currentIndex - 1 : currentIndex + 1;
        const newSentenceObj = sentences[nextIndex];
        if (direction === 'prev') {
            if (!isPrevAvaliable || !newSentenceObj) {
                handlePlayerRewind(
                    currentSentenceObj,
                    newSentenceObj,
                    currentIndex
                );
                setIsPrevAvaliable(true);
                return;
            }
            handlePlayerRewind(
                newSentenceObj,
                sentences[nextIndex - 1],
                nextIndex
            );
        }
        if (direction === 'next') {
            handlePlayerRewind(newSentenceObj, currentSentenceObj, nextIndex);
        }
    };

    const handlePlayerRewind = (targetSentence, neighborSentence, index) => {
        if (!targetSentence) return;
        player.setWasForwarded(true);
        if (!neighborSentence || !neighborSentence.words) {
            const newTime = targetSentence.offset - TicksInSecond / 5;
            player.handleChangeAudioTime(newTime, true);
            setCurrentIndex(0);
            return;
        }
        const neighborWord = neighborSentence.words.slice(-1)[0];
        const neighborEndTime = neighborWord.offset + neighborWord.duration;
        const currentOffset = targetSentence.offset;
        const interval = Math.min(
            (currentOffset - neighborEndTime) / 2,
            HALF_SECOND
        );
        const newTime = targetSentence.offset - interval;
        player.handleChangeAudioTime(newTime, true);
        setCurrentIndex(index);
    };

    const handleTracklineClick = (e) => {
        const { clientX, target } = e;
        const { left, width } = getTopCenterAndOffset(target);

        const clickPercent = ((clientX - left) / width) * 100;
        const newTime = (player.duration * clickPercent) / 100;

        player.handleChangeAudioTime(newTime, true);
        player.setWasForwarded(false);
    };

    const handlePlayPauseClick = () => {
        if (!player.isPlaying) {
            const sentence = player.sentences[currentIndex];
            if (sentence && !player.wasPaused) {
                const lastActiveIdx = sentence.words.findLastIndex(
                    (w) => w.active
                );
                const targetIdx = lastActiveIdx >= 2 ? lastActiveIdx - 2 : 0;
                const targetWord = sentence.words[targetIdx];
                if (lastActiveIdx !== -1 && targetWord && targetWord.offset) {
                    player.handleChangeAudioTime(targetWord.offset, true);
                }
            }
        }
        player.handlePlayPauseClick();
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                speedPickerRef.current &&
                !speedPickerRef.current.contains(event.target)
            ) {
                setSpeedPicker(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [speedPickerRef]);

    useEffect(() => {
        player.setHighlightTranslation(null);
    }, [player.isPlaying]);

    useEffect(() => {
        if (!player.sentences) return;
        setIsNextDisabled(
            player.sentences.length > 1 && !player.sentences[currentIndex + 1]
        );
        setIsPrevDisabled(!player.sentences[currentIndex - 1]);
    }, [currentIndex]);

    return (
        <div className={cl.controlsCont}>
            <div className={cl.trackLineWrapper}>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(
                        Math.round(player.currentTime / TicksInSecond)
                    )}
                </span>
                <div className={cl.trackLine} onClick={handleTracklineClick}>
                    <div
                        className={cl.trackProgress}
                        style={{ width: `${percent}%` }}
                    />
                    <div
                        className={cl.dragger}
                        style={{ left: `${percent}%` }}
                    />
                </div>
                <span className={cl.timeItem}>
                    {toHoursAndMinutes(player.duration / TicksInSecond)}
                </span>
            </div>
            <div
                className={`${cl.buttonsContainer} ${
                    player.isLoaded ? '' : cl.disabled
                }`}
            >
                <button
                    ref={speedPickerRef}
                    className={`${cl.playbackBtn} ${player.playbackSpeed !== '1x' ? cl.active : ''}`}
                    onClick={toggleSpeedPicker}
                >
                    <span>{parseFloat(player.playbackSpeed).toFixed(1)}</span>
                    <div
                        className={`${cl.speedOptionsContainer}  ${speedPicker ? cl.visible : ''}`}
                    >
                        {SPEED_OPTIONS.map((speed, i) => (
                            <div
                                className={`${cl.speedBtn} ${
                                    player.playbackSpeed === speed
                                        ? cl.active
                                        : ''
                                }`}
                                key={i}
                                onClick={() => {
                                    player.setPlaybackSpeed(speed);
                                }}
                            >
                                {parseFloat(speed).toFixed(1)}
                            </div>
                        ))}
                    </div>
                </button>
                <button
                    className={`${cl.basicControlButton} ${isPrevDisabled ? cl.disabled : ''}`}
                    onClick={() => handlePrevNextSentenceButton('prev')}
                >
                    <IconGoPrev />
                </button>
                <button
                    className={cl.basicControlButton}
                    onClick={handlePlayPauseClick}
                >
                    {player.isPlaying ? <IconPause /> : <IconPlay />}
                </button>
                <button
                    className={`${cl.basicControlButton} ${isNextDisabled ? cl.disabled : ''}`}
                    onClick={() => handlePrevNextSentenceButton('next')}
                >
                    <IconGoNext />
                </button>
                <button
                    className={`${cl.autoPauseBtn} ${
                        player.pauseAfterEach ? cl.active : ''
                    }`}
                    title={t('user_view.pause_track_after_sentence')}
                    onClick={togglePauseAfterEach}
                >
                    <IconPauseSwitch />
                </button>
            </div>
        </div>
    );
};

export default observer(UserPlayerControls);
