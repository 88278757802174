import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import teachersStore from '../../store/teachersStore';
import { Button } from '../../../../UI';
import cl from './TeacherCard.module.css';

const TeacherCard = ({ teacher }) => {
    const { t } = useTranslation();
    const { name, lessonsCount } = teacher;

    const handleClick = () => {
        teachersStore.setCurrentTeacher(teacher);
    };

    return (
        <div className={cl.teacherCard} onClick={handleClick}>
            <p className={cl.teacherName}>
                {name || t('exercises.unnamed_teacher')}
            </p>
            <div className={cl.infoContainer} />
            <div className={cl.controls}>
                <Button
                    style={{
                        pointerEvents: 'none',
                        padding: '12px',
                        textTransform: 'lowercase',
                    }}
                    variant={'grey'}
                    text={`${lessonsCount ?? 0} ${t('exercises.lessons')}`}
                />
            </div>
        </div>
    );
};

export default observer(TeacherCard);
