import React from 'react';
import { ToggleButton } from '../../../../UI';
import { AdvancedQuizStatuses } from '../../../../pages/TeacherContent/data/constants';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './UserAdvancedQuizPreview.module.css';

const UserAdvancedQuizPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;
    const { questions } = data;
    const userAnswers =
        results.userAnswers || Array(questions.length).fill(null);

    const handleAnswerClick = (questionIndex, status) => {
        const statusIndex = AdvancedQuizStatuses.findIndex((s) => s === status);
        setResults({
            userAnswers: userAnswers.map((a, i) =>
                i === questionIndex
                    ? statusIndex === -1
                        ? null
                        : statusIndex
                    : a
            ),
        });
    };

    const getVariant = (qIdx) => {
        if (!isCompleted) return 'exercise';
        if (isCompleted) {
            return AdvancedQuizStatuses[userAnswers[qIdx]] ===
                questions[qIdx].status
                ? 'correct'
                : 'wrong';
        }
    };

    if (!questions) return <></>;

    return (
        <>
            <QuestionsLabel label={'advanced_quiz_user_info'} />
            <div className={cl.questionsList}>
                {questions?.map((q, qIdx) => (
                    <div className={cl.questionCont} key={q.id ?? Math.random}>
                        <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>

                        <ToggleButton
                            variant={getVariant(qIdx)}
                            nullable
                            value={AdvancedQuizStatuses[userAnswers[qIdx]]}
                            withTranslations={'exercises'}
                            options={AdvancedQuizStatuses}
                            onChange={(status) => {
                                handleAnswerClick(qIdx, status);
                            }}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default UserAdvancedQuizPreview;
