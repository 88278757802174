import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useFetching } from '../../hooks/useFetching';
import SituationsService from '../../api/SituationsService';
import userDialoguesStore from './pages/UserDialogues/store/userDialogues';
import { useTranslation } from 'react-i18next';
import languagesStore from '../../store/interface';
import { authStore } from '../../App';
import CategoryService from '../../api/CategoryService';
import userVideosStore from './pages/UserVideos/store/userVideos';
import UserToDo from './pages/UserToDo/UserToDo';
import UserVideos from './pages/UserVideos/UserVideos';
import UserDialogues from './pages/UserDialogues/UserDialogues';
import UserWordPacks from './pages/UserWordPacks/UserWordPacks';
import UserLibrary from './pages/UserLibrary/UserLibrary';
import ProgressService from '../../api/ProgressService';
import userStore from './store/userStore';
import ExerciseService from '../../api/ExerciseService';
import wizardStore from '../LessonsKanban/components/LessonWizard/store/wizardStore';
import LessonModal from '../LessonModal/LessonModal';
import UserExercises from './pages/UserExercises/UserExercises';
import { ConfirmChapterCloseModal } from './components';

const UserView = ({ view }) => {
    const { i18n } = useTranslation();
    const isFirstRender = useRef(true);
    const { lang, nativeLang } = languagesStore;
    const { user } = authStore;

    const { language: interfaceLang } = i18n;

    const [getSituations] = useFetching(async () => {
        userDialoguesStore.setSituations([]);

        const { data } = await SituationsService.getAvailableCategories({
            language: lang.toUpperCase(),
            interfaceLanguage: interfaceLang.toUpperCase(),
        });

        const situations = data.map((d) => d.situations).flat();

        userDialoguesStore.setSituations(situations);
        userDialoguesStore.setCategories(
            data.map((c) => ({ value: c.id, name: c.title }))
        );
    });

    const [getCategories] = useFetching(async () => {
        const { data } = await CategoryService.getCategories({ lang });
        userVideosStore.setCategoryOptions(data);
    });

    const [fetchFavorites, favoritesLoading] = useFetching(async () => {
        const [dialogues, exercises, wordPacks, videos, words] =
            await Promise.all([
                ProgressService.getSituations({ lang }),
                ProgressService.getExercises({ lang }),
                ProgressService.getUserCategories({ lang }),
                ProgressService.getTracks({ lang }),
                ProgressService.getWords({ lang }),
            ]);

        userStore.setDialogues(dialogues.data.items);
        userStore.setExercises(exercises.data.items);
        userStore.setFavVideos(videos.data.items);
        userStore.setWords(words.data.items);

        const wordPacksWithWords = await Promise.all(
            wordPacks.data.items.map(async (pack) => {
                const { data } = await ProgressService.getCategoryWords({
                    categoryId: pack.id,
                    lang,
                });

                return { ...pack, words: data.items };
            })
        );

        userStore.setFavWordPacks(wordPacksWithWords);
        fetchWordsData(words.data.items);
    });

    const [getLessonOptions] = useFetching(async () => {
        const { data: grammarData } = await ExerciseService.getGrammarOptions({
            lang,
        });
        const { data: lexicalData } =
            await ExerciseService.getVocabularyOptions({ lang });

        wizardStore.setGrammarOptions(grammarData.items);
        wizardStore.setVocabularyOptions(lexicalData.items);
        wizardStore.setDataLang(lang);
    });

    const [fetchWordsData] = useFetching(async (words) => {
        const processedWords = await Promise.all(
            words.map(async (item) => {
                const { data: dictionaryData } =
                    await userStore.getCachedWordByText({
                        word: item.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });
                return { ...item, ...dictionaryData };
            })
        );
        userStore.setWordsData(processedWords);
    });

    const fetchCommonData = () => {
        getLessonOptions();
        getSituations();
        getCategories();
    };

    useEffect(() => {
        fetchCommonData();
        fetchFavorites();
    }, [interfaceLang, lang, user]);

    useEffect(() => {
        fetchCommonData();
        fetchFavorites();
        isFirstRender.current = false;
    }, [interfaceLang, lang, user]);

    useEffect(() => {
        if (!isFirstRender.current && view === 'todo') {
            fetchFavorites();
        }
    }, [view]);

    const renderCurrentView = () => {
        switch (view) {
            case 'todo':
                return <UserToDo isLoading={favoritesLoading} />;
            case 'videos':
                return <UserVideos />;
            case 'videos_history':
                return <UserVideos isHistory />;
            case 'dialogues':
                return <UserDialogues />;
            case 'dialogues_history':
                return <UserDialogues isHistory />;
            case 'words':
                return <UserWordPacks isLoading={favoritesLoading} />;
            case 'words_history':
                return <UserWordPacks isHistory isLoading={favoritesLoading} />;
            case 'exercises_history':
                return <UserExercises isLoading={favoritesLoading} />;
            case 'library':
                return <UserLibrary />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (view !== 'todo') userStore.setActiveChapter({});
    }, [view]);

    useEffect(() => {
        userStore.init(lang);
        setTimeout(() => userStore.startProcessingWords());

        return () => {
            userStore.stopProcessingWords();
        };
    }, [lang]);

    return (
        <>
            <LessonModal isUser />
            <ConfirmChapterCloseModal />
            {renderCurrentView()}
        </>
    );
};

export default observer(UserView);
