export const lessons = [
    {
        id: '1d0d74de-e7b5-4327-b102-11f49521507c',
        themeTitle: 'Verbs and Tenses',
        title: 'Have A Nice Flight! Present Simple/Continuous',
        description: null,
        lang: 'en',
        pdfs: null,
        pictures: null,
        ownerId: 'b48fe240-6e73-4b93-85b2-7b69113eac16',
        age: 5,
        difficulty: 2,
        grammarTopic: 'Present Simple vs. Present Continuous',
        vocabularyTopic:
            'Exploring the challenges and benefits of urban living',
        exam: '',
        basic: false,
        isPrivate: false,
        createdAt: '2025-01-22T13:18:00.043Z',
        chapters: [
            {
                id: '3028eaf4-f994-4430-bb82-52530522072f',
                title: 'Present Simple vs. Present Continuous: Urban Living',
                exerciseType: 'statements_transformation',
                difficulty: 2,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '4ed7f86a-c25d-431a-a0c1-b586352ed43c',
                title: 'Exploring Top Airports',
                exerciseType: 'free_form_questions',
                difficulty: 2,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '0b9394c2-4478-43ca-bb91-0444c569d6f7',
                title: 'Present Simple vs. Present Continuous Exercise',
                exerciseType: 'multichoice',
                difficulty: 2,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: 'cd4e66b8-db8e-4423-8a12-c212bac735e3',
                title: 'Heathrow Airport Travel Tips',
                exerciseType: 'advanced_quiz',
                difficulty: 2,
                trackId: '05a4ec67-81f9-4727-812c-fb2d4f5cb95c',
                trackInterval: [0, 150.003],
                type: 'exercise',
                order: 0,
            },
            {
                id: '4c2853cb-e1b9-4a39-b9de-49a4dff96eb6',
                title: 'Navigating Heathrow Airport: Tips and Advice',
                exerciseType: 'free_form_questions',
                difficulty: 2,
                trackId: '05a4ec67-81f9-4727-812c-fb2d4f5cb95c',
                trackInterval: [148.003, 307.7253],
                type: 'exercise',
                order: 0,
            },
            {
                id: '36970b3e-9bc9-467f-ab9d-7d0278d546b8',
                title: "Where's My Luggage?",
                categoryId: '6142a26a-1117-492f-88c4-03d27b4e7878',
                image: '/s3/situation-images/6cd304bc-a4f2-467e-81a3-0eccae8b5c27.webp',
                type: 'situation',
                order: 0,
                level: ['A2'],
            },

            {
                id: '05a4ec67-81f9-4727-812c-fb2d4f5cb95c',
                title: '10 Important Things to Know About London Heathrow Airport',
                image: {
                    md: 'https://i.ytimg.com/vi/ldXtIlFyBLA/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: '10 Important Things to Know About London Heathrow Airport',
                },
                description:
                    'My top tips for visiting London via Heathrow Airport. Heathrow has multiple terminals and is the international airport that most intercontinental flights will fly into and depart from. These are the important things to know before arriving to London Heathrow Airport.\n\n*Correction - wifi is free for everyone\n\nMore tips for London visitors:\nWhat to Pack When Visiting London: https://www.youtube.com/watch?v=HlFIY6ZiMI0&index=2&list=PL6u96nZN-L_ao52yOCcYXgaV-JalLZag1\n15 biggest mistakes London tourists ALWAYS make: https://www.youtube.com/watch?v=yWhRNGnV_XQ&t\nWhat to know before going to a London pub: https://www.youtube.com/watch?v=vKDCymezsRM&t\n\n*GET OUR FREE LONDON 101 GUIDE: http://loveandlondon.com/london-101-guide-main?utm_source=Youtube&utm_medium=video&utm_campaign=heathrow%20guide\n\nWant your London itinerary sorted for you? Grab one of our London itineraries: https://shop.loveandlondon.com/collections/itineraries\n\nBehind-the-scenes London-y stuff on Instagram: http://instagram.com/loveandlondon\nFree London 101 Guide for first-time London visitors: https://goo.gl/Eh3HP3\nLondon resources (luggage transfers, SIM cards, etc.): https://goo.gl/nsZZ64',
                podcastTitle: 'Love and London',
                duration: 382,
                difficulty: 2,
                type: 'track',
                order: 0,
            },
            {
                id: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                title: 'Airport Vocabulary B1',
                level: 2,
                type: 'words',
                order: 0,
                wordsCount: 10,
            },
        ],
    },
    {
        id: '81594af1-db72-4d57-b51b-5190b0f4c2ed',
        themeTitle: 'Sentence Structures',
        title: 'From Brollies to Bollocks: Cracking the Brit Code: Cleft Sentences Emphasis',
        description: null,
        lang: 'en',
        pdfs: null,
        pictures: null,
        ownerId: 'b48fe240-6e73-4b93-85b2-7b69113eac16',
        age: 5,
        difficulty: 3,
        grammarTopic: 'Emphasis using cleft sentences (What I need is...)',
        vocabularyTopic: 'From Brollies to Bollocks: Cracking the Brit Code',
        exam: '',
        basic: false,
        isPrivate: false,
        createdAt: '2025-01-27T11:02:19.590Z',
        chapters: [
            {
                id: '53f4bf14-ce56-4663-abe5-aff6f6f0f1fc',
                title: 'Statements Transformation: Complex Relative Clauses and Cleft Sentences',
                exerciseType: 'statements_transformation',
                difficulty: 3,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: 'bd1b6775-7302-4797-8d89-211080490173',
                title: ' Cleft Sentences Emphasis: Understanding British Vocabulary',
                exerciseType: 'words_order',
                difficulty: 3,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: 'e077fa40-2e01-4a1e-b0b8-e83d4bbb414d',
                title: 'British slang in 2014: most common terms',
                exerciseType: 'substitution',
                difficulty: 3,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '554c4f27-6237-4db8-b49e-19a5fccc57c7',
                title: 'British Foods with Florence Pugh',
                exerciseType: 'quiz',
                difficulty: 3,
                trackId: '09daeb49-142f-4e9f-bad3-947d48184e79',
                trackInterval: [0, 243.434],
                type: 'exercise',
                order: 0,
            },
            {
                id: '4c4bab07-e83d-412b-9f89-c19abd029f34',
                title: 'Requesting Assistance with Sizes or Measurements',
                categoryId: '60845447-3c94-4f66-95ed-ff9df3911f6a',

                image: '/s3/situation-images/74b5087a-1578-4515-b642-7c8a0bce3823.webp',
                type: 'situation',
                order: 0,
                level: ['B2', 'C1'],
            },
            {
                id: '09daeb49-142f-4e9f-bad3-947d48184e79',
                title: 'Florence Pugh Eats 11 English Dishes - Mukbang | Vogue',
                image: {
                    md: 'https://i.ytimg.com/vi/3cxHwQl9pNM/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'Florence Pugh Eats 11 English Dishes - Mukbang | Vogue',
                },
                description:
                    "Florence Pugh was recently nominated for her first Oscar for her performance in 'Little Women,' and she's celebrating with a twelve-course menu of English cuisine.\r\n\r\nWant more Vogue? Subscribe to the magazine, receive an exclusive gift and become an insider on what's next in fashion, beauty and culture: https://bit.ly/3749dP2\r\n\r\nDirector: Rom Bokobza\r\nEditor: Robby Massey\r\nVisual Director: Samantha Adler\n\nStill haven’t subscribed to Vogue on YouTube? ►► http://bit.ly/vogueyoutubesub\r\n \r\nABOUT VOGUE\r\nVogue is the authority on fashion news, culture trends, beauty coverage, videos, celebrity style, and fashion week updates. \n\nFlorence Pugh Eats 11 English Dishes - Mukbang | Vogue",
                podcastTitle: 'Vogue',
                duration: 608,
                difficulty: 3,
                type: 'track',
                order: 0,
            },
            {
                id: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                title: 'British slang B2',
                level: 3,
                type: 'words',
                order: 0,
                wordsCount: 14,
            },
        ],
    },
    {
        id: 'e60283cb-58e2-4033-853f-9bfcfe21a623',
        title: 'Meal Times: Time Prepositions',
        themeTitle: 'Revison',
        description: null,
        lang: 'en',
        pdfs: null,
        pictures: null,
        ownerId: 'b48fe240-6e73-4b93-85b2-7b69113eac16',
        age: null,
        difficulty: 1,
        grammarTopic: 'Prepositions of time (at, on, in)',
        vocabularyTopic: 'Meal times and daily menus',
        exam: '',
        basic: false,
        isPrivate: false,
        createdAt: '2025-01-28T12:00:07.884Z',
        chapters: [
            {
                id: '1af5a2d1-710a-403e-bd8b-9822a3e6dcbb',
                title: 'Understanding Popular Meals and Drinks',
                exerciseType: 'quiz',
                difficulty: 1,
                trackId: 'e64254a4-dc53-4a29-8e1c-1b30aa706488',
                trackInterval: [0, 327.121],
                type: 'exercise',
                order: 0,
            },
            {
                id: '714eb3ac-f772-402a-93ac-80370d3ba93e',
                title: 'Understanding Prepositions of Time',
                exerciseType: 'free_form_questions',
                difficulty: 1,
                trackId: 'c3d39838-a317-4d8d-b00d-5d6fb91d081e',
                trackInterval: [0, 309.287],
                type: 'exercise',
                order: 0,
            },
            {
                id: '7c6a9033-c623-47f3-9e4a-d9023adb671a',
                title: 'Meal Time! | Prepositions of Time: At, On, In',
                exerciseType: 'multichoice',
                difficulty: 1,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '2ae2a4ff-5e3f-461e-889b-f9877c208d82',
                title: 'Holidays in Portugal | Prepositions of Time: At, On, In',
                exerciseType: 'cloze',
                difficulty: 1,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '25ebb59f-83c1-42d2-a632-8f43eee1f9be',
                title: 'Talking to a Nutritionist/Dietitian',
                categoryId: '2f5dbc9c-efb9-43c6-bc85-787b7a588c84',
                image: '/s3/situation-images/d69b5b89-ae45-4ea9-bed1-3402123e8b82.webp',
                type: 'situation',
                order: 0,
                level: ['B2'],
            },
            {
                id: 'e64254a4-dc53-4a29-8e1c-1b30aa706488',
                title: 'Food and Drinks Vocabulary. ENGLISH FOR BEGINNERS | A1-A2',
                image: {
                    md: 'https://i.ytimg.com/vi/T5c-n80NDSQ/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'Food and Drinks Vocabulary. ENGLISH FOR BEGINNERS | A1-A2',
                },
                description:
                    'Hello, friends! \n\nIn this video, you will learn the names of the most popular dishes and drinks in English. Let’s see what people eat for breakfast, lunch, and dinner.  This topic is useful for anyone who is learning English as it may be used to talk about preferences, daily routine, to order in a restaurant, etc. \n\nChapters:\n0:00​ Introduction\n0:14​ Breakfast\n0:55​ Lunch\n1:50​ Brunch\n2:27​ Snack\n3:25​ Dinner\n\n\nFollow our channel for more videos!\nEmail: malaga.levelup@gmail.com',
                podcastTitle: 'Habla Manana',
                duration: 336,
                difficulty: 1,
                type: 'track',
                order: 0,
            },
            {
                id: 'c3d39838-a317-4d8d-b00d-5d6fb91d081e',
                title: 'Prepositions of Time – English Grammar Lessons',
                image: {
                    md: 'https://i.ytimg.com/vi/fRrVkXG0-v4/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'Prepositions of Time – English Grammar Lessons',
                },
                description:
                    'When do we use in, on, and at to talk about time in English? Learn these common prepositions for clock time, days, dates, months, years, and seasons in this Ellii grammar video! There is also a practice exercise at the end. Happy studying!\n\n#LearnEnglish #English #Ellii #Grammar #Preposition #PrepositionsOfPlace',
                podcastTitle: 'Ellii (formerly ESL Library)',
                duration: 325,
                difficulty: 1,
                type: 'track',
                order: 0,
            },
            {
                id: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                title: 'Meals and daily menus. - A2',
                level: 1,
                type: 'words',
                order: 0,
                wordsCount: 24,
            },
        ],
    },
    {
        id: 'a1613296-0f26-452a-bbd7-242612f9c167',
        title: 'Business English | Contracts and Meetings: Complex Conjunctions',
        themeTitle: 'Clauses',
        description: null,
        lang: 'en',
        pdfs: null,
        pictures: null,
        ownerId: 'b48fe240-6e73-4b93-85b2-7b69113eac16',
        age: null,
        difficulty: 3,
        grammarTopic:
            'Complex conjunctions (as long as, provided that, So that, In order that, In case)',
        vocabularyTopic: 'International Contracts and Negotiations',
        exam: '',
        basic: false,
        isPrivate: false,
        createdAt: '2025-01-27T16:52:14.281Z',
        chapters: [
            {
                id: '9ce1c17a-5d65-405b-a624-6e8e05b1cd84',
                title: 'Navigating Difficult Work Relationships',
                exerciseType: 'quiz',
                difficulty: 3,
                trackId: 'fbf903d5-d584-4484-917e-e988ccc607f9',
                trackInterval: [0, 184.144],
                type: 'exercise',
                order: 0,
            },
            {
                id: 'd6836195-0543-4543-8f06-42b2fe63c20f',
                title: 'Conducting Effective Business Meetings',
                exerciseType: 'free_form_questions',
                difficulty: 3,
                trackId: '53cf5e47-38d5-44b7-8cda-fc60c6685f5a',
                trackInterval: [0, 281.203],
                type: 'exercise',
                order: 0,
            },
            {
                id: '1ac7bec3-95e5-4e56-ad28-ce2cfcce7f26',
                title: 'Complex Conjunctions in International Contracts and Negotiations',
                exerciseType: 'statements_transformation',
                difficulty: 3,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '642bd9f6-4379-45a3-9163-12848882be34',
                title: 'Understanding the Art of Saying No',
                exerciseType: 'advanced_quiz',
                difficulty: 3,
                trackId: null,
                trackInterval: null,
                type: 'exercise',
                order: 0,
            },
            {
                id: '7e412dd8-63f1-4c48-9203-6a03f5cf30fd',
                title: 'Effective Interview Communication',
                categoryId: '4ead0f06-487b-4df9-a51c-a8340f6405e2',
                image: '/s3/situation-images/466fe594-3ae9-4868-8961-0e487c4455e4.webp',
                type: 'situation',
                order: 0,
                level: ['B2'],
            },
            {
                id: 'fbf903d5-d584-4484-917e-e988ccc607f9',
                title: 'Difficult People: What to Do When All Else Fails / The Harvard Business Review Guide',
                image: {
                    md: 'https://i.ytimg.com/vi/YEEKCLUfnbI/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'Difficult People: What to Do When All Else Fails / The Harvard Business Review Guide',
                },
                description:
                    'Before you throw in the towel, here are some last-ditch strategies to help you craft a work environment where you are able to do your job without all the drama, and keep your self-respect intact.\n\nThe advice in this video comes from HBR contributing editor Amy Gallo’s new book, “Getting Along: How to Work with Anyone (Even Difficult People)”, available here: https://www.amazon.com/Getting-Along-Anyone-Difficult-People/dp/1647821061.\n\n00:00 Do you work with someone who’s difficult? Try these tactics before you give up completely on them.\n00:45 Tactic 1: Set boundaries and limit exposure.\n03:10 Tactic 2: Document your colleague’s transgressions and your successes.\n04:30 Tactic 3: Bring the issue to someone in power (with caution!). \n05:55 Tactic 4: Think long and hard about quitting.\n06:50 OK, let’s review!\n\nProduced by Amy Gallo, Jessica Gidal, and Scott LaPierre\nEdited by Jessica Gidal\nVideo by Andy Robinson\nDesign by Riko Cribbs, Alex Belser, and Karen Player\n\nFollow us:\nhttps://hbr.org/\nhttps://www.linkedin.com/company/harvard-business-review/\nhttps://www.facebook.com/HBR/\nhttps://twitter.com/HarvardBiz\nhttps://www.instagram.com/harvard_business_review\n\nSign up for Newsletters: https://hbr.org/email-newsletters\n\n#HarvardBusinessReview #DifficultCoworkers #AmyGallo #Harvard #HarvardBusinessSchool  \n\nCopyright © 2023 Harvard Business School Publishing. All rights reserved.',
                podcastTitle: 'Harvard Business Review',
                duration: 523,
                difficulty: 3,
                type: 'track',
                order: 0,
            },
            {
                id: '53cf5e47-38d5-44b7-8cda-fc60c6685f5a',
                title: 'How to open a business meeting in English - Business English lessons',
                image: {
                    md: 'https://i.ytimg.com/vi/HDmDm1ENFio/sddefault.jpg',
                    sm: null,
                    xl: null,
                    alt: 'How to open a business meeting in English - Business English lessons',
                },
                description:
                    "If you can start the meeting with confidence, the rest of the meeting will be much easier for you! Learn the exact phrases to use in your next meeting. \n***Succeed at your job interview in English with my proven preparation method that has already helped students start a new professional life: https://christinarebuffetcourses.com/join-get-the-job/ \n\nWHAT YOU'LL LEARN IN THIS EPISODE:\nHow to handle introductions\nHow to create a situation for a smooth meeting\nWhat to say to start the meeting\n\nWHAT ABOUT YOU?\nWhat are your own tips to conduct a smooth meeting?\nTell us about it in the comments! We’ll build a collection of tips for best practices! \n\n\nIMPROVE YOUR ENGLISH EVEN MORE:\nHow to correct 50 common mistakes and avoid confusion in American English: https://christinarebuffet.com/what-was-that-pre-launch/?utm_source=youtube_description&utm_medium=episode_159&utm_campaign=WWT_book\nBecome fluent faster with vocabulary and speaking practice: https://christinarebuffet.com/join-faster-fluency-conversation-club/?utm_source=youtube_description&utm_medium=episode_159&utm_campaign=FFCC\nUnderstand 12 essential phrases that Americans say fast: https://christinarebuffet.com/american-accent-survival-kit/?utm_source=youtube&utm_medium=episode_159&utm_campaign=AASK_optin\n\nSPEAK FLUENT ENGLISH FASTER:\nBeing regular with your English is essential to become more fluent. Get a new English episode every week when you join my email list: https://christinarebuffet.com/join-sewc-2/?utm_source=youtube&utm_medium=display&utm_campaign=sewcsignups\n\nPRACTICE ENGLISH EVERY DAY WITH ME ON SOCIAL MEDIA:\nLinkedIn: https://www.linkedin.com/in/christinarebuffetbroadus\nInstagram: https://www.instagram.com/speakenglishwithchristina/\nFacebook: https://www.facebook.com/SpeakEnglishWithChristina/",
                podcastTitle: 'Business English with Christina',
                duration: 314,
                difficulty: 3,
                type: 'track',
                order: 0,
            },
            {
                id: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                title: 'Business English - B2 |Contracts and Negotiations',
                level: 3,
                type: 'words',
                order: 0,
                wordsCount: 15,
            },
        ],
    },
];
