import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import {
    AgeOptions,
    getExamOptions,
    LessonAccessStatuses,
} from '../../../../../../pages/TeacherContent/data/constants';
import {
    Button,
    Input,
    Label,
    Select,
    ToggleButton,
    Tree,
} from '../../../../../../UI';
import { Chapters, LessonsGroupBy } from '../../../../data/constants';
import AvailableLessons from '../AvailableLessons/AvailableLessons';
import cl from './CreateLesson.module.css';
import { LanguageLevels } from '../../../../../../data/common';
import { AvailableLanguageLevels } from '../ExerciseWizard/ExerciseWizard';

const DefaultFormData = {
    age: '',
    difficulty: '',
    exam: '',
    grammarTopic: '',
    vocabularyTopic: '',
    title: '',
    isPrivate: false,
};

export const ExerciseTypes = {
    Grammar: 'grammar',
    Text: 'text',
    Video: 'video',
};

const CreateLesson = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { groupBy, difficulty: kanbanLevel } = lessonsStore;
    const { grammarOptions, vocabularyOptions } = wizardStore;

    const [formData, setFormData] = useState(DefaultFormData);
    const [formErrors, setFormErrors] = useState({});

    const [filteredGrammar, setFilteredGrammar] = useState(grammarOptions);
    const [filteredVocabulary, setFilteredVocabulary] =
        useState(vocabularyOptions);

    const { difficulty, title, grammarTopic, vocabularyTopic, isPrivate } =
        formData;

    const [createLesson, isLoading] = useFetching(async () => {
        const { data } = await ExerciseService.createLesson({
            ...formData,
            title,
            age: formData.age === '' ? undefined : formData.age,
            lang,
            isPrivate,
            grammarTopic,
            vocabularyTopic,
            order: 0,
        });
        if (data && data.id) {
            processLesson(data);
            wizardStore.nextStep();
        }
    });

    const getLessonTitle = () => {
        const gObj = grammarTopic
            ? filteredGrammar
                  .flatMap((gObj) => gObj.topics)
                  .find((tObj) => tObj.topic === grammarTopic)
            : null;
        const vObj = filteredVocabulary
            .flatMap((lObj) => lObj.topics)
            .find((tObj) => tObj.topic === vocabularyTopic);

        return `${vObj?.shortName ?? vocabularyTopic}${gObj ? `: ${gObj.shortName}` : ''}`;
    };

    const processLesson = (lessonObject) => {
        lessonsStore.setCurrentLesson(lessonObject);
        lessonsStore.addLesson(
            groupBy === (LessonsGroupBy.Grammar && grammarTopic)
                ? grammarTopic
                : vocabularyTopic,
            {
                id: lessonObject.id,
                title: lessonObject.title,
                age: formData.age,
                chapters: [],
                difficulty,
                exam: formData.exam,
                grammarTopic: grammarTopic || lessonObject.grammarTopic,
                vocabularyTopic:
                    vocabularyTopic || lessonObject.vocabularyTopic,
                order: 0,
            }
        );
    };

    const handleLessonCreate = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                difficulty: typeof formData.difficulty !== 'number',
                vocabularyTopic: !formData.vocabularyTopic,
                isPrivate: typeof formData.isPrivate !== 'boolean',
                title: !formData.title,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            createLesson();
        });
    };

    const handleInputChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        setFormData(DefaultFormData);
    }, []);

    useEffect(() => {
        setFormErrors({});
    }, [formData]);

    useEffect(() => {
        const currentGrammar = grammarOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredGrammar(currentGrammar);

        const currentVocabulary = vocabularyOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredVocabulary(currentVocabulary);
    }, [grammarOptions, vocabularyOptions, difficulty]);

    useEffect(() => {
        handleInputChange('grammarTopic', '');
        handleInputChange('vocabularyTopic', '');
    }, [difficulty]);

    useEffect(() => {
        formData.title = getLessonTitle();
    }, [grammarTopic, vocabularyTopic]);

    useEffect(() => {
        if (kanbanLevel) {
            handleInputChange(
                'difficulty',
                LanguageLevels.indexOf(kanbanLevel)
            );
        }
    }, [kanbanLevel]);

    return (
        <>
            <div className={cl.paramsContainer}>
                <div>
                    <Label
                        text={t('users.level')}
                        isError={formErrors['difficulty']}
                        isRequired
                    />
                    <ToggleButton
                        options={LanguageLevels}
                        availableOptions={AvailableLanguageLevels}
                        disabled
                        value={LanguageLevels[formData.difficulty]}
                        onChange={(value) =>
                            handleInputChange(
                                'difficulty',
                                LanguageLevels.indexOf(value)
                            )
                        }
                        variant={
                            formErrors['difficulty'] ? 'error' : 'outlined'
                        }
                    />
                </div>
                <div>
                    <Label
                        text={t('lesson_wizard.lesson_access_status')}
                        isError={formErrors['isPrivate']}
                        isRequired
                    />
                    <Select
                        className={cl.select}
                        variant={
                            formErrors['isPrivate'] ? 'errorSmall' : 'grey'
                        }
                        value={formData.isPrivate}
                        options={LessonAccessStatuses.map((l) => ({
                            ...l,
                            name: t(l.name),
                        }))}
                        onChange={(value) =>
                            handleInputChange('isPrivate', value)
                        }
                        ignoreOverflow
                        hideReset
                    />
                </div>
                <div>
                    <Label text={t('users.age')} />
                    <Select
                        className={cl.select}
                        variant="grey"
                        value={formData.age}
                        options={AgeOptions}
                        onChange={(value) => handleInputChange('age', value)}
                        ignoreOverflow
                    />
                </div>
                <div>
                    <Label text={t('exercises.exam')} />
                    <Select
                        className={cl.select}
                        variant="grey"
                        value={formData.exam}
                        options={getExamOptions(lang)}
                        onChange={(value) => handleInputChange('exam', value)}
                        ignoreOverflow
                    />
                </div>
            </div>
            <div>
                <Label
                    text={t('lesson_wizard.select_lexical_topic')}
                    isError={formErrors['vocabularyTopic']}
                    isRequired
                />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    variant={
                        formErrors['vocabularyTopic'] ? 'errorSmall' : 'grey'
                    }
                    value={formData.vocabularyTopic}
                    onChange={(value) =>
                        handleInputChange('vocabularyTopic', value)
                    }
                    options={filteredVocabulary}
                    hideReset
                    ignoreOverflow
                    height={240}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div>
                <Label text={t('lesson_wizard.select_grammar_topic')} />
                <Tree
                    label={t('lesson_wizard.grammar_topic')}
                    variant="grey"
                    value={formData.grammarTopic}
                    onChange={(value) =>
                        handleInputChange('grammarTopic', value)
                    }
                    options={filteredGrammar}
                    ignoreOverflow
                    height={180}
                    disabled={difficulty === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div>
                <Label
                    text={t('exercises.lesson_title')}
                    isError={formErrors['title']}
                    isRequired
                />
                <Input
                    placeholder={t('exercises.lesson_title')}
                    variant={formErrors['title'] ? 'erroredSmall' : 'filled'}
                    value={formData.title}
                    onChange={(value) => handleInputChange('title', value)}
                />
            </div>

            <div className={cl.flexContainer}>
                <AvailableLessons
                    currentParams={formData}
                    processLesson={processLesson}
                />
                <Button
                    text={t('exercises.add_lesson')}
                    onClick={handleLessonCreate}
                    isLoading={isLoading}
                    variant={Chapters.Track}
                />
            </div>
        </>
    );
};

export default observer(CreateLesson);
