import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Typography from '../Typography/Typography';
import LessonsCard from '../LessonsCard/LessonsCard';
import { lessons } from '../../data/lessons';
import DemoLessonModal from '../DemoLessonModal/DemoLessonModal';
import guestOnboarding from '../../store/guestOnboarding';
import LanguageBubble from '../LanguageBubble/LanguageBubble';
import cl from './LessonsExample.module.css';

const LessonsExample = () => {
    const sectionRef = useRef();
    const isInView = useInView(sectionRef, { once: true, margin: '-300px' });

    const { wasTypeChanged } = guestOnboarding;
    const [wasOnboarded, setWasOnboarded] = useState(false);
    const [currentLesson, setCurrentLesson] = useState();

    const handleLessonClick = (lesson) => {
        setCurrentLesson(lesson);
    };

    const handleCardShow = (element) => {
        if (wasOnboarded) return;
        document.body.style.overflow = 'hidden';
        setWasOnboarded(true);
        scrollIntoView(element);
        setTimeout(() => {
            guestOnboarding.setCurrentItem('lesson_examples');
        }, 700);
    };

    const scrollIntoView = (element) => {
        const offset = 50;

        const rect = element.getBoundingClientRect();

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
            top: scrollTop + rect.top - offset,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (wasOnboarded || !wasTypeChanged) return;
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleCardShow(sectionRef.current);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.7,
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [wasTypeChanged, wasOnboarded]);

    return (
        <div className={cl.lessonsExampleContainer} ref={sectionRef}>
            <motion.div
                className={cl.textContainer}
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                <Typography
                    variant={'h2'}
                    className={cl.title}
                    dangerouslySetInnerHTML={t(
                        'teacher_tutorial.lessons_example_title'
                    )}
                />
                <Typography
                    variant={'h3'}
                    className={cl.subTitle}
                    dangerouslySetInnerHTML={t(
                        'teacher_tutorial.lessons_example_subtitle'
                    )}
                />
            </motion.div>

            <LanguageBubble
                language={'en'}
                className={cl.logoEN}
                animate={{}}
            />
            <LanguageBubble
                language={'it'}
                className={cl.logoIT}
                animate={{}}
            />

            <motion.div
                className={cl.cardsBlock}
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={{
                    hidden: { opacity: 0 },
                    visible: {
                        opacity: 1,
                        transition: { staggerChildren: 0.2 },
                    },
                }}
            >
                {lessons.map((el, i) => (
                    <motion.div
                        className={cl.cardWrapper}
                        key={i}
                        variants={{
                            hidden: { opacity: 0, y: 70 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        <LessonsCard
                            lesson={el}
                            onClick={() => handleLessonClick(el)}
                            isFirst={i === 0}
                            isFocused={i === 0}
                        />
                    </motion.div>
                ))}
            </motion.div>
            {currentLesson && (
                <DemoLessonModal
                    lessonObj={currentLesson}
                    onClose={() => setCurrentLesson()}
                />
            )}
        </div>
    );
};

export default observer(LessonsExample);
