import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { motion, useInView } from 'framer-motion';
import languagesStore from '../../../../store/interface';
import guestOnboarding from '../../store/guestOnboarding';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import { Button, Select } from '../../../../UI';
import {
    DemoChapterCard,
    DemoDictionaryPreview,
    DemoSituationPreview,
    DemoVideoPreview,
    InfoWrapper,
    SelectionBar,
    Typography,
} from '..';
import ExercisePreview from '../../../../features/ExercisePreview/ExercisePreview';
import { BubblePositions } from '../../../../features/Onboarding';
import { getSituationsByEnv } from '../../data/dialogues';
import { tracks } from '../../data/tracks';
import { infoWordPacks } from '../../data/words';
import { getInfoExercisesByLang } from '../../data/exersises';
import { ReactComponent as FlagAr } from '../../../../assets/svg/flag_ar.svg';
import { ReactComponent as FlagDe } from '../../../../assets/svg/flag_de.svg';
import { ReactComponent as FlagEl } from '../../../../assets/svg/flag_el.svg';
import { ReactComponent as FlagEn } from '../../../../assets/svg/flag_en.svg';
import { ReactComponent as FlagEs } from '../../../../assets/svg/flag_es.svg';
import { ReactComponent as FlagFi } from '../../../../assets/svg/flag_fi.svg';
import { ReactComponent as FlagFr } from '../../../../assets/svg/flag_fr.svg';
import { ReactComponent as FlagHu } from '../../../../assets/svg/flag_hu.svg';
import { ReactComponent as FlagIt } from '../../../../assets/svg/flag_it.svg';
import { ReactComponent as FlagJa } from '../../../../assets/svg/flag_ja.svg';
import { ReactComponent as FlagNl } from '../../../../assets/svg/flag_nl.svg';
import { ReactComponent as FlagPl } from '../../../../assets/svg/flag_pl.svg';
import { ReactComponent as FlagPt } from '../../../../assets/svg/flag_pt.svg';
import { ReactComponent as FlagRu } from '../../../../assets/svg/flag_ru.svg';
import { ReactComponent as FlagTr } from '../../../../assets/svg/flag_tr.svg';
import { ReactComponent as FlagUk } from '../../../../assets/svg/flag_uk.svg';
import { ReactComponent as FlagZh } from '../../../../assets/svg/flag_zh.svg';
import { ReactComponent as IconVisible } from '../../../../assets/svg/visible.svg';
import cl from './ChapterExamples.module.css';
import { Roles } from '../../../../data/common';

export const getIcon = (lang) => {
    switch (lang) {
        case 'ar':
            return <FlagAr />;
        case 'de':
            return <FlagDe />;
        case 'el':
            return <FlagEl />;
        case 'en':
            return <FlagEn />;
        case 'es':
            return <FlagEs />;
        case 'fi':
            return <FlagFi />;
        case 'fr':
            return <FlagFr />;
        case 'hu':
            return <FlagHu />;
        case 'it':
            return <FlagIt />;
        case 'ja':
            return <FlagJa />;
        case 'nl':
            return <FlagNl />;
        case 'pl':
            return <FlagPl />;
        case 'pt':
            return <FlagPt />;
        case 'ru':
            return <FlagRu />;
        case 'tr':
            return <FlagTr />;
        case 'uk':
            return <FlagUk />;
        case 'zh':
            return <FlagZh />;
        default:
            return null;
    }
};

const renderIcon = (lang) => {
    return <div className={cl.iconContainer}>{getIcon(lang)}</div>;
};

const ChapterExamples = () => {
    const { languages, interfaceLang, nativeLang } = languagesStore;
    const { currentItem, wasTypeChanged } = guestOnboarding;

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-50px' });
    const isInitialRender = useRef(true);

    const exercisesList = getInfoExercisesByLang(interfaceLang);
    const videoList = tracks;
    const situationList = getSituationsByEnv(
        window.location.hostname === 'thepony.app' ? 'prod' : 'dev'
    );
    const wordPackList = infoWordPacks;

    const [type, setType] = useState(Chapters.Dictionary);

    const [currentSituation, setCurrentSituation] = useState(situationList[0]);
    const [currentPack, setCurrentPack] = useState(wordPackList[0]);
    const [currentVideo, setCurrentVideo] = useState(videoList[0]);
    const [currentExercise, setCurrentExercise] = useState(exercisesList[0]);

    const [isStudentPreview, setStudentPreview] = useState(false);

    const [onboardingFlags, setOnboardingFlags] = useState({
        [Chapters.Dictionary]: false,
        [Chapters.Exercise]: false,
        [Chapters.Situation]: false,
        [Chapters.Track]: false,
    });

    useEffect(() => {
        setStudentPreview(false);
    }, [currentSituation, currentPack, currentVideo, currentExercise]);

    const renderPreview = () => {
        switch (type) {
            case Chapters.Exercise:
                return (
                    <ExercisePreview
                        type={Roles.Guest}
                        exerciseId={currentExercise.id}
                    />
                );
            case Chapters.Dictionary:
                return (
                    <DemoDictionaryPreview
                        wordPack={currentPack}
                        isStudent={isStudentPreview}
                    />
                );
            case Chapters.Situation:
                return (
                    <DemoSituationPreview
                        situation={currentSituation}
                        isStudent={isStudentPreview}
                    />
                );
            case Chapters.Track:
                return (
                    <DemoVideoPreview
                        video={currentVideo}
                        isStudent={isStudentPreview}
                    />
                );
            default:
                return null;
        }
    };

    const scrollIntoView = (element) => {
        const offset = 15;

        const rect = element.getBoundingClientRect();

        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;

        window.scrollTo({
            top: scrollTop + rect.top - offset,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else if (ref.current) {
            scrollIntoView(ref.current);
        }
    }, [type]);

    useEffect(() => {
        if (onboardingFlags[Chapters.Dictionary] || currentItem !== 'await')
            return;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleCardShow(ref.current);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.5,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [onboardingFlags[Chapters.Dictionary], currentItem]);

    const handleNewTypeSelect = () => {
        if (onboardingFlags[type] || type === Chapters.Dictionary) return;

        guestOnboarding.setCurrentItem(type);
        setOnboardingFlags({
            ...onboardingFlags,
            [type]: true,
        });
    };

    const handleCardShow = () => {
        document.body.style.overflow = 'hidden';
        setOnboardingFlags({
            ...onboardingFlags,
            [Chapters.Dictionary]: true,
        });

        setTimeout(() => {
            guestOnboarding.setCurrentItem('navigation');
        }, 700);
    };

    const getChaptersByType = () => {
        switch (type) {
            case Chapters.Dictionary:
                return wordPackList;
            case Chapters.Situation:
                return situationList;
            case Chapters.Track:
                return videoList;
            case Chapters.Exercise:
                return exercisesList;
            default:
                return [];
        }
    };

    const handleCurrentChapterChange = (chapter) => {
        if (type === Chapters.Dictionary) setCurrentPack(chapter);
        if (type === Chapters.Exercise) setCurrentExercise(chapter);
        if (type === Chapters.Situation) setCurrentSituation(chapter);
        if (type === Chapters.Track) setCurrentVideo(chapter);
    };

    const getIsChapterActive = (c) => {
        switch (type) {
            case Chapters.Dictionary:
                return c.id === currentPack.id;
            case Chapters.Situation:
                return c.id === currentSituation.id;
            case Chapters.Track:
                return c.id === currentVideo.id;
            case Chapters.Exercise:
                return c.id === currentExercise.id;
            default:
                return false;
        }
    };

    const renderCurrentChapters = () => {
        const currentChapters = getChaptersByType();
        return currentChapters.map((c) => (
            <DemoChapterCard
                key={c.id}
                chapter={c}
                type={type}
                isActive={getIsChapterActive(c)}
                onClick={handleCurrentChapterChange}
            />
        ));
    };

    useEffect(() => {
        handleNewTypeSelect();
        if (type !== Chapters.Dictionary) {
            guestOnboarding.setWasTypeChanged(true);
        }
    }, [type]);

    useEffect(() => {
        languagesStore.setNativeLang(interfaceLang === 'de' ? 'en' : 'de');
    }, [interfaceLang]);

    useEffect(() => {
        if (currentItem === Chapters.Dictionary) {
            scrollIntoView(ref.current);
        }
    }, [currentItem]);

    useEffect(() => {
        if (wasTypeChanged) return;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
                    document.body.style.overflow = 'hidden';

                    scrollIntoView(ref.current);

                    setTimeout(() => {
                        guestOnboarding.setCurrentItem('extra_navigation');
                        guestOnboarding.setWasTypeChanged(true);
                    }, 1000);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 0.9,
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [wasTypeChanged]);

    return (
        <div className={cl.chapterSection}>
            <motion.div
                className={cl.textContainer}
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                <p className={cl.sectionTitle}>
                    {t('teacher_tutorial.task_example_title')}
                </p>
                <Typography
                    variant={'h3'}
                    className={cl.sectionSubtitle}
                    dangerouslySetInnerHTML={t(
                        'teacher_tutorial.task_example_subtitle'
                    )}
                />
            </motion.div>
            <motion.div
                className={cl.previewContainer}
                initial={{ opacity: 0 }}
                animate={isInView ? { opacity: 1 } : {}}
                transition={{ duration: 0.6, ease: 'easeOut', delay: 0.8 }}
                ref={ref}
            >
                <SelectionBar
                    className={cl.selectionBar}
                    isTaskExample={true}
                    type={type}
                    onChange={setType}
                />
                <div className={cl.previewInner}>
                    <InfoWrapper
                        onboardingKey={guestOnboarding.isOnboarding(
                            'exercise_examples'
                        )}
                        title={t('teacher_tutorial.exercise_mode_info')}
                        subtitle={t(
                            'teacher_tutorial.exercise_mode_info_subtitle'
                        )}
                        delay={200}
                        bubblePosition={{
                            x: BubblePositions.x.Right,
                            y: BubblePositions.y.TopBorder,
                        }}
                        showButton
                        variant={Chapters.Exercise}
                    >
                        <div className={cl.chaptersContainer}>
                            <div className={cl.chaptersList}>
                                {renderCurrentChapters()}
                            </div>
                            {type !== Chapters.Exercise && (
                                <div className={cl.buttonDescription}>
                                    {!isStudentPreview ? (
                                        <>
                                            <p
                                                className={
                                                    cl.buttonDescriptionTitle
                                                }
                                            >
                                                {t(
                                                    'teacher_tutorial.task_example_button_title_2'
                                                )}
                                            </p>
                                            <p
                                                className={
                                                    cl.buttonDescriptionSubtitle
                                                }
                                            >
                                                {t(
                                                    'teacher_tutorial.task_example_button_subtitle_2'
                                                )}
                                            </p>

                                            <InfoWrapper
                                                onboardingKey={guestOnboarding.isOnboarding(
                                                    'student_button'
                                                )}
                                                title={t(
                                                    'teacher_tutorial.student_mode_info'
                                                )}
                                                subtitle={t(
                                                    'teacher_tutorial.student_mode_info_subtitle'
                                                )}
                                                delay={200}
                                                bubblePosition={{
                                                    x: BubblePositions.x
                                                        .RightBorder,
                                                    y: BubblePositions.y.Top,
                                                }}
                                                showButton
                                                variant={Chapters.Dictionary}
                                            >
                                                <div className={cl.buttonsBar}>
                                                    <Select
                                                        className={`${cl.select} ${cl[type]}`}
                                                        variant={'landing'}
                                                        label={
                                                            languages.find(
                                                                (l) =>
                                                                    l.value ===
                                                                    nativeLang
                                                            ).name
                                                        }
                                                        icon={renderIcon(
                                                            nativeLang
                                                        )}
                                                        options={languages.filter(
                                                            (lObj) =>
                                                                lObj.value !==
                                                                interfaceLang
                                                        )}
                                                        value={nativeLang}
                                                        onChange={
                                                            languagesStore.setNativeLang
                                                        }
                                                        hideReset
                                                    />

                                                    <Button
                                                        className={cl.button}
                                                        variant={type}
                                                        text={t(
                                                            'teacher_tutorial.view_as_student'
                                                        )}
                                                        onClick={() =>
                                                            setStudentPreview(
                                                                !isStudentPreview
                                                            )
                                                        }
                                                        icon={
                                                            <IconVisible
                                                                className={
                                                                    cl.startIcon
                                                                }
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </InfoWrapper>
                                        </>
                                    ) : (
                                        <Button
                                            className={cl.teacherButton}
                                            variant={type}
                                            text={t(
                                                'teacher_tutorial.view_as_teacher'
                                            )}
                                            onClick={() =>
                                                setStudentPreview(
                                                    !isStudentPreview
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </InfoWrapper>
                    {renderPreview()}
                </div>
            </motion.div>
        </div>
    );
};

export default observer(ChapterExamples);
