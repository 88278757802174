import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Typography from '../Typography/Typography';
import { t } from 'i18next';
import { authStore } from '../../../../App';
import { AuthModes } from '../../../../store/auth';
import CostCard from '../CostCard/CostCard';
import LanguageBubble from '../LanguageBubble/LanguageBubble';
import { Button } from '../../../../UI';
import cl from './CostSection.module.css';

const CostSection = () => {
    const sectionRef = useRef(null);
    const isInView = useInView(sectionRef, { once: true, margin: '-300px' });

    const handleSignInButton = () => {
        authStore.setAuthMode(AuthModes.Register);
        authStore.setIsAuth(true);
    };

    return (
        <div ref={sectionRef} className={cl.costBlock}>
            <motion.div
                className={cl.textContainer}
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                <Typography
                    variant={'h2'}
                    className={cl.title}
                    dangerouslySetInnerHTML={t('teacher_tutorial.cost_title')}
                />
                <Typography
                    variant={'h3'}
                    className={cl.subtitle}
                    dangerouslySetInnerHTML={`${t('teacher_tutorial.cost_subtitle')}`}
                />
            </motion.div>
            <motion.div
                className={cl.cardsBlock}
                initial={{ opacity: 0, y: 70 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut', delay: 0.6 }}
            >
                <div className={cl.purpleCircleLeft} />
                <div className={cl.purpleCircleRight} />
                <div className={cl.cardsContainer}>
                    <CostCard role={'teacher'} />
                    <CostCard role={'student'} />
                </div>
                <Button
                    variant={'purple'}
                    className={cl.joinButton}
                    text={t('teacher_tutorial.costcard_button')}
                    onClick={handleSignInButton}
                />
            </motion.div>

            <LanguageBubble
                language={'de'}
                className={cl.logoDE}
                animate={{}}
            />

            <LanguageBubble
                language={'pt'}
                className={cl.logoPT}
                animate={{}}
            />
        </div>
    );
};

export default CostSection;
