import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../../../hooks/useFetching';
import DictionaryService from '../../../../../../api/DictionaryService';
import languagesStore from '../../../../../../store/interface';
import userWordsStore, {
    PackSizeOptions,
    WordCardModes,
    WordStatuses as LearnedStatuses,
    WordStatuses,
} from '../../../../../../features/UserPlatform/store/userWords';
import wordsStore from '../../../../../../store/words';

import {
    Button,
    FilledRange,
    Label,
    MicroButton,
    Modal,
    Switch,
    ToggleButton,
} from '../../../../../../UI';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import { MediaPlayBackOptions } from '../../../../../../data/common';
import {
    DictionaryServerErrors,
    UserWordsCategory,
} from '../../data/constants';
import { ChaptersList } from '../../../../components';
import { ReactComponent as IconSettings } from '../../../../../../assets/svg/settings.svg';
import { ReactComponent as IconClose } from '../../../../../../assets/svg/icon-close.svg';
import LearnWords from '../../../../../../features/LearnWords/LearnWords';
import { UserChapterPreview } from '../../../../../../features/UserPlatform/components/';
import cl from './WordsUserPreview.module.css';

const WordRequestInterval = 2000;
const Timeout = 20000;

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <div className={cl.microButtons}>
                <MicroButton
                    icon={<IconSettings />}
                    variant={'grey'}
                    size={'regular'}
                    onClick={() => setVisible(true)}
                />
            </div>

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                                color={Chapters.Dictionary}
                                isDisabled={
                                    cardModes[index] &&
                                    cardModes.filter((m) => m).length <= 1
                                }
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <Label text={t('glossary_settings.words_in_pack')} />
                    <div className={cl.packSizeSelector}>
                        <ToggleButton
                            value={wordsPerPack}
                            onChange={userWordsStore.setWordsPerPack}
                            options={PackSizeOptions}
                            isGrid
                            variant={'transparent'}
                        />
                    </div>
                </div>

                <div className={cl.rangeSelector}>
                    <Label text={t('glossary_settings.playback_speed')} />
                    <FilledRange
                        value={playbackSpeed}
                        min={MediaPlayBackOptions[0]}
                        step={MediaPlayBackOptions[1] - MediaPlayBackOptions[0]}
                        max={
                            MediaPlayBackOptions[
                                MediaPlayBackOptions.length - 1
                            ]
                        }
                        color={'var(--words-green)'}
                        onChange={handlePlaybackChange}
                    />
                </div>
            </Modal>
        </>
    );
});

const WordsUserPreview = ({ categoryId, onClose }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;
    const { processedWords } = wordsStore;

    const { currentWord, pendingWords, searchString, status, dictionaryWords } =
        userWordsStore;

    const [learnMode, setLearnMode] = useState(false);

    const [getCategoryWords, isLoading] = useFetching(async () => {
        const currentPendingWords = [];
        userWordsStore.setDictionaryWords([]);

        const words = [];

        const processedWords = words?.length
            ? (
                  await Promise.all(
                      words?.map(async (item) => {
                          const { data: dictionaryData } =
                              await wordsStore.getCachedWordByText({
                                  word: item.word,
                                  sourceLang: lang,
                                  targetLang: nativeLang,
                              });

                          if (!dictionaryData) {
                              currentPendingWords.push(item);
                          }

                          return { ...item, ...dictionaryData };
                      })
                  )
              ).sort((a, b) => a.word.localeCompare(b.word))
            : [];

        userWordsStore.setWordsData(
            processedWords.map((w) => ({
                ...w,
                status: LearnedStatuses.Active,
            }))
        );

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
        userWordsStore.setCurrentWord(processedWords[0]);
    });

    const toggleLearnMode = () => setLearnMode(!learnMode);

    const processPendingWords = async (currentPendingWords) => {
        userWordsStore.setPendingWords(currentPendingWords);

        for (const word of currentPendingWords) {
            try {
                await DictionaryService.translateWord({
                    word: word.word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });
                const startTime = Date.now();

                while (Date.now() - startTime < Timeout) {
                    const response = await wordsStore.getCachedWordByText({
                        word: word.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });

                    if (response && response.errorCode) {
                        if (
                            response.errorCode ===
                            DictionaryServerErrors.NotFound
                        ) {
                            await DictionaryService.translateWord({
                                word: word.word,
                                sourceLang: lang,
                                targetLang: nativeLang,
                            });
                        }
                    }

                    const { data } = response;

                    if (data) {
                        userWordsStore.removePendingWord(word.word, data);
                        break;
                    }

                    await new Promise((resolve) =>
                        setTimeout(resolve, WordRequestInterval)
                    );
                }
            } catch (error) {
                console.error(
                    `Error processing pending word: ${word.word}`,
                    error
                );
            }
        }
    };

    const syncNewWords = async (words) => {
        const currentPendingWords = [];

        for (const wObj of words) {
            const { word, categoryId } = wObj;

            if (dictionaryWords.some((w) => w.word === word)) return;

            const { data } = await wordsStore.getCachedWordByText({
                word,
                sourceLang: lang,
                targetLang: lang,
            });
            const wordId = data.data[0].id;

            const { data: translatedData } =
                await wordsStore.getCachedWordByText({
                    word,
                    sourceLang: lang,
                    targetLang: nativeLang,
                });

            wordsStore.deletePendingWord({ word, categoryId });
            userWordsStore.addNewDictionaryWord({
                word,
                id: wordId,
                wordId,
                lastIteration: 0,
                lang,
                status: LearnedStatuses.Active,
                ...translatedData,
            });

            if (!translatedData) {
                currentPendingWords.push({
                    word,
                    id: wordId,
                    wordId,
                    lang,
                });
            }
        }

        if (currentPendingWords.length > 0) {
            processPendingWords(currentPendingWords);
        }
    };

    const getIsLearnDisabled = () => {
        return dictionaryWords.some((wObj) => !wObj?.translations);
    };

    const getWordsByStatus = () => {
        const currentWords = dictionaryWords
            .map((w) =>
                pendingWords.some(
                    (pW) => pW.wordId === w.id || pW.word === w.word
                )
                    ? { ...w, pending: true }
                    : w
            )
            .filter((w) => w.status === status);

        const currentProcessedWords =
            status === LearnedStatuses.Learned
                ? []
                : processedWords
                      .filter((w) => w.categoryId === UserWordsCategory)
                      .map((w) => ({ ...w, pending: true }));

        return [...currentWords, ...currentProcessedWords].sort((a, b) =>
            a.word.localeCompare(b.word)
        );
    };

    useEffect(() => {
        userWordsStore.setPendingWords([]);
    }, [nativeLang]);

    useEffect(() => {
        setLearnMode(false);
        getCategoryWords();
    }, [categoryId]);

    useEffect(() => {
        const readyWords = processedWords.filter(
            (w) =>
                w.categoryId === UserWordsCategory &&
                w.status === WordStatuses.Ready
        );

        const newReadyWords = [];

        readyWords.forEach((wObj) => {
            if (dictionaryWords.some((w) => w.word === wObj.word)) {
                wordsStore.deletePendingWord({
                    word: wObj.word,
                    categoryId,
                });
            } else {
                newReadyWords.push(wObj);
            }
        });

        if (newReadyWords.length) {
            syncNewWords(newReadyWords);
        }
    }, [dictionaryWords, processedWords]);

    useEffect(() => {
        if (learnMode) userWordsStore.setCurrentWord({});
    }, [learnMode]);

    if (!nativeLang || lang === nativeLang)
        return (
            <p className={cl.emptyAlert}>{t('user_view.select_target_lang')}</p>
        );

    if (learnMode)
        return (
            <div className={cl.learnWordsContainer}>
                <LearnWords words={dictionaryWords} categoryId={categoryId} />
                <div className={cl.closeButton}>
                    <MicroButton
                        icon={<IconClose />}
                        variant={'grey'}
                        size={'regular'}
                        onClick={() => setLearnMode(false)}
                    />
                </div>
            </div>
        );
    return (
        <>
            <Settings />
            <p style={{ margin: 'auto' }}>Under Construction</p>
            {/* <div className={cl.wordsList}>
                {!learnMode && (
                    <div className={cl.chaptersContainer}>
                        <ChaptersList
                            chapters={getWordsByStatus()}
                            type={Chapters.Dictionary}
                            current={currentWord}
                            setCurrent={userWordsStore.setCurrentWord}
                            isLoading={isLoading}
                        />
                        {dictionaryWords.length !== 0 && (
                            <Button
                                variant={
                                    learnMode ? 'grey' : Chapters.Dictionary
                                }
                                text={t(
                                    learnMode
                                        ? 'buttons.close'
                                        : 'glossary_settings.learn_words'
                                )}
                                style={{ marginTop: 16 }}
                                className={cl.learnButton}
                                onClick={toggleLearnMode}
                                isDisabled={getIsLearnDisabled()}
                            />
                        )}
                    </div>
                )}
            </div> */}
            {/* <div className={cl.previewContainer}>
                <UserChapterPreview
                    chapter={{ ...currentWord, id: currentWord?.word }}
                    type={Chapters.Dictionary}
                />
 
            </div> */}
        </>
    );
};

export default observer(WordsUserPreview);
