import React from 'react';
import cl from './ChapterPreviewContainer.module.css';

const ChapterPreviewContainer = ({ children, className }) => {
    return (
        <div className={`${cl.container} ${className ? className : ''}`}>
            {children}
        </div>
    );
};

export default ChapterPreviewContainer;
