import { makeAutoObservable } from 'mobx';
import ProgressService from '../api/ProgressService';
import DictionaryService from '../api/DictionaryService';
import {
    CheckWordStatusInterval,
    DictionaryCodes,
    ProcessedWordsKey,
    ServerWordStatuses,
    UserWordsCategory,
    WordStatuses,
} from '../pages/TeacherContent/views/DictionaryView/data/constants';
import { Roles } from '../data/common';

const WordsCacheStorageKey = 'cachedDictionaryWords';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class WordsStore {
    processedWords = [];
    intervalRef = null;
    lang = 'en';
    role = null;

    cachedWords = new Map();

    constructor() {
        makeAutoObservable(this);
        this.loadFromLocalStorage();
    }

    init(role, lang) {
        this.role = role;
        this.lang = lang;
        this.getProcessedWords();
    }

    setLang(lang) {
        this.lang = lang;
    }

    setProcessedWords(processedWords) {
        this.processedWords = processedWords;
        localStorage.setItem(ProcessedWordsKey, JSON.stringify(processedWords));
    }

    getProcessedWords() {
        const savedData = localStorage.getItem(ProcessedWordsKey);
        const processedWords = savedData ? JSON.parse(savedData) : [];
        this.setProcessedWords(processedWords);
    }

    addWord({ word, categoryId, status, targetLang }) {
        if (!word) return;

        const newProcessedWords = [
            ...this.processedWords.filter(
                (w) => !(w.word === word && w.categoryId === categoryId)
            ),
            { word, categoryId, status, targetLang },
        ];
        this.setProcessedWords(newProcessedWords);
    }

    deletePendingWord({ word, categoryId }) {
        const newProcessedWords = this.processedWords.filter(
            (w) => !(w.word === word && w.categoryId === categoryId)
        );
        this.setProcessedWords(newProcessedWords);
    }

    updatePendingWord({ word, categoryId, status }) {
        this.setProcessedWords(
            this.processedWords.map((w) =>
                w.word === word && w.categoryId === categoryId
                    ? { ...w, status }
                    : w
            )
        );
    }

    async processWord(wObj) {
        if (![WordStatuses.Queued, WordStatuses.Pending].includes(wObj.status))
            return;
        const { word, categoryId, targetLang } = wObj;
        const {
            data: wordObj,
            status,
            errorCode,
        } = await this.getCachedWordByText({
            word,
            sourceLang: this.lang,
            targetLang: this.lang,
        });

        if (errorCode === DictionaryCodes.NotFound) {
            await this.sendAddRequest({ word, targetLang });
            return;
        }

        if (status === DictionaryCodes.Ok) {
            const wordId = wordObj.data[0].id;

            await this.keepWord({
                word: wordObj.word,
                originalForm: word,
                wordId,
                categoryId,
                targetLang,
            });
        } else {
            if (
                [
                    DictionaryCodes.InvalidWord,
                    DictionaryCodes.TranslationError,
                ].includes(errorCode)
            ) {
                this.updatePendingWord({
                    word,
                    categoryId,
                    status: WordStatuses.Invalid,
                });
                return;
            }
            if (errorCode === DictionaryCodes.NoTranslation) {
                await DictionaryService.translateWord({
                    word,
                    sourceLang: this.lang,
                    targetLang,
                });
            }

            if (wObj.status === WordStatuses.Queued) {
                await this.sendAddRequest({ word, targetLang });
                this.updatePendingWord({
                    word,
                    categoryId,
                    status: WordStatuses.Pending,
                });
            }
        }
    }

    async sendAddRequest({ word, targetLang }) {
        await DictionaryService.addWord({
            word,
            sourceLang: this.lang,
            targetLang: targetLang
                ? targetLang
                : this.lang === 'en'
                  ? 'de'
                  : 'en',
        });
    }

    async keepWord({ word, originalForm, wordId, categoryId, targetLang }) {
        await ProgressService.addFavoriteWord({
            id: wordId,
            word,
            lang: this.lang,
        });

        if (categoryId !== UserWordsCategory) {
            try {
                await ProgressService.addCategoryWord({
                    categoryId,
                    word,
                    lang: this.lang,
                });
            } catch (e) {
                console.error(e);
            }
        }
        DictionaryService.translateWord({
            word,
            sourceLang: this.lang,
            targetLang: targetLang
                ? targetLang
                : this.lang === 'en'
                  ? 'de'
                  : 'en',
        });
        this.updatePendingWord({
            word: originalForm,
            categoryId,
            status: WordStatuses.Ready,
        });
    }

    async processWords() {
        const pendingWords = this.processedWords.filter(
            (w) =>
                ![WordStatuses.Invalid, WordStatuses.Ready].includes(w.status)
        );

        if (!pendingWords?.length) return;

        const plainActiveWords = pendingWords.map((w) => w.word);

        if (this.role === Roles.User) {
            await Promise.all(
                plainActiveWords.map(async (word) => {
                    await DictionaryService.addWord({
                        word,
                        sourceLang: this.lang,
                        targetLang: plainActiveWords[0].targetLang,
                    });
                })
            );
        } else {
            DictionaryService.addWordsBatch({
                words: plainActiveWords,
                sourceLang: this.lang,
                targetLang: plainActiveWords[0].targetLang,
            });
        }

        const { data } = await DictionaryService.getBatch({
            words: plainActiveWords,
            sourceLang: this.lang,
        });

        if (!data?.length) return;

        const wordMap = new Map();

        data.forEach((wordObj) => {
            const existingWord = wordMap.get(wordObj.word);

            if (existingWord) {
                existingWord.forms = Array.from(
                    new Set([...existingWord.forms, ...wordObj.forms])
                );
            } else {
                wordMap.set(wordObj.word, { ...wordObj });
            }
        });

        const uniqueWords = Array.from(wordMap.values());

        const pendingWordsMap = new Map(
            pendingWords.map((wordObj) => [wordObj.word, wordObj])
        );

        const mappedReadyWords = [];

        uniqueWords.forEach((batchWord) => {
            const { word, id, forms } = batchWord;

            const matchingOriginalForm = forms.find((form) =>
                pendingWordsMap.has(form)
            );

            if (matchingOriginalForm) {
                const { targetLang, categoryId } =
                    pendingWordsMap.get(matchingOriginalForm);

                mappedReadyWords.push({
                    word,
                    wordId: id,
                    originalForm: matchingOriginalForm,
                    categoryId,
                    targetLang,
                });
            }
        });

        if (mappedReadyWords?.length) {
            for (const word of mappedReadyWords) {
                this.keepWord(word);
                await delay(100);
            }
        }
    }

    startProcessingWords() {
        clearInterval(this.intervalRef);
        this.processWords();
        this.intervalRef = setInterval(() => {
            this.processWords();
        }, CheckWordStatusInterval);
    }

    stopProcessingWords() {
        clearInterval(this.intervalRef);
    }

    async getCachedWordByText({ word, sourceLang, targetLang }) {
        const cacheKey = `${word}-${sourceLang}-${targetLang}`;

        if (this.cachedWords.has(cacheKey)) {
            return { data: this.cachedWords.get(cacheKey) };
        }

        const res = await DictionaryService.getWordByText({
            word,
            sourceLang,
            targetLang,
        });

        if (res.data) {
            this.cachedWords.set(cacheKey, res.data);
            this.saveToLocalStorage();
        }

        return { data: res.data, word, errorCode: res.errorCode || null };
    }

    saveToLocalStorage() {
        const cacheObject = Object.fromEntries(this.cachedWords);
        localStorage.setItem(WordsCacheStorageKey, JSON.stringify(cacheObject));
    }

    loadFromLocalStorage() {
        const storedCache = localStorage.getItem(WordsCacheStorageKey);
        if (storedCache) {
            this.cachedWords = new Map(Object.entries(JSON.parse(storedCache)));
        }
    }
}

const wordsStore = new WordsStore();
export default wordsStore;
