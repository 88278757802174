import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ExerciseService from '../../../../../../api/ExerciseService';
import { useFetching } from '../../../../../../hooks/useFetching';
import languagesStore from '../../../../../../store/interface';
import {
    onboardingStore,
    OnboardingKeys,
} from '../../../../../../features/Onboarding';
import ExercisePreview from '../../../../../../features/ExercisePreview/ExercisePreview';
import {
    ExerciseMediaTypes,
    ExercisesPaginationCount,
    FilterModes,
    getExerciseTypesByMedia,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { LanguageLevels, Roles } from '../../../../../../data/common';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '../../../../components';
import { Input, Select, ToggleButton, Toolbar } from '../../../../../../UI';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import cl from './SelectExerciseModal.module.css';

const SelectExerciseModal = ({
    visible,
    setVisible,
    onClick,
    withCategorySelector,
    alreadyAdded = [],
    presetSearchString = '',
    presetDifficulty = '',
    addLoading,
    isOwn,
}) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.ExercisesAwaitSelect
    );

    const lastScroll = useRef(0);

    const mediaTypeOptions = [...Object.values(ExerciseMediaTypes)].map(
        (mediaType) => ({
            value: mediaType,
            name: t(`lesson_wizard.${mediaType}_exercise`),
        })
    );

    const [typeOptions, setExercisesTypeOptions] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [page, setPage] = useState(0);
    const [currentExercise, setCurrentExercise] = useState({});
    const [difficulty, setDifficulty] = useState(presetDifficulty);
    const [searchString, setSearchString] = useState(presetSearchString);
    const [paginationEnd, setPaginationEnd] = useState(false);
    const [type, setType] = useState('');
    const [mediaType, setMediaType] = useState('');
    const [mode, setMode] = useState();

    const [getExercises, isLoading] = useFetching(async () => {
        if (!visible) return;

        const { data } = await ExerciseService.getExercises({
            offset: 0,
            // TODO add pagination @saratovkin
            limit: 1000,
            searchString,
            lang,
            type,
            mediaType,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
        });
        const { items } = data;
        if (!items.length || items.length < ExercisesPaginationCount) {
            setPaginationEnd(true);
        }
        const newExercises = (
            page > 0 ? [...exercises, ...items] : items
        ).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item.id === t.id)
        );
        const withoutExisting = newExercises.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );
        setExercises(withoutExisting);
        if (!currentExercise.id) setCurrentExercise(withoutExisting[0]);
    });

    const [getOwnExercises, isOwnLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getSavedExercises({
            offset: 0,
            // TODO add pagination @saratovkin
            limit: 1000,
            type,
            mediaType,
            difficulty: difficulty && LanguageLevels.indexOf(difficulty),
            searchString,
            lang,
        });

        const items = data?.items ?? [];
        if (!items.length || items.length < ExercisesPaginationCount) {
            setPaginationEnd(true);
        }

        const withoutExisting = items.filter(
            (e) => !alreadyAdded.some((a) => a.id === e.id)
        );

        setExercises(withoutExisting);
        if (!currentExercise.id) setCurrentExercise(withoutExisting[0]);
    });

    const handleListScroll = (e) => {
        if (paginationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > lastScroll.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                setPage(page + 1);
            }
        }
        lastScroll.current = list.scrollLeft;
    };

    const handleExerciseClick = (exerciseObj, index) => {
        if (currentExercise && currentExercise?.id === exerciseObj.id) return;
        setCurrentExercise({ ...exerciseObj, index });
    };

    const handleExerciseSelect = () => {
        if (!currentExercise) return;
        const { id, title } = currentExercise;
        onClick({ id, title });
        setVisible(false);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    };

    const resetPagination = () => {
        setPage(0);
        setPaginationEnd(false);
        setExercises([]);
        setCurrentExercise({});
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    useEffect(() => {
        resetPagination();
        if (!visible || !mode) return;
        mode === FilterModes.All ? getExercises() : getOwnExercises();
    }, [searchString, lang, type, mediaType, difficulty, visible, mode]);

    useEffect(() => {
        setSearchString(presetSearchString);
    }, [presetSearchString]);

    useEffect(() => {
        setDifficulty(presetDifficulty);
        setSearchString(presetSearchString);
    }, [visible]);

    useEffect(() => {
        if (!mediaType) {
            setExercisesTypeOptions(
                [
                    ...Object.values(GrammarExerciseTypes),
                    ...Object.values(MediaExerciseTypes),
                ].map((type) => ({ value: type, name: t(`exercises.${type}`) }))
            );
        } else {
            const availableTypes = getExerciseTypesByMedia(mediaType);
            setExercisesTypeOptions(
                Object.values(availableTypes).map((type) => ({
                    value: type,
                    name: t(`exercises.${type}`),
                }))
            );
        }
    }, [mediaType]);

    return (
        <AddChapterModal
            visible={visible}
            isOwn={isOwn}
            setVisible={handleClose}
            type={Chapters.Exercise}
        >
            <Toolbar>
                <ToggleButton
                    nullable
                    label={t('users.level')}
                    options={LanguageLevels}
                    value={difficulty}
                    onChange={setDifficulty}
                />
                {withCategorySelector && (
                    <ToggleButton
                        value={mode}
                        onChange={setMode}
                        options={Object.values(FilterModes)}
                        disabled
                        withTranslations={'exercises'}
                    />
                )}
                <Select
                    style={{ minWidth: 212 }}
                    label={t('exercises.section')}
                    value={mediaType}
                    options={mediaTypeOptions}
                    variant={'white'}
                    onChange={setMediaType}
                />
                <Select
                    style={{ minWidth: 212 }}
                    label={t('exercises.type')}
                    value={type}
                    options={typeOptions}
                    variant={'white'}
                    onChange={setType}
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                    search
                    showReset
                />
            </Toolbar>
            <div className={cl.exercisesContainer}>
                <ChaptersScrollableList
                    type={Chapters.Exercise}
                    isLoading={isLoading || isOwnLoading}
                    chapters={exercises}
                    current={currentExercise}
                    setCurrent={handleExerciseClick}
                    onScroll={handleListScroll}
                    onSelect={handleExerciseSelect}
                />
                {visible && currentExercise?.id ? (
                    <ChapterPreviewContainer>
                        <ExercisePreview
                            type={Roles.Teacher}
                            isLoading={addLoading}
                            exerciseId={currentExercise?.id}
                        />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default observer(SelectExerciseModal);
