import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import shuffleArray from '../../../../utils/shuffleArray';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { ReactComponent as IconDrag } from '../../../../assets/svg/exercises-drag.svg';
import cl from './UserSentenceOrderPreview.module.css';

const SentenceCard = ({ id, index, text, showAnswers, isCorrect, isPdf }) => {
    if (isPdf)
        return <div className={`${cl.sentenceCont} ${cl.pdf}`}>{text}</div>;
    return (
        <Draggable draggableId={id} index={index} isDragDisabled={isPdf}>
            {(provided) => (
                <div
                    className={`${cl.sentenceCont} ${
                        showAnswers ? (isCorrect ? cl.correct : cl.wrong) : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <IconDrag />
                    {text}
                </div>
            )}
        </Draggable>
    );
};

const UserSentenceOrderPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;
    const { sentences } = data;
    const { userAnswers } = results;

    const sentencesToRender = userAnswers
        ? userAnswers?.map((text) => sentences.find((s) => s.text === text))
        : shuffleArray(sentences);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newSentences = [...sentencesToRender];
        const [removedSentence] = newSentences.splice(source.index, 1);
        newSentences.splice(destination.index, 0, removedSentence);

        setResults({ userAnswers: newSentences.map((s) => s.text) });
    };

    return (
        <div>
            <QuestionsLabel label={'sentences_order_user_info'} />
            {sentencesToRender?.length ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="sentence-list">
                        {(provided) => (
                            <div
                                className={`${cl.answersCont}`}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {sentencesToRender.map((s, index) => (
                                    <SentenceCard
                                        key={s?.id}
                                        id={s?.id}
                                        index={index}
                                        text={s?.text ?? ''}
                                        showAnswers={isCompleted}
                                        isCorrect={
                                            sentences[index]?.text === s?.text
                                        }
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : null}
        </div>
    );
};

export default UserSentenceOrderPreview;
