import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import PodcastService from '../../../../api/PodcastService';
import {
    LabeledInput,
    Modal,
    PrimaryButton,
    PrimaryInput,
} from '../../../../UI';
import { ROUTES } from '../../../../router';
import cl from './TrackCardModal.module.css';

const DuplicatedDataErrorCode = '042';

const TrackCardModal = ({ className, trackData, onDelete }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        id,
        podcastId,
        title,
        rating: defaultRating,
        recognized,
        difficulty,
    } = trackData;

    const [modal, setModal] = useState(false);
    const [reason, setReason] = useState('');
    const [presetSelect, setPresetSelect] = useState(false);
    const [rating, setRating] = useState(defaultRating || 0);

    const [updateTrackPriority, priorityLoading] = useFetching(async () => {
        await TrackService.updateTrackPriority({
            id,
            preset: true,
            level: difficulty,
            rating,
        });
        setPresetSelect(false);
    });

    const [deleteTrack, deleteLoading] = useFetching(async () => {
        const res = await TrackService.deleteTrack(id);
        processResponse(res);
    });

    const [blacklistTrack, blTrackLoading, blTrackError, resetBlTrackError] =
        useFetching(async () => {
            const res = await TrackService.blacklistTrack({ id, reason });
            processResponse(res);
        });

    const [
        blacklistPodcast,
        blPodcastLoading,
        blPodcastError,
        resetBlPodcastError,
    ] = useFetching(async () => {
        const res = await PodcastService.blacklistPodcast(podcastId);
        processResponse(res);
    });

    const processResponse = (res) => {
        if (res.status === 200) {
            setModal();
            onDelete(id);
        }
    };

    const handlePlayerButton = () => {
        if (recognized !== 'true') return;
        window.open(`${ROUTES.listen}?id=${id}`, '_blank');
    };

    const handleDeleteTrack = (e) => {
        e.stopPropagation();
        setModal('delete');
    };

    const handleBlacklistTrack = (e) => {
        e.stopPropagation();
        setModal('blacklist');
    };

    const confirmDeleteTrack = (isBlacklist) => {
        deleteTrack();
        if (isBlacklist) {
            blacklistPodcast();
        }
    };

    const handleRatingUpdate = () => {
        updateTrackPriority();
    };

    const handleLevelInput = (text) => {
        const number = parseInt(text.replace(/\D/g, ''), 10);
        setRating(Number.isNaN(number) ? 0 : number);
    };

    const renderError = () => {
        const error = blPodcastError || blTrackError;
        if (!error || !error.message || error.code !== DuplicatedDataErrorCode)
            return (
                <p className={cl.errorMessage}>
                    {t('errors.unexpected_error')}
                </p>
            );
        return (
            <p className={cl.errorMessage}>{t('errors.blacklisted_track')}</p>
        );
    };

    useEffect(() => {
        resetBlPodcastError();
        resetBlTrackError();
    }, [modal]);

    useEffect(() => {
        setRating(defaultRating || 0);
    }, [presetSelect, defaultRating]);

    return (
        <>
            {presetSelect ? (
                <div className={cl.presetContainer}>
                    <p className={cl.sideLabel}>{t('library.preset_label')}</p>
                    <PrimaryInput value={rating} onChange={handleLevelInput} />
                    <PrimaryButton
                        isDisabled={rating === null}
                        variant={'magenta'}
                        text={t('buttons.send')}
                        onClick={handleRatingUpdate}
                        isLoading={priorityLoading}
                    />
                    <PrimaryButton
                        variant={'dark'}
                        text={t('buttons.close')}
                        onClick={() => setPresetSelect(false)}
                    />
                </div>
            ) : (
                <div className={className}>
                    {recognized === 'true' && (
                        <>
                            <PrimaryButton
                                variant={'magenta'}
                                text={t('library.preset')}
                                onClick={() => setPresetSelect(true)}
                            />
                            <PrimaryButton
                                variant={'dark'}
                                text={t('Pony Player')}
                                onClick={handlePlayerButton}
                            />
                        </>
                    )}
                    {onDelete && (
                        <>
                            <PrimaryButton
                                variant={'dark'}
                                text={t('track_card.block_track')}
                                onClick={handleBlacklistTrack}
                            />
                            <PrimaryButton
                                variant={'red'}
                                text={t('track_card.delete')}
                                onClick={handleDeleteTrack}
                            />
                        </>
                    )}
                </div>
            )}

            <Modal
                title={
                    modal === 'delete'
                        ? t('track_card.confirm_deletion')
                        : t('track_card.confirm_blocking')
                }
                visible={modal}
                setVisible={setModal}
            >
                <div className={cl.modalInner}>
                    <p>
                        {modal === 'delete'
                            ? `${t('track_card.are_u_sure')} "${title}"?`
                            : `${t('track_card.ask_block_track')} "${title}"?`}
                    </p>
                    {blTrackError || blPodcastError ? (
                        <div className={cl.buttonsContainer}>
                            {renderError()}
                            <PrimaryButton
                                onClick={() => setModal()}
                                text={t('buttons.close')}
                            />
                        </div>
                    ) : (
                        <>
                            {modal === 'blacklist' && (
                                <LabeledInput
                                    value={reason}
                                    onChange={setReason}
                                    label={t('track_card.reason')}
                                />
                            )}
                            <div className={cl.buttonsContainer}>
                                {modal === 'delete' && (
                                    <PrimaryButton
                                        variant={'red'}
                                        text={t('buttons.delete_and_blacklist')}
                                        onClick={() => confirmDeleteTrack(true)}
                                        isLoading={blPodcastLoading}
                                    />
                                )}
                                <>
                                    <PrimaryButton
                                        variant={'red'}
                                        text={t('buttons.yes')}
                                        onClick={() => {
                                            modal === 'delete'
                                                ? confirmDeleteTrack(false)
                                                : blacklistTrack();
                                        }}
                                        isLoading={
                                            modal === 'delete'
                                                ? deleteLoading
                                                : blTrackLoading
                                        }
                                    />
                                    <PrimaryButton
                                        onClick={() => setModal()}
                                        text={t('buttons.no')}
                                    />
                                </>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default TrackCardModal;
