import React from 'react';
import { t } from 'i18next';
import { ReactComponent as AspiringTeacher1 } from '../../../../assets/png/aspiring-teacher-1.png';
import { ReactComponent as AspiringTeacher2 } from '../../../../assets/svg/aspiring-teacher-2.svg';
import { ReactComponent as AspiringTeacher3 } from '../../../../assets/svg/aspiring-teacher-3.svg';
import { ReactComponent as iconWords } from '../../../../assets/svg/icon-dictionaries-mini.svg';
import { ReactComponent as Student } from '../../../../assets/svg/student.svg';
import { ReactComponent as ProgressBar } from '../../../../assets/svg/progress_bar.svg';
import { ReactComponent as OrderIcon } from '../../../../assets/svg/icon-order.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/svg/icon-camera-mini.svg';
import { ReactComponent as QuizIcon } from '../../../../assets/svg/icon-quiz.svg';
import { ReactComponent as IconExercises } from '../../../../assets/svg/icon-exercises-mini.svg';
import { ReactComponent as StarIcon } from '../../../../assets/svg/icon-star.svg';
import cl from './ProfitCard.module.css';

let el = {
    track: {
        title: 'Exam',
        age: '12-14',
        level: 'C1',
        certificate: 'IELTS',
    },
    tasks: [
        {
            type: 'words',
            text: 'Religion — vocabulary',
        },
        {
            type: 'words',
            text: 'Job Search Advice ',
        },
        {
            type: 'words',
            text: 'Religion — vocabulary',
        },
    ],
};

let getTaskImage = (type) => {
    switch (type) {
        case 'words':
            return iconWords;
        default:
            return '';
    }
};

let getTitle = (index) => {
    switch (index) {
        case 0:
            return t('teacher_tutorial.profit_card_aspiring_teacher');
        case 1:
            return t('teacher_tutorial.profit_card_experienced_teacher');
        case 2:
            return t('teacher_tutorial.profit_card_specialist');
        case 3:
            return t('teacher_tutorial.profit_card_student');
        default:
            return '';
    }
};

let getSubTitle = (index) => {
    switch (index) {
        case 0:
            return t('teacher_tutorial.profit_card_aspiring_teacher_subtitle');
        case 1:
            return t(
                'teacher_tutorial.profit_card_experienced_teacher_subtitle'
            );
        case 2:
            return t('teacher_tutorial.profit_card_specialist_subtitle');
        case 3:
            return t('teacher_tutorial.profit_card_student_subtitle');
        default:
            return '';
    }
};

const getExampleBlock = (index) => {
    switch (index) {
        case 0:
            return (
                <div className={`${cl.exampleBlock} ${cl.aspiringTeacher}`}>
                    <div>
                        <div className={cl.firstImage} />
                        <div className={cl.aspiringTeacher_textBlock}>
                            <p className={cl.block_title}>
                                Human rights and racial diversity
                            </p>
                            <p className={cl.gray}>
                                {t('situations.male')} ∙{' '}
                                {t('situations.friendly')} ∙ B1
                            </p>
                        </div>
                    </div>
                    <div>
                        <AspiringTeacher2 className={cl.img} />
                        <div className={cl.aspiringTeacher_textBlock}>
                            <p className={cl.block_title}>Past Simple</p>
                            <p className={cl.gray}>
                                {t('exercises.substitution')} ∙ B1
                            </p>
                        </div>
                    </div>
                    <div>
                        <AspiringTeacher3 className={cl.img} />
                        <div className={cl.aspiringTeacher_textBlock}>
                            <p className={cl.block_title}>
                                5G Technology - Explained (New Apple IPhone 12
                                Tech)
                            </p>
                            <p className={cl.gray}>13:39 ∙ B1 ∙ IELTS</p>
                        </div>
                    </div>
                </div>
            );
        case 1:
            return (
                <div className={`${cl.exampleBlock} ${cl.experiencedTeacher}`}>
                    <div className={cl.experiencedTeacher_block}>
                        <IconExercises
                            className={cl.experiencedTeacher_check}
                        />
                        <OrderIcon />
                        <p>{t('exercises.sentence_order')}</p>
                        <VideoIcon />
                    </div>
                    <div className={cl.experiencedTeacher_block}>
                        <StarIcon className={cl.experiencedTeacher_star} />
                        <QuizIcon />
                        <p>{t('exercises.quiz')}</p>
                        <VideoIcon />
                    </div>
                </div>
            );
        case 2:
            return (
                <div className={`${cl.exampleBlock} ${cl.specialist}`}>
                    <div>
                        <div className={cl.cardContent}>
                            <div className={cl.cardTrack}>
                                <p>{el.track.title}</p>
                                <div>
                                    <p>{el.track.age}</p>∙
                                    <p>{el.track.level}</p>∙
                                    <p>{el.track.certificate}</p>
                                </div>
                            </div>
                            <div className={cl.cardTasks}>
                                {el.tasks.map((el, i) => {
                                    const TaskImage = getTaskImage(el.type);
                                    return (
                                        <div className={cl.task} key={i}>
                                            {TaskImage && <TaskImage />}
                                            <p>{el.text}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 3:
            return (
                <div className={`${cl.exampleBlock} ${cl.student}`}>
                    <Student />
                    <ProgressBar className={cl.progressBar} />
                </div>
            );
        default:
            return null;
    }
};

const getStyle = (index) => {
    switch (index) {
        case 0:
            return cl.orange;
        case 1:
            return cl.blue;
        case 2:
            return cl.turquoise;
        case 3:
            return cl.heliotrope;
        default:
            return '';
    }
};

const ProfitCard = ({ index }) => {
    return (
        <div className={`${cl.profitCard} ${getStyle(index)}`}>
            <div className={cl.textBlock}>
                <p className={cl.index}>{'0' + (index + 1)}</p>
                <p className={cl.title}>{getTitle(index)}</p>
                <p
                    className={cl.subtitle}
                    dangerouslySetInnerHTML={{
                        __html: getSubTitle(index),
                    }}
                />
            </div>
            {getExampleBlock(index)}
        </div>
    );
};

export default ProfitCard;
