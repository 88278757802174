import React, { useEffect, useRef } from 'react';
import cl from './FilledRange.module.css';

const DefaultRangeColor = 'var(--purple)';

const FilledRange = ({
    className,
    value,
    min,
    step,
    max,
    onChange,
    style,
    color,
}) => {
    const rangeRef = useRef(null);
    const currentValueRef = useRef(null);

    const rangeColor = color || DefaultRangeColor;

    useEffect(() => {
        const percentage = ((value - min) / (max - min)) * 100;

        if (rangeRef.current) {
            rangeRef.current.style.background = `linear-gradient(to right, var(--purple) ${percentage}%, var(--primary-light-gray) ${percentage}%)`;
            rangeRef.current.style.background = `linear-gradient(to right, ${rangeColor} ${percentage}%, var(--primary-light-gray) ${percentage}%)`;
        }

        if (currentValueRef.current) {
            currentValueRef.current.style.left = `${percentage}%`;
        }
    }, [value, min, max]);

    return (
        <div
            className={`${cl.rangeContainer} ${className ? className : ''}`}
            style={style}
        >
            <span className={cl.label}>{min.toString()}</span>
            <input
                ref={rangeRef}
                className={cl.range}
                type="range"
                value={value}
                onChange={onChange}
                min={min}
                step={step}
                max={max}
            />
            <div className={cl.currentValueContainer}>
                <span ref={currentValueRef} className={cl.currentValue}>
                    {value}
                </span>
            </div>

            <span className={cl.label}>{max.toString()}</span>
        </div>
    );
};

export default FilledRange;
