import React from 'react';
import cl from './MicroButton.module.css';

const MicroButton = ({
    icon,
    size,
    text,
    variant,
    onClick,
    style,
    isDisabled,
    className,
}) => {
    const classNames = [cl.button, cl[size], cl[variant], className || ''];

    return (
        <button
            style={style}
            className={classNames.join(' ')}
            disabled={isDisabled}
            onClick={onClick}
        >
            {icon && <div className={cl.icon}>{icon}</div>}
            {text}
        </button>
    );
};

export default MicroButton;
