import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authStore } from '../../App';
import { getDefaultRouteByRole } from '../../router';
import toastStore, { ToastVariants } from '../../features/Toast/store/toast';
import { Roles } from '../../data/common';
import { AuthModes } from '../../store/auth';

const FollowTeacher = () => {
    const { user, userTeachers } = authStore;
    const { role } = user;
    const location = useLocation();
    const navigate = useNavigate();

    const handleGuestTeacherFollow = (teacherId) => {
        authStore.setPendingTeacher(teacherId);
        authStore.setAuthMode(AuthModes.Register);
        authStore.setIsAuth(true);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const teacherId = params.get('id');

        if (teacherId) {
            if (role === Roles.User) {
                authStore.followTeacher(teacherId);
            } else {
                handleGuestTeacherFollow(teacherId);
            }
            const defaultRoute = getDefaultRouteByRole(role);
            navigate(defaultRoute);
        }
    }, [location.search, role]);

    return <></>;
};

export default FollowTeacher;
