import React from 'react';
import { observer } from 'mobx-react-lite';
import { Ages } from '../../../../pages/TeacherContent/data/constants';
import { LanguageLevels } from '../../../../data/common';
import EditLessonModal from '../EditLessonModal/EditLessonModal';
import cl from './ModalHeader.module.css';

const ModalHeader = ({ lesson, onUpdate, hideEdit }) => {
    return (
        <div className={cl.lessonHeader}>
            <div className={cl.lessonInfo}>
                <p className={cl.title}>{lesson.title}</p>

                {typeof lesson.age === 'number' && (
                    <p className={cl.lessonInfoText}>{Ages[lesson.age]}</p>
                )}
                {typeof lesson.difficulty === 'number' && (
                    <p className={cl.lessonInfoText}>
                        {LanguageLevels[lesson.difficulty]}
                    </p>
                )}
                {lesson.exam && (
                    <p className={cl.lessonInfoText}>{lesson.exam}</p>
                )}

                {!hideEdit && onUpdate && (
                    <EditLessonModal lesson={lesson} onUpdate={onUpdate} />
                )}
            </div>
        </div>
    );
};

export default observer(ModalHeader);
