import React from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as IconVideo } from '../../../../assets/svg/icon-video-medium.svg';
import { ReactComponent as IconExercises } from '../../../../assets/svg/icon-exercises-medium.svg';
import { ReactComponent as IconSituations } from '../../../../assets/svg/icon-situations-medium.svg';
import { ReactComponent as IconDictionaries } from '../../../../assets/svg/icon-dictionaries-medium.svg';
import { t } from 'i18next';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import guestOnboarding from '../../store/guestOnboarding';
import InfoWrapper from '../InfoWrapper/InfoWrapper';
import {
    ArrowPositions,
    BubblePositions,
} from '../../../../features/Onboarding';
import cl from './SelectionBar.module.css';

export const renderIcon = (type) => {
    switch (type) {
        case Chapters.Track:
            return <IconVideo />;
        case Chapters.Situation:
            return <IconSituations />;
        case Chapters.Exercise:
            return <IconExercises />;
        case Chapters.Dictionary:
            return <IconDictionaries />;
        default:
            return null;
    }
};

const SELECTION_ITEMS = [
    { type: Chapters.Dictionary, label: 'navbar.dictionaries' },
    { type: Chapters.Exercise, label: 'navbar.exercises' },
    { type: Chapters.Situation, label: 'navbar.situations' },
    { type: Chapters.Track, label: 'navbar.tracks' },
];

const SelectionBar = ({
    isTeacher,
    onChange,
    isTaskExample = false,
    isCostSection = false,
    type = Chapters.Dictionary,
}) => {
    const renderNavigationIcon = (type) => (
        <div className={cl.iconContainer}>{renderIcon(type)}</div>
    );

    const renderSelectionItem = (item) => {
        return (
            <div
                key={item.type}
                className={`${cl.optionContainer} ${cl[item.type]} ${item.type === type ? cl.active : ''}`}
                onClick={() => onChange(item.type)}
            >
                {renderNavigationIcon(item.type)}
                <p className={cl.title}>{t(item.label)}</p>
            </div>
        );
    };

    const onboardingKey =
        guestOnboarding.isOnboarding('navigation') ||
        guestOnboarding.isOnboarding('extra_navigation');

    return (
        <>
            {isCostSection && (
                <div className={cl.selectionBlock}>
                    <p
                        className={`${cl.button} ${isTeacher ? cl.focus : ''}`}
                        onClick={() => onChange(true)}
                    >
                        {t('teacher_tutorial.selection_teacher')}
                    </p>
                    <p
                        className={`${cl.button} ${isTeacher ? '' : cl.focus}`}
                        onClick={() => onChange(false)}
                    >
                        {t('teacher_tutorial.selection_student')}
                    </p>
                </div>
            )}
            {isTaskExample && (
                <InfoWrapper
                    onboardingKey={onboardingKey}
                    title={t(
                        guestOnboarding.isOnboarding('navigation')
                            ? 'teacher_tutorial.navigation_info'
                            : 'teacher_tutorial.extra_navigation_info'
                    )}
                    subtitle={t(
                        guestOnboarding.isOnboarding('navigation')
                            ? 'teacher_tutorial.navigation_info_subtitle'
                            : 'teacher_tutorial.extra_navigation_info_subtitle'
                    )}
                    delay={200}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Bottom,
                    }}
                    arrowPosition={ArrowPositions.Bottom}
                    showButton
                >
                    <div className={cl.selectionTaskBlock}>
                        {SELECTION_ITEMS.map(renderSelectionItem)}
                    </div>
                </InfoWrapper>
            )}
        </>
    );
};

export default observer(SelectionBar);
