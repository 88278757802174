import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import userDialoguesStore from './store/userDialogues';
import userStore from '../../store/userStore';
import { Input, PageWrapper, Select, Toolbar, ViewTitle } from '../../../../UI';
import { UserChapterPreview, UserContentContainer } from '../../components';
import { ReactComponent as IconSituation } from '../../../../assets/svg/lessons-situation.svg';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { LanguageLevels } from '../../../../data/common';
import { Chapters } from '../../../LessonsKanban/data/constants';
import cl from './UserDialogues.module.css';

const UserDialogues = ({ isHistory }) => {
    const { t } = useTranslation();

    const { user } = authStore;

    const { favDialogues, historyDialogues } = userStore;

    const {
        categories,
        categoryId,
        currentSituation,
        filteredSituations,
        searchString,
        situations,
    } = userDialoguesStore;

    const history = isHistory ? historyDialogues : null;

    useEffect(() => {
        const filteredSituations = situations.filter((s) => {
            const matchesTitle = searchString
                ? s.title.toLowerCase().includes(searchString.toLowerCase())
                : true;

            const matchesCategory = categoryId
                ? s.categoryId === categoryId
                : true;

            const matchesLevel = !Array.isArray(history)
                ? s.level.includes(LanguageLevels[user.level])
                : true;

            const matchesToDo = isHistory
                ? true
                : ![...favDialogues, ...historyDialogues].some(
                      (d) => d.situationId === s.id
                  );

            const matchesHistory = Array.isArray(history)
                ? history.some((sObj) => sObj.situationId === s.id)
                : true;

            return (
                matchesHistory &&
                matchesLevel &&
                matchesToDo &&
                matchesTitle &&
                matchesCategory
            );
        });

        userDialoguesStore.setFilteredSituations(filteredSituations);
    }, [
        searchString,
        situations,
        categoryId,
        favDialogues,
        historyDialogues,
        history,
    ]);

    useEffect(() => {
        userDialoguesStore.setCurrentSituation({});
    }, [isHistory]);

    return (
        <PageWrapper additionalClass={cl.dialoguesWrapper}>
            <ViewTitle
                text={t(
                    isHistory
                        ? 'user_view.situations_history'
                        : 'user_view.my_dialogues'
                )}
                icon={<IconSituation />}
            />
            <UserContentContainer>
                <Toolbar variant={Chapters.Situation}>
                    <Input
                        value={searchString}
                        onChange={userDialoguesStore.setSearchString}
                        placeholder={t('user_view.search')}
                    />
                    <Select
                        label={t('exercises.category')}
                        style={{ minWidth: 220 }}
                        variant={'white'}
                        value={categoryId}
                        onChange={userDialoguesStore.setCategoryId}
                        options={categories}
                    />
                </Toolbar>
                <ChaptersList
                    chapters={filteredSituations}
                    type={Chapters.Situation}
                    current={currentSituation}
                    setCurrent={userDialoguesStore.setCurrentSituation}
                />
                <UserChapterPreview
                    chapter={currentSituation}
                    type={Chapters.Situation}
                    isHistory={isHistory}
                />
            </UserContentContainer>
        </PageWrapper>
    );
};

export default observer(UserDialogues);
