import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../hooks/useFetching';
import exercisesStore from '../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../store/interface';
import lessonsStore from '../LessonsKanban/store/lessonsStore';
import onboardingStore from '../Onboarding/store/onboarding';
import wizardStore from '../LessonsKanban/components/LessonWizard/store/wizardStore';
import ExerciseService from '../../api/ExerciseService';
import ProgressService from '../../api/ProgressService';
import { CloseButton, EmptyChaptersAlert, Loader } from '../../UI';
import {
    AddChapter,
    CloneLesson,
    FilePreview,
    ModalHeader,
} from './components';
import Player from '../../pages/Player/Player';
import { ChapterSection } from '../LessonsKanban/components';
import ExercisePreview from '../ExercisePreview/ExercisePreview';
import { CategoryWords } from '../../pages/TeacherContent/views/DictionaryView/components';
import { Situation } from '../../pages/TeacherContent/views/SituationsView/components';
import {
    Chapters,
    StudentExerciseStatuses,
    Views,
} from '../LessonsKanban/data/constants';
import {
    OnboardingWrapper,
    OnboardingModal,
    OnboardingKeys,
    BubblePositions,
    ArrowPositions,
} from '../Onboarding';
import { FileTypes, Roles } from '../../data/common';
import cl from './LessonModal.module.css';

const statusPriority = {
    [StudentExerciseStatuses.AwaitingEvaluation]: 1,
    [StudentExerciseStatuses.Done]: 2,
    [StudentExerciseStatuses.Unfinished]: 3,
};

const LessonModal = ({ isUser = false }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const { view, chaptersModalType, currentStudentChapters } = exercisesStore;
    const { currentLesson, currentChapter, isResetStudent, isClonePreview } =
        lessonsStore;

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitLessonModalClose
    );

    const awaitingAddChapter = onboardingStore.isOnboarding(
        OnboardingKeys.AwaitAddChapter
    );

    const isLibrary = view === Views.Library || isClonePreview;
    const isStudents = view === Views.Students;

    const pdfs = currentLesson?.pdfs ?? [];
    const pictures = currentLesson?.pictures ?? [];

    const [currentWord, setCurrentWord] = useState();
    const [tracks, setTracks] = useState([]);
    const [situations, setSituations] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [dictionaries, setDictionaries] = useState([]);
    const [files, setFiles] = useState([]);
    const [hasFetched, setHasFetched] = useState(false);

    const chapterRefs = useRef({});

    const formatExercises = () => {
        if (!currentStudentChapters.exercises) return exercises;

        return exercises
            .map((e) => ({
                ...e,
                status: currentStudentChapters.exercises.find(
                    (se) => se.id === e.id
                )?.status,
            }))
            .sort(
                (a, b) => statusPriority[a?.status] - statusPriority[b?.status]
            );
    };

    const chaptersList = [
        {
            items: formatExercises(),
            type: Chapters.Exercise,
        },
        {
            items: situations,
            type: Chapters.Situation,
        },

        {
            items: tracks,
            type: Chapters.Track,
        },
        {
            items: dictionaries,
            type: Chapters.Dictionary,
        },
        {
            items: files,
            type: Chapters.File,
        },
    ];

    const [getChaptersData, chaptersLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getLesson({
            id: currentLesson.id,
        });
        const chapters = data.chapters ?? [];
        lessonsStore.setCurrentLesson({ ...currentLesson, chapters });

        setHasFetched(true);

        await fetchWordPacks(data.chapters);
    });

    const fetchWordPacks = async (chapters) => {
        const wordPacks = chapters.filter(
            (c) => c.type === Chapters.Dictionary
        );
        if (!wordPacks.length) return;

        const wordPacksData = await Promise.all(
            wordPacks.map(async (wordPack) => {
                const { id: categoryId, title } = wordPack;
                const { data } = await ProgressService.getCategoryWords({
                    categoryId,
                    lang,
                });
                return data && data.items
                    ? { title, id: categoryId, ...data }
                    : null;
            })
        );

        const validWordPacks = wordPacksData.filter((pack) => pack !== null);

        wizardStore.setWordPacksArray(validWordPacks);

        if (validWordPacks.length > 0) {
            wizardStore.setWordPack(validWordPacks[0]);
        }
    };

    const [addChapter, addLoading, error, resetError] = useFetching(
        async ({ chapterId, chapterType, title }) => {
            await ExerciseService.addChapterToLesson({
                title,
                lessonId: currentLesson.id,
                chapterId,
                chapterType,
                order: 0,
            });

            const { data } = await ExerciseService.getLesson({
                id: currentLesson.id,
            });

            lessonsStore.updateLesson({
                ...currentLesson,
                chapters: data.chapters,
            });

            const newChapter = data.chapters.find((c) => c.id === chapterId);
            lessonsStore.setCurrentChapter(newChapter);

            if (awaitingAddChapter) {
                onboardingStore.moveToNextComponent();
            }
        }
    );

    const [updateLesson] = useFetching(async ({ id, ...params }) => {
        const isPrivate = params.status !== 'public';
        await ExerciseService.updateLesson({ id, ...params, isPrivate });
    });

    const [deleteLesson] = useFetching(async ({ id }) => {
        try {
            await ExerciseService.removeExercise({
                id,
            });
        } catch (_e) {
            try {
                await ExerciseService.deleteExercise({
                    id,
                });
            } catch (e) {
                console.error(e);
            }
        }
    });

    const handleLessonUpdate = async (params) => {
        const {
            age,
            difficulty,
            exam,
            vocabularyTopic,
            title,
            grammarTopic,
            isPrivate,
        } = params;

        const newTitle =
            title ||
            `${grammarTopic ? `${grammarTopic}: ` : ''}${vocabularyTopic}`;

        await updateLesson({
            id: currentLesson.id,
            title: newTitle,
            age,
            difficulty,
            exam,
            vocabularyTopic,
            grammarTopic,
            isPrivate,
        });
        lessonsStore.updateLesson({
            ...currentLesson,
            title: newTitle,
            age,
            difficulty,
            exam,
            vocabularyTopic,
            grammarTopic,
            isPrivate,
        });
    };

    const handleLessonClose = () => {
        lessonsStore.setCurrentLesson(null);
        lessonsStore.setCurrentChapter({});
        lessonsStore.setIsClonePreview(false);

        resetChapters();
        setHasFetched(false);

        if (isResetStudent) {
            exercisesStore.setCurrentStudent(null);
            lessonsStore.setCurrentStudentLessons([]);
        }
        lessonsStore.setIsResetStudent(false);
        isStudents && exercisesStore.setCurrentStudent(null);
        if (
            onboardingStore.isOnboarding(OnboardingKeys.AwaitLessonModalClose)
        ) {
            onboardingStore.moveToNextComponent();
        }
    };

    const handleLessonDelete = async () => {
        if (!currentLesson) return;
        lessonsStore.deleteThemeLesson(currentLesson.id);

        await deleteLesson({ id: currentLesson.id });
    };

    const handleFileUpload = (file) => {
        if (file.name.endsWith(FileTypes.Pdf)) {
            updateLesson({ id: currentLesson.id, pdf: file });
            lessonsStore.updateLesson({
                ...currentLesson,
                pdfs: [file.name],
            });
        } else {
            updateLesson({ id: currentLesson.id, picture: file });
            lessonsStore.updateLesson({
                ...currentLesson,
                pictures: [file.name],
            });
        }
    };

    const handleChapterDelete = (deletedChapterId) => {
        const deletedChapter = currentLesson?.chapters.find(
            (c) => c.id === deletedChapterId
        );
        if (deletedChapter && deletedChapter.type === Chapters.Dictionary) {
            wizardStore.resetWordPack();
        }
        const newLesson = {
            ...currentLesson,
            chapters: currentLesson?.chapters.filter(
                (c) => c.id !== deletedChapterId
            ),
        };
        lessonsStore.updateLesson(newLesson);
        if (currentChapter && currentChapter.id === deletedChapterId) {
            lessonsStore.setCurrentChapter({});
        }
    };

    const handleExerciseChange = ({ id, title, difficulty }) =>
        setExercises(
            exercises.map((e) =>
                e.id === id ? { ...e, title, difficulty } : e
            )
        );

    const renderCurrentChapter = () => {
        if (!hasFetched) return null;
        if (chaptersModalType) return null;
        return (
            <div
                className={
                    currentChapter?.type === Chapters.Dictionary
                        ? cl.wordPackCard
                        : cl.chapterCard
                }
            >
                {getChapterPreview()}
            </div>
        );
    };

    const handleWordPackUpdate = ({ id, wordsCount, title, level }) => {
        setDictionaries(
            dictionaries.map((d) =>
                d.id === id ? { ...d, wordsCount, title, level } : d
            )
        );

        if (currentChapter && currentChapter.id === id) {
            lessonsStore.setCurrentChapter({
                ...currentChapter,
                wordsCount,
                title,
                level,
            });
        }
    };

    const getChapterPreview = () => {
        switch (currentChapter.type) {
            case Chapters.Track:
                return (
                    <Player
                        key={'modalView'}
                        track={{
                            id: currentChapter.id,
                            title: currentChapter?.title,
                        }}
                    />
                );
            case Chapters.Situation: {
                return <Situation situation={currentChapter} />;
            }
            case Chapters.Exercise: {
                return (
                    <ExercisePreview
                        type={Roles.Teacher}
                        exerciseId={currentChapter.id}
                        onChange={handleExerciseChange}
                        hideEdit={isLibrary}
                        isUserLibrary={isUser}
                    />
                );
            }
            case Chapters.Dictionary: {
                return (
                    <CategoryWords
                        category={currentChapter}
                        lang={lang}
                        currentWord={currentWord}
                        setCurrentWord={setCurrentWord}
                        onUpdate={handleWordPackUpdate}
                        outlined
                    />
                );
            }
            case Chapters.File: {
                return <FilePreview file={currentChapter} />;
            }
            default:
                return (
                    <EmptyChaptersAlert title={t(`exercises.select_chapter`)} />
                );
        }
    };

    const getModalClassName = () => {
        const classNames = [cl.lessonModal];
        if (isLibrary) classNames.push(cl.cloneMode);

        return classNames.join(' ');
    };

    const selectFirstChapter = () => {
        const chapters = currentLesson.chapters ?? [];
        const activeChapter = chapters.length ? chapters[0] : {};
        lessonsStore.setCurrentChapter(activeChapter);
    };

    const renderChapterButton = () => {
        if (isLibrary || isUser || isStudents || wizardStore.isWizardVisible)
            return null;
        return (
            <OnboardingWrapper
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.LessonAddChapter
                )}
                title={t('teacher_onboarding.add_chapter_info')}
                subtitle={t('teacher_onboarding.add_chapter_info_subtitle')}
                delay={1200}
                bubblePosition={{
                    x: BubblePositions.x.Center,
                    y: BubblePositions.y.Top,
                }}
                bubbleAutoWidth
                arrowPosition={ArrowPositions.Top}
            >
                <div style={{ maxHeight: 32 }}>
                    <AddChapter
                        lang={lang}
                        onAdd={addChapter}
                        onFileSelect={handleFileUpload}
                        isLoading={addLoading}
                        isError={error}
                        resetError={resetError}
                        alreadyAdded={{
                            dictionaries,
                            exercises,
                            situations,
                            tracks,
                        }}
                        forceOpen={onboardingStore.isOnboarding(
                            OnboardingKeys.ExercisesContentSelect
                        )}
                    />
                </div>
            </OnboardingWrapper>
        );
    };

    const resetChapters = () => {
        setTracks([]);
        setSituations([]);
        setExercises([]);
        setDictionaries([]);
        setFiles([]);
    };

    useEffect(() => {
        if (!currentLesson) {
            wizardStore.resetWordPack();
            wizardStore.resetExerciseParams();
            return;
        }
        const chapters = currentLesson.chapters ?? [];

        setFiles([...pdfs, ...pictures]);
        setTracks(chapters.filter((c) => c.type === Chapters.Track));
        setSituations(chapters.filter((c) => c.type === Chapters.Situation));
        setExercises(chapters.filter((c) => c.type === Chapters.Exercise));
        setDictionaries(chapters.filter((c) => c.type === Chapters.Dictionary));

        if (!currentChapter?.id) {
            selectFirstChapter();
        }
    }, [currentLesson, currentLesson]);

    useEffect(() => {
        if (!currentLesson && isResetStudent) {
            exercisesStore.setCurrentStudent(null);
            lessonsStore.setCurrentStudentLessons([]);
            lessonsStore.setIsResetStudent(false);
        }
    }, [currentLesson, isResetStudent]);

    useEffect(() => {
        if (currentLesson?.id) getChaptersData();
    }, [currentLesson?.id]);

    useEffect(() => {
        if (!currentLesson && isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    }, [isOnboarding, currentLesson]);

    return !currentLesson ? null : (
        <div className={cl.overlay} onClick={handleLessonClose}>
            <div
                className={getModalClassName()}
                onClick={(e) => e.stopPropagation()}
            >
                <ModalHeader
                    lesson={currentLesson}
                    onUpdate={handleLessonUpdate}
                    onDelete={handleLessonDelete}
                    hideEdit={isLibrary || isUser}
                />

                <div className={cl.mainContainer}>
                    <div className={cl.aside}>
                        <div className={cl.chaptersList}>
                            {chaptersLoading ? (
                                <Loader
                                    style={{ margin: 'auto', height: '100%' }}
                                />
                            ) : chaptersList.map((c) => c.items).flat()
                                  .length !== 0 ? (
                                <div className={cl.scrollContainer}>
                                    {chaptersList.map((c) => (
                                        <ChapterSection
                                            ref={(el) =>
                                                (chapterRefs.current[c.type] =
                                                    el)
                                            }
                                            key={c.type}
                                            chapters={c.items}
                                            type={c.type}
                                            lessonId={currentLesson.id}
                                            onDelete={handleChapterDelete}
                                        />
                                    ))}
                                </div>
                            ) : hasFetched ? (
                                <div className={cl.emptyAlert}>
                                    <p className={cl.alertTitle}>
                                        {t('exercises.empty_lesson')}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                        {(isLibrary || isUser) && (
                            <CloneLesson
                                lesson={currentLesson}
                                isUser={isUser}
                            />
                        )}
                        {renderChapterButton()}
                    </div>

                    {renderCurrentChapter()}
                </div>
                <div style={{ postion: 'absolute' }}>
                    <CloseButton onClick={handleLessonClose} />
                </div>
            </div>
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.ChapterAdded
                )}
                title={t('teacher_onboarding.chapter_selected_title')}
                subtitle={t('teacher_onboarding.chapter_selected_subtitle')}
                delay={2000}
                style={{ zIndex: 10 }}
            />
        </div>
    );
};

export default observer(LessonModal);
