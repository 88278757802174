import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import languagesStore from '../../../../store/interface';
import lessonsStore from '../../store/lessonsStore';
import LessonWizard from '../LessonWizard/LessonWizard';
import wizardStore from '../LessonWizard/store/wizardStore';
import { LanguageLevels } from '../../../../data/common';
import {
    AgeOptions,
    getExamOptions,
} from '../../../../pages/TeacherContent/data/constants';
import {
    ControlButton,
    Input,
    Select,
    Separator,
    ToggleButton,
    Toolbar,
} from '../../../../UI';
import { LessonsGroupBy } from '../../data/constants';
import cl from './LessonSearchBar.module.css';

const LessonSearchBar = ({ isLibrary, isUser }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const {
        age,
        exam,
        collapseCards,
        difficulty,
        lessons,
        searchString,
        groupBy,
    } = lessonsStore;
    const { grammarOptions, vocabularyOptions } = wizardStore;

    const handleCollapseClick = () => {
        lessonsStore.toggleCollapseCards();
    };

    const changeGrid = (value) => {
        lessonsStore.adjustGridSize(value);
    };

    const getSearchHints = () => {
        const grammar = grammarOptions.flatMap((gObj) => gObj.topics);
        const vocabulary = vocabularyOptions.flatMap((vObj) => vObj.topics);

        const grammarTopics = Array.from(
            new Set(lessons.map((lObj) => lObj.grammarTopic))
        );
        const vocabularyTopics = Array.from(
            new Set(lessons.map((lObj) => lObj.vocabularyTopic))
        );

        const currentGrammar = grammar
            .filter((gObj) =>
                grammarTopics.some((topic) => topic === gObj.topic)
            )
            .map((gObj) => gObj.shortName);

        const currentVocabulary = vocabulary
            .filter((vObj) =>
                vocabularyTopics.some((topic) => topic === vObj.topic)
            )
            .map((gObj) => gObj.shortName);

        return [...currentGrammar, ...currentVocabulary];
    };

    return (
        <div className={cl.searchContainer}>
            <Toolbar>
                {!isLibrary && !isUser && (
                    <>
                        <LessonWizard />
                        <Separator />
                    </>
                )}
                {!isUser && (
                    <ToggleButton
                        nullable
                        options={LanguageLevels}
                        value={difficulty}
                        onChange={lessonsStore.setDifficulty}
                        variant={'round'}
                    />
                )}
                <Select
                    className={cl.select}
                    variant={'white'}
                    label={t('users.age')}
                    value={age}
                    options={AgeOptions}
                    onChange={lessonsStore.setAge}
                />
                <Select
                    className={cl.select}
                    variant={'white'}
                    label={t('exercises.exam')}
                    value={exam}
                    options={getExamOptions(lang)}
                    onChange={lessonsStore.setExam}
                />
                <Input
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={lessonsStore.setSearchString}
                    search
                    showReset
                    hints={getSearchHints()}
                    initialHints
                />
                {difficulty && (
                    <ToggleButton
                        options={[
                            LessonsGroupBy.Grammar,
                            LessonsGroupBy.Vocabulary,
                        ]}
                        value={groupBy}
                        onChange={lessonsStore.setGroupBy}
                        withTranslations={'lesson_wizard'}
                        isGrid
                        className={cl.groupToggle}
                    />
                )}
                <Separator />
                <ControlButton
                    type={collapseCards ? 'uncollapse' : 'collapse'}
                    onClick={handleCollapseClick}
                />
                <div className={cl.buttonsCont}>
                    <ControlButton
                        type={'minus'}
                        onClick={() => changeGrid(-1)}
                    />
                    <ControlButton
                        type={'plus'}
                        onClick={() => changeGrid(1)}
                    />
                </div>
            </Toolbar>
        </div>
    );
};

export default observer(LessonSearchBar);
