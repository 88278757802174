import { makeAutoObservable } from 'mobx';
class UserExercisesStore {
    exercises = [];
    exercisesCount = null;

    type = '';
    searchString = '';

    currentExercise = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    setExercises(exercises) {
        this.exercises = exercises;
    }

    setSearchString(searchString) {
        this.searchString = searchString;
    }

    setType(type) {
        this.type = type;
    }

    setCurrentExercise(currentExercise) {
        this.currentExercise = currentExercise;
    }

    resetSearch() {
        this.setTracks([]);
    }
}
const userExercisesStore = new UserExercisesStore();
export default userExercisesStore;
