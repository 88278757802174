import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GapWord from '../GapWord/GapWord';
import { Label } from '../../../../../../../../UI';
import { GrammarExerciseTypes } from '../../../../../../data/constants';
import cl from './MultichoiceEditor.module.css';

const MultichoiceEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const { sentences } = exerciseData;
    const [edit, setEdit] = useState(false);

    const handleWordChange = (sentenceId, wordId, { answer, options }) => {
        onChange({
            sentences: sentences?.map((s) =>
                s.id === sentenceId
                    ? {
                          ...s,
                          words: s?.words?.map((w) => {
                              if (w.id !== wordId) return w;

                              const updatedWord = { ...w };
                              if (options.length > 0) {
                                  updatedWord.word = answer;
                                  updatedWord.options = options;
                              } else {
                                  delete updatedWord.options;
                              }

                              return updatedWord;
                          }),
                      }
                    : s
            ),
        });
    };

    return (
        <div className={`${cl.textCont} ${edit ? cl.edit : ''}`}>
            <Label text={t('exercises.edit_multichoice_label')} />
            <div className={cl.exerciseText}>
                <div className={cl.textInner}>
                    {sentences?.map((s) => {
                        return (
                            <div key={s.id} className={cl.sentence}>
                                {s.words?.map((word) => (
                                    <GapWord
                                        key={word.id}
                                        type={GrammarExerciseTypes.Multichoice}
                                        sentenceId={s.id}
                                        word={word}
                                        onChange={({ answer, options }) =>
                                            handleWordChange(s.id, word.id, {
                                                answer,
                                                options,
                                            })
                                        }
                                        setEdit={setEdit}
                                    />
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MultichoiceEditor;
