import React from 'react';
import { Chapters } from '../../features/LessonsKanban/data/constants';
import { ReactComponent as IconDictionary } from '../../assets/svg/dictionary.svg';
import { ReactComponent as IconVideo } from '../../assets/svg/lessons-track.svg';
import { ReactComponent as IconExercise } from '../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconStar } from '../../assets/svg/user-icon-star.svg';
import { ReactComponent as IconEyes } from '../../assets/svg/lessons-eyes.svg';
import cl from './EmptyChaptersAlert.module.css';

const renderIcon = (icon) => {
    switch (icon) {
        case Chapters.Track:
            return (
                <div className={cl.videoIcon}>
                    <IconVideo />
                </div>
            );
        case Chapters.Dictionary:
            return (
                <div className={cl.dictionaryIcon}>
                    <IconDictionary />
                </div>
            );
        case Chapters.Exercise:
            return (
                <div className={cl.exerciseIcon}>
                    <IconExercise />
                </div>
            );
        case Chapters.Situation:
            return (
                <div className={cl.situationIcon}>
                    <IconSituation />
                </div>
            );
        case 'todo':
            return (
                <div className={cl.todoIcon}>
                    <IconStar />
                </div>
            );
        default:
            return <IconEyes className={cl.iconEyes} />;
    }
};

const EmptyChaptersAlert = ({ icon, title, subtitle, variant }) => {
    return (
        <div className={`${cl.emptyAlert} ${variant ? cl[variant] : ''}`}>
            {renderIcon(icon)}
            {title && <p className={cl.alertTitle}>{title}</p>}
            {subtitle && <p className={cl.alertSubtitle}>{subtitle}</p>}
        </div>
    );
};

export default EmptyChaptersAlert;
