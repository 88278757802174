import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userStore from '../../store/userStore';
import UserChapterCard from '../UserChapterCard/UserChapterCard';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { ReactComponent as IconDictionary } from '../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconFile } from '../../../../assets/svg/lessons-file.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import cl from './UserChapterSection.module.css';

const UserChapterSection = forwardRef(
    ({ chapters = [], type, onDelete, isWordPack }, ref) => {
        const { t } = useTranslation();
        const { activeChapter } = userStore;

        const [isExpanded, setIsExpanded] = useState(false);
        const toggleExpanded = () => setIsExpanded(!isExpanded);

        const renderIcon = () => {
            switch (type) {
                case Chapters.Track:
                    return <IconTrack />;
                case Chapters.Situation:
                    return <IconSituation />;
                case Chapters.Exercise:
                    return <IconExercise />;
                case Chapters.Dictionary:
                    return <IconDictionary />;
                case Chapters.File:
                    return <IconFile />;
                default:
                    return null;
            }
        };

        useEffect(() => {
            setIsExpanded(true);
        }, []);

        if (chapters.length === 0) return null;

        return (
            <div className={cl.chapterContainer} ref={ref}>
                <div
                    className={`${cl.chapterTypeCont} ${cl[type]}`}
                    onClick={toggleExpanded}
                >
                    {renderIcon()}
                    <p
                        className={cl.chapterType}
                    >{`${t(`exercises.${type}_view`)} ${`(${chapters.length})`}`}</p>

                    <div
                        className={cl.control}
                        style={{
                            transform: !isExpanded ? 'rotate(180deg)' : '',
                        }}
                    >
                        <IconArrow className={cl.iconArrow} />
                    </div>
                </div>
                <div
                    className={`${cl.chaptersList} ${isExpanded ? '' : cl.hidden}`}
                >
                    {chapters.map((chapter) => (
                        <UserChapterCard
                            key={chapter.id}
                            type={type}
                            chapter={chapter}
                            onDelete={onDelete}
                            isActive={
                                chapter?.id && activeChapter?.id === chapter.id
                            }
                            setActive={userStore.setActiveChapter}
                            isWordPack={isWordPack}
                        />
                    ))}
                </div>
            </div>
        );
    }
);

export default observer(UserChapterSection);
