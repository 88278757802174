import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../store/userStore';
import { Button, Input, Label } from '../../../../UI';
import { useTranslation } from 'react-i18next';
import { GenerateChapterModal } from '../../../../pages/TeacherContent/components';
import { Chapters } from '../../../LessonsKanban/data/constants';
import cl from './AddWord.module.css';

const AddWord = ({ onAdd, words }) => {
    const { t } = useTranslation();

    const flatLearnedWords = userStore.historyWords.flatMap((w) =>
        w.word.toLowerCase()
    );

    const [word, setWord] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [visible, setVisible] = useState(false);

    const handleWordsGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                word: !word,
                alreadyLearned: flatLearnedWords.some(
                    (w) => w === word.toLowerCase()
                ),
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addWord();
        });
    };

    const addWord = () => {
        setVisible(false);
        if (words.some((w) => w.word.toLowerCase() === word.toLowerCase())) {
            return;
        }
        onAdd(word);
    };

    useEffect(() => {
        setWord('');
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [word]);

    return (
        <>
            <Button
                variant={'words'}
                text={t('exercises.add_word_to_category')}
                onClick={() => setVisible(true)}
            />
            <GenerateChapterModal
                visible={visible}
                setVisible={setVisible}
                title={t('exercises.add_word_to_category')}
                variant={Chapters.Dictionary}
                className={cl.addWordModal}
            >
                <div>
                    <Label
                        isError={formErrors.alreadyLearned}
                        text={t(
                            formErrors.alreadyLearned
                                ? 'user_view.word_already_learned'
                                : 'user_view.add_word'
                        )}
                    />
                    <Input
                        value={word}
                        onChange={setWord}
                        variant={
                            formErrors.word || formErrors.alreadyLearned
                                ? 'erroredSmall'
                                : 'outlinedSmall'
                        }
                        placeholder={t('user_view.add_word_placeholder')}
                    />
                </div>
                <Button
                    className={cl.addWordButton}
                    text={t('buttons.add')}
                    onClick={handleWordsGenerate}
                    variant={'words'}
                />
            </GenerateChapterModal>
        </>
    );
};

export default observer(AddWord);
