import React from 'react';
import { ReactComponent as iconVideo } from '../../../../assets/svg/icon-video-mini.svg';
import { ReactComponent as iconExercises } from '../../../../assets/svg/icon-exercises-mini.svg';
import { ReactComponent as iconDialogues } from '../../../../assets/svg/icon-situations-mini.svg';
import { ReactComponent as iconWords } from '../../../../assets/svg/icon-dictionaries-mini.svg';
import InfoWrapper from '../InfoWrapper/InfoWrapper';
import guestOnboarding from '../../store/guestOnboarding';
import { useTranslation } from 'react-i18next';
import {
    ArrowPositions,
    BubblePositions,
} from '../../../../features/Onboarding';
import { observer } from 'mobx-react-lite';
import { LanguageLevels } from '../../../../data/common';
import { Ages } from '../../../TeacherContent/data/constants';
import cl from './LessonsCard.module.css';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';

let getTaskImage = (type) => {
    switch (type) {
        case Chapters.Exercise:
            return iconExercises;
        case Chapters.Track:
            return iconVideo;
        case Chapters.Situation:
            return iconDialogues;
        case Chapters.Dictionary:
            return iconWords;
        default:
            return '';
    }
};

const LessonsCard = ({
    lesson,
    isFocused = false,
    onClick,
    isFirst = false,
}) => {
    const { age, exam, difficulty, title, themeTitle, chapters } = lesson;

    const { t } = useTranslation();

    const onboardingKey = guestOnboarding.isOnboarding('lesson_examples');
    const isOnboarding = isFirst && onboardingKey;

    const handleLessonClick = () => {
        if (onboardingKey) return;

        onClick(lesson);
    };

    return (
        <div
            className={`${cl.lessonsCard} ${isFocused ? cl.focused : ''}`}
            onClick={handleLessonClick}
        >
            {isOnboarding ? (
                <InfoWrapper
                    onboardingKey={guestOnboarding.isOnboarding(
                        'lesson_examples'
                    )}
                    title={t('teacher_tutorial.lesson_examples_info')}
                    subtitle={t(
                        'teacher_tutorial.lesson_examples_info_subtitle'
                    )}
                    delay={200}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Top,
                    }}
                    arrowPosition={ArrowPositions.Top}
                    showButton
                >
                    <div className={`${cl.cardContainer} ${cl.onboarding}`}>
                        <div className={cl.titleBlock}>
                            <p>{themeTitle}</p>
                        </div>
                        <div className={cl.cardContent}>
                            <div className={cl.cardTrack}>
                                <p>{title}</p>
                                <div>
                                    {typeof age === 'number' && (
                                        <p>{Ages[age]}</p>
                                    )}
                                    <p>{LanguageLevels[difficulty]}</p>
                                    {exam && <p>{exam}</p>}
                                </div>
                            </div>
                            <div className={cl.cardTasks}>
                                {chapters.map((el, i) => {
                                    const TaskImage = getTaskImage(el.type);
                                    return (
                                        <div className={cl.task} key={i}>
                                            {TaskImage && <TaskImage />}
                                            <p>{el.title}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </InfoWrapper>
            ) : (
                <div className={cl.cardContainer}>
                    <div className={cl.titleBlock}>
                        <p>{themeTitle}</p>
                    </div>
                    <div className={cl.cardContent}>
                        <div className={cl.cardTrack}>
                            <p>{title}</p>
                            <div>
                                {typeof age === 'number' && <p>{Ages[age]}</p>}
                                <p>{LanguageLevels[difficulty]}</p>
                                {exam && <p>{exam}</p>}
                            </div>
                        </div>
                        <div className={cl.cardTasks}>
                            {chapters.map((el, i) => {
                                const TaskImage = getTaskImage(el.type);
                                return (
                                    <div className={cl.task} key={i}>
                                        {TaskImage && <TaskImage />}
                                        <p>{el.title}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(LessonsCard);
