import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import exercisesStore from '../../../../store/exercisesStore';
import {
    AdjustableTextarea,
    Button,
    CloseButton,
    Input,
    Label,
    Modal,
    ToggleButton,
} from '../../../../../../UI';
import {
    AdvancedQuizEditor,
    ClozeEditor,
    MultichoiceEditor,
    QuestionsEditor,
    QuizEditor,
    SentenceOrderEditor,
    SubstitutionEditor,
    SummaryEditor,
    WordsOrderEditor,
} from './components';
import {
    ExerciseMediaTypes,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { LanguageLevels } from '../../../../../../data/common';
import { DeleteConfirmation } from '../../../../../../features/LessonsKanban/components';
import { deepEqual } from '../../../../../../utils/deepEqual';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import cl from './EditExerciseModal.module.css';

const ExerciseErrors = {
    Empty: 'empty',
    EmptyQuestion: 'empty_question',
    EmptyAnswer: 'empty_answer',
    InvalidAnswers: 'invalid_answers',
    NoAnswer: 'no_answer',
    IncorrectAnswer: 'incorrect_answer',
};

const EditExerciseModal = ({ currentExercise, onChange }) => {
    const { t } = useTranslation();

    const containerRef = useRef();

    const { editMode } = exercisesStore;
    const {
        id,
        type,
        mediaType,
        categoryId,
        difficulty,
        title,
        text,
        trackId,
        description,
        data,
    } = currentExercise;

    const isTextExercise = [
        ExerciseMediaTypes.Video,
        ExerciseMediaTypes.Text,
    ].includes(mediaType);

    const [newData, setNewData] = useState({});
    const [formErrors, setFormErrors] = useState({});

    const [isConfirm, setIsConfirm] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [newTitle, setNewTitle] = useState(title);
    const [newDesc, setNewDesc] = useState(description);
    const [newText, setNewText] = useState(text);
    const [newDifficulty, setNewDifficulty] = useState(difficulty);

    const wasChanged = useMemo(() => {
        return (
            !deepEqual(currentExercise.data, newData) ||
            currentExercise.title !== newTitle ||
            currentExercise.description !== newDesc ||
            currentExercise.text !== newText ||
            currentExercise.difficulty !== newDifficulty
        );
    }, [currentExercise, newData, newTitle, newDesc, newText, newDifficulty]);

    const handleExerciseUpdate = () => {
        setIsConfirm(false);
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                title: !newTitle,
                desc: !newDesc,
                text: isTextExercise && !newText,
                data: validateDataByType(),
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) {
                console.error('error in exercise editor form');
                console.error(
                    'missing params:',
                    Object.entries(errors)
                        .filter(([_error, flag]) => flag)
                        .map(([error]) => error)
                        ?.join(', ')
                );
                return;
            }
            updateExercise();
        });
    };

    const validateDataByType = () => {
        if (type === GrammarExerciseTypes.Multichoice) {
            return validateMultichoice();
        }
        if (type === MediaExerciseTypes.AdvancedQuiz) {
            return validateAdvancedQuiz();
        }
        if (type === MediaExerciseTypes.FreeFormQuestions) {
            return validateQuestions();
        }
        if (type === MediaExerciseTypes.Quiz) {
            return validateQuiz();
        }
        if (type === MediaExerciseTypes.SentencesOrder) {
            return validateSentences();
        }
        if (type === GrammarExerciseTypes.StatementsTransformation) {
            return validateQuestions();
        }
        if (type === MediaExerciseTypes.Summary) {
            return validateSummary();
        }
        if (type === GrammarExerciseTypes.WordsOrder) {
            return validateSentences();
        }
        return false;
    };

    const validateMultichoice = () => {
        const { sentences: sArr } = newData;
        // TODO implement Multichoice validator @saratovkin
        if (sArr.some((s) => s.words.some((w) => !w.word))) {
            console.error('no word');
            return false;
        }

        if (sArr.some((s) => s.words.some((w) => !w.options?.length))) {
            console.error('no options');
            return false;
        }

        if (
            sArr.some((s) =>
                s.words.some((w) => w?.options.some((o) => !o.text))
            )
        ) {
            console.error('no option text');
            return false;
        }

        return false;
    };

    const validateQuiz = () => {
        const { questions: qArr } = newData;

        if (!qArr.length) return ExerciseErrors.Empty;
        if (qArr.some((q) => !q.text)) return ExerciseErrors.EmptyQuestion;
        if (qArr.some((q) => q.options.length < 2))
            return ExerciseErrors.EmptyAnswer;

        if (qArr.some((q) => !q.options.some((o) => o.text === q.answer)))
            return ExerciseErrors.NoAnswer;

        if (qArr.some((q) => q.options.some((o) => o.text.trim().length === 0)))
            return ExerciseErrors.IncorrectAnswer;

        const hasDuplicateOptions = qArr.some((q) => {
            const optionTexts = q.options.map((o) => o.text);
            const uniqueOptionTexts = new Set(optionTexts);
            return uniqueOptionTexts.size !== optionTexts.length;
        });

        if (hasDuplicateOptions) return ExerciseErrors.InvalidAnswers;

        return false;
    };

    const validateAdvancedQuiz = () => {
        const { questions: qArr } = newData;

        if (!qArr.length) return ExerciseErrors.Empty;
        if (qArr.some((q) => !q.text)) return ExerciseErrors.EmptyQuestion;
        if (qArr.some((q) => !q.status)) return ExerciseErrors.EmptyAnswer;

        return false;
    };

    const validateSummary = () => {
        const { options: oArr } = newData;

        if (!oArr.length) return ExerciseErrors.Empty;
        if (oArr.length < 2) return ExerciseErrors.EmptyQuestion;
        if (oArr.some((o) => !o.text)) return ExerciseErrors.EmptyAnswer;
        if (!oArr.some((o) => o.correct)) return ExerciseErrors.InvalidAnswers;

        return false;
    };

    const validateQuestions = () => {
        const { questions: qArr } = newData;

        if (!qArr.length) return ExerciseErrors.Empty;
        if (qArr.some((o) => !o.question)) return ExerciseErrors.EmptyQuestion;

        return false;
    };

    const validateSentences = () => {
        const { sentences: sArr } = newData;

        if (!sArr.length) return ExerciseErrors.Empty;
        if (type === MediaExerciseTypes.SentencesOrder && sArr.length < 2)
            return ExerciseErrors.InvalidAnswers;
        if (sArr.some((s) => !s.text)) return ExerciseErrors.EmptyQuestion;

        return false;
    };

    const [updateExercise, updateLoading] = useFetching(async () => {
        const { data: exerciseObj } = await ExerciseService.updateExercise({
            id,
            type,
            categoryId,
            difficulty: newDifficulty,
            title: newTitle,
            data: newData,
            description: newDesc,
            text: newText,
        });
        onChange({ ...exerciseObj, data: newData });

        exercisesStore.updateExercise({ ...exerciseObj, data: newData });
    });

    const [deleteExercise, deleteLoading] = useFetching(async () => {
        const res = await ExerciseService.deleteExercise({ id });
        if (res) {
            exercisesStore.deleteExercise(id);
        }
    });

    const handleTitleChange = (title) => {
        setNewTitle(title);
    };

    const handleDescChange = (description) => {
        setNewDesc(description);
    };

    const handleTextChange = (text) => {
        setNewText(text);
    };

    const handleDifficultyChange = (difficulty) => {
        setNewDifficulty(LanguageLevels.indexOf(difficulty));
    };

    const renderEditor = () => {
        switch (type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoiceEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozeEditor exerciseData={newData} onChange={setNewData} />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <QuestionsEditor
                        type={type}
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizEditor exerciseData={newData} onChange={setNewData} />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );

            case MediaExerciseTypes.Summary:
                return (
                    <SummaryEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizEditor
                        exerciseData={newData}
                        onChange={setNewData}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleDeleteButton = (e) => {
        e.stopPropagation();
        setIsDelete(true);
    };

    const resetData = () => {
        setNewData({});
    };

    const handleClose = () => {
        if (!wasChanged) {
            exercisesStore.setEditMode(false);
        } else {
            setIsConfirm(true);
        }
    };

    const handleEditingReset = () => {
        exercisesStore.setEditMode(false);
        setIsConfirm(false);
    };

    useEffect(() => {
        resetData();

        if (!currentExercise.id || !editMode) return;

        return () => {
            exercisesStore.setEditMode(false);
        };
    }, [currentExercise.id, editMode]);

    useEffect(() => {
        setNewData(data);
    }, [data]);

    useEffect(() => {
        setNewTitle(title);
        setNewDesc(description);
        setNewText(text);
        setNewDifficulty(difficulty);
    }, [title, description, text, difficulty]);

    useEffect(() => {
        setFormErrors({});
    }, [newTitle, newDesc, newDifficulty, newText, newData]);

    useEffect(() => {
        if (formErrors.data && containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
        if (
            (formErrors.title || formErrors.desc || formErrors.text) &&
            containerRef.current
        ) {
            containerRef.current.scrollTo({
                top: 3,
                behavior: 'smooth',
            });
        }
    }, [formErrors]);

    return (
        Object.keys(currentExercise).length !== 0 && (
            <>
                <Modal
                    visible={editMode}
                    setVisible={handleClose}
                    className={cl.editModal}
                    style={{ zIndex: 100 }}
                    scrollToBottom={formErrors.quiz}
                >
                    <div className={cl.titleContainer}>
                        <div className={cl.backgroundCircle} />
                        <div className={cl.backgroundCircle} />
                        <div className={cl.backgroundCircle} />

                        <p className={cl.title}>
                            {t('exercises.edit_exercise')}
                        </p>
                    </div>
                    <div className={cl.editorContainer} ref={containerRef}>
                        <div className={cl.horizontalCont}>
                            <div style={{ width: '100%' }}>
                                <Label
                                    text={t('exercises.exercise_title')}
                                    isError={formErrors.title}
                                />
                                <Input
                                    variant={
                                        formErrors.title
                                            ? 'erroredSmall'
                                            : 'outlinedSmall'
                                    }
                                    style={{ width: '100%' }}
                                    value={newTitle}
                                    placeholder={t(
                                        'exercises.title_placeholder'
                                    )}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <div>
                                <Label text={t('situations.level')} />
                                <ToggleButton
                                    options={LanguageLevels}
                                    value={LanguageLevels[newDifficulty]}
                                    onChange={handleDifficultyChange}
                                    variant={'outlined'}
                                />
                            </div>
                        </div>
                        <div>
                            <Label
                                text={t('exercises.exercise_explanatory')}
                                isError={formErrors.desc}
                            />
                            <AdjustableTextarea
                                className={cl.descriptionInput}
                                value={newDesc}
                                placeholder={t(
                                    'exercises.explanatory_placeholder'
                                )}
                                onChange={handleDescChange}
                                isError={formErrors.desc}
                            />
                        </div>
                        {newText !== null &&
                            !trackId &&
                            mediaType !== ExerciseMediaTypes.Audio && (
                                <div>
                                    <Label
                                        text={t('exercises.edit_text_label')}
                                        isError={formErrors.text}
                                    />
                                    <AdjustableTextarea
                                        value={newText}
                                        placeholder={t('exercises.text')}
                                        onChange={handleTextChange}
                                        style={{ fontSize: 12 }}
                                        isError={formErrors.text}
                                    />
                                </div>
                            )}
                        {renderEditor()}
                        {formErrors.data && (
                            <Label
                                text={t(
                                    `exercise_editor_errors.${type}_${formErrors.data}`
                                )}
                                isError={true}
                            />
                        )}
                    </div>
                    <div className={cl.buttonsContainer}>
                        {wasChanged && (
                            <Button
                                text={t('buttons.save')}
                                variant={Chapters.Exercise}
                                onClick={handleExerciseUpdate}
                                isLoading={updateLoading}
                            />
                        )}
                        <Button
                            text={t('buttons.delete')}
                            variant={'red'}
                            onClick={handleDeleteButton}
                            isLoading={deleteLoading}
                            style={{ marginLeft: 'auto' }}
                        />
                    </div>
                    <DeleteConfirmation
                        title={t(
                            `exercises.delete_exercise_confirmation_title`
                        )}
                        visible={isDelete}
                        setVisible={setIsDelete}
                        onDelete={deleteExercise}
                    />
                    <CloseButton onClick={handleClose} />
                </Modal>
                <DeleteConfirmation
                    title={t(`exercises.update_exercise_confirmation_title`)}
                    visible={isConfirm}
                    variant={Chapters.Exercise}
                    setVisible={setIsConfirm}
                    onReset={handleEditingReset}
                    onDelete={handleExerciseUpdate}
                    isSave={true}
                    style={{ zIndex: 100 }}
                />
            </>
        )
    );
};

export default observer(EditExerciseModal);
