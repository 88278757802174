import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import studentsStore from '../../store/studentsStore';
import { ControlButton, Input, Select, Toolbar } from '../../../../UI';

const StudentLessonsSearchBar = () => {
    const { t } = useTranslation();

    const {
        collapseCards,
        lessonSearchString,
        lessonTheme,
        lessonThemeOptions,
    } = studentsStore;

    return (
        <Toolbar>
            <Select
                style={{ minWidth: 'calc(33% - 9px)' }}
                value={lessonTheme}
                options={lessonThemeOptions}
                variant={'white'}
                onChange={studentsStore.setLessonTheme}
                label={t('exercises.theme_filter')}
            />
            <Input
                showReset
                search
                variant={'white'}
                placeholder={t('library.search')}
                value={lessonSearchString}
                onChange={studentsStore.setLessonSearchString}
            />

            <ControlButton
                type={collapseCards ? 'uncollapse' : 'collapse'}
                onClick={studentsStore.toggleCollapseCards}
            />
        </Toolbar>
    );
};

export default observer(StudentLessonsSearchBar);
