import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import player from '../../store/player';
import PlayerControls from '../../components/PlayerControls/PlayerControls';
import YoutubePlayer from '../../components/YoutubePlayer/YoutubePlayer';
import { AddTrackInput } from './components';
import { TrackCard } from '../Library/components';
import { ROUTES } from '../../router';
import { Modal, PageWrapper, PrimaryButton } from '../../UI';
import { TicksInSecond } from '../../data/common';

const AddTrack = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const stateFromNavigate = location.state || {};

    const [sourceLang, setSourceLang] = useState([]);
    const [showPreviewPopup, setShowPreviewPopup] = useState(false);
    const [url, setUrl] = useState('');
    const [track, setTrack] = useState(null);

    const reset = () => {
        setUrl('');
        setSourceLang([]);
        setTrack(null);
    };

    const handleLibraryButton = () => {
        navigate(ROUTES.library);
    };

    useEffect(() => {
        if (stateFromNavigate.track) {
            setTrack({
                isYoutube: stateFromNavigate.track.isYoutube,
                id: stateFromNavigate.track.id,
                reason: 'success',
                json: stateFromNavigate.track,
            });
        }
    }, []);

    useEffect(() => {
        if (!track) return;
        player.externalId(track.externalId);
        player.setTrackLength(track.duration * TicksInSecond);
        setSourceLang(track.lang || sourceLang);
    }, [track]);

    const previewModal = useMemo(() => {
        if (showPreviewPopup) {
            return (
                <Modal
                    wrapTitle={true}
                    style={{ maxWidth: 660 }}
                    visible={showPreviewPopup}
                    title={track?.json?.title}
                    setVisible={setShowPreviewPopup}
                >
                    <YoutubePlayer />
                    <PlayerControls />
                    <PrimaryButton
                        text={t('buttons.ok')}
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        variant={'dark'}
                        onClick={() => {
                            setShowPreviewPopup(false);
                        }}
                    />
                </Modal>
            );
        } else return null;
    }, [track, showPreviewPopup]);

    return (
        <PageWrapper
            isArrowed={stateFromNavigate.needBackArrow}
            style={{ paddingTop: 20, overflowY: 'auto' }}
        >
            {track ? (
                <TrackCard
                    onDelete={reset}
                    trackData={track}
                    style={{ marginBottom: 10 }}
                />
            ) : (
                <AddTrackInput url={url} setUrl={setUrl} setTrack={setTrack} />
            )}
            {track && (
                <div
                    style={{
                        display: 'flex',
                        gap: 13,
                        marginBottom: 30,
                        marginTop: 30,
                    }}
                >
                    <PrimaryButton
                        text={t('add_track.add_another_track')}
                        variant={'dark'}
                        onClick={reset}
                    />
                    {stateFromNavigate.needBackArrow ? (
                        <PrimaryButton
                            text={t('buttons.back')}
                            onClick={() => navigate(-1)}
                        />
                    ) : (
                        <PrimaryButton
                            text={t('add_track.go_to_track_list')}
                            onClick={handleLibraryButton}
                        />
                    )}
                </div>
            )}
            {previewModal}
        </PageWrapper>
    );
};

export default observer(AddTrack);
