import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import learnSituationStore from '../../store';
import {
    ExerciseMode,
    LearnSituationControls,
    ListenMode,
    SpeakMode,
} from '..';
import { CloseButton, EmptyChaptersAlert } from '../../../../UI';
import { LearningModes } from '../../data';
import { Chapters } from '../../../LessonsKanban/data/constants';
import cl from './LearnSituationCards.module.css';

const getCardAnimationVariants = (direction) => ({
    enter: {
        opacity: 0.5,
        transform:
            direction > 0 ? 'translate(-50%, 0%)' : 'translate(-50%, -100%)',
    },

    center: { opacity: 1, transform: 'translate(-50%, -50%)' },
    exit: {
        opacity: 0.5,
        transform:
            direction > 0 ? 'translate(-50%, -100%)' : 'translate(-50%, 0%)',
    },
});

const LearnSituationCards = ({ isSwitching }) => {
    const { t } = useTranslation();

    const startY = useRef(null);
    const isSwiping = useRef(false);

    const { direction, mode, statements, statementIndex } = learnSituationStore;

    const handleMouseDown = (e) => {
        startY.current = e.clientY;
        isSwiping.current = true;
    };

    const handleMouseUp = (e) => {
        if (!isSwiping.current) return;

        const delta = e.clientY - startY.current;
        const threshold = window.innerHeight * 0.15;

        processMouseEvent(delta, threshold);

        isSwiping.current = false;
        startY.current = null;
    };

    const handleScroll = (e) => {
        const delta = e.deltaY;
        const threshold = window.innerHeight * 0.15;
        processMouseEvent(delta, threshold);
    };

    const processMouseEvent = (delta, threshold) => {
        if (delta > threshold) {
            learnSituationStore.prevStatement();
        } else if (delta < -threshold) {
            learnSituationStore.nextStatement();
        }
    };

    const renderActorIcon = () => {
        if (!statements[statementIndex]) return null;
        const { actor } = statements[statementIndex];
        return (
            <div className={actor === 'A' ? cl.iconMe : cl.iconThey}>
                <div className={`${cl.equalizer} ${true ? cl.active : ''}`}>
                    <span /> <span /> <span /> <span /> <span />
                </div>
                <span />
                <span>
                    {t(`learn_dialogue.${actor === 'A' ? 'you' : 'opponent'}`)}
                </span>
            </div>
        );
    };

    const renderMode = () => {
        if (isSwitching) return null;

        if (statementIndex >= statements.length)
            return (
                <EmptyChaptersAlert
                    icon={Chapters.Exercise}
                    title={t('user_view.dialogue_mode_completed_title')}
                    subtitle={t('user_view.dialogue_mode_completed_subtitle')}
                />
            );
        if (!statements[statementIndex]) return null;
        if (statements[statementIndex].actor === 'B')
            return <ListenMode statement={statements[statementIndex]} />;
        switch (mode) {
            case LearningModes[0]:
                return <ListenMode statement={statements[statementIndex]} />;
            case LearningModes[1]:
                return <ExerciseMode statement={statements[statementIndex]} />;
            case LearningModes[2]:
                return <SpeakMode statement={statements[statementIndex]} />;
            case LearningModes[3]:
                return (
                    <SpeakMode
                        statement={statements[statementIndex]}
                        translationMode
                    />
                );
            default:
                return null;
        }
    };

    const handleCloseClick = () => {
        learnSituationStore.setMode('');
    };

    return (
        <div
            className={cl.modeContainer}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onWheel={handleScroll}
        >
            {statements.length !== 0 && (
                <div className={cl.progressContainer}>
                    {Array(statements.length - 1)
                        .fill('')
                        .map((_, i) => (
                            <span
                                className={`${cl.progressChip} ${i < statementIndex ? cl.active : ''}`}
                                key={i}
                            />
                        ))}
                </div>
            )}

            <motion.div
                className={cl.card}
                key={statementIndex}
                custom={direction}
                initial="enter"
                animate="center"
                exit="exit"
                variants={getCardAnimationVariants(direction)}
                transition={{ duration: 0.5 }}
            >
                <div className={cl.cardInner}>
                    {renderActorIcon()}
                    {renderMode()}
                </div>
            </motion.div>

            <LearnSituationControls />
            <CloseButton onClick={handleCloseClick} />
        </div>
    );
};

export default observer(LearnSituationCards);
