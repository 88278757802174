export const infoWordPacks = [
    {
        id: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
        title: 'Airport Vocabulary - B1',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 2,
        verified: false,
        wordsCount: 10,
        createdAt: '2025-01-22T12:56:13.943Z',
        words: [
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'baggage',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'terminal',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'departure',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'arrival',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'luggage',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'gate',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'security',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'custom',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'passport',
                lang: 'en',
            },
            {
                categoryId: 'ba827d2a-b76b-40a5-8f7a-2ab70483c3ed',
                word: 'check-in',
                lang: 'en',
            },
        ],
    },
    {
        id: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
        title: 'British slang - B2',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 3,
        verified: false,
        wordsCount: 14,
        createdAt: '2025-01-27T11:02:56.967Z',
        words: [
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'brolly',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'gobsmacked',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'bollocks',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'knackered',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'chuffed',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'faff',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'loo',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'bloke',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'quid',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'gutted',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'nosh',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'snog',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'telly',
                lang: 'en',
            },
            {
                categoryId: 'e441537b-2c66-4d11-93d9-2c3d616dc3a6',
                word: 'whinge',
                lang: 'en',
            },
        ],
    },
    {
        id: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
        title: 'Business English - B2 | \nContracts and Negotiations',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 3,
        verified: false,
        wordsCount: 14,
        createdAt: '2025-01-27T16:52:52.905Z',
        words: [
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'negotiation',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'consensus',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'breach',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'arbitration',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'dispute',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'enforceable',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'jurisdiction',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'liability',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'mediation',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'provision',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'stipulation',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'obligation',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'settlement',
                lang: 'en',
            },
            {
                categoryId: 'dfc1b5ff-ad8a-40ea-b65c-2875618113f2',
                word: 'counteroffer',
                lang: 'en',
            },
        ],
    },
    {
        id: '0bd59fbc-3da3-489c-8522-8b09001c8604',
        title: 'Web Interface Terms - B2',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 3,
        verified: false,
        wordsCount: 24,
        createdAt: '2025-01-28T16:15:26.099Z',
        words: [
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'menu',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'widget',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'dashboard',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'layout',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'navigation',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'interface',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'icon',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'button',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'modal',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'dialog',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'tab',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'form',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'panel',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'footer',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'header',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'grid',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'sidebar',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'theme',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'template',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'overlay',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'animation',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'breadcrumb',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'dropdown',
                lang: 'en',
            },
            {
                categoryId: '0bd59fbc-3da3-489c-8522-8b09001c8604',
                word: 'toolbar',
                lang: 'en',
            },
        ],
    },
    {
        id: '05d243ea-2e49-4249-9047-682d4982dd76',
        title: 'Piano Terms - B2',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 3,
        verified: false,
        wordsCount: 14,
        createdAt: '2025-01-28T16:10:18.097Z',
        words: [
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'melody',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'chord',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'octave',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'harmony',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'pedal',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'tempo',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'notation',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'repertoire',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'concert',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'timbre',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'sonata',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'arpeggio',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'symphony',
                lang: 'en',
            },
            {
                categoryId: '05d243ea-2e49-4249-9047-682d4982dd76',
                word: 'interval',
                lang: 'en',
            },
        ],
    },

    {
        id: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
        title: 'Irregular Verbs - B1',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 2,
        verified: false,
        wordsCount: 22,
        createdAt: '2025-01-30T12:22:07.016Z',
        words: [
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'break',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'drink',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'drive',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'fall',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'fly',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'come',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'hear',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'have',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'begin',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'become',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'build',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'bring',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'catch',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'buy',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'find',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'choose',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'feel',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'forget',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'eat',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'grow',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'leave',
                lang: 'en',
            },
            {
                categoryId: '21941e6b-3ed9-4343-9c89-9325c0a7e9d6',
                word: 'give',
                lang: 'en',
            },
        ],
    },
];

export const globalWordPacks = [
    ...infoWordPacks,
    {
        id: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
        title: 'Animals - A2',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 1,
        verified: false,
        wordsCount: 9,
        createdAt: '2025-01-30T12:19:41.023Z',
        words: [
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'cat',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'dog',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'bird',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'fish',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'cow',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'turtle',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'sheep',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'duck',
                lang: 'en',
            },
            {
                categoryId: '7e6729dd-968d-4b03-85d0-959e8a865ccd',
                word: 'frog',
                lang: 'en',
            },
        ],
    },
    {
        id: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
        title: 'Meals and daily menus - A2',
        lang: 'en',
        topic: null,
        status: 'learned',
        level: 1,
        verified: false,
        wordsCount: 24,
        createdAt: '2025-01-22T12:56:13.943Z',
        words: [
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'snack',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'lunch',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'breakfast',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'meal',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'dinner',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'dessert',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'Juice',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'oat milk',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'sugar',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'fried egg',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'cereal',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'pancake',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'toast',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'bacon',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'rice',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'pasta',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'salad',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'bread',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'fruit',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'fizzy drinks',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'brunch',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'sausage',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'nuts',
                lang: 'en',
            },
            {
                categoryId: '0d798573-0d60-4e9c-8e68-43372ff2ee08',
                word: 'ice cream',
                lang: 'en',
            },
        ],
    },
];
