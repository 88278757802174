import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { AnimatePresence, motion } from 'framer-motion';
import { authStore } from '../../App';
import languagesStore from '../../store/interface';
import userWordsStore, {
    WordCardModes,
    WordStatuses,
} from '../UserPlatform/store/userWords';
import wordsStore from '../../store/words';
import { useFetching } from '../../hooks/useFetching';
import ProgressService from '../../api/ProgressService';
import InfoCard from './components/InfoCard/InfoCard';
import KeyboardCard from './components/KeyboardCard/KeyboardCard';
import PronunciationCard from './components/PronunciationCard/PronunciationCard';
import LettersCard from './components/LettersCard/LettersCard';
import PhraseCard from './components/PhraseCard/PhraseCard';
import WriteCard from './components/WriteCard/WriteCard';
import { Loader } from '../../UI';
import { Roles } from '../../data/common';
import { ReactComponent as IconArrow } from '../../assets/svg/arrow_short.svg';
import { ReactComponent as IconStar } from '../../assets/svg/user-icon-star.svg';
import cl from './LearnWords.module.css';
import userStore from '../UserPlatform/store/userStore';

const cardAnimation = {
    enter: { opacity: 0, transform: 'translate(-50%, -35%)' },
    center: { opacity: 1, transform: 'translate(-50%, -50%)' },
    exitRight: { opacity: 0, transform: 'translate(-40%, -50%) rotate(5deg)' },
    exitLeft: { opacity: 0, transform: 'translate(-60%, -50%) rotate(-5deg)' },
};

const transition = { duration: 0.5, ease: 'easeOut' };

const LearnWords = ({ words = [], alreadyFetched, categoryId }) => {
    const { t } = useTranslation();
    const { user } = authStore;
    const isUser = user.role === Roles.User;
    const wordsSource = isUser ? userStore : wordsStore;

    const { lang, nativeLang } = languagesStore;

    const { activeWords, cardModes, wordsPerPack, currentCardIdx } =
        userWordsStore;

    const finalIteration = cardModes.lastIndexOf(true);

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [currentPack, setCurrentPack] = useState([]);
    const [isLearned, setIsLearned] = useState(null);
    const [isEnd, setIsEnd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showControls, setShowControls] = useState(true);

    const [updateWord] = useFetching(async ({ id, status, lastIteration }) => {
        if (alreadyFetched || !isUser) return;
        await ProgressService.updateWord({ id, status, lastIteration });
    });

    const getAllWordsData = async () => {
        setIsLoading(true);
        const active = words.filter(
            (w) => w.status === WordStatuses.Active || !w.status
        );

        let wordsDataArr = [];

        if (!alreadyFetched) {
            for (const w of active) {
                const { data: wordData } =
                    await wordsSource.getCachedWordByText({
                        word: w.word,
                        sourceLang: lang,
                        targetLang: nativeLang,
                    });
                if (wordData && wordData.data) {
                    wordsDataArr.push({
                        ...w,
                        ...wordData,
                        ...wordData.data[0],
                        lastIteration: getWordLastIteration(w),
                    });
                }
            }
        } else {
            wordsDataArr = words;
        }

        setIsLoading(false);
        setIsInitialRender(false);

        userWordsStore.setActiveWords(wordsDataArr);
    };

    const getWordLastIteration = (word) => {
        if (!isUser) return 0;

        const wordObj = userStore.wordsIterations.find(
            (w) => w.id === word.wordId
        );
        return wordObj?.lastIteration || 0;
    };

    const getCardTitle = (index) => {
        return (
            <p className={cl.cardTitle}>
                {t(`glossary_settings.${Object.values(WordCardModes)[index]}`)}
            </p>
        );
    };

    const getNextCard = (word) => {
        switch (word.lastIteration) {
            case 0:
                return <InfoCard wordObj={word} playAudio />;
            case 1:
                return (
                    <LettersCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 2:
                return (
                    <KeyboardCard
                        wordObj={word}
                        mode={'audio'}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 3:
                return (
                    <KeyboardCard
                        wordObj={word}
                        mode={'translation'}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 4:
                return (
                    <PhraseCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 5:
                return (
                    <PronunciationCard
                        sourceLang={lang}
                        wordObj={word}
                        isTranslation
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 6:
                return (
                    <PronunciationCard
                        sourceLang={lang}
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );
            case 7:
                return (
                    <WriteCard
                        wordObj={word}
                        onComplete={() => handleNextCard(true)}
                    />
                );

            default:
                return null;
        }
    };

    const renderNextCard = () => {
        if (!activeWords.length) {
            if (!isInitialRender) {
                setIsEnd(true);
            }
            return;
        }

        const word = currentPack[currentCardIdx] || activeWords[0];
        if (word.lastIteration > finalIteration) {
            updateWord({ id: word.id, status: WordStatuses.Learned });
            userStore.removeFavWord(word);
            userWordsStore.removeActiveWord(word.id);
            userWordsStore.setCurrentCardIdx(currentCardIdx + 1);
            return;
        }

        const card = getNextCard(word);

        if (!card) return null;

        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key={`${currentCardIdx}_${word.lastIteration}`}
                    className={cl.cardsCont}
                    variants={cardAnimation}
                    initial="enter"
                    animate="center"
                    exit={exitDirection}
                    transition={transition}
                >
                    {getCardTitle(word.lastIteration)}
                    {card}
                </motion.div>
            </AnimatePresence>
        );
    };

    const updateIteration = (word, iteration) => {
        updateWord({
            id: word.id,
            lastIteration: iteration ?? word.lastIteration + 1,
        });
        userStore.updateWordIteration(word.id, iteration);
        userWordsStore.changeWordLevel(word);
    };

    const handlePackComplete = () => {
        if (!isUser) return;
        userStore.removeFavWordPack({ id: categoryId });
    };

    const handleNextCard = (isLearned) => {
        setCardStyle(isLearned);
        setTimeout(() => {
            const word = currentPack[currentCardIdx] || currentPack[0];
            const { id, lastIteration } = word;
            if (isLearned) {
                if (lastIteration >= Object.keys(WordCardModes).length) {
                    updateWord({ id, status: WordStatuses.Learned });
                }
                updateIteration(word);
            }

            if (currentCardIdx + 1 >= currentPack.length) {
                userWordsStore.setCurrentCardIdx(0);
            } else {
                userWordsStore.setCurrentCardIdx(currentCardIdx + 1);
            }
        }, 500);
    };

    const setCardStyle = (isLearned) => {
        setIsLearned(isLearned);
        setTimeout(() => {
            setIsLearned(null);
        }, 500);
    };

    useEffect(() => {
        setIsInitialRender(true);
        setIsEnd(false);
        getAllWordsData();
    }, [words, categoryId]);

    useEffect(() => {
        setCurrentPack(activeWords.slice(0, wordsPerPack));
    }, [activeWords, wordsPerPack]);

    useEffect(() => {
        if (isEnd) {
            userWordsStore.setCurrentWord({});
            handlePackComplete();
        }
    }, [isEnd]);

    useEffect(() => {
        const currentIteration = cardModes.findIndex((flag) => flag);
        for (const word of currentPack) {
            updateIteration(word, currentIteration);
        }

        setCurrentPack(
            currentPack.map((w) => ({ ...w, lastIteration: currentIteration }))
        );
    }, [cardModes]);

    useEffect(() => {
        if (isLearned !== null) {
            setShowControls(false);
            setTimeout(() => setShowControls(true), 1500);
        }
    }, [isLearned]);

    const exitDirection =
        isLearned === true
            ? 'exitRight'
            : isLearned === false
              ? 'exitLeft'
              : null;

    const getClassName = () => {
        if (isLearned === true) return cl.continue;
        if (isLearned === false) return cl.repeat;
        return '';
    };

    const renderLearnWordsBody = () => {
        if (isLoading)
            return (
                <div className={cl.loaderContainer}>
                    <Loader />
                </div>
            );

        if (isEnd)
            return (
                <div className={cl.resultsCont}>
                    <div className={cl.resultsContInner}>
                        <IconStar className={cl.starIcon} />
                        <p>{t('user_view.pack_end_title')}</p>
                        <p className={cl.subtitle}>
                            {t('user_view.pack_end_subtitle')}
                        </p>
                    </div>
                </div>
            );

        return (
            <>
                {renderNextCard()}
                {showControls && (
                    <div className={cl.cardControls}>
                        <div
                            className={cl.repeatButton}
                            onClick={() => {
                                if (typeof isLearned === 'boolean') return;
                                handleNextCard(false);
                            }}
                        >
                            <div className={cl.buttonInner}>
                                <IconArrow />
                                <p>{t('user_view.repeat')}</p>
                            </div>
                        </div>
                        <div
                            className={cl.continueButton}
                            onClick={() => {
                                if (typeof isLearned === 'boolean') return;
                                handleNextCard(true);
                            }}
                        >
                            <div className={cl.buttonInner}>
                                <p>{t('user_view.i_know')}</p>
                                <IconArrow />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={`${cl.learnWords} ${getClassName()}`}>
            {renderLearnWordsBody()}
        </div>
    );
};

export default observer(LearnWords);
