import axios from 'axios';
import { DEVICE_ID } from '../utils/getDeviceId';

const URL = 'v1/users';

const headers = {
    'x-actor-device': DEVICE_ID,
};

export default class UserService {
    static async googleLogin({ code, appType, role }) {
        const response = await axios.post(
            `${URL}/users/google-login`,
            {
                code,
                appType,
                role,
            },
            { headers }
        );
        return response;
    }

    static async login(email, password) {
        const response = await axios.post(
            `${URL}/users/password-login`,
            {
                email,
                password,
            },
            { headers }
        );
        return response;
    }

    static async sendCode({ email }) {
        const response = await axios.post(
            `${URL}/users/send-code`,
            {
                email,
            },
            { headers }
        );
        return response;
    }

    static async register({ email, password, code, role, name, lang }) {
        const response = await axios.post(
            `${URL}/users/register`,
            {
                email,
                password,
                code,
                role,
                name,
                lang,
            },
            { headers }
        );
        return response;
    }

    static async logout() {
        const response = await axios.delete(`${URL}/users/logout`, { headers });
        return response;
    }

    static async getMe() {
        const response = await axios.get(`${URL}/users/me`, { headers });
        return response;
    }

    static async banUser(id) {
        const user = await axios.patch(`${URL}/users/${id}/ban`, { headers });
        return user;
    }

    static async unbanUser(id) {
        const user = await axios.patch(`${URL}/users/${id}/unban`, { headers });
        return user;
    }

    static async getUser(id) {
        const user = await axios.get(`${URL}/users/${id}`, { headers });
        return user;
    }

    static async updateUser({ id, ...body }) {
        const response = await axios.patch(
            `${URL}/users/${id}/update`,
            {
                ...body,
            },
            { headers }
        );
        return response;
    }

    static async getList({
        birthdate,
        name,
        sex,
        lang,
        nativeLang,
        level,
        intensity,
        createdFrom,
        createdTo,
        search,
        limit,
        offset,
        sortColumn,
        sortOrder,
    }) {
        const params = new URLSearchParams({
            offset,
            limit,
        });

        const optional = {
            lang,
            birthdate,
            name,
            sex,
            nativeLang,
            level,
            intensity,
            search,
            createdFrom,
            createdTo,
            sortColumn,
            sortOrder,
        };

        Object.keys(optional).forEach((param) => {
            if (
                optional[param] !== undefined &&
                optional[param] !== null &&
                optional[param] !== ''
            ) {
                params.append(param, optional[param]);
            }
        });

        const list = await axios.get(`${URL}/admin/users?${params.toString()}`);
        return list;
    }

    static async getAdmin({ id }) {
        try {
            const response = await axios.get(`${URL}/admin/${id}`, { headers });
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getTeachers() {
        try {
            const response = await axios.get(`${URL}/users/teachers`, {
                headers,
            });
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getHealthCheck() {
        try {
            const response = await axios.get(`${URL}/admin/healthcheck`, {
                headers,
            });

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getRecentlyRegistered() {
        try {
            const response = await axios.get(
                `${URL}/admin/recently-registered`,
                {
                    headers,
                }
            );

            return response;
        } catch (e) {
            return null;
        }
    }

    static async getStudents() {
        try {
            const response = await axios.get(`${URL}/users/students`, {
                headers,
            });
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getByDeviceId({ deviceId }) {
        try {
            const response = await axios.post(
                `${URL}/admin/users/by-device`,
                {
                    deviceIds: [deviceId],
                },
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async removeStudent({ id }) {
        try {
            const response = await axios.delete(
                `${URL}/users/remove-student/${id}`,
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async updateStudent({ id, nickname, level }) {
        try {
            const response = await axios.patch(
                `${URL}/users/student-view/${id}`,
                { nickname, level },
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async followTeacher({ id }) {
        try {
            const response = await axios.post(
                `${URL}/users/follow-teacher/${id}`,
                {},
                {
                    headers,
                }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async unfollowTeacher({ id }) {
        try {
            const response = await axios.delete(
                `${URL}/users/unfollow-teacher/${id}`,
                { data: {}, headers }
            );
            return response;
        } catch (e) {
            return null;
        }
    }

    static async getMyTeachers() {
        try {
            const response = await axios.get(`${URL}/users/my-teachers`, {
                headers,
            });
            return response;
        } catch (e) {
            return null;
        }
    }
}
