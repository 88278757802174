import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { GenerateChapterModal } from '../../../../components';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import { GenerateDialogue } from '../../../../../../features/LessonsKanban/components/LessonWizard/components/DialogueWizard/DialogueWizard';

const GenerateSituationModal = ({ visible, onGenerate, setVisible }) => {
    const { t } = useTranslation();

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('situations.situation_add_title')}
            variant={Chapters.Situation}
        >
            <GenerateDialogue onGenerate={onGenerate} />
        </GenerateChapterModal>
    );
};

export default observer(GenerateSituationModal);
