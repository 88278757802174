import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import ProgressService from '../../../../api/ProgressService';
import TrackService from '../../../../api/TrackService';
import { authStore } from '../../../../App';
import exercisesPlayer from '../../../../pages/TeacherContent/store/exercisesPlayer';
import exercisesStore from '../../../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../../../store/interface';
import lessonsStore from '../../../LessonsKanban/store/lessonsStore';
import studentsStore from '../../../../pages/TeacherStudents/store/studentsStore';
import userStore from '../../../UserPlatform/store/userStore';
import userExercisesStore from '../../../UserPlatform/pages/UserExercises/store/userExercises';
import {
    EditExerciseModal,
    ExerciseAudioPlayer,
    ExercisePlayer,
    ExercisePlayerControls,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { Button, Input, Loader, MicroButton, Tooltip } from '../../../../UI';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import MultichoicePreview from '../MultichoicePreview/MultichoicePreview';
import SubstitutionPreview from '../SubstitutionPreview/SubstitutionPreview';
import ClozePreview from '../ClozePreview/ClozePreview';
import QuizPreview from '../QuizPreview/QuizPreview';
import SentenceOrderPreview from '../SentenceOrderPreview/SentenceOrderPreview';
import WordsOrderPreview from '../WordsOrderPreview/WordsOrderPreview';
import SummaryPreview from '../SummaryPreview/SummaryPreview';
import AdvancedQuizPreview from '../AdvancedQuizPreview/AdvancedQuizPreview';
import FreeFormQuestionsPreview from '../FreeFormQuestionsPreview/FreeFormQuestionsPreview';
import { Roles, TicksInSecond } from '../../../../data/common';
import { ROUTES } from '../../../../router';
import {
    Chapters,
    StudentExerciseStatuses,
    Views,
} from '../../../LessonsKanban/data/constants';
import {
    ExerciseMediaTypes,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../pages/TeacherContent/data/constants';
import { ReactComponent as IconEdit } from '../../../../assets/svg/lessons-edit.svg';
import { ReactComponent as IconShare } from '../../../../assets/svg/lessons-hyperlink.svg';
import { ReactComponent as IconPdf } from '../../../../assets/svg/lessons-pdf.svg';
import { ReactComponent as IconVisible } from '../../../../assets/svg/visible.svg';
import { ReactComponent as IconHide } from '../../../../assets/svg/hide.svg';
import cl from './TeacherExercisePreview.module.css';

const TeacherExercisePreview = ({
    exerciseId,
    onChange,
    hideEdit,
    isUserLibrary,
}) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const { user } = authStore;
    const { role } = user;

    const { currentLesson, isClonePreview } = lessonsStore;
    const { currentStudent, chaptersModalType, editMode, showAnswers, view } =
        exercisesStore;

    const containerRef = useRef();

    const isUser = role === Roles.User;
    const isStudents = view === Views.Students;
    const isLibrary = view === Views.Library || isClonePreview;

    const currentStudentChapters = isStudents
        ? exercisesStore.currentStudentChapters
        : {};

    const isFav = userStore.favExercises.some((eObj) => eObj.id === exerciseId);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const isExerciseHaveAnswers = () => {
        if (
            [ExerciseMediaTypes.Audio, ExerciseMediaTypes.Video].includes(
                exerciseObj.mediaType
            )
        )
            return true;
        return ![
            MediaExerciseTypes.FreeFormQuestions,
            GrammarExerciseTypes.StatementsTransformation,
            MediaExerciseTypes.Presentation,
        ].includes(exerciseObj.type);
    };

    const isRenderEditButton = () => {
        if (hideEdit || isLibrary || isUserLibrary) return false;
        if (chaptersModalType === Chapters.Exercise) return false;
        if (currentStudent && currentLesson) return false;
        return true;
    };

    const [exerciseObj, setExerciseObj] = useState({});
    const [results, setResults] = useState();
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');
    const [isEmptyComment, setIsEmptyComment] = useState(false);
    const [isHyperlinkTooltip, setHyperlinkTooltip] = useState(false);

    const hyperlinkTimeoutRef = useRef();

    const [getExerciseData, dataLoading] = useFetching(async () => {
        if (!exerciseId) return;
        const { data } = await ExerciseService.getExercise({
            id: exerciseId,
        });
        if (data.trackId && data.trackInterval) {
            await getTrack({
                trackId: data.trackId,
                trackInterval: data.trackInterval,
            });
        } else {
            exercisesPlayer.resetMediaParams();
        }
        setExerciseObj(data);
    });

    const [getTrack, trackLoading] = useFetching(
        async ({ trackId, trackInterval }) => {
            const { data } = await TrackService.getTrack(trackId);
            const rangeInTicks = trackInterval.map((t) => t * TicksInSecond);
            exercisesPlayer.setTrackData(data);
            exercisesPlayer.setMediaRange(rangeInTicks);
            exercisesPlayer.setCurrentTime(rangeInTicks[0]);
        }
    );

    const [evaluateExercise] = useFetching(async () => {
        if (!comment) {
            setIsEmptyComment(true);
            return;
        }
        await ProgressService.evaluateExercise({
            studentId: currentStudent.id,
            exerciseId,
            status: StudentExerciseStatuses.Done,
            comment,
        });
        setStatus(StudentExerciseStatuses.Done);
        const { data } = await ProgressService.getNotifications({
            lang,
        });
        exercisesStore.setCurrentStudentChapters({
            ...currentStudentChapters,
            exercises: currentStudentChapters.exercises.map((e) =>
                e.id === exerciseId
                    ? { ...e, status: StudentExerciseStatuses.Done }
                    : e
            ),
        });
        studentsStore.setNotifications(data.items ?? []);
    });

    const [saveExerciseAnswers] = useFetching(async ({ answers }) => {
        const data = { answers };
        const { data: responseData } =
            await ProgressService.sendExerciseAnswers({ exerciseId, data });
    });

    const [toggleExerciseStatus, isToggleLoading] = useFetching(async () => {
        if (isFav) {
            await ProgressService.updateExerciseStatus({
                exerciseId: exerciseId,
                status: StudentExerciseStatuses.Done,
            });
            userStore.removeFavExercise(exerciseId);
            userStore.setActiveChapter({});
        } else {
            await ProgressService.updateExerciseStatus({
                exerciseId: exerciseId,
                status: StudentExerciseStatuses.Unfinished,
            });
            userStore.addFavExercise(exerciseObj);
            userExercisesStore.setCurrentExercise({});
        }
    });

    const getMediaPreview = () => {
        if (view === Chapters.Track) return null;
        if (exerciseObj.mediaType === ExerciseMediaTypes.Video) {
            return (
                <div className={cl.videoContainer} key={exerciseObj.trackId}>
                    <ExercisePlayer isPreview={true} />
                    <ExercisePlayerControls mode={'video'} isPreview={true} />
                    {!hideEdit && !showAnswers && !isUserLibrary && (
                        <div>
                            <QuestionsLabel
                                label={'exercise_preview_text_info'}
                            />
                            <div className={cl.exerciseText}>
                                {exerciseObj.text}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        if (exerciseObj.mediaType === ExerciseMediaTypes.Audio) {
            return (
                <div key={exerciseObj.trackId}>
                    <ExerciseAudioPlayer id={exerciseObj.id} />
                    {!hideEdit && !showAnswers && !isUserLibrary && (
                        <div>
                            <QuestionsLabel
                                label={'exercise_preview_text_info'}
                            />
                            <div className={cl.exerciseText}>
                                {exerciseObj.text}
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        if (exerciseObj.text) {
            return (
                <div>
                    <QuestionsLabel label={'gap_word_select'} />
                    <div className={cl.exerciseText}>{exerciseObj.text}</div>
                </div>
            );
        }

        return null;
    };

    const getPreview = () => {
        switch (exerciseObj.type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoicePreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozePreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        results={results}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Summary:
                return (
                    <SummaryPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                    />
                );
            case MediaExerciseTypes.Presentation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                        showInput={hideEdit}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showInput={hideEdit}
                    />
                );
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        results={results}
                        showAnswers={showAnswers}
                        showInput={hideEdit}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleShowAnswers = () => {
        exercisesStore.setShowAnswers(!showAnswers);
    };

    const handleCommentChange = (text) => {
        setIsEmptyComment(false);
        setComment(text);
    };

    const renderUserControls = () => {
        if (!isUser || !exerciseObj?.type || isUserLibrary) return null;
        return (
            <div className={cl.userButtonsContainer}>
                {isExerciseHaveAnswers() && !showAnswers && (
                    <Button
                        variant={Chapters.Exercise}
                        text={t('user_view.check_answers')}
                        onClick={() => exercisesStore.setShowAnswers(true)}
                    />
                )}
                <Button
                    variant={'grey'}
                    text={t(
                        isFav
                            ? 'user_view.mark_as_done'
                            : 'user_view.repeat_exercise'
                    )}
                    onClick={toggleExerciseStatus}
                    isLoading={isToggleLoading}
                />
            </div>
        );
    };

    const renderResultsControl = () => {
        if (!results || isUser) return null;

        let child = null;

        if (status === StudentExerciseStatuses.AwaitingEvaluation) {
            child = (
                <>
                    <Input
                        placeholder={t('buttons.comment')}
                        variant={isEmptyComment ? 'erroredSmall' : 'filled'}
                        style={{ marginBottom: 12 }}
                        value={comment}
                        onChange={handleCommentChange}
                    />
                    <Button
                        text={t('exercises.evaluate')}
                        variant={Chapters.Exercise}
                        style={{ margin: '0 auto' }}
                        onClick={evaluateExercise}
                    />
                </>
            );
        } else {
            const text =
                status === StudentExerciseStatuses.Unfinished
                    ? 'exercises.exercise_unfinished'
                    : 'exercises.exercise_done';

            child = <p className={`${cl.doneText} ${cl[status]}`}>{t(text)}</p>;
        }

        return (
            <div className={hideEdit ? cl.previewButtons : cl.buttonsContainer}>
                {child}
            </div>
        );
    };

    const handleHyperlinkClick = () => {
        const link = `${window.location.origin}${ROUTES.hyperlinkDemo.replace(':id', exerciseId)}?mode=preview`;

        navigator.clipboard.writeText(link).catch((err) => {
            console.error('Failed to copy link: ', err);
        });
        setHyperlinkTooltip(true);
    };

    const handlePdfClick = () => {
        window.open(
            `${window.location.origin}${ROUTES.hyperlinkDemo.replace(':id', exerciseId)}?mode=pdf`,
            '_blank'
        );
    };
    const handleEditButton = () => {
        exercisesStore.setEditMode(true);
    };

    const renderMicroButtons = () => {
        if (
            (!isRenderEditButton() && !isExerciseHaveAnswers()) ||
            results ||
            isUser
        ) {
            return null;
        }

        return (
            <div className={cl.microButtons}>
                {isRenderEditButton() && (
                    <>
                        <MicroButton
                            icon={<IconEdit className={cl.iconStroke} />}
                            variant={'grey'}
                            size={'regular'}
                            onClick={handleEditButton}
                        />
                        <div className={cl.hyperlinkContainer}>
                            <MicroButton
                                icon={<IconShare />}
                                variant={'blue'}
                                size={'regular'}
                                onClick={handleHyperlinkClick}
                            />

                            <Tooltip
                                className={`${cl.hyperlinkTooltip} ${
                                    isHyperlinkTooltip ? cl.visible : ''
                                }`}
                                variant={'hyperlink'}
                                text={t('exercises.hyperlink_tooltip')}
                                tailPosition={'topRight'}
                                onHide={() => setHyperlinkTooltip(false)}
                                // showClose={true}
                            />
                        </div>

                        <MicroButton
                            icon={<IconPdf />}
                            variant={'burgundy'}
                            size={'regular'}
                            onClick={handlePdfClick}
                        />
                    </>
                )}
                {isExerciseHaveAnswers() && (
                    <MicroButton
                        icon={
                            showAnswers ? (
                                <IconHide className={cl.iconStroke} />
                            ) : (
                                <IconVisible className={cl.iconStroke} />
                            )
                        }
                        variant={Chapters.Exercise}
                        size={'regular'}
                        onClick={handleShowAnswers}
                    />
                )}
            </div>
        );
    };

    const syncChanges = (data) => {
        setExerciseObj(data);

        if (onChange) {
            const { id, title, difficulty } = data;
            onChange({ id, title, difficulty });
        }
    };

    useEffect(() => {
        setComment('');
        setExerciseObj({});
        if (!exerciseId) return;
        exercisesStore.setShowAnswers(false);
        setTimeout(() => {
            getExerciseData();
        }, 100);
    }, [exerciseId]);

    useEffect(() => {
        const currentStudentProgress = currentStudentChapters.exercises?.find(
            (e) => e.id === exerciseId
        );
        setResults(currentStudentProgress?.data ?? null);
        setStatus(currentStudentProgress?.status ?? '');
    }, [exerciseId, currentStudentChapters]);

    useEffect(() => {
        if (showAnswers) {
            scrollToBottom();
        }
    }, [showAnswers]);

    useEffect(() => {
        if (isHyperlinkTooltip) {
            hyperlinkTimeoutRef.current = setTimeout(() => {
                setHyperlinkTooltip(false);
            }, 2500);
        } else {
            clearTimeout(hyperlinkTimeoutRef.current);
        }
    }, [isHyperlinkTooltip]);

    if (dataLoading || trackLoading)
        return <Loader style={{ margin: 'auto' }} />;

    return (
        <div className={cl.preview}>
            <div className={cl.container} ref={containerRef}>
                <div className={cl.titleContainer}>
                    <p className={cl.exerciseTitle}>{exerciseObj.title}</p>
                    {renderMicroButtons()}
                </div>
                <div className={cl.previewContainer}>
                    <p className={cl.exerciseDescription}>
                        {exerciseObj.description}
                    </p>
                    {getMediaPreview()}
                    {getPreview()}
                    {renderUserControls()}
                </div>
            </div>

            {renderResultsControl()}

            {editMode && (
                <EditExerciseModal
                    currentExercise={exerciseObj}
                    onChange={syncChanges}
                />
            )}
        </div>
    );
};

export default observer(TeacherExercisePreview);
