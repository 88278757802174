import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import guestOnboarding from '../../store/guestOnboarding';
import { Button, Modal } from '../../../../UI';
import { calculateTextTimeout } from '../../data/constants';
import cl from './InfoModal.module.css';

const InfoModal = ({
    onboardingKey,
    title,
    subtitle,
    delay,
    style,
    onClose,
    variant,
}) => {
    const { t } = useTranslation();
    const timeoutRef = useRef();

    const { isDelay } = guestOnboarding;

    const handleButtonClick = () => {
        onClose ? onClose() : guestOnboarding.moveToNextComponent();
    };

    useEffect(() => {
        if (isDelay || !onboardingKey) return;

        document.body.style.overflow = 'hidden';

        const timeout = calculateTextTimeout(`${title} ${subtitle}`);

        timeoutRef.current = setTimeout(() => {
            handleButtonClick();
        }, timeout);

        return () => {
            clearTimeout(timeoutRef.current);
            document.body.style.overflow = '';
        };
    }, [onboardingKey]);

    if (!onboardingKey || isDelay) return null;

    return (
        <Modal
            visible={true}
            setVisible={() => {}}
            className={cl.intro}
            style={style}
            addBlur
        >
            <p className={cl.title}>{title}</p>
            <p
                dangerouslySetInnerHTML={{ __html: subtitle }}
                className={cl.subtitle}
            />
            <Button
                variant={variant}
                text={t('teacher_onboarding.close_button')}
                onClick={handleButtonClick}
                progressDuration={calculateTextTimeout(`${title} ${subtitle}`)}
                progressDelay={delay}
            />
        </Modal>
    );
};

export default observer(InfoModal);
