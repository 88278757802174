import React from 'react';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './UserQuizPreview.module.css';

const UserQuizPreview = ({ exerciseObj, results, setResults, isCompleted }) => {
    const { data } = exerciseObj;
    const { questions } = data;

    const userAnswers =
        results.userAnswers || Array(questions.length).fill(null);

    const handleAnswerClick = (questionIndex, answerIndex) => {
        setResults({
            userAnswers: userAnswers.map((a, i) => {
                return i === questionIndex
                    ? a === answerIndex
                        ? null
                        : answerIndex
                    : a;
            }),
        });
    };

    const getAnswerClassName = (questionIdx, answerIndex) => {
        const classNames = [cl.answer];

        const question = questions[questionIdx];
        const userAnswer = userAnswers[questionIdx];

        if (userAnswer !== answerIndex) return classNames.join(' ');

        if (isCompleted) {
            const correctIndex = question.options.findIndex(
                (o) => o.text === question.answer
            );
            classNames.push(
                correctIndex === answerIndex ? cl.correct : cl.wrong
            );
        } else {
            classNames.push(cl.active);
        }

        return classNames.join(' ');
    };

    if (!questions) return <></>;

    return (
        <>
            <QuestionsLabel label={'quiz_user_info'} />
            <div className={cl.questionsCont}>
                {questions?.map((q, qIdx) => (
                    <div className={cl.question} key={q.id}>
                        <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>
                        <div className={cl.answersCont}>
                            {q.options.map((o, oIdx) => (
                                <span
                                    className={getAnswerClassName(qIdx, oIdx)}
                                    key={o.id}
                                    onClick={() =>
                                        handleAnswerClick(qIdx, oIdx)
                                    }
                                >
                                    {o.text}
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default UserQuizPreview;
