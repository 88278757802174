import { BaseUrl, LanguageLevels } from '../../../data/common';
import toHoursAndMinutes from '../../../utils/toHoursAndMinutes';
import { Chapters } from '../../../features/LessonsKanban/data/constants';

const getCardParamsByType = (chapter, type, t) => {
    const params = [];
    if (type === Chapters.Exercise) {
        params.push(
            t(
                `exercises.${
                    chapter.type === 'exercise'
                        ? chapter.exerciseType
                        : chapter.type
                }`
            )
        );
    }
    if (chapter.podcastTitle) {
        params.push(chapter.podcastTitle);
    }
    if (chapter.categoryTitle) {
        params.push(chapter.categoryTitle);
    }
    if (chapter.level !== undefined) {
        if (type === Chapters.Dictionary) {
            params.push(LanguageLevels[chapter.level]);
        } else {
            params.push(
                chapter.level?.length > 1
                    ? `${chapter.level[0]}-${chapter.level.slice(-1)}`
                    : chapter.level
            );
        }
    }
    if (typeof chapter.difficulty === 'number') {
        params.push(LanguageLevels[chapter.difficulty]);
    }
    if (chapter.duration) {
        params.push(toHoursAndMinutes(chapter.duration));
    }
    return params;
};

const getPreview = (chapter) => {
    const situationImage = chapter.image?.md
        ? null
        : chapter.image || chapter.picture;

    if (situationImage) {
        const imagePath = situationImage.split('/')[3];
        return `${BaseUrl}/filedump/situation-images/${imagePath}`;
    }

    return chapter.image?.md || '';
};

export const mapChapterData = (chapter, type, t) => ({
    id: chapter.id,
    title: chapter.title || chapter.word,
    preview: getPreview(chapter),
    params: getCardParamsByType(chapter, type, t),
    ...chapter,
});
