import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import userStore from '../../store/userStore';
import { CloseButton, EmptyChaptersAlert, Input } from '../../../../UI';
import languagesStore from '../../../../store/interface';
import userWordsStore from '../../store/userWords';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { GenerateChapterModal } from '../../../../pages/TeacherContent/components';
import UserChapterCard from '../UserChapterCard/UserChapterCard';

import cl from './UserWordPacksSelector.module.css';

const UserWordPacksSelector = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { favWordPacks } = userStore;
    const { groupedWord } = userWordsStore;

    const [filtered, setFiltered] = useState([]);
    const [searchString, setSearchString] = useState('');

    const handleClose = () => {
        userWordsStore.setGroupedWord(null);
    };

    useEffect(() => {
        setSearchString('');
    }, [groupedWord]);

    useEffect(() => {
        setFiltered(
            favWordPacks.filter((pObj) =>
                pObj.title.toLowerCase().includes(searchString.toLowerCase())
            )
        );
    }, [favWordPacks, searchString]);

    return (
        <GenerateChapterModal
            title={t('user_view.add_word_to_pack_title')}
            variant={Chapters.Dictionary}
            className={cl.modal}
            visible={!!groupedWord}
            setVisible={handleClose}
        >
            {favWordPacks.length !== 0 && (
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('library.search')}
                    value={searchString}
                    onChange={setSearchString}
                    search
                    showReset
                />
            )}

            {filtered.length !== 0 ? (
                <div className={cl.packsList}>
                    {filtered.map((pack) => (
                        <UserChapterCard
                            key={pack.id}
                            chapter={pack}
                            type={Chapters.Dictionary}
                            setActive={() =>
                                userStore.togglePackWord({
                                    id: pack.id,
                                    word: groupedWord.word,
                                    lang,
                                })
                            }
                            isWordPack
                            isSelected={pack?.words?.some(
                                (w) => w.word === groupedWord?.word
                            )}
                        />
                    ))}
                </div>
            ) : (
                <EmptyChaptersAlert
                    title={t(
                        favWordPacks.length
                            ? 'user_view.packs_not_found'
                            : 'user_view.no_packs_yet'
                    )}
                    subtitle={t(
                        favWordPacks.length
                            ? 'user_view.packs_not_found_subtitle'
                            : 'user_view.no_packs_yet_subtitle'
                    )}
                />
            )}

            <CloseButton onClick={handleClose} />
        </GenerateChapterModal>
    );
};

export default observer(UserWordPacksSelector);
