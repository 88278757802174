import { motion } from 'framer-motion';
import cl from './LanguageBubble.module.css';

const languageData = {
    en: {
        animate: {
            x: [10, 12, 30],
            y: [10, 41, 27],
            rotate: [-13, -9, -15, -17, -5, 4],
            scale: [1, 0.9, 1.1],
        },
    },
    es: {
        animate: {
            x: [0, 17, 3],
            y: [10, -8, 20],
            rotate: [13, 19, 16, 11, 9, 11, 13, 17],
            scale: [1.1, 1, 0.9],
        },
    },
    it: {
        animate: {
            x: [10, 20, 30],
            y: [10, 20, 30],
            rotate: [-11, -13, -15, -11, -17, -12, -13, -15],
            scale: [1, 0.9, 1.1],
        },
    },
    de: {
        animate: {
            x: [10, 20, 30],
            y: [10, 20, 30],
            rotate: [-5, -13, -7, -4, -17, -3, -5, -15],
            scale: [1.1, 1, 0.9],
        },
    },
    fr: {
        animate: {
            x: [0, -20, -40],
            y: [11, 21, 43],
            rotate: [-3, -3, -4, -6, -9, -11, -7, -2],
            scale: [1, 0.9, 1.1],
        },
    },
    pt: {
        animate: {
            x: [0, 0, 0],
            y: [0, 0, 0],
            rotate: [23, 23, 24, 26, 29, 19, 17, 22],
            scale: [1.1, 1, 0.9],
        },
    },
};

const bubbleTransition = {
    duration: 16,
    repeat: Infinity,
    repeatType: 'mirror',
    ease: 'easeInOut',
};

const LanguageBubble = ({ className, language, animate, transition }) => {
    const data = languageData[language] || {};

    return (
        <motion.div
            className={`${cl.bubble} ${cl[language]} ${className || ''}`}
            animate={animate || data.animate}
            transition={transition || bubbleTransition}
        />
    );
};

export default LanguageBubble;
