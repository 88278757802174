import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import wizardStore from '../LessonWizard/store/wizardStore';
import { Button } from '../../../../UI';
import { ReactComponent as IconEyes } from '../../../../assets/svg/lessons-eyes.svg';
import cl from './EmptyKanban.module.css';

const EmptyKanban = ({ isLibrary, isUser }) => {
    const { t } = useTranslation();

    const handleButtonClick = () => {
        wizardStore.setWizardVisible(true);
    };

    return (
        <div className={cl.emptyAlert}>
            <IconEyes className={cl.iconEyes} />
            <p className={cl.alertTitle}>
                {t(
                    isLibrary
                        ? 'exercises.empty_lesson_library'
                        : isUser
                          ? 'user_view.empty_lesson_library'
                          : 'exercises.no_own_lessons'
                )}
            </p>
            {isLibrary && (
                <p className={cl.alertSubtitle}>
                    {t('exercises.empty_lesson_library_subtitle')}
                </p>
            )}
            {isUser && (
                <p className={cl.alertSubtitle}>
                    {t('user_view.empty_lesson_library_subtitle')}
                </p>
            )}
            <div className={cl.buttons}>
                {!isLibrary && !isUser && (
                    <Button
                        variant={'grey'}
                        text={t('exercises.add_lesson')}
                        onClick={handleButtonClick}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(EmptyKanban);
