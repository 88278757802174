import React from 'react';
import { useTranslation } from 'react-i18next';
import cl from './Footer.module.css';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className={cl.footer}>
            <div className={cl.greyBlock}>
                <p className={cl.link}>©Pony, 2024</p>
                <p className={cl.link}>{t('landing.commercial_terms')}</p>
                <p className={cl.link}>{t('landing.contact_us')}</p>
            </div>
        </div>
    );
};

export default Footer;
