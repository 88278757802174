import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import userVideosStore from './store/userVideos';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import { Input, PageWrapper, Select, Toolbar, ViewTitle } from '../../../../UI';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { UserChapterPreview, UserContentContainer } from '../../components';
import { ChaptersScrollableList } from '../../../../pages/TeacherContent/components';
import { ReactComponent as IconPlayer } from '../../../../assets/svg/lessons-track.svg';
import cl from './UserVideos.module.css';

const TRACKS_PER_PAGE = 50;

const UserVideos = ({ isHistory }) => {
    const { t, i18n } = useTranslation();
    const { user } = authStore;
    const { language: lang } = i18n;

    const scrollRef = useRef();

    const {
        currentTrack,
        page,
        searchString,
        tracks,
        paginationEnd,
        categoryId,
        categoryOptions,
    } = userVideosStore;

    const [getTracks, isLoading] = useFetching(async () => {
        const params = {
            offset: page * TRACKS_PER_PAGE,
            limit: TRACKS_PER_PAGE,
            lang,
            categories: categoryId ? [categoryId] : null,
            difficulty: user.level,
            searchString,
            recognized: true,
            processed: false,
            sortColumn: 'id',
            sortOrder: 'asc',
        };
        const { data } = isHistory
            ? await TrackService.getHistory({
                  ...params,
                  podcastId: categoryId,
              })
            : await TrackService.getRecommendations(params);
        userVideosStore.setTrackData(data);
    });

    const handleTrackListScroll = (e) => {
        const list = e.target;
        if (paginationEnd || !list) return;

        if (!isLoading && list.scrollTop > scrollRef.current) {
            const halfwayPoint = list.scrollHeight / 2;

            if (list.scrollTop + list.clientHeight >= halfwayPoint) {
                userVideosStore.setPage(page + 1);
            }
        }

        scrollRef.current = list.scrollTop;
    };

    useEffect(() => {
        getTracks();
    }, [page, isHistory]);

    useEffect(() => {
        if (page !== 0) {
            userVideosStore.setPage(0);
        } else {
            getTracks();
        }
    }, [user, searchString, categoryId, isHistory]);

    useEffect(() => {
        userVideosStore.setTracks([]);
        userVideosStore.setCurrentTrack({});
    }, [isHistory]);

    return (
        <PageWrapper additionalClass={cl.videoWrapper}>
            <ViewTitle
                text={t(
                    isHistory
                        ? 'user_view.tracks_history'
                        : 'user_view.my_videos'
                )}
                icon={<IconPlayer />}
            />
            <UserContentContainer>
                <Toolbar variant={Chapters.Track}>
                    <Input
                        value={searchString}
                        onChange={userVideosStore.setSearchString}
                        placeholder={t('user_view.search')}
                    />
                    <Select
                        label={t('exercises.category')}
                        style={{ minWidth: 220 }}
                        variant={'white'}
                        value={categoryId}
                        onChange={userVideosStore.setCategoryId}
                        options={categoryOptions}
                    />
                </Toolbar>

                <ChaptersScrollableList
                    className={cl.videoList}
                    chapters={tracks}
                    type={Chapters.Track}
                    current={currentTrack}
                    setCurrent={userVideosStore.setCurrentTrack}
                    isLoading={isLoading}
                    onScroll={handleTrackListScroll}
                    isUser
                />
                <UserChapterPreview
                    chapter={currentTrack}
                    type={Chapters.Track}
                />
            </UserContentContainer>
        </PageWrapper>
    );
};

export default observer(UserVideos);
