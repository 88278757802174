import React from 'react';
import { useTranslation } from 'react-i18next';
import cl from './QuestionsLabel.module.css';

const QuestionsLabel = ({ label, variant }) => {
    const { t } = useTranslation();
    return (
        <p className={`${cl.questionsLabel} ${variant ? cl[variant] : ''}`}>
            <span>{t(`exercises.${label}`)}</span>
        </p>
    );
};

export default QuestionsLabel;
