import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import languagesStore from '../../store/interface';
import UserService from '../../api/UserService';
import { useFetching } from '../../hooks/useFetching';
import {
    DatePicker,
    LabeledInput,
    LabeledSelect,
    List,
    MicroButton,
    PageWrapper,
    PrimaryButton,
    PrimaryInput,
} from '../../UI';
import { UserItem, UserProgress } from './components';
import {
    AllLanguages,
    DefaultSort,
    SortColumns,
    SortOrder,
    UsersPerPage,
} from './data/constants';
import { transformDate } from './helpers/transformDate';
import { LevelOptions } from '../TeacherContent/data/constants';
import cl from './Users.module.css';

const Users = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const interfaceLang = i18n.language;
    const { languages } = languagesStore;

    const queryParams = new URLSearchParams(location.search);
    const queryDeviceId = queryParams.get('device_id');
    const queryId = queryParams.get('id');

    const scrollRef = useRef();

    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({});

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [pagniationEnd, setPaginationEnd] = useState(false);

    const [search, setSearch] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [name, setName] = useState('');
    const [lang, setLang] = useState('');
    const [userLang, setUserLang] = useState('');
    const [nativeLang, setNativeLang] = useState('');
    const [level, setLevel] = useState('');
    const [sortColumn, setSortColumn] = useState(DefaultSort.Column);
    const [sortOrder, setSortOrder] = useState(DefaultSort.Order);

    const LanguageOptions = [
        { value: '', name: t('track_statuses.all') },
        ...languages,
    ];

    const NativeLanguageOptions = [
        { value: '', name: t('track_statuses.all') },
        ...AllLanguages,
    ];

    const LanguageLevelOptions = [
        { value: '', name: t('track_statuses.all') },
        ...LevelOptions,
    ];

    const SortOptions = [
        ...SortColumns.map((c) => ({ value: c, name: t(`users.${c}`) })),
    ];

    const [getUser, userLoading] = useFetching(async (deviceId) => {
        const { data } = await UserService.getByDeviceId({
            offset: 0,
            limit: 1,
            deviceId,
        });
        const user = data[0] ?? {};
        if (!user?.email) return;

        const { data: userData } = await UserService.getList({
            offset: 0,
            limit: 1,
            search: user.email,
        });

        const additionalData = userData?.items[0] ?? {};

        setCurrentUser({ ...user, ...additionalData });
    });

    const [getUsers, isLoading] = useFetching(async () => {
        const createdFrom = transformDate(startDate);
        const createdTo = transformDate(endDate);
        const { data } = await UserService.getList({
            offset: page * UsersPerPage,
            limit: UsersPerPage,
            search,
            lang,
            nativeLang,
            level,
            name,
            createdFrom,
            createdTo,
            sortColumn,
            sortOrder,
        });
        const { items, total } = data;
        if (!items.length) {
            setPaginationEnd(true);
        }

        const newUsers = (page > 0 ? [...users, ...items] : items).filter(
            (p, index, self) =>
                !self.slice(0, index).some((item) => item.id === p.id)
        );
        setUsers(newUsers);
        setTotal(total);
    });

    const renderResetButton = () =>
        startDate || endDate || name || lang || nativeLang || level;

    const handleListScroll = (e) => {
        if (pagniationEnd) return;
        const list = e.target;
        if (!isLoading && list.scrollTop > scrollRef.current) {
            if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
                setPage(page + 1);
            }
        }
        scrollRef.current = list.scrollTop;
    };

    const handleReset = () => {
        setStartDate('');
        setEndDate('');
        setName('');
        setLang('');
        setNativeLang('');
        setLevel('');
    };

    const handleUserClick = async (id) => {
        const { data } = await UserService.getUser(id);
        setCurrentUser(data);
        navigate({
            pathname: location.pathname,
            search: `?id=${id}`,
        });
    };

    const handleOrderChange = () => {
        setSortOrder(
            sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
        );
    };

    const handleUserClose = () => {
        setCurrentUser({});
        navigate({
            pathname: location.pathname,
        });
    };

    useEffect(() => {
        if (!queryId) {
            setCurrentUser({});
        }
    }, [queryId]);

    useEffect(() => {
        getUser(queryDeviceId);
    }, [queryDeviceId]);

    useEffect(() => {
        if (currentUser?.lang) {
            setUserLang(currentUser.lang);
        } else {
            setUserLang(interfaceLang);
        }
    }, [currentUser]);

    useEffect(() => {
        getUsers();
    }, [page]);

    useEffect(() => {
        setPaginationEnd(false);
        setPage(0);
        getUsers();
    }, [
        lang,
        level,
        name,
        nativeLang,
        search,
        startDate,
        endDate,
        sortColumn,
        sortOrder,
    ]);

    if (currentUser.id)
        return (
            <UserProgress
                user={currentUser}
                lang={userLang}
                userLoading={userLoading}
                onClose={handleUserClose}
            />
        );

    return (
        <PageWrapper>
            <div className={cl.navBar}>
                <PrimaryInput
                    value={search}
                    onChange={setSearch}
                    placeholder={t('users.search')}
                />
                <DatePicker
                    label={t('users.start_date')}
                    value={startDate}
                    onChange={setStartDate}
                />
                <DatePicker
                    label={t('users.end_date')}
                    value={endDate}
                    onChange={setEndDate}
                />
                <LabeledSelect
                    label={t('users.sort_column')}
                    options={SortOptions}
                    value={sortColumn}
                    onChange={setSortColumn}
                />
                <MicroButton
                    size={'large'}
                    variant={'greyOld'}
                    onClick={handleOrderChange}
                    text={sortOrder === SortOrder.Asc ? '↑' : '↓'}
                />
                {renderResetButton() && (
                    <PrimaryButton
                        variant={'dark'}
                        text={t('buttons.reset')}
                        onClick={handleReset}
                    />
                )}
                <div className={cl.recentlyCounter}>
                    {`${t('users.amount')}: `}
                    <span>{total || '---'}</span>
                </div>
            </div>
            <div className={cl.tableHeader}>
                <div className={cl.columnName}>
                    <div className={cl.lockedSearch}>{t('email')}</div>
                </div>
                <div className={cl.columnName}>
                    <div className={cl.lockedSearch}>
                        {t('users.last_seen')}
                    </div>
                </div>
                <div className={cl.columnName}>
                    <LabeledInput
                        label={t('users.name')}
                        value={name}
                        placeholder={'John Smith'}
                        onChange={setName}
                    />
                </div>
                <div className={cl.columnName}>
                    <LabeledSelect
                        label={t('users.lang')}
                        value={lang}
                        options={LanguageOptions}
                        onChange={setLang}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className={cl.columnName}>
                    <LabeledSelect
                        label={t('users.native')}
                        value={nativeLang}
                        options={NativeLanguageOptions}
                        onChange={setNativeLang}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className={cl.columnName}>
                    <LabeledSelect
                        label={t('users.level')}
                        value={level}
                        options={LanguageLevelOptions}
                        onChange={setLevel}
                        style={{ width: '100%' }}
                    />
                </div>
            </div>
            <List
                onScroll={handleListScroll}
                isLoading={isLoading}
                style={{ padding: 0, gap: 6, overflow: 'hidden visible' }}
            >
                {users.map((u) => (
                    <UserItem key={u.id} user={u} onClick={handleUserClick} />
                ))}
            </List>
        </PageWrapper>
    );
};

export default observer(Users);
