import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
    onboardingStore,
    OnboardingKeys,
} from '../../../../features/Onboarding';
import { EmptyChaptersAlert, Loader } from '../../../../UI';
import ChapterCard from '../ChapterCard/ChapterCard';
import cl from './ChaptersScrollableList.module.css';
import { UserChapterCard } from '../../../../features/UserPlatform/components';

const ChaptersScrollableList = ({
    className,
    isLoading,
    chapters,
    type,
    current,
    setCurrent,
    onScroll,
    onSelect,
    isUser = false,
}) => {
    const { t } = useTranslation();

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.ChapterCard
    );
    const isLesson = false;

    return (
        <div
            className={`${cl.chaptersContainer} ${className ? className : ''}`}
        >
            {chapters.length ? (
                <div className={cl.list} onScroll={onScroll}>
                    {chapters.map((chapter, index) => {
                        return isUser ? (
                            <UserChapterCard
                                key={chapter.id}
                                type={type}
                                chapter={chapter}
                                isActive={current?.id === chapter.id}
                                setActive={setCurrent}
                                isScrollable
                                onSelect={onSelect}
                                onboardingKey={
                                    index === 0 ? isOnboarding : null
                                }
                            />
                        ) : (
                            <ChapterCard
                                key={chapter.id}
                                type={type}
                                chapter={chapter}
                                isActive={current?.id === chapter.id}
                                setActive={setCurrent}
                                isScrollable
                                onSelect={onSelect}
                                onboardingKey={
                                    index === 0 ? isOnboarding : null
                                }
                            />
                        );
                    })}
                </div>
            ) : isLoading ? (
                <div className={cl.emptyAlert}>
                    <Loader style={{ opacity: 0.2 }} />
                </div>
            ) : (
                <EmptyChaptersAlert
                    title={t(
                        `exercises.empty_${isLesson ? 'fav' : 'search'}_${type}_title`
                    )}
                    subtitle={t(
                        `exercises.empty_${isLesson ? 'fav' : 'search'}_${type}_subtitle`
                    )}
                />
            )}
        </div>
    );
};

export default observer(ChaptersScrollableList);
