import { ReactComponent as IconDictionary } from '../../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconFile } from '../../../../../assets/svg/lessons-file.svg';
import { Chapters } from '../../../../../features/LessonsKanban/data/constants';

export const PresentationTypes = {
    Transcription: 'transcription',
    ShortSummary: 'short_summary',
    CreativeWriting: 'creative_writing',
    Paraphrasing: 'paraphrasing',
    PersonalReaction: 'personal_reaction',
    OpinionPiece: 'opinion_piece',
    CharacterAnalysis: 'character_analysis',
    AnalysisEssay: 'analysis_essay',
    ComparativeEssay: 'comparative_essay',
};

export const iconMap = {
    [Chapters.Track]: <IconTrack />,
    [Chapters.Situation]: <IconSituation />,
    [Chapters.Exercise]: <IconExercise />,
    [Chapters.Dictionary]: <IconDictionary />,
    [Chapters.File]: <IconFile />,
};

export const titleMap = {
    [Chapters.Track]: 'teacher_navbar.tracks',
    [Chapters.Situation]: 'teacher_navbar.situations',
    [Chapters.Exercise]: 'teacher_navbar.exercises',
    [Chapters.Dictionary]: 'teacher_navbar.dictionaries',
};
