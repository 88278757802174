import { makeAutoObservable, action, runInAction } from 'mobx';
import DictionaryService from '../../../api/DictionaryService';
import ProgressService from '../../../api/ProgressService';
import TrackService from '../../../api/TrackService';
import { StudentExerciseStatuses } from '../../LessonsKanban/data/constants';
import { WordStatuses } from './userWords';
import { WordPacksStatuses } from '../../../data/common';
import { UserWordsCategory } from '../../../pages/TeacherContent/views/DictionaryView/data/constants';
import {
    CheckWordStatusInterval,
    DictionaryCodes,
    ProcessedWordsKey,
    WordStatuses as WordProcessStatuses,
} from '../../../pages/TeacherContent/views/DictionaryView/data/constants';

const WordsCacheStorageKey = 'cachedDictionaryWords';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class UserStore {
    activeChapter = {};

    historyDialogues = [];
    historyExercises = [];
    historyWords = [];

    favExercises = [];
    favDialogues = [];
    favVideos = [];
    favWordPacks = [];
    favWords = [];

    libraryDialogues = [];
    libraryVideos = [];
    wordsIterations = [];

    videoCache = new Map();

    clickedWords = [];
    chapterCloseModal = false;
    pendingEvent = null;

    processedWords = [];
    intervalRef = null;
    lang = null;
    role = null;

    cachedWords = new Map();
    wordsData = [];

    constructor() {
        makeAutoObservable(
            this,
            {
                setFavVideos: action,
                addFavVideo: action,
                removeFavVideo: action,
                updateDialogueProgress: action,
                updateExerciseProgress: action,
                updateVideoProgress: action,
            },
            { autoBind: true, deep: true }
        );
        this.loadFromLocalStorage();
    }

    init(lang, wordsStore) {
        this.lang = lang;
        this.wordsStoreAdatper = wordsStore;
        this.getProcessedWords();
    }

    setActiveChapter(chapter) {
        this.activeChapter = chapter;
    }

    setExercises(exercises) {
        this.historyExercises = exercises.filter(
            (eObj) => eObj.status !== StudentExerciseStatuses.Unfinished
        );
        this.favExercises = exercises.filter(
            (eObj) => eObj.status === StudentExerciseStatuses.Unfinished
        );
    }

    setDialogues(dialogues) {
        this.historyDialogues = dialogues.filter((dObj) => !dObj.favourite);
        this.favDialogues = dialogues.filter((dObj) => dObj.favourite);
    }

    setWords(words) {
        this.historyWords = words.filter(
            (wObj) => wObj.status === WordStatuses.Learned
        );
        this.favWords = words.filter(
            (wObj) => wObj.status === WordStatuses.Active
        );
        this.setWordsIterations(words);
    }

    setWordsIterations(words) {
        const mappedWords = words.map((w) => ({
            id: w.wordId,
            lastIteration: w.lastIteration,
        }));
        this.wordsIterations = mappedWords;
    }

    updateWordIteration(id, iteration) {
        this.wordsIterations = this.wordsIterations.map((w) =>
            w.id === id
                ? { id, lastIteration: iteration ?? w.lastIteration + 1 }
                : w
        );
    }

    setFavWordPacks(favWordPacks) {
        this.favWordPacks = favWordPacks;
    }

    async setFavVideos(favVideos) {
        const fullVideos = await this.syncFullVideoObjects(favVideos);

        runInAction(() => {
            this.favVideos = fullVideos;
        });
    }

    setFavVideoProgress(favVideos) {
        this.favVideos = favVideos;
    }

    async syncFullVideoObjects(favVideos) {
        const missingVideos = favVideos.filter(
            (v) => v && !this.videoCache.has(v.trackId)
        );

        if (missingVideos.length > 0) {
            const fetchedVideos = await this.fetchVideoData(missingVideos);

            fetchedVideos.forEach((video) => {
                this.videoCache.set(video.trackId, video);
            });
        }

        return favVideos.map((v) => {
            const cachedVideo = this.videoCache.get(v.trackId);
            return cachedVideo ? { ...cachedVideo } : v;
        });
    }

    async fetchVideoData(items) {
        return Promise.all(
            items.map(async (tObj) => {
                try {
                    if (this.videoCache.has(tObj.trackId)) {
                        return this.videoCache.get(tObj.trackId);
                    }

                    const [{ data: videoData }, progressResponse] =
                        await Promise.all([
                            TrackService.getTrack(tObj.trackId),
                            ProgressService.getTrackHistory({
                                trackId: tObj.trackId,
                            }).catch((error) => {
                                console.error(
                                    'Error while getting video progress'
                                );
                                console.error(error);
                                return { data: { progress: 0 } };
                            }),
                        ]);

                    const videoWithProgress = {
                        ...videoData,
                        progress: progressResponse.data.progress || 0,
                    };
                    this.videoCache.set(tObj.trackId, videoWithProgress);
                    return videoWithProgress;
                } catch (e) {
                    return { ...tObj, id: tObj.trackId, progress: 0 };
                }
            })
        );
    }

    async addFavVideo({ id }) {
        let videoData = this.videoCache.get(id);

        if (!videoData) {
            try {
                const { data } = await TrackService.getTrack(id);
                videoData = data;

                runInAction(() => {
                    this.videoCache.set(id, videoData);
                });
            } catch (e) {
                videoData = { id };
            }
        }

        runInAction(() => {
            this.favVideos = [videoData, ...this.favVideos];
        });
    }

    removeFavVideo({ id }) {
        this.favVideos = this.favVideos.filter((v) => v.id !== id);
        this.videoCache.delete(id);
    }

    addFavDialogue(favDialogue) {
        this.favDialogues = [favDialogue, ...this.favDialogues];
        this.historyDialogues = this.historyDialogues.filter(
            (dObj) => dObj.situationId !== favDialogue.situationId
        );
    }

    removeFavDialogue(favDialogue) {
        this.favDialogues = this.favDialogues.filter(
            (dObj) => dObj.situationId !== favDialogue.id
        );
        this.historyDialogues = [
            { ...favDialogue, situationId: favDialogue.id },
            ...this.historyDialogues,
        ];
    }

    addFavWordPack(favWordPack) {
        this.setFavWordPacks([...this.favWordPacks, favWordPack]);
    }

    removeFavWordPack({ id }) {
        if (id === UserWordsCategory) return;

        const removedPack = this.favWordPacks.find((pObj) => pObj.id === id);

        if (removedPack) {
            removedPack.words.forEach(({ word }) =>
                this.removeActiveWord(word)
            );
            this.favWordPacks = this.favWordPacks.filter(
                (pObj) => pObj.id !== id
            );
            ProgressService.deleteWordsCategory({ id });
        }
    }

    async removeActiveWord(word) {
        const wObj = this.favWords.find((w) => w.word === word);
        if (!wObj || !wObj.wordId) return;

        await ProgressService.updateWord({
            id: wObj.wordId,
            status: WordPacksStatuses.Learned,
        });
        this.removeFavWord(wObj);
    }

    async togglePackWord({ id, word, lang }) {
        const pack = this.favWordPacks.find((p) => p.id === id);
        if (!pack) return;

        const isWordInPack = pack.words.some((w) => w.word === word);

        if (isWordInPack) {
            this.removePackWord({ id, word });
            await ProgressService.deleteCategoryWord({
                categoryId: id,
                word,
            });
        } else {
            this.addPackWord({ id, word });
            await ProgressService.addCategoryWord({
                categoryId: id,
                word,
                lang,
            });
        }
    }

    addPackWord({ id, word }) {
        this.favWordPacks = this.favWordPacks.map((pObj) =>
            pObj.id === id
                ? {
                      ...pObj,
                      words: [...pObj.words, { word, categoryId: id }],
                      wordsCount: pObj.wordsCount + 1,
                  }
                : pObj
        );
    }

    removePackWord({ id, word }) {
        this.favWordPacks = this.favWordPacks.map((pObj) =>
            pObj.id === id
                ? {
                      ...pObj,
                      words: pObj.words.filter((wObj) => wObj.word !== word),
                      wordsCount: pObj.words.filter(
                          (wObj) => wObj.word !== word
                      ).length,
                  }
                : pObj
        );
    }

    addFavExercise(favExercise) {
        this.historyExercises = this.historyExercises.filter(
            (eObj) => eObj.id !== favExercise.id
        );
        this.favExercises = [favExercise, ...this.favExercises];
    }

    removeFavExercise(exerciseId) {
        const exercise = this.favExercises.find(
            (eObj) => eObj.id === exerciseId
        );
        if (!exercise) return;

        this.historyExercises = [exercise, ...this.historyExercises];
        this.favExercises = this.favExercises.filter(
            (eObj) => eObj.id !== exerciseId
        );
    }

    addFavWord(favWord) {
        this.historyWords = this.historyWords.filter(
            (wObj) => wObj.wordId !== favWord.wordId
        );
        if (!this.favWords.some((w) => w.wordId === favWord.wordId)) {
            this.favWords = [
                { ...favWord, lastIteration: 0, status: WordStatuses.Active },
                ...this.favWords,
            ];
        }
    }

    removeFavWord(favWord) {
        this.historyWords = [favWord, ...this.historyWords];
        this.favWords = this.favWords.filter(
            (wObj) => wObj.wordId !== favWord.wordId
        );
    }

    deleteWord(favWord) {
        this.historyWords = this.historyWords.filter(
            (wObj) => wObj.wordId !== favWord.wordId
        );
        this.favWords = this.favWords.filter(
            (wObj) => wObj.wordId !== favWord.wordId
        );
    }

    getDialogueProgress(id) {
        const dialogue = [
            ...this.favDialogues,
            ...this.historyDialogues,
            ...this.libraryDialogues,
        ].find((dObj) => dObj.situationId === id);
        return {
            listen: dialogue?.listeningCompleted,
            exercise: dialogue?.puzzleCompleted,
            speak: dialogue?.speakingCompleted,
            translation: dialogue?.translationCompleted,
        };
    }

    async updateDialogueProgress({ id, lang, favourite, progress, isLibrary }) {
        if (!progress) return;

        const mappedProgress = {
            listeningCompleted: progress.listen,
            puzzleCompleted: progress.exercise,
            speakingCompleted: progress.speak,
            translationCompleted: progress.translation,
        };
        this.setDialogueProgress(id, mappedProgress, isLibrary);
        try {
            await ProgressService.sendSituationProgress({
                situationId: id,
                language: lang,
                favourite,
                ...mappedProgress,
            });
        } catch (e) {
            console.error('Error while saving dialogue progress');
        }
    }

    setDialogueProgress = action((id, mappedProgress, isLibrary) => {
        if (isLibrary) {
            const found = this.libraryDialogues.some(
                (dObj) => dObj.situationId === id
            );
            if (found) {
                this.libraryDialogues = this.libraryDialogues.map((dObj) =>
                    dObj.situationId === id
                        ? { ...dObj, ...mappedProgress }
                        : dObj
                );
            } else {
                this.libraryDialogues = [
                    ...this.libraryDialogues,
                    { situationId: id, ...mappedProgress },
                ];
            }
        } else {
            this.favDialogues = this.favDialogues.map((dObj) =>
                dObj.situationId === id ? { ...dObj, ...mappedProgress } : dObj
            );
        }
    });

    getExerciseProgress(id) {
        const exercise = [...this.favExercises, ...this.historyExercises].find(
            (eObj) => eObj.id === id
        );
        return exercise?.data || {};
    }

    getExerciseTeacherComment(id) {
        const exercise = [...this.favExercises, ...this.historyExercises].find(
            (eObj) => eObj.id === id
        );
        return exercise?.comment || '';
    }

    async updateExerciseProgress({ exerciseId, data }) {
        try {
            this.setExerciseProgress(exerciseId, data);
            await ProgressService.sendExerciseAnswers({ exerciseId, data });
        } catch (e) {
            console.error('Error while saving exercise progress');
        }
    }

    setExerciseProgress = action((id, data) => {
        this.favExercises = this.favExercises.map((eObj) =>
            eObj.id === id ? { ...eObj, data } : eObj
        );
    });

    getVideoProgress(id) {
        return (
            [...this.favVideos, ...this.libraryVideos].find((v) => v.id === id)
                ?.progress || 0
        );
    }

    setClickedWords(clickedWords) {
        this.clickedWords = clickedWords;
    }

    addClickedWord(word) {
        const existingWord = this.clickedWords.find((w) => w.word === word);

        if (!existingWord) {
            this.setClickedWords([
                ...this.clickedWords,
                { word, active: true },
            ]);
        }
    }

    toggleClickedWord(word) {
        const updatedWords = this.clickedWords.map((w) =>
            w.word === word ? { ...w, active: !w.active } : w
        );

        this.setClickedWords(updatedWords);
    }

    setChapterCloseModal(chapterCloseModal) {
        this.chapterCloseModal = chapterCloseModal;
    }

    setPendingEvent(event) {
        this.pendingEvent = event;
    }

    setProcessedWords(processedWords) {
        this.processedWords = processedWords;
        localStorage.setItem(ProcessedWordsKey, JSON.stringify(processedWords));
    }

    getProcessedWords() {
        const savedData = localStorage.getItem(ProcessedWordsKey);
        const processedWords = savedData ? JSON.parse(savedData) : [];
        this.setProcessedWords(processedWords);
    }

    addWord({ word, categoryId, status, targetLang }) {
        if (!word) return;

        const newProcessedWords = [
            ...this.processedWords.filter(
                (w) => !(w.word === word && w.categoryId === categoryId)
            ),
            { word, categoryId, status, targetLang },
        ];
        this.setProcessedWords(newProcessedWords);
    }

    deletePendingWord({ word, categoryId }) {
        const newProcessedWords = this.processedWords.filter(
            (w) => !(w.word === word && w.categoryId === categoryId)
        );
        this.setProcessedWords(newProcessedWords);
    }

    updatePendingWord({ word, categoryId, status }) {
        this.setProcessedWords(
            this.processedWords.map((w) =>
                w.word === word && w.categoryId === categoryId
                    ? { ...w, status }
                    : w
            )
        );
    }

    async sendAddRequest({ word, targetLang }) {
        await DictionaryService.addWord({
            word,
            sourceLang: this.lang,
            targetLang: targetLang
                ? targetLang
                : this.lang === 'en'
                  ? 'de'
                  : 'en',
        });
    }

    async keepWord(wordObj) {
        const { word, originalForm, wordId, categoryId, targetLang } = wordObj;
        await ProgressService.addFavoriteWord({
            id: wordId,
            word,
            lang: this.lang,
        });

        if (categoryId !== UserWordsCategory) {
            try {
                await ProgressService.addCategoryWord({
                    categoryId,
                    word,
                    lang: this.lang,
                });
            } catch (e) {
                console.error(e);
            }
        }
        await DictionaryService.translateWord({
            word,
            sourceLang: this.lang,
            targetLang: targetLang
                ? targetLang
                : this.lang === 'en'
                  ? 'de'
                  : 'en',
        });

        const { data } = await this.getCachedWordByText({
            word,
            sourceLang: this.lang,
            targetLang,
        });

        this.deletePendingWord({
            word: originalForm,
            categoryId,
        });

        if (!data.errorCode) {
            this.addFavWord({
                wordId: wordId,
                lang: this.lang,
                word,
                status: 'active',
                lastIteration: 0,
                learnedAt: null,
            });

            const mappedWordData = { ...data, wordId: data.data[0].id };
            this.addWordData(mappedWordData);
        }
    }

    async processWords() {
        const pendingWords = this.processedWords.filter(
            (w) => ![WordProcessStatuses.Invalid].includes(w.status)
        );

        if (!pendingWords?.length) return;

        const plainActiveWords = pendingWords.map((w) => w.word);

        await Promise.all(
            plainActiveWords.map(async (word) => {
                await DictionaryService.addWord({
                    word,
                    sourceLang: this.lang,
                    targetLang: pendingWords[0].targetLang,
                });
            })
        );

        await Promise.all(
            plainActiveWords.map(async (word) => {
                await DictionaryService.translateWord({
                    word,
                    sourceLang: this.lang,
                    targetLang: pendingWords[0].targetLang,
                });
            })
        );

        const results = await Promise.all(
            plainActiveWords.map(async (word) => {
                const { data, errorCode } = await this.getCachedWordByText({
                    word,
                    sourceLang: this.lang,
                    targetLang: pendingWords[0].targetLang,
                });
                return { ...data, word, errorCode };
            })
        );

        const validResults = results.filter((w) => w && w.data);
        const invalidResults = results.filter((w) =>
            [
                DictionaryCodes.InvalidWord,
                DictionaryCodes.TranslationError,
            ].includes(w.errorCode)
        );

        const uniqueWords = validResults.filter(
            (w, index, self) =>
                self.findIndex((item) => item.word === w.word) === index
        );

        const pendingWordsMap = new Map(
            pendingWords.map((wordObj) => [wordObj.word, wordObj])
        );

        for (const wObj of invalidResults) {
            const word = wObj.word;
            const matchingWord = pendingWordsMap.get(word);

            if (matchingWord) {
                const { categoryId } = matchingWord;
                this.deletePendingWord({ word, categoryId });
            }
        }

        if (!validResults.length) return;

        const mappedReadyWords = [];

        uniqueWords.forEach((wordData) => {
            const { word } = wordData;
            const wordId = wordData.data[0].id;
            const forms = wordData.data.flatMap((d) => d.forms);

            const matchingOriginalForm = forms.find((form) =>
                pendingWordsMap.has(form)
            );

            if (matchingOriginalForm) {
                const { targetLang, categoryId } =
                    pendingWordsMap.get(matchingOriginalForm);

                mappedReadyWords.push({
                    word,
                    wordId,
                    originalForm: matchingOriginalForm,
                    categoryId,
                    targetLang,
                });
            }
        });

        if (mappedReadyWords.length) {
            for (const word of mappedReadyWords) {
                this.keepWord(word);
                await delay(100);
            }
        }
    }

    startProcessingWords() {
        clearInterval(this.intervalRef);
        this.processWords();
        this.intervalRef = setInterval(() => {
            this.processWords();
        }, CheckWordStatusInterval);
    }

    stopProcessingWords() {
        clearInterval(this.intervalRef);
    }

    async getCachedWordByText({ word, sourceLang, targetLang }) {
        const cacheKey = `${word}-${sourceLang}-${targetLang}`;

        if (this.cachedWords.has(cacheKey)) {
            return { data: this.cachedWords.get(cacheKey) };
        }

        const res = await DictionaryService.getWordByText({
            word,
            sourceLang,
            targetLang,
        });

        if (res.data) {
            this.cachedWords.set(cacheKey, res.data);
            this.saveToLocalStorage();
        }

        return { data: res.data, word, errorCode: res.errorCode || null };
    }

    saveToLocalStorage() {
        const cacheObject = Object.fromEntries(this.cachedWords);
        localStorage.setItem(WordsCacheStorageKey, JSON.stringify(cacheObject));
    }

    loadFromLocalStorage() {
        const storedCache = localStorage.getItem(WordsCacheStorageKey);
        if (storedCache) {
            this.cachedWords = new Map(Object.entries(JSON.parse(storedCache)));
        }
    }

    setWordsData(wordsData) {
        this.wordsData = wordsData;
    }

    addWordData(wordData) {
        this.wordsData = [...this.wordsData, wordData];
    }

    async updateVideoProgress({ trackId, progress, isLibrary }) {
        await ProgressService.sendTrackProgress({
            trackId,
            progress,
            language: this.lang,
            rating: null,
        });

        if (isLibrary) {
            const found = this.libraryVideos.some(
                (vObj) => vObj.id === trackId
            );
            if (found) {
                this.libraryVideos = this.libraryVideos.map((vObj) =>
                    vObj.id === trackId ? { ...vObj, progress } : vObj
                );
            } else {
                this.libraryVideos = [
                    ...this.libraryVideos,
                    { id: trackId, progress },
                ];
            }
        } else {
            this.setFavVideoProgress(
                this.favVideos.map((vObj) =>
                    vObj.id === trackId ? { ...vObj, progress } : vObj
                )
            );
        }
    }
}

const userStore = new UserStore();
export default userStore;
