import React, { useEffect, useState } from 'react';
import shuffleArray from '../../../../utils/shuffleArray';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './WordsOrderPreview.module.css';

const WordsOrderPreview = ({
    exerciseObj,
    results,
    showAnswers,
    isPdf = false,
    isPreview = false,
}) => {
    const { data } = exerciseObj;
    const { sentences } = data;

    const [formatedSentences, setFormatedSenteces] = useState([]);
    const [variants, setVariants] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isCheck, setIsCheck] = useState(false);

    const handleAnswerClick = (sentenceIdx, answer) => {
        const newAnswers = [...answers];
        const pos = newAnswers[sentenceIdx].findIndex((w) => !w.pos);
        newAnswers[sentenceIdx][pos] = answer;
        setAnswers(newAnswers);
        const newVariants = [...variants];
        newVariants[sentenceIdx].words = newVariants[sentenceIdx].words.filter(
            (w) => w.pos !== answer.pos
        );
        setVariants(newVariants);
    };

    const handleAnswerRemove = (sentenceIdx, answer) => {
        if (!answer.pos) return;
        const newAnswers = [...answers];
        const pos = newAnswers[sentenceIdx].findIndex(
            (w) => w.pos === answer.pos
        );
        newAnswers[sentenceIdx][pos] = '';
        setAnswers(newAnswers);
        const newVariants = [...variants];
        newVariants[sentenceIdx].words = [
            answer,
            ...newVariants[sentenceIdx].words,
        ];
        setVariants(newVariants);
    };

    const displayAnswers = () => {
        setAnswers(formatedSentences.map((s) => s.words));
        setVariants(variants.map((v) => ({ ...v, words: [] })));
    };

    const resetAnswers = () => {
        setAnswers(
            new Array(formatedSentences.length)
                .fill('')
                .map((_a, i) =>
                    new Array(formatedSentences[i].words?.length).fill('')
                )
        );
        setVariants(
            formatedSentences.map((s) => ({
                ...s,
                words: shuffleArray(s?.words ?? []),
            }))
        );
    };

    useEffect(() => {
        showAnswers ? displayAnswers() : resetAnswers();
        setIsCheck(showAnswers);
    }, [showAnswers]);

    useEffect(() => {
        if (!sentences || !sentences.length) return;
        const formatedSentences = sentences.map((s) => ({
            id: s.id,
            words: s.text
                ?.split(' ')
                .filter((w) => w !== ' ' && w.length)
                .map((w, i) => ({ text: w, pos: i + 1 })),
        }));
        setFormatedSenteces(formatedSentences);
        if (!results) {
            setAnswers(
                new Array(formatedSentences.length)
                    .fill('')
                    .map((_a, i) =>
                        new Array(formatedSentences[i].words?.length).fill('')
                    )
            );
            setVariants(
                formatedSentences.map((s) => ({
                    ...s,
                    words: shuffleArray(s?.words ?? []),
                }))
            );
        } else {
            setVariants(formatedSentences.map((s) => ({ ...s, words: [] })));
            setAnswers(
                results.filledSentences?.map((o) =>
                    o.map((s, i) => ({ text: s, pos: i }))
                ) || []
            );
        }
    }, [sentences, results]);

    const getIsCorrect = (sIdx, aIdx, answer) => {
        if (!answer || !isCheck) return '';
        return formatedSentences[sIdx].words[aIdx].text === answer.text
            ? cl.correct
            : cl.wrong;
    };

    const getLabel = () => {
        if (results) return 'student_answers';
        if (isPdf) return 'words_order_pdf_info';
        if (isPreview) return 'words_order_preview_info';
        return 'statements';
    };

    return (
        <>
            <QuestionsLabel
                label={getLabel()}
                variant={isPreview ? 'demo' : ''}
            />
            <div className={cl.sentencesCont}>
                {variants?.map((s, idx) => (
                    <div className={cl.sentence} key={idx}>
                        <div className={cl.text}>
                            {isPdf ? (
                                <span className={cl.pdfGap} />
                            ) : (
                                answers[idx]?.map((w, i) => (
                                    <span
                                        className={`${cl.answer} ${getIsCorrect(idx, i, w)}`}
                                        key={`${w}${i}`}
                                        onClick={
                                            results
                                                ? () => {}
                                                : () =>
                                                      handleAnswerRemove(idx, w)
                                        }
                                    >
                                        {w.text}
                                        {w.endChar}
                                    </span>
                                ))
                            )}
                        </div>
                        {s.words.length !== 0 && (
                            <div
                                className={`${cl.variantsCont} ${isPdf ? cl.pdf : ''}`}
                            >
                                {s.words.map((w, i) => (
                                    <span
                                        className={cl.variant}
                                        key={`${w}${i}`}
                                        onClick={() =>
                                            handleAnswerClick(idx, w)
                                        }
                                    >
                                        {w.text}
                                        {w.endChar}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default WordsOrderPreview;
