import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { AdjustableTextarea } from '../../../../UI';
import cl from './UserFreeFormQuestionsPreview.module.css';

const UserFreeFormQuestionsPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { t } = useTranslation();
    const { questions } = exerciseObj?.data;

    const userAnswers =
        results?.userAnswers ||
        questions?.reduce((acc, item) => {
            acc[item.id] = '';
            return acc;
        }, {});

    const enteredText = results?.enteredText || '';

    const handleAnswerChange = (questionid, text) => {
        setResults({ userAnswers: { ...userAnswers, [questionid]: text } });
    };

    const handleTextChange = (text) => {
        setResults({ enteredText: text });
    };

    const renderQuestions = () => {
        return questions?.map((q, i) => {
            const hasNumeration = /^\d+\.\s/.test(q.question);
            const displayedQuestion = hasNumeration
                ? q.question
                : `${i + 1}. ${q.question}`;

            return (
                <Fragment key={q.id}>
                    <p className={cl.question} key={q.id}>
                        {displayedQuestion}
                    </p>
                    {isCompleted ? (
                        <p className={cl.userText}>{userAnswers[q.id]}</p>
                    ) : (
                        <AdjustableTextarea
                            className={cl.answer}
                            placeholder={t('exercises.answer')}
                            value={userAnswers[q.id]}
                            onChange={(text) => handleAnswerChange(q.id, text)}
                        />
                    )}
                </Fragment>
            );
        });
    };

    if (!questions)
        return isCompleted ? (
            <p className={cl.userText}>{enteredText}</p>
        ) : (
            <AdjustableTextarea
                className={cl.textAnswer}
                placeholder={t('exercises.type_your_text')}
                value={enteredText}
                onChange={handleTextChange}
            />
        );

    return <div className={cl.questions}>{renderQuestions()}</div>;
};

export default UserFreeFormQuestionsPreview;
