import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import wizardStore from '../../../../../../features/LessonsKanban/components/LessonWizard/store/wizardStore';
import ProgressService from '../../../../../../api/ProgressService';
import ExerciseService from '../../../../../../api/ExerciseService';
import { DefaultWordsParams, WordsRange } from '../../data/constants';
import {
    Button,
    FilledRange,
    Input,
    Label,
    Select,
    ToggleButton,
    Tree,
} from '../../../../../../UI';
import { LanguageLevels } from '../../../../../../data/common';
import { GenerateChapterModal } from '../../../../components';
import {
    Chapters,
    PartsOfSpeech,
} from '../../../../../../features/LessonsKanban/data/constants';
import { AvailableLanguageLevels } from '../../../../../../features/LessonsKanban/components/LessonWizard/components/ExerciseWizard/ExerciseWizard';
import cl from './GenerateWordsCategoryModal.module.css';

const GenerateWordsCategoryModal = ({ lang, visible, setVisible, onAdd }) => {
    const { t } = useTranslation();

    const { vocabularyOptions } = wizardStore;

    const [isLastMoment, setLastMoment] = useState(false);

    const [filteredVocabulary, setFilteredVocabulary] = useState([]);
    const [wordsParams, setWordsParams] = useState(DefaultWordsParams);
    const [formErrors, setFormErrors] = useState({});

    const { level, topic } = wordsParams;

    const [generateWords, generateLoading, generateError, resetGenerateError] =
        useFetching(async () => {
            setLastMoment(false);
            const { topic, level, title, wordsAmount, partOfSpeech } =
                wordsParams;
            const { data } = await ExerciseService.generateWords({
                topic,
                difficulty: LanguageLevels.indexOf(level),
                lang,
                wordsAmount,
                partOfSpeech,
            });
            const { words } = data;
            setLastMoment(false);
            createCategory({ words, title, level });
        });

    const [createCategory, createLoading] = useFetching(
        async ({ words, title, level }) => {
            setLastMoment(false);
            const { data } = await ProgressService.createWordsCategory({
                title,
                lang,
                level: LanguageLevels.indexOf(level),
            });
            setVisible(false);
            setLastMoment(false);
            onAdd(data, words);
        }
    );

    const setWordsCount = (e) => {
        setWordsParams({ ...wordsParams, wordsAmount: e.target.value });
    };

    const handleWordsGenerate = () => {
        if (createLoading || generateLoading) return;
        resetErrors();

        setTimeout(() => {
            const errors = {
                topic: !wordsParams.topic,
                title: !wordsParams.title,
                level: !wordsParams.level,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            generateWords();
        });
    };

    const resetErrors = () => {
        resetGenerateError();
        setFormErrors({});
    };

    useEffect(() => {
        if (!level) return;
        const difficulty = LanguageLevels.indexOf(level);

        const currentVocabulary = vocabularyOptions
            .map((g) => ({
                ...g,
                topics: g.topics.filter(
                    (tObj) => tObj.difficulty === difficulty
                ),
            }))
            .filter((g) => g.topics?.length);
        setFilteredVocabulary(currentVocabulary);
    }, [vocabularyOptions, level]);

    useEffect(() => {
        setWordsParams(DefaultWordsParams);
        setFormErrors({});
    }, [visible]);

    useEffect(() => {
        setFormErrors({});
    }, [wordsParams]);

    useEffect(() => {
        setWordsParams({ ...wordsParams, title: topic });
    }, [topic]);

    const getExerciseProgressDuration = () => {
        if (!(generateLoading || createLoading)) return null;

        return Math.min(Math.max(wordsParams.wordsAmount * 200, 3000), 15000);
    };

    const getButtonText = () => {
        if (generateLoading || createLoading)
            return isLastMoment
                ? 'exercises.generating_last_moment'
                : 'exercises.word_pack_is_generating';

        return 'exercises.add_new_words';
    };

    useEffect(() => {
        const progressDuration = getExerciseProgressDuration();
        if (progressDuration !== null) {
            const timeout = setTimeout(() => {
                setLastMoment(true);
            }, progressDuration - 500);

            return () => clearTimeout(timeout);
        }
    }, [createLoading, generateLoading]);

    return (
        <GenerateChapterModal
            visible={visible}
            setVisible={setVisible}
            title={t('lesson_wizard.words_generate_title')}
            variant={Chapters.Dictionary}
        >
            <div>
                <Label
                    text={t('situations.level')}
                    isError={formErrors['level']}
                />
                <ToggleButton
                    style={{ width: 212 }}
                    label={t('users.level')}
                    options={LanguageLevels}
                    availableOptions={AvailableLanguageLevels}
                    disabled
                    value={wordsParams.level}
                    variant={formErrors['level'] ? 'error' : 'outlined'}
                    onChange={(level) =>
                        setWordsParams({ ...wordsParams, level })
                    }
                />
            </div>
            <div>
                <Label
                    text={t('exercises.words_category_title')}
                    isError={formErrors['title']}
                />
                <Input
                    variant={
                        formErrors['title'] ? 'erroredSmall' : 'outlinedSmall'
                    }
                    placeholder={t(
                        'exercises.words_category_title_placeholder'
                    )}
                    value={wordsParams.title}
                    onChange={(title) =>
                        setWordsParams({ ...wordsParams, title })
                    }
                />
            </div>
            <div>
                <Label
                    isError={formErrors['topic']}
                    text={t('lesson_wizard.select_pack_lexical_topic')}
                />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    variant={formErrors['topic'] ? 'errorSmall' : 'grey'}
                    value={wordsParams.topic}
                    onChange={(topic) =>
                        setWordsParams({ ...wordsParams, topic })
                    }
                    options={filteredVocabulary}
                    hideReset
                    ignoreOverflow
                    height={240}
                    disabled={level === ''}
                    tooltip={t('lesson_wizard.grammar_topic_select_level')}
                />
            </div>
            <div className={cl.flexContainer}>
                <div>
                    <Label
                        text={t('dictionary_administration.part_of_speech')}
                    />
                    <Select
                        className={cl.wideComponent}
                        variant={'grey'}
                        value={wordsParams.partOfSpeech}
                        options={Object.values(PartsOfSpeech).map((value) => ({
                            value,
                            name: t(`parts_of_speech.${value}`),
                        }))}
                        onChange={(partOfSpeech) =>
                            setWordsParams({ ...wordsParams, partOfSpeech })
                        }
                        height={180}
                        ignoreOverflow
                        hideReset
                    />
                </div>
                <div>
                    <Label text={t('exercises.words_amount')} />
                    <FilledRange
                        color={`var(--words-green)`}
                        value={wordsParams.wordsAmount}
                        min={WordsRange.Min}
                        step={WordsRange.Step}
                        max={WordsRange.Max}
                        onChange={setWordsCount}
                    />
                </div>
            </div>

            {generateError && (
                <p className={cl.error}>{t('errors.unexpected_error')}</p>
            )}
            <Button
                className={cl.generateButton}
                text={t(getButtonText())}
                variant={Chapters.Dictionary}
                onClick={handleWordsGenerate}
                progressDuration={getExerciseProgressDuration()}
            />
        </GenerateChapterModal>
    );
};

export default GenerateWordsCategoryModal;
