import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../hooks/useFetching';
import TrackService from '../../../../api/TrackService';
import exercisesStore from '../../store/exercisesStore';
import languagesStore from '../../../../store/interface';
import {
    onboardingStore,
    OnboardingKeys,
} from '../../../../features/Onboarding';
import Player from '../../../Player/Player';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import {
    AddChapterModal,
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersScrollableList,
} from '..';
import { Checkbox, Input, Select, ToggleButton, Toolbar } from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import { FilterModes, TrackPaginationLimit } from '../../data/constants';
import { splitText } from '../../../DictionaryAdministration/helpers/splitText';
import cl from './SelectVideoModal.module.css';

const SelectVideoModal = ({
    visible,
    setVisible,
    onClick,
    isOwn,
    withCategorySelector,
    alreadyAdded = [],
    presetDifficulty = null,
    vocabulary = [],
}) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const timeoutRef = useRef(null);

    const isOnboarding = onboardingStore.isOnboarding(
        OnboardingKeys.VideosAwaitSelect
    );

    const { difficulty: globalDifficulty, categoryOptions } = exercisesStore;

    const scrollRef = useRef(0);

    const [currentTrack, setCurrentTrack] = useState();
    const [mode, setMode] = useState();
    const [page, setPage] = useState(0);
    const [tracks, setTracks] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [difficulty, setDifficulty] = useState(
        presetDifficulty || globalDifficulty
    );
    const [paginationEnd, setPaginationEnd] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [category, setCategory] = useState();
    const [vocabularyString, setVocabularyString] = useState(
        vocabulary.join(' ')
    );

    const [getTracks, isLoading] = useFetching(async () => {
        if (!visible || typeof mode !== 'string') return;
        const vocabulary = splitText(vocabularyString);

        const params = {
            offset: page * TrackPaginationLimit,
            limit: TrackPaginationLimit,
            lang,
            difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
            processed: processed ? processed : null,
            searchString,
            recognized: true,
            categories: category ? [category] : null,
            vocabulary,
        };

        const { data } =
            mode === FilterModes.All
                ? await TrackService.getTracks(params)
                : await TrackService.getFavorites(params);
        handleTracksResponse(data);
    }, true);

    const handleTracksResponse = (data) => {
        const { items } = data;
        if (!items.length) setPaginationEnd(true);
        const newTracks = (page > 0 ? [...tracks, ...items] : items).filter(
            (t, index, self) =>
                !self.slice(0, index).some((item) => item?.id === t?.id) &&
                (difficulty
                    ? t.difficulty === LanguageLevels.indexOf(difficulty)
                    : true)
        );

        const withoutExisting = newTracks.filter(
            (e) => !alreadyAdded.some((a) => a?.id === e?.id)
        );
        setTracks(withoutExisting);
        setCurrentTrack(withoutExisting[0]);
    };

    const handleTrackListScroll = (e) => {
        const list = e.target;
        if (paginationEnd || !list) return;

        if (!isLoading && list.scrollTop > scrollRef.current) {
            const halfwayPoint = list.scrollHeight / 2;

            if (list.scrollTop + list.clientHeight >= halfwayPoint) {
                setPage(page + 1);
            }
        }

        scrollRef.current = list.scrollTop;
    };

    const handleTrackSelect = () => {
        if (!currentTrack) return;
        const { id, title } = currentTrack;
        onClick({ id, title });
        setVisible(false);
        if (isOnboarding) {
            onboardingStore.moveToNextComponent();
        }
    };

    const handleClose = (flag) => {
        if (isOnboarding) return;
        setVisible(flag);
    };

    useEffect(() => {
        setMode(isOwn ? FilterModes.Teacher : FilterModes.All);
    }, [isOwn]);

    useEffect(() => {
        getTracks();
    }, [page, visible]);

    useEffect(() => {
        if (!visible) setTracks([]);
    }, [visible]);

    useEffect(() => {
        if (!mode) return;
        setPage(0);
        setPaginationEnd(false);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setTracks([]);
            getTracks();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [difficulty, lang, searchString, processed, mode, category, visible]);

    useEffect(() => {
        setDifficulty(presetDifficulty || globalDifficulty);
        setSearchString('');
        setVocabularyString(vocabulary.join(' '));
    }, [visible]);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            getTracks();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [vocabularyString, mode]);

    return (
        <AddChapterModal
            visible={visible}
            isOwn={isOwn}
            setVisible={handleClose}
            type={Chapters.Track}
        >
            <Toolbar>
                <ToggleButton
                    value={difficulty}
                    onChange={setDifficulty}
                    options={LanguageLevels}
                    nullable
                />

                {withCategorySelector && (
                    <ToggleButton
                        value={mode}
                        onChange={setMode}
                        options={Object.values(FilterModes)}
                        disabled
                        withTranslations={'exercises'}
                    />
                )}
                <Select
                    label={t('tags_cont.choose_category')}
                    style={{ minWidth: 220 }}
                    variant={'white'}
                    value={category}
                    onChange={setCategory}
                    options={categoryOptions}
                />
                <Checkbox
                    value={processed}
                    onChange={setProcessed}
                    label={t('exercises.processed_tracks')}
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('exercises.search_by_name')}
                    value={searchString}
                    onChange={setSearchString}
                    search
                    showReset
                />
                <Input
                    variant={'outlinedSmall'}
                    placeholder={t('exercises.search_by_content')}
                    value={vocabularyString}
                    onChange={setVocabularyString}
                    search
                    showReset
                />
            </Toolbar>
            <div className={cl.tracksContainer}>
                <ChaptersScrollableList
                    type={Chapters.Track}
                    isLoading={isLoading}
                    chapters={tracks}
                    current={currentTrack}
                    setCurrent={setCurrentTrack}
                    onScroll={handleTrackListScroll}
                    onSelect={handleTrackSelect}
                />
                {visible && currentTrack ? (
                    <ChapterPreviewContainer>
                        <Player track={currentTrack} />
                    </ChapterPreviewContainer>
                ) : (
                    <ChapterPlug />
                )}
            </div>
        </AddChapterModal>
    );
};

export default SelectVideoModal;
