import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router-dom';
import exercisesStore from '../../../../store/exercisesStore';
import { authStore } from '../../../../../../App';
import ExerciseService from '../../../../../../api/ExerciseService';
import TrackService from '../../../../../../api/TrackService';
import { useFetching } from '../../../../../../hooks/useFetching';
import {
    AdvancedQuizPreview,
    ClozePreview,
    FreeFormQuestionsPreview,
    MultichoicePreview,
    QuestionsLabel,
    QuizPreview,
    SentenceOrderPreview,
    SubstitutionPreview,
    SummaryPreview,
    WordsOrderPreview,
} from '../../../../../../features/ExercisePreview/components';
import { Button, Input, Label, Loader } from '../../../../../../UI';
import {
    BaseUrl,
    ExerciseHyperlinkModes,
    Roles,
} from '../../../../../../data/common';
import {
    ExerciseMediaTypes,
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../data/constants';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import { ReactComponent as TeacherLogo } from '../../../../../../assets/svg/lessons-logo.svg';
import { ReactComponent as TeacherLogoText } from '../../../../../../assets/svg/lessons-logo-text.svg';
import { ReactComponent as IconPDF } from '../../../../../../assets/svg/icon-pdf.svg';
import { ReactComponent as IconAudio } from '../../../../../../assets/svg/exercise-media-audio.svg';
import { ReactComponent as IconVideo } from '../../../../../../assets/svg/exercise-media-video.svg';
import { ReactComponent as IconEyes } from '../../../../../../assets/svg/lessons-eyes.svg';
import cl from './ExerciseHyperlink.module.css';

const ExerciseHyperlink = () => {
    const { t } = useTranslation();

    const { role } = authStore;

    const { id } = useParams();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');

    const isPdf = mode === ExerciseHyperlinkModes.Pdf;
    const isPreview = mode === ExerciseHyperlinkModes.Preview;

    const [formErrors, setFormErrors] = useState({});
    const [showAnswers, setShowAnswers] = useState(false);
    const [studentName, setStudentName] = useState('');
    const [exerciseObj, setExerciseObj] = useState({});
    const [mediaUrl, setMediaUrl] = useState('');

    const [getExerciseData, dataLoading] = useFetching(async () => {
        if (!id) return;
        const { data } = await ExerciseService.getExercise({
            id,
        });
        if (data.mediaType === ExerciseMediaTypes.Video) {
            await getTrack(data.trackId);
        }
        if (data.mediaType === ExerciseMediaTypes.Audio) {
            setMediaUrl(`${BaseUrl}/filedump/exercises/${id}.mp3`);
        }
        setExerciseObj(data);
    }, true);

    const [getTrack, trackLoading] = useFetching(async (trackId) => {
        const { data } = await TrackService.getTrack(trackId);
        setMediaUrl(data.url);
    });

    const getMediaPreview = () => {
        if (exerciseObj.mediaType === ExerciseMediaTypes.Video) {
            return (
                <a href={mediaUrl} className={cl.mediaButton}>
                    <IconVideo className={cl.mediaIcon} />
                    {t('exercises.watch_yt_video')}
                </a>
            );
        }
        if (exerciseObj.mediaType === ExerciseMediaTypes.Audio) {
            return (
                <a href={mediaUrl} className={cl.mediaButton}>
                    <IconAudio className={cl.mediaIcon} />
                    {t('exercises.listen_audio')}
                </a>
            );
        }
        if (exerciseObj.text) {
            return (
                <div>
                    <QuestionsLabel label={'gap_word_select'} />
                    <div className={cl.exerciseText}>{exerciseObj.text}</div>
                </div>
            );
        }

        return null;
    };

    const getPreview = () => {
        switch (exerciseObj.type) {
            case GrammarExerciseTypes.Multichoice:
                return (
                    <MultichoicePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case GrammarExerciseTypes.Substitution:
                return (
                    <SubstitutionPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case GrammarExerciseTypes.Cloze:
                return (
                    <ClozePreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.Quiz:
                return (
                    <QuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.SentencesOrder:
                return (
                    <SentenceOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={setShowAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case GrammarExerciseTypes.WordsOrder:
                return (
                    <WordsOrderPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.Summary:
                return (
                    <SummaryPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        setShowAnswers={exercisesStore.setShowAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.AdvancedQuiz:
                return (
                    <AdvancedQuizPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.Presentation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case MediaExerciseTypes.FreeFormQuestions:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showInput={true}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            case GrammarExerciseTypes.StatementsTransformation:
                return (
                    <FreeFormQuestionsPreview
                        exerciseObj={exerciseObj}
                        showAnswers={showAnswers}
                        showInput={true}
                        isPdf={isPdf}
                        isPreview={isPreview}
                    />
                );
            default:
                return <></>;
        }
    };

    const handleHomeClick = () => {
        window.open(BaseUrl, '_blank');
    };

    const handlePdfByStudent = () => {
        setFormErrors({});
        setTimeout(() => {
            const errors = {
                studentName: !studentName,
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;
            window.print();
        });
    };

    useEffect(() => {
        setExerciseObj({});
        if (!id) return;
        setTimeout(() => {
            getExerciseData();
        }, 100);
    }, [id]);

    useEffect(() => {
        if (exerciseObj.title) {
            document.title = exerciseObj.title;
        }
    }, [exerciseObj]);

    useEffect(() => {
        setFormErrors({});
    }, [studentName]);

    // TODO fix guest access @saratovkin
    // if (role !== Roles.Teacher && mode === ExerciseHyperlinkModes.Pdf)
    if (false)
        return (
            <div className={cl.noAccessContainer}>
                <IconEyes />
                <p className={cl.alertTitle}>{t('exercises.no_access')}</p>
                <Button
                    variant={'grey'}
                    text={t('exercises.go_home')}
                    onClick={handleHomeClick}
                />
            </div>
        );

    return (
        <div className={cl.container}>
            <div className={cl.pdfContainer}>
                <header className={cl.header}>
                    <div className={cl.logo}>
                        <TeacherLogo className={cl.teacherLogo} />
                        <TeacherLogoText />
                    </div>
                    {isPdf && (
                        <>
                            <Button
                                icon={<IconPDF className={cl.pdfIcon} />}
                                variant={'burgundy'}
                                className={cl.printButton}
                                text={t('exercises.save_pdf')}
                                onClick={() => {
                                    window.print();
                                }}
                            />
                            <a href={BaseUrl} className={cl.platformButton}>
                                <TeacherLogo className={cl.buttonLogo} />
                                {t('exercises.click_to_open_platform')}
                            </a>
                        </>
                    )}
                    {isPreview && (
                        <>
                            <div className={cl.previewControls}>
                                <Input
                                    className={cl.nameInput}
                                    placeholder={t('exercises.your_name')}
                                    variant={
                                        formErrors.studentName
                                            ? 'erroredSmall'
                                            : 'outlinedSmall'
                                    }
                                    value={studentName}
                                    onChange={setStudentName}
                                />
                                <Button
                                    icon={<IconPDF className={cl.pdfIcon} />}
                                    variant={Chapters.Exercise}
                                    className={cl.printButton}
                                    text={t('exercises.save_pdf_for_teacher')}
                                    onClick={handlePdfByStudent}
                                />
                            </div>
                            <p
                                className={cl.completedByLabel}
                            >{`${t('exercises.completed_by')}: ${studentName}`}</p>
                        </>
                    )}
                </header>
                {dataLoading || trackLoading ? (
                    <Loader style={{ margin: 'auto' }} />
                ) : (
                    <>
                        <div className={cl.exerciseContainer}>
                            <p className={cl.exerciseTitle}>
                                {exerciseObj.title}
                            </p>
                            <p className={cl.exerciseSubtitle}>
                                {exerciseObj.description}
                            </p>
                            <div className={cl.exerciseBody}>
                                {getMediaPreview()}
                                {getPreview()}
                            </div>
                        </div>
                    </>
                )}

                <div className={cl.bottomContainer}>
                    <div className={cl.footer}>
                        <p>©Pony, 2025</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(ExerciseHyperlink);
