import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetching } from '../../hooks/useFetching';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../App';
import player from '../../store/player';
import TrackService from '../../api/TrackService';
import { TextDisplay } from './components';
import PlayerControls from '../../components/PlayerControls/PlayerControls';
import YoutubePlayer from '../../components/YoutubePlayer/YoutubePlayer';
import { PonyPlayer } from '../../features/UserPlatform/components';
import { Button, MicroButton, Loader } from '../../UI';
import { ROUTES } from '../../router';
import { Chapters } from '../../features/LessonsKanban/data/constants';
import { Roles } from '../../data/common';
import { ReactComponent as IconVisible } from '../../assets/svg/visible.svg';
import { ReactComponent as IconHide } from '../../assets/svg/hide.svg';
import cl from './Player.module.css';
import languagesStore from '../../store/interface';

const Player = ({ track, isGuest }) => {
    const { t, i18n } = useTranslation();

    const isUser = authStore.user.role === Roles.User;
    const lang = languagesStore.lang ?? i18n.language;

    const navigate = useNavigate();

    const [isStudentPreview, setIsStudentPreview] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const id = params.get('id') || location.state?.id || track?.id;

    const textRef = useRef(null);
    const timerRef = useRef();

    const [getTrack, isLoading, isError, resetError] = useFetching(async () => {
        if (!id) return;
        const { data } = await TrackService.getTrackSentences({
            id,
            lang,
        });
        player.parseTrackData({ ...data });
    });

    const refresh = () => {
        resetError();
        getTrack();
    };

    const setAutoScrollDelay = () => {
        clearTimeout(timerRef.current);
        window.autoScrollDelay = true;
        timerRef.current = setTimeout(() => {
            window.autoScrollDelay = false;
        }, 4000);
    };

    const handleArrowClick = (e) => {
        const dir =
            e.currentTarget.attributes.getNamedItem('data-direction').value;
        let curSenIndex = 0;
        if (player.currentSentenceObj) {
            player.sentences.forEach((el, i) => {
                if (el.key === player.currentSentenceObj.key) {
                    curSenIndex = i;
                    return;
                }
            });
        }
        if (!curSenIndex && !dir) return;
        const nextIndex = dir === 'prev' ? curSenIndex - 1 : curSenIndex + 1;
        const newSentenceObj = player.sentences[nextIndex];
        if (!newSentenceObj) return;
        clearTimeout(window.falseScrollTimeout);
        window.autoScrollDelay = false;
        player.setCurrentSentenceObj(newSentenceObj);
        player.handleChangeAudioTime(newSentenceObj.offset, true);
    };

    useEffect(() => {
        if (!id && !track) navigate(ROUTES.library);
        refresh();
        player.resetStates();
        player.setExternalId(id);
        setIsStudentPreview(false);
        getTrack();
    }, [id]);

    if (isError) {
        return (
            <div className={cl.trackErrorAlert}>
                <p>{t('library.request_error')}</p>
                <Button
                    text={t('buttons.refresh')}
                    variant={'grey'}
                    onClick={refresh}
                />
            </div>
        );
    }

    if (isStudentPreview)
        return (
            <PonyPlayer
                trackId={id}
                onClose={() => setIsStudentPreview(false)}
            />
        );

    return (
        <>
            {isLoading || !id ? (
                <Loader style={{ margin: 'auto', height: '100%' }} />
            ) : (
                <div
                    className={`${cl.playerContainer} ${track ? '' : cl.ponyPlayer}`}
                    key={id}
                >
                    {track?.title && (
                        <p className={`${cl.title} ${isGuest ? cl.guest : ''}`}>
                            {track.title}
                        </p>
                    )}
                    <div className={cl.microButtons}>
                        {!isGuest && !isUser && (
                            <MicroButton
                                icon={
                                    isStudentPreview ? (
                                        <IconHide className={cl.iconStroke} />
                                    ) : (
                                        <IconVisible
                                            className={cl.iconStroke}
                                        />
                                    )
                                }
                                variant={Chapters.Track}
                                size={'regular'}
                                onClick={() =>
                                    setIsStudentPreview(!isStudentPreview)
                                }
                            />
                        )}
                    </div>
                    <div className={cl.content}>
                        <div className={`${cl.trackContainer}`}>
                            <YoutubePlayer />
                            <PlayerControls onRewind={handleArrowClick} />
                        </div>

                        {track && (
                            <div className={cl.transcriptionTitle}>
                                <div className={cl.line} />
                                <p>
                                    {t(
                                        'dictionary_administration.transcription'
                                    )}
                                </p>
                                <div className={cl.line} />
                            </div>
                        )}

                        <div className={cl.textContainer}>
                            <TextDisplay
                                textRef={textRef}
                                onScroll={setAutoScrollDelay}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default observer(Player);
