import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../../UI';
import { ReactComponent as IconArrow } from '../../../../assets/svg/arrow.svg';
import cl from './OnboardingWrapper.module.css';
import onboardingStore from '../../store/onboarding';

const BorderWidth = 12;

export const Pos = {
    x: {
        Left: 'left',
        Center: 'center',
        Right: 'right',
        LeftBorder: 'leftBorder',
        RightBorder: 'rightBorder',
    },
    y: {
        Top: 'top',
        Center: 'center',
        Bottom: 'bottom',
        TopBorder: 'topBorder',
        BottomBorder: 'bottomBorder',
    },
};

export const ArrowPos = {
    Top: 'top',
    Left: 'left',
    Right: 'right',
    Bottom: 'bottom',
    LeftClose: 'leftClose',
};

const OnboardingWrapper = ({
    children,
    onboardingKey,
    bubblePosition,
    narrow,
    delay,
    title,
    subtitle,
    onConfirm,
    showBlur = true,
    showBubble = true,
    showButton = false,
    arrowPosition = null,
    preventClick,
    bubbleStyle,
    bubbleAutoWidth,
    style,
}) => {
    const { t } = useTranslation();
    const childRef = useRef();
    const [rect, setRect] = useState(null);

    const { isDelay } = onboardingStore;

    const handleOnboardingClose = (e) => {
        if (preventClick) return;
        e.stopPropagation();
        onboardingStore.moveToNextComponent();
        onConfirm && onConfirm();
    };

    const getBubbleStyle = () => {
        const { x, y } = bubblePosition;
        const r = rect;
        const b = BorderWidth;
        const db = BorderWidth * 2;

        const xOffsets = {
            [Pos.x.LeftBorder]: { left: r.left - b, translateX: 0 },
            [Pos.x.Center]: { left: r.left + r.width / 2, translateX: '-50%' },
            [Pos.x.RightBorder]: {
                left: r.left + r.width + b,
                translateX: '-100%',
            },
            [Pos.x.Left]: { left: r.left - db, translateX: '-100%' },
            [Pos.x.Right]: { left: r.left + r.width + db, translateX: 0 },
        };

        const yOffsets = {
            [Pos.y.Top]: { top: r.top - db, translateY: '-100%' },
            [Pos.y.Center]: { top: r.top + r.height / 2, translateY: '-50%' },
            [Pos.y.Bottom]: { top: r.top + r.height + db, translateY: 0 },
            [Pos.y.TopBorder]: { top: r.top, translateY: 0 },
            [Pos.y.BottomBorder]: { top: r.top + r.height, translateY: 0 },
        };

        const { left = 0, translateX = 0 } = xOffsets[x] || {};
        const { top = 0, translateY = 0 } = yOffsets[y] || {};

        return {
            top,
            left,
            transform: `translate(${translateX}, ${translateY})`,
            width: bubbleAutoWidth ? r.width : 'fit-content',
            maxWidth: bubbleAutoWidth ? r.width : 350,
            ...bubbleStyle,
        };
    };

    const getArrowStyle = () => {
        switch (arrowPosition) {
            case ArrowPos.Right:
                return {
                    top: rect.top + rect.height / 2,
                    left: rect.left + rect.width,
                };

            case ArrowPos.Left:
            case ArrowPos.LeftClose:
                return {
                    top: rect.top + rect.height / 2,
                    left: rect.left,
                };

            case ArrowPos.Bottom:
                return {
                    top: rect.top + rect.height,
                    left: rect.left + rect.width / 2,
                };

            case ArrowPos.Top:
                return {
                    top: rect.top,
                    left: rect.left + rect.width / 2,
                };

            default:
                return {
                    display: 'none',
                };
        }
    };

    const handleBubbleClick = (e) => {
        if (showButton || preventClick) return;
        e.stopPropagation();
    };

    useEffect(() => {
        if (delay && onboardingKey) {
            onboardingStore.setIsDelay(true);

            setTimeout(() => {
                onboardingStore.setIsDelay(false);
            }, delay);
        }
    }, [onboardingKey, delay]);

    useEffect(() => {
        if (childRef.current) {
            setRect(childRef.current.getBoundingClientRect());
        }
    }, [children]);

    if (!onboardingKey || isDelay) return children;

    return (
        <>
            <div className={showBlur ? cl.overlay : cl.transparentOverlay} />
            {React.cloneElement(children, { ref: childRef })}
            {rect && (
                <>
                    {React.cloneElement(children, {
                        style: {
                            ...children.props.style,
                            position: 'fixed',
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            zIndex: 20,
                            borderRadius: BorderWidth,
                            minWidth: 'unset',
                            pointerEvents: showButton ? 'none' : 'all',
                            transition: 'none',
                            ...style,
                        },
                        onClick: handleOnboardingClose,
                    })}
                    <div
                        className={cl.border}
                        style={{
                            top: rect.top,
                            left: rect.left,
                            width: rect.width,
                            height: rect.height,
                            borderRadius: BorderWidth,
                        }}
                    />
                    {arrowPosition !== null && (
                        <div
                            className={`${cl.arrowContainer} ${cl[arrowPosition]}`}
                            style={getArrowStyle()}
                        >
                            <IconArrow className={cl.arrow} />
                        </div>
                    )}
                    {showBubble && (
                        <div
                            className={`${cl.messageBubble} ${narrow ? cl.narrow : ''}`}
                            style={getBubbleStyle()}
                            onClick={handleBubbleClick}
                        >
                            {title && <p className={cl.title}>{title}</p>}
                            {subtitle && (
                                <p
                                    className={cl.subtitle}
                                    dangerouslySetInnerHTML={{
                                        __html: subtitle,
                                    }}
                                />
                            )}
                            {showButton && (
                                <Button
                                    style={{ padding: '8px 12px' }}
                                    variant={'purple'}
                                    text={t('teacher_onboarding.close_button')}
                                    onClick={handleOnboardingClose}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default observer(OnboardingWrapper);
