import React from 'react';
import cl from './UserContentContainer.module.css';

const UserContentContainer = ({ className, variant, children }) => {
    return (
        <div
            className={`${cl.contentContainer} ${className ?? ''} ${variant ? cl[variant] : ''}`}
        >
            <div className={cl.aside}>
                {children[0]}
                {children[1]}
            </div>
            <div className={cl.main}>{children[2]}</div>
        </div>
    );
};

export default UserContentContainer;
