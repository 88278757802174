import { makeAutoObservable } from 'mobx';
import { ToastVariants } from '../constants';

class ToastStore {
    text = '';
    variant = '';
    isVisible = false;

    constructor() {
        makeAutoObservable(this);
        this.hideToast = this.hideToast.bind(this);
    }

    renderToast({ text, variant }) {
        this.text = text;
        this.variant = variant;
        this.isVisible = true;
        setTimeout(() => {
            this.hideToast();
        }, 3000);
    }

    hideToast = () => {
        this.isVisible = false;
    };
}

const toastStore = new ToastStore();

export { ToastVariants };
export default toastStore;
