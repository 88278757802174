import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../App';
import languagesStore from '../../../../store/interface';
import userStore from '../../../UserPlatform/store/userStore';
import userWordsStore from '../../../UserPlatform/store/userWords';
import wordsStore from '../../../../store/words';
import { useFetching } from '../../../../hooks/useFetching';
import { Button, Loader, MicroButton } from '../../../../UI';
import { Roles } from '../../../../data/common';
import { ReactComponent as IconSound } from '../../../../assets/svg/icon-sound.svg';
import cl from './InfoCard.module.css';
import {
    DictionaryServerErrors,
    mapDictionaryServerErrors,
} from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import { Chapters } from '../../../LessonsKanban/data/constants';

const InfoCard = ({ wordObj, playAudio }) => {
    const { t } = useTranslation();

    const { user } = authStore;
    const isUser = user.role === Roles.User;
    const wordsSource = isUser ? wordsStore : userStore;

    const { playbackSpeed } = userWordsStore;
    const { nativeLang } = languagesStore;

    const [fetchedWord, setFetchedWord] = useState({});
    const [audio] = useState(new Audio());
    const [error, setError] = useState(null);

    const wordData = wordObj.pronunciation ? wordObj : fetchedWord;

    const { word, pronunciation, translations, voicePath } = wordData;

    const [getWord, isLoading] = useFetching(async () => {
        if (wordObj.data && wordObj.data[0]) {
            setFetchedWord({ ...wordObj, ...wordObj.data[0] });
        } else {
            const res = await wordsSource.getCachedWordByText({
                word: wordObj.word,
                sourceLang: wordObj.lang,
                targetLang: nativeLang,
            });
            if (res && res.data) {
                const { data } = res;
                setFetchedWord({ ...data, ...data.data[0] });
                return;
            }
            setError(mapDictionaryServerErrors[res.errorCode]);
        }
    });

    const handleAudioClick = () => {
        setTimeout(() => {
            audio.playbackRate = playbackSpeed;
            audio.play();
        });
    };

    useEffect(() => {
        setError(false);
        setFetchedWord({});
        if (wordObj.id && !wordObj.pronunciation) getWord();
    }, [wordObj]);

    useEffect(() => {
        if (voicePath) {
            audio.pause();
            audio.src = voicePath;
            if (playAudio) {
                setTimeout(() => {
                    audio.play();
                }, 500);
            }
        } else {
            audio.src = '';
        }
    }, [voicePath]);

    if (isLoading) return <Loader className={cl.wordLoader} />;

    if (error) {
        return (
            <div className={cl.theoryCard}>
                <div className={cl.mainInfo}>
                    <p className={cl.wordText}>{wordObj.word}</p>
                </div>
                <div className={cl.alert}>
                    <p className={cl.infoText}>
                        {`${t(`dictionary_administration.${error}_word`)}`}
                    </p>

                    {error !== DictionaryServerErrors.Generating && (
                        <p className={cl.infoText}>
                            {t('dictionary_administration.delete_word')}
                        </p>
                    )}
                    <Button
                        variant={Chapters.Dictionary}
                        text={t('buttons.refresh')}
                        onClick={getWord}
                    />
                </div>
            </div>
        );
    }

    if (!wordData?.data?.length) return null;

    if (error) return error;

    return (
        <>
            <div className={cl.theoryCard}>
                <div className={cl.mainInfo}>
                    <p className={cl.wordText}>{word}</p>
                    <p className={cl.wordInfo}>{pronunciation}</p>
                    {translations?.length ? (
                        <p className={cl.infoText}>{translations.join(', ')}</p>
                    ) : null}
                </div>
                {wordData.data.map((partOfSpeechObj) => {
                    const { partOfSpeech, forms, synonyms, examples } =
                        partOfSpeechObj;
                    return (
                        <Fragment key={partOfSpeechObj.id}>
                            <p className={cl.partOfSpeech}>{partOfSpeech}</p>

                            <div className={cl.additionalInfo}>
                                {forms?.length ? (
                                    <div className={cl.infoCont}>
                                        <p className={cl.infoLabel}>
                                            {t('demo_page.forms')}
                                        </p>
                                        <p className={cl.infoText}>
                                            {forms.join(', ')}
                                        </p>
                                    </div>
                                ) : null}
                                {synonyms?.length ? (
                                    <div className={cl.infoCont}>
                                        <p className={cl.infoLabel}>
                                            {t('demo_page.synonyms')}
                                        </p>
                                        <p className={cl.infoText}>
                                            {synonyms.join(', ')}
                                        </p>
                                    </div>
                                ) : null}
                                {examples?.length ? (
                                    <div className={cl.examplesCont}>
                                        <p className={cl.infoLabel}>
                                            {t('demo_page.phrases')}
                                        </p>
                                        {examples.map((eObj, i) => (
                                            <div key={eObj.id}>
                                                <p className={cl.infoText}>
                                                    {eObj.example}
                                                </p>
                                                <p
                                                    className={
                                                        cl.infoTranslation
                                                    }
                                                >
                                                    {eObj.translation}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            {wordObj?.voicePath && (
                <div className={cl.buttonContainer}>
                    <MicroButton
                        variant={'grey'}
                        size={'regular'}
                        icon={<IconSound className={cl.soundIcon} />}
                        onClick={handleAudioClick}
                    />
                </div>
            )}
        </>
    );
};

export default observer(InfoCard);
