import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import lessonsStore from '../../../LessonsKanban/store/lessonsStore';
import wizardStore from '../../../LessonsKanban/components/LessonWizard/store/wizardStore';
import { Button, Tooltip } from '../../../../UI';
import { Chapters } from '../../../LessonsKanban/data/constants';
import {
    onboardingStore,
    BubblePositions,
    OnboardingWrapper,
    OnboardingKeys,
} from '../../../Onboarding';
import { ReactComponent as IconDictionary } from '../../../../assets/svg/lessons-dictionary.svg';
import { ReactComponent as IconExercise } from '../../../../assets/svg/lessons-exercise.svg';
import { ReactComponent as IconSituation } from '../../../../assets/svg/lessons-situation.svg';
import { ReactComponent as IconTrack } from '../../../../assets/svg/lessons-track.svg';
import { ReactComponent as IconFile } from '../../../../assets/svg/lessons-file.svg';
import cl from './AddChapter.module.css';

const ChapterOptions = [
    {
        type: Chapters.Exercise,
        onboardingKey: OnboardingKeys.ExercisesContentSelect,
    },
    {
        type: Chapters.Situation,
        onboardingKey: OnboardingKeys.SituationsContentSelect,
    },
    {
        type: Chapters.Track,
        onboardingKey: OnboardingKeys.VideosContentSelect,
    },
    {
        type: Chapters.Dictionary,
        onboardingKey: OnboardingKeys.DictionaryContentSelect,
    },
];

const AddChapter = ({
    onAdd,
    alreadyAdded,
    onFileSelect,
    isLoading,
    isError,
    resetError,
    forceOpen,
}) => {
    const { t } = useTranslation();
    const { currentLesson } = lessonsStore;
    const addedChapters = currentLesson?.chapters || [];
    const buttonsRef = useRef();
    const optionsRef = useRef();

    const [isExpanded, setIsExpanded] = useState(false);

    const renderIcon = (type) => {
        switch (type) {
            case Chapters.Track:
                return <IconTrack />;
            case Chapters.Situation:
                return <IconSituation />;
            case Chapters.Exercise:
                return <IconExercise />;
            case Chapters.Dictionary:
                return <IconDictionary />;
            case Chapters.File:
                return <IconFile />;
            default:
                return null;
        }
    };

    const getClassName = () => {
        const classNames = [cl.buttonContainer];
        if (isExpanded) classNames.push(cl.expanded);

        return classNames.join(' ');
    };

    const handleAddButtonClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleClose = () => {
        if (forceOpen) return;
        setIsExpanded(false);
    };

    const renderShadow = () => {
        const optionsCont = optionsRef.current;
        if (!optionsCont || !isExpanded) return null;

        const coords = optionsCont.getBoundingClientRect();
        const style = {
            top: coords.top,
            left: coords.left,
            width: coords.width,
            height: coords.height,
        };

        return <div className={cl.shadow} style={style} />;
    };

    const handleTypeClick = ({ type, event }) => {
        event.stopPropagation();

        setIsExpanded(false);
        wizardStore.setCurrentView(type);
        wizardStore.setWizardVisible(true);
        onboardingStore.moveToNextComponent();
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (buttonsRef.current && !buttonsRef.current.contains(e.target)) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [buttonsRef]);

    useEffect(() => {
        setIsExpanded(forceOpen);
    }, [forceOpen]);

    useEffect(() => {
        const handleResize = () => {
            setIsExpanded(false);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div
                ref={buttonsRef}
                className={getClassName()}
                onClick={handleAddButtonClick}
            >
                <Button
                    className={cl.button}
                    isLoading={isLoading}
                    text={t('exercises.add_chapter')}
                    variant={'purple'}
                    onClick={() => {}}
                />
                {!isLoading && isError && (
                    <Tooltip
                        variant={'error'}
                        text={t('exercises.chapter_conflict')}
                        onHide={resetError}
                        tailPosition="center"
                        style={{
                            top: -45,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    />
                )}
                <OnboardingWrapper
                    onboardingKey={onboardingStore.isOnboarding(
                        OnboardingKeys.ExercisesContentSelect
                    )}
                    title={t('teacher_onboarding.select_chapter_type')}
                    subtitle={t('teacher_onboarding.press_to_continue')}
                    delay={600}
                    bubblePosition={{
                        x: BubblePositions.x.Center,
                        y: BubblePositions.y.Top,
                    }}
                    bubbleAutoWidth
                >
                    <div className={cl.options} ref={optionsRef}>
                        {ChapterOptions.map((o) => (
                            <div
                                key={o.type}
                                className={`${cl.optionContainer} ${cl[o.type]}`}
                                onClick={(e) => {
                                    handleTypeClick({ type: o.type, event: e });
                                }}
                            >
                                <div className={cl.iconContainer}>
                                    {renderIcon(o.type)}
                                </div>
                                <div className={cl.optionText}>
                                    <p className={cl.title}>
                                        {t(`exercises.add_${o.type}`)}
                                    </p>
                                </div>
                            </div>
                        ))}
                        {renderShadow()}
                    </div>
                </OnboardingWrapper>
            </div>
        </>
    );
};

export default observer(AddChapter);
