import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button } from '../../../../UI';
import cl from './FilePreview.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
).toString();

const FilePreview = ({ file }) => {
    const { t } = useTranslation();
    const { src, isPdf } = file;

    const [imageError, setImageError] = useState(false);
    const [numPages, setNumPages] = useState(null);

    const handleError = () => {
        setImageError(true);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div className={cl.previewContainer}>
            <a
                className={cl.downloadLink}
                href={src}
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    style={{ marginLeft: 'auto' }}
                    variant={'dark'}
                    text={t('buttons.download')}
                    onClick={() => {}}
                />
            </a>
            {imageError || isPdf ? (
                isPdf ? (
                    <div className={cl.pdfPreview}>
                        <Document
                            file={src}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={cl.pdfDocument}
                        >
                            {numPages ? (
                                Array.from({ length: numPages }, (_, i) => (
                                    <Page pageNumber={i + 1} key={i + 1} />
                                ))
                            ) : (
                                <Page pageNumber={1} />
                            )}
                        </Document>
                    </div>
                ) : (
                    <p className={cl.label}>
                        {t('exercises.preview_load_error')}
                    </p>
                )
            ) : (
                <img src={src} alt={src} onError={handleError} />
            )}
        </div>
    );
};

export default FilePreview;
