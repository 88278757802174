import React, { useState, useEffect } from 'react';
import cl from './AdjustableInput.module.css';

const AdjustableInput = ({ value, onChange, isActive, isCorrect, isPdf }) => {
    const className =
        isCorrect !== undefined
            ? isCorrect
                ? cl.correct
                : cl.wrong
            : isPdf
              ? cl.pdf
              : isActive
                ? cl.active
                : '';
    const [width, setWidth] = useState(0);

    const getWidthOfInput = () => {
        const tempElem = document.createElement('span');
        tempElem.textContent = value;
        tempElem.className = isActive
            ? cl.widthActiveCalculator
            : cl.widthCalculator;
        document.body.appendChild(tempElem);
        setWidth(+tempElem.getBoundingClientRect().width + 20);
        document.body.removeChild(tempElem);
    };

    const changeHandler = (e) => {
        getWidthOfInput();
        onChange(e);
    };

    useEffect(() => {
        getWidthOfInput();
    }, [value]);

    return (
        <input
            style={{ width: width + 'px' }}
            placeholder={isPdf ? '' : '...'}
            className={`${cl.keyboardGap} ${className}`}
            type="textarea"
            value={value}
            onChange={changeHandler}
        />
    );
};

export default AdjustableInput;
