import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import languagesStore from '../../../../store/interface';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import {
    Checkbox,
    Input,
    Loader,
    Select,
    ToggleButton,
    Toolbar,
} from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import { ReactComponent as IconFolder } from '../../../../assets/svg/lessons-folder.svg';
import { ReactComponent as IconEyes } from '../../../../assets/svg/lessons-eyes.svg';
import cl from './WordPacks.module.css';

const WordPacks = () => {
    const { t } = useTranslation();

    const { availableLanguages } = languagesStore;

    const [categories, setCategories] = useState([]);

    const [lang, setLang] = useState('');
    const [searchString, setSearchString] = useState('');
    const [level, setLevel] = useState('');

    const [getPacks, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getWordsCategories({
            searchString: searchString ? searchString : null,
            level: level ? LanguageLevels.indexOf(level) : null,
            lang: lang ? lang : null,
        });
        setCategories(data.items);
    });

    const handleCategoryChange = ({ id, verified }) => {
        setCategories(
            categories.map((c) => (c.id === id ? { ...c, verified } : c))
        );
    };

    useEffect(() => {
        getPacks();
    }, [lang, searchString, level]);

    return (
        <>
            <Toolbar>
                <Input
                    value={searchString}
                    onChange={setSearchString}
                    placeholder={t('library.search')}
                />
                <ToggleButton
                    options={LanguageLevels}
                    value={level}
                    onChange={setLevel}
                    nullable
                />
                <Select
                    variant={'white'}
                    label={t('dictionary_administration.source_lang')}
                    value={lang}
                    onChange={setLang}
                    options={availableLanguages}
                />
            </Toolbar>
            {isLoading ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <div className={cl.list}>
                    {categories.length ? (
                        categories.map((c) => (
                            <CategoryCard
                                key={c.id}
                                category={c}
                                onChange={handleCategoryChange}
                            />
                        ))
                    ) : level !== null || searchString !== '' ? (
                        <div className={cl.emptyAlert}>
                            <IconEyes />
                            <p className={cl.alertTitle}>
                                {t(`exercises.empty_search_words_title`)}
                            </p>
                            <p className={cl.alertSubtitle}>
                                {t(`exercises.empty_search_words_subtitle`)}
                            </p>
                        </div>
                    ) : (
                        <div className={cl.emptyAlert}>
                            <IconEyes />
                            <p className={cl.alertTitle}>
                                {t(`exercises.empty_fav_words_title`)}
                            </p>
                            <p className={cl.alertSubtitle}>
                                {t(`exercises.empty_fav_words_subtitle`)}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

const CategoryCard = ({ category, onChange }) => {
    const { t } = useTranslation();

    const { id, lang, title, level, verified } = category;

    const [words, setWords] = useState([]);

    const [getWords] = useFetching(async () => {
        const { data } = await ProgressService.getCategoryWords({
            categoryId: id,
            lang,
        });
        setWords(data.items);
    });

    const [verify] = useFetching(async () => {
        await ProgressService.verifyCategory({ id, verified: !verified });
        onChange({ ...category, verified: !verified });
    });

    useEffect(() => {
        getWords();
    }, []);

    return (
        <div className={cl.categoryCard}>
            <div className={cl.preview}>
                <div className={cl.folder}>
                    <IconFolder />
                </div>
                <p className={cl.count}>{words?.length ?? '?'}</p>
            </div>
            <div className={cl.cardInner}>
                <p className={cl.title}>
                    {title}
                    <span>{LanguageLevels[level]}</span>
                </p>
                <div className={cl.words}>
                    {words.map((w) => (
                        <span className={cl.word} key={`${id}_${w.word}`}>
                            {w.word}
                        </span>
                    ))}
                </div>
                <Checkbox
                    value={verified}
                    onChange={verify}
                    label={t('exercises.verified')}
                />
            </div>
        </div>
    );
};

export default WordPacks;
