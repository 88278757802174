import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DictionaryService from '../../../../api/DictionaryService';
import { useFetching } from '../../../../hooks/useFetching';
import { splitText } from '../../helpers/splitText';
import ControlsBar from '../ControlsBar/ControlsBar';
import ConfirmationBar from '../ConfirmationBar/ConfirmationBar';
import { AdjustableTextarea, Chip } from '../../../../UI';
import { AddBatchStates } from '../../data/constants';
import cl from './AddWords.module.css';

const AddWords = () => {
    const { t } = useTranslation();

    const [text, setText] = useState('');
    const [words, setWords] = useState([]);
    const [sourceLang, setSourceLang] = useState('en');
    const [status, setStatus] = useState(AddBatchStates.Default);

    const [addWords, isLoading, error, resetError] = useFetching(async () => {
        await DictionaryService.addWordsBatch({
            words,
            sourceLang,
        });
        setText('');
        setStatus(AddBatchStates.Added);
    });

    const handleWordDelete = (idx) => {
        const updatedWords = [...words];
        updatedWords.splice(idx, 1);
        setText(updatedWords.join(', '));
    };

    useEffect(() => {
        setWords(splitText(text));
    }, [text]);

    useEffect(() => {
        if (status === AddBatchStates.Default) return;
        setTimeout(() => {
            resetError();
            setStatus(AddBatchStates.Default);
        }, 2000);
    }, [status]);

    useEffect(() => {
        if (!error) return;
        setStatus(AddBatchStates.Error);
    }, [error]);

    return (
        <div className={cl.cont}>
            <ControlsBar
                sourceLang={sourceLang}
                setSourceLang={setSourceLang}
            />
            <AdjustableTextarea
                placeholder={'apfel, haus, sonne...'}
                value={text}
                onChange={setText}
                isFocus
            />
            {words.length !== 0 && (
                <>
                    <div className={cl.preview}>
                        {words.map((w, i) => (
                            <Chip
                                key={`${w}_${i}`}
                                text={w}
                                onDelete={() => handleWordDelete(i)}
                            />
                        ))}
                    </div>
                    <ConfirmationBar
                        words={words}
                        sourceLang={sourceLang}
                        isLoading={isLoading}
                        onClick={addWords}
                    />
                </>
            )}
            {status && (
                <p
                    className={`${cl.status} ${
                        AddBatchStates.Error === status ? cl.red : ''
                    }`}
                >
                    {t(`dictionary_administration.${status}`)}
                </p>
            )}
        </div>
    );
};

export default AddWords;
