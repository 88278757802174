import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import exercisesStore from '../../../../../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import wordsStore from '../../../../../../store/words';
import { useFetching } from '../../../../../../hooks/useFetching';
import ExerciseService from '../../../../../../api/ExerciseService';
import ProgressService from '../../../../../../api/ProgressService';
import { WordsRange } from '../../../../../../pages/TeacherContent/data/constants';
import { Button, FilledRange, Label, Select, Tree } from '../../../../../../UI';
import { Chapters, PartsOfSpeech } from '../../../../data/constants';
import { SelectDictionaryModal } from '../../../../../../pages/TeacherContent/views/DictionaryView/components';
import { WizardViews } from '../../LessonWizard';
import { WordStatuses } from '../../../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import SelectContentButtons from '../SelectContentButtons/SelectContentButtons';
import cl from './WordPackWizard.module.css';

const SelectWordPack = observer(
    ({ searchString, setSearchString, onSelect }) => {
        const { lang } = languagesStore;
        const { currentLesson } = lessonsStore;
        const { difficulty } = currentLesson;

        const [isOwn, setIsOwn] = useState(false);

        const [allPacksCount, setAllPacksCount] = useState(null);
        const [favPacksCount, setFavPacksCount] = useState(null);

        const [isModal, setIsModal] = useState(false);

        const [addCategory, addCategoryLoading] = useFetching(
            async (category) => {
                const { id, title } = category;
                if (!isOwn) {
                    cloneExistingCategory({ id, title });
                } else {
                    handleOwnPackSelect({ id, title });
                }

                onSelect({ id, title });
            }
        );

        const [getCategories, isLoading] = useFetching(async () => {
            const { data: allPacks } = await ProgressService.getCategories({
                lang,
                difficulty,
                searchString,
            });

            const { data: favPacks } = await ProgressService.getUserCategories({
                lang,
                difficulty,
                searchString,
            });

            setAllPacksCount(allPacks.items?.length || 0);
            setFavPacksCount(favPacks.items?.length || 0);
        });

        const [cloneExistingCategory, isCloneLoading] = useFetching(
            async ({ id, title }) => {
                const { data } = await ProgressService.getCategoryWords({
                    categoryId: id,
                    lang,
                });

                if (data.items) {
                    for (const item of data.items) {
                        const { data } = await wordsStore.getCachedWordByText({
                            word: item.word,
                            sourceLang: lang,
                            targetLang: lang,
                        });

                        await ProgressService.addFavoriteWord({
                            id: data?.data[0]?.id,
                            word: item.word,
                            lang: item.lang,
                        });
                    }
                }
                await ProgressService.cloneWordsCategory({ id, lang });
                syncLessonPacks({
                    id,
                    title,
                    data,
                });
                exercisesStore.refresh();
            }
        );

        const syncLessonPacks = ({ id, title, data }) => {
            if (!currentLesson) return;
            const words = data.items.map((wObj) => wObj.word);

            wizardStore.handleNewWordPack({
                id,
                title,
                items: words.map((word) => ({
                    categoryId: id,
                    lang,
                    word,
                })),
            });
        };

        const handleOwnPackSelect = async ({ id, title }) => {
            const { data } = await ProgressService.getCategoryWords({
                categoryId: id,
                lang,
            });

            if (data.items) {
                syncLessonPacks({
                    id,
                    title,
                    data,
                });
            }
        };

        const handleSelectClick = () => {
            setIsOwn(false);
            setTimeout(() => {
                setIsModal(true);
            });
        };

        const handleOwnClick = () => {
            setIsOwn(true);
            setTimeout(() => {
                setIsModal(true);
            });
        };

        useEffect(() => {
            if (!searchString) {
                getCategories();
            }
        }, [searchString]);

        useEffect(() => {
            setSearchString(currentLesson.vocabularyTopic);
            setTimeout(() => {
                getCategories();
            });
        }, [currentLesson]);

        return (
            <>
                <SelectContentButtons
                    variant={Chapters.Dictionary}
                    onCreateClick={wizardStore.nextStep}
                    onOwnClick={handleOwnClick}
                    onLibraryClick={handleSelectClick}
                    ownCount={favPacksCount}
                    libraryCount={allPacksCount}
                />
                <SelectDictionaryModal
                    visible={isModal}
                    setVisible={setIsModal}
                    onClick={addCategory}
                    // TODO: pass already added word packs @saratovkin
                    alreadyAdded={[]}
                    isOnboarding={false}
                    isOwn={isOwn}
                    presetDifficulty={difficulty}
                    presetSearch={searchString}
                />
            </>
        );
    }
);

const GenerateWordPack = observer(({ searchString, onGenerate }) => {
    const { t } = useTranslation();

    const { lang, nativeLang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { vocabularyOptions } = wizardStore;
    const { difficulty, vocabularyTopic } = currentLesson;

    const [partOfSpeech, setPartOfSpeech] = useState(PartsOfSpeech.Any);
    const [wordsAmount, setWordsAmount] = useState(10);

    const [activeVocabularyTopic, setActiveVocabularyTopic] = useState(
        searchString ?? vocabularyTopic
    );

    const getWordPackTitle = () => {
        const currentVocabulary = vocabularyOptions
            .flatMap((o) => o.topics)
            .find((tObj) => tObj.topic === activeVocabularyTopic);

        return currentVocabulary?.wordPackTitle || activeVocabularyTopic;
    };

    const [generateWords, generateLoading, generateError, resetGenerateError] =
        useFetching(async () => {
            const title = getWordPackTitle();
            const { data } = await ExerciseService.generateWords({
                topic: activeVocabularyTopic,
                title,
                partOfSpeech,
                difficulty,
                lang,
                wordsAmount,
            });
            const { words } = data;
            createCategory({ words, title, difficulty });
        });

    const [createCategory, createLoading] = useFetching(
        async ({ words, title, difficulty }) => {
            const { data } = await ProgressService.createWordsCategory({
                title,
                lang,
                level: difficulty,
            });
            exercisesStore.refresh();

            wizardStore.handleNewWordPack({
                id: data.id,
                title: title,
                items: words.map((word) => ({
                    categoryId: data.id,
                    lang,
                    word,
                })),
            });
            handleGeneratedCategoryAdd(data, words);
        }
    );

    const handleGeneratedCategoryAdd = (addedCategory, generatedWords) => {
        const { id, title } = addedCategory;

        onGenerate({ id, title });
        for (const word of generatedWords) {
            wordsStore.addWord({
                word,
                categoryId: id,
                status: WordStatuses.Queued,
                targetLang: nativeLang,
            });
        }
        wizardStore.setWordPack({
            id,
            title,
            items: generatedWords.map((w) => ({
                word: w,
                lang,
                categoryId: id,
            })),
        });
    };

    const handleWordsGenerate = () => {
        generateWords();
    };

    const handleRangeInput = (e) => {
        setWordsAmount(e.target.value);
    };

    const isLoading = createLoading || generateLoading;

    return (
        <>
            <div>
                <Label text={t(`lesson_wizard.words_generate_subtitle`)} />
                <Label text={t('lesson_wizard.generate_pack_info')} />
            </div>
            <div>
                <Label text={t('lesson_wizard.select_pack_lexical_topic')} />
                <Tree
                    label={t('lesson_wizard.lexical_topic')}
                    variant={'grey'}
                    value={activeVocabularyTopic}
                    onChange={setActiveVocabularyTopic}
                    options={vocabularyOptions}
                    hideReset
                    ignoreOverflow
                    height={240}
                />
            </div>
            <div className={cl.flexContainer}>
                <div>
                    <Label
                        text={t('dictionary_administration.part_of_speech')}
                    />
                    <Select
                        className={cl.wideComponent}
                        variant={'grey'}
                        value={partOfSpeech}
                        options={Object.values(PartsOfSpeech).map((value) => ({
                            value,
                            name: t(`parts_of_speech.${value}`),
                        }))}
                        onChange={setPartOfSpeech}
                        height={120}
                        hideReset
                        style={{ width: '100%' }}
                    />
                </div>
                <div>
                    <Label text={t('exercises.words_amount')} />
                    <FilledRange
                        color={`var(--words-green)`}
                        value={wordsAmount}
                        min={WordsRange.Min}
                        step={WordsRange.Step}
                        max={WordsRange.Max}
                        onChange={handleRangeInput}
                    />
                </div>
            </div>

            {generateError && (
                <Label isError text={t('errors.unexpected_error')} />
            )}

            <Button
                style={{ marginTop: 'auto' }}
                text={t('exercises.add_new_words')}
                variant={Chapters.Dictionary}
                onClick={handleWordsGenerate}
                isLoading={isLoading}
            />
        </>
    );
});

const WordPackWizard = ({ onAdd }) => {
    const { currentLesson } = lessonsStore;

    const [view, step] = wizardStore.getCurrentViewAndStep();
    const [searchString, setSearchString] = useState(
        currentLesson?.vocabularyTopic ?? ''
    );

    const handleWordPackAdd = ({ id, title }) => {
        onAdd({ chapterType: Chapters.Dictionary, chapterId: id, title });
    };

    if (!view || view !== WizardViews.WordPack) return null;

    switch (step) {
        case 'select':
            return (
                <SelectWordPack
                    onSelect={handleWordPackAdd}
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
            );
        case 'generate':
            return (
                <GenerateWordPack
                    searchString={searchString}
                    onGenerate={handleWordPackAdd}
                />
            );

        default:
            return null;
    }
};

export default observer(WordPackWizard);
