import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import {
    AdjustableTextarea,
    Checkbox,
    Label,
} from '../../../../../../../../UI';
import { AddButton } from '../../../';
import { Chapters } from '../../../../../../../../features/LessonsKanban/data/constants';
import cl from './QuizEditor.module.css';

const OptionCard = ({
    option,
    questionId,
    isCorrect,
    onClick,
    onChange,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <div className={cl.answersCont}>
            <div className={cl.answerInner}>
                <div className={cl.answerInput}>
                    <AdjustableTextarea
                        className={cl.option}
                        placeholder={t('exercises.add_answer')}
                        value={option?.text}
                        onChange={(text) =>
                            onChange(questionId, option?.id, text)
                        }
                        onDelete={() => onDelete(questionId, option?.id)}
                        isFocus={!option?.text?.length}
                        showDelete
                    />
                </div>
                <Checkbox
                    variant={Chapters.Exercise}
                    value={isCorrect}
                    onChange={() => {
                        onClick(questionId, option?.id);
                    }}
                />
            </div>
        </div>
    );
};

const QuizEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const questions = exerciseData.questions || [];

    const handleQuestionAdd = () => {
        onChange({
            questions: [
                ...questions,
                {
                    id: uuid(),
                    text: '',
                    answer: ' ',
                    options: [],
                },
            ],
        });
    };

    const handleQuestionBlur = (questionId) => {
        setTimeout(() => {
            onChange({
                questions: questions.map((q) =>
                    q.id === questionId
                        ? {
                              ...q,
                              options:
                                  q.options.length >= 2
                                      ? q.options
                                      : q.text
                                        ? [
                                              { id: uuid(), text: ' ' },
                                              { id: uuid(), text: '' },
                                          ]
                                        : [],
                          }
                        : q
                ),
            });
        });
    };

    const handleQuestionChange = (questionId, text) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          text,
                      }
                    : q
            ),
        });
    };

    const handleQuestionDelete = (questionId) => {
        onChange({
            questions: questions.filter((q) => q.id !== questionId),
        });
    };

    const handleOptionAdd = (questionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          options: [...q.options, { id: uuid(), text: '' }],
                      }
                    : q
            ),
        });
    };

    const handleOptionChange = (questionId, optionId, text) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          answer:
                              q.options.find((o) => o.id === optionId)?.text ===
                              q.answer
                                  ? text
                                  : q.answer,
                          options: q.options.map((o) =>
                              o.id === optionId ? { ...o, text } : o
                          ),
                      }
                    : q
            ),
        });
    };

    const handleOptionDelete = (questionId, optionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === questionId
                    ? {
                          ...q,
                          options: q.options.filter((o) => o.id !== optionId),
                      }
                    : q
            ),
        });
    };

    const handleAnswerChange = (id, optionId) => {
        onChange({
            questions: questions.map((q) =>
                q.id === id
                    ? {
                          ...q,
                          answer: q.options.find((o) => o.id === optionId)
                              ?.text,
                      }
                    : q
            ),
        });
    };

    return (
        <div>
            <Label text={t('exercises.edit_quiz_label')} />
            <AddButton
                text={t(
                    questions.length
                        ? 'exercises.new_question'
                        : 'exercises.first_question'
                )}
                onClick={handleQuestionAdd}
            />
            <div className={cl.quizEditor}>
                {questions?.length ? (
                    questions.map((q, qIdx) => (
                        <div className={cl.questionCont} key={q.id}>
                            <p
                                className={cl.questionNumber}
                            >{`${qIdx + 1}.`}</p>
                            <AdjustableTextarea
                                className={cl.question}
                                placeholder={t('exercises.add_question')}
                                value={q.text}
                                onChange={(text) =>
                                    handleQuestionChange(q.id, text)
                                }
                                onDelete={() => handleQuestionDelete(q.id)}
                                onBlur={() => handleQuestionBlur(q.id)}
                                isFocus={!q.text.length}
                                showDelete
                            />
                            <AddButton
                                text={t(
                                    q.options.length
                                        ? 'exercises.new_answer'
                                        : 'exercises.first_answer'
                                )}
                                onClick={() => handleOptionAdd(q.id)}
                            />
                            {q.options.length ? (
                                <div className={cl.answers}>
                                    {q.options.map((o) => (
                                        <OptionCard
                                            key={o.id}
                                            option={o}
                                            questionId={q.id}
                                            isCorrect={q.answer === o.text}
                                            onChange={handleOptionChange}
                                            onDelete={handleOptionDelete}
                                            onClick={handleAnswerChange}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default QuizEditor;
