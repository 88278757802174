import React from 'react';
import { observer } from 'mobx-react-lite';
import adminExercisesStore from '../../store/adminExercisesStore';
import { CloseButton, Modal } from '../../../../UI';
import ExercisePreview from '../../../../features/ExercisePreview/ExercisePreview';
import { Roles } from '../../../../data/common';
import cl from './AdminExercisePreview.module.css';

const AdminExercisePreview = () => {
    const { currentExercise } = adminExercisesStore;

    const handleClose = () => adminExercisesStore.setCurrentExercise(null);

    return (
        <Modal
            className={cl.exercisePreview}
            visible={currentExercise}
            setVisible={handleClose}
        >
            <ExercisePreview
                type={Roles.Teacher}
                exerciseId={currentExercise.id}
            />
            <CloseButton onClick={handleClose} />
        </Modal>
    );
};

export default observer(AdminExercisePreview);
