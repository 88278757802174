import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import { authStore } from '../../../../../../App';
import languagesStore from '../../../../../../store/interface';
import wordsStore from '../../../../../../store/words';
import ProgressService from '../../../../../../api/ProgressService';
import {
    Button,
    CloseButton,
    Input,
    Label,
    MicroButton,
    ToggleButton,
    Tooltip,
} from '../../../../../../UI';
import WordCard from '../WordCard/WordCard';
import { LanguageLevels, Roles } from '../../../../../../data/common';
import { WordStatuses } from '../../data/constants';
import { ReactComponent as IconBin } from '../../../../../../assets/svg/lessons-bin.svg';
import { ReactComponent as IconEdit } from '../../../../../../assets/svg/lessons-edit.svg';
import { ReactComponent as IconVisible } from '../../../../../../assets/svg/visible.svg';
import { GenerateChapterModal } from '../../../../components';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import WordsUserPreview from '../WordsUserPreview/WordsUserPreview';
import { globalWordPacks } from '../../../../../TeachersTutorial/data/words';
import cl from './CategoryWords.module.css';

const CategoryWords = ({
    dictionary,
    category,
    currentWord,
    setCurrentWord,
    addedWord,
    setAddedWord,
    onAdd,
    onDelete,
    onUpdate,
    isInvalid,
    setIsInvalid,
    isDemo = false,
    outlined = false,
}) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const isUser = authStore.user.role === Roles.User;

    const { id, level, title } = category;
    const { processedWords } = wordsStore;

    const [isEdit, setIsEdit] = useState(false);
    const [isStudentPreview, setIsStudentPreview] = useState(false);

    const [difficulty, setDifficulty] = useState('');
    const [fetchedWords, setFetchedWords] = useState([]);
    const [inputTimeout, setInputTimeout] = useState();
    const [spellcheckError, setSpellcheckError] = useState(false);
    const [newTitle, setNewTitle] = useState(title);
    const [newDifficulty, setNewDifficulty] = useState('');

    const [getCategoryWords] = useFetching(async () => {
        if (isDemo) {
            const currentCategory = globalWordPacks.find(
                (pObj) => pObj.id === id
            );
            setFetchedWords(currentCategory.words);
        } else {
            const { data } = await ProgressService.getCategoryWords({
                categoryId: id,
                lang,
            });

            const sorted = data.items.sort((a, b) =>
                a.word.localeCompare(b.word)
            );
            setFetchedWords(sorted);
            if (!data.items.some((w) => w.word === currentWord?.word)) {
                setCurrentWord(sorted[0]);
            }
        }
    });

    const [updateCategory, updateLoading] = useFetching(async () => {
        const level = newDifficulty
            ? LanguageLevels.indexOf(newDifficulty)
            : difficulty;
        const title = newTitle;
        await ProgressService.updateWordsCategory({
            id,
            level,
            title,
        });
        onUpdate({ ...category, level, title });
        setIsEdit(false);
    });

    const handleWordInput = (text) => {
        setAddedWord(text);

        if (!dictionary || !dictionary.check) return;
        setSpellcheckError(false);
        if (inputTimeout) {
            clearTimeout(inputTimeout);
        }

        const timeoutId = setTimeout(() => {
            setSpellcheckError(!dictionary.check(text));
        }, 500);

        setInputTimeout(timeoutId);
    };

    const handleWordAdd = () => {
        const word = addedWord.trim();
        if (fetchedWords.some((w) => w === word)) {
            setAddedWord('');
            return;
        }
        onAdd({ word, categoryId: category.id }, true);
    };

    const syncWord = ({ word, categoryId }) => {
        const addedWord = { categoryId, word, lang };

        onUpdate &&
            onUpdate({
                ...category,
                wordsCount: category.wordsCount ? category.wordsCount + 1 : 1,
            });
        setFetchedWords([...fetchedWords, addedWord]);

        wordsStore.deletePendingWord({ word, categoryId });
    };

    useEffect(() => {
        if (category.chapterId) return;

        const difficulty = level !== undefined ? LanguageLevels[level] : '';
        setDifficulty(difficulty);
        setNewDifficulty(difficulty);
        getCategoryWords();
        setIsStudentPreview(false);
    }, [category]);

    useEffect(() => {
        setNewTitle(title);
    }, [title]);

    useEffect(() => {
        if (!isEdit) {
            setNewDifficulty(difficulty);
            setNewTitle(title);
        }
    }, [isEdit, difficulty, title]);

    useEffect(() => {
        const readyWords = processedWords.filter(
            (w) => w.categoryId === id && w.status === WordStatuses.Ready
        );

        if (readyWords.length) {
            for (const word of readyWords) {
                syncWord(word);
            }
        }
    }, [processedWords]);

    if (isStudentPreview)
        return (
            <WordsUserPreview categoryId={id} onClose={setIsStudentPreview} />
        );

    return (
        <div className={`${cl.category} ${outlined ? cl.outlined : ''} `}>
            <div className={cl.titleContainer}>
                <p className={cl.packTitle}>{newTitle}</p>
                <div className={cl.microButtons}>
                    {!isUser && (
                        <>
                            <MicroButton
                                icon={<IconEdit className={cl.iconStroke} />}
                                variant={'grey'}
                                size={'regular'}
                                onClick={() => setIsEdit(true)}
                            />
                            <MicroButton
                                icon={<IconVisible className={cl.iconStroke} />}
                                variant={Chapters.Dictionary}
                                size={'regular'}
                                onClick={() => setIsStudentPreview(true)}
                            />
                        </>
                    )}
                </div>
            </div>
            {setAddedWord && (
                <div className={cl.inputContainer}>
                    {addedWord && spellcheckError && (
                        <Tooltip
                            text={t('exercises.check_spell')}
                            variant={'warning'}
                            style={{ top: -48, left: 25 }}
                        />
                    )}
                    <p>{'+'}</p>
                    <Input
                        onBlur={handleWordAdd}
                        placeholder={t('exercises.add_word_to_category')}
                        value={addedWord}
                        onChange={handleWordInput}
                        onFocus={() => setCurrentWord()}
                        error={isInvalid ? t('exercises.invalid_word') : ''}
                        resetError={setIsInvalid}
                    />
                </div>
            )}

            <div className={cl.wordsList}>
                {fetchedWords.map((w, i) => (
                    <div
                        className={`${cl.word} ${
                            currentWord?.word === w.word
                                ? cl.active
                                : w.invalid
                                  ? cl.invalid
                                  : ''
                        }`}
                        key={`${w.word}_${i}`}
                        onClick={() => setCurrentWord(w)}
                    >
                        <span>{`${i + 1}.`}</span>
                        <p>{w.word}</p>
                        {setAddedWord && (
                            <div className={cl.controls}>
                                {onDelete && (
                                    <MicroButton
                                        icon={
                                            <IconBin
                                                style={{
                                                    filter: 'grayscale(1)',
                                                }}
                                            />
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(w);
                                        }}
                                        size={'small'}
                                        variant={'grey'}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <WordCard
                currentWord={currentWord}
                category={category}
                onClose={() => setCurrentWord()}
                lang={lang}
            />
            <GenerateChapterModal
                visible={isEdit}
                setVisible={setIsEdit}
                title={t('exercises.edit_word_pack')}
                variant={Chapters.Dictionary}
                className={cl.modal}
            >
                <div>
                    <Label
                        text={t('exercises.words_category_title')}
                        isError={!newTitle}
                    />
                    <Input
                        variant={!newTitle ? 'erroredSmall' : 'outlinedSmall'}
                        placeholder={t(
                            'exercises.words_category_title_placeholder'
                        )}
                        value={newTitle}
                        onChange={setNewTitle}
                    />
                </div>
                <div>
                    <Label text={t('situations.level')} />
                    <ToggleButton
                        variant={'outlined'}
                        style={{ width: 212 }}
                        label={t('users.level')}
                        options={LanguageLevels}
                        value={newDifficulty}
                        onChange={setNewDifficulty}
                    />
                </div>
                <Button
                    text={t('buttons.save')}
                    variant={Chapters.Dictionary}
                    onClick={updateCategory}
                    isLoading={updateLoading}
                    isDisabled={!newTitle}
                />

                <CloseButton onClick={() => setIsEdit(false)} />
            </GenerateChapterModal>
        </div>
    );
};

export default observer(CategoryWords);
