import React, { useEffect, useState } from 'react';
import { ToggleButton } from '../../../../UI';
import { AdvancedQuizStatuses } from '../../../../pages/TeacherContent/data/constants';
import cl from './AdvancedQuizPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { useTranslation } from 'react-i18next';

const AdvancedQuizPreview = ({
    exerciseObj,
    results,
    showAnswers,
    isPdf,
    isPreview,
}) => {
    const { t } = useTranslation();

    const { data } = exerciseObj;
    const { questions } = data;
    const [answers, setAnswers] = useState({});

    const handleAnswerClick = (questionIndex, status) => {
        const newAnswers = { ...answers };
        newAnswers[questionIndex] =
            newAnswers[questionIndex] === status ? '' : status;
        setAnswers(newAnswers);
    };

    const displayAnswers = () => {
        const correctAnswers = questions.map((q) => q.status);
        setAnswers(correctAnswers);
    };

    const resetAnswers = () => {
        if (results) return;
        setAnswers({});
    };

    const getVariant = (isCorrect) => {
        if (!results) return 'outlined';
        return isCorrect ? 'correct' : 'wrong';
    };

    const getLabel = () => {
        if (results) return 'student_answers';
        if (isPdf) return 'advanced_quiz_pdf_info';
        if (isPreview) return 'advanced_quiz_preview_info';
        return 'statements';
    };

    useEffect(() => {
        showAnswers ? displayAnswers() : resetAnswers();
    }, [showAnswers]);

    useEffect(() => {
        if (results && results.userAnswers) {
            setAnswers(results.userAnswers.map((a) => AdvancedQuizStatuses[a]));
        }
    }, [results]);

    if (!questions) return <></>;

    return (
        <>
            <QuestionsLabel
                label={getLabel()}
                variant={isPreview ? 'demo' : ''}
            />
            <div className={cl.questionsList}>
                {questions?.map((q, qIdx) => (
                    <div className={cl.questionCont} key={q.id ?? Math.random}>
                        <p className={cl.text}>{`${qIdx + 1}. ${q.text}`}</p>
                        {isPdf ? (
                            <div className={cl.pdfOptions}>
                                {AdvancedQuizStatuses.map((s) => (
                                    <div className={cl.pdfAnswer} key={s}>
                                        {t(`exercises.${s}`)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <ToggleButton
                                variant={getVariant(q.status === answers[qIdx])}
                                nullable
                                value={answers[qIdx]}
                                withTranslations={'exercises'}
                                options={AdvancedQuizStatuses}
                                onChange={(status) => {
                                    handleAnswerClick(qIdx, status);
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default AdvancedQuizPreview;
