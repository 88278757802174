import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import languagesStore from '../../../../store/interface';
import lessonsStore from '../../../LessonsKanban/store/lessonsStore';
import ExerciseService from '../../../../api/ExerciseService';
import { SmallLoader } from '../../../../UI';
import {
    Chapters,
    StudentExerciseStatuses,
} from '../../../LessonsKanban/data/constants';
import cl from './CloneLesson.module.css';
import userStore from '../../../UserPlatform/store/userStore';
import wordsStore from '../../../../store/words';
import { WordStatuses } from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';

const CloneLesson = ({ lesson, isUser }) => {
    const { t } = useTranslation();

    const { lang, nativeLang } = languagesStore;
    const { themes } = lessonsStore;
    const wordsSource = isUser ? wordsStore : userStore;

    const { id } = lesson;

    const [copied, setCopied] = useState(false);

    const [cloneLesson, isLoading] = useFetching(async () => {
        const { data } = await ExerciseService.cloneLesson({ id });
        syncLessons(data);

        setCopied(true);
    });

    const [cloneUserContent, isUserLoading] = useFetching(async () => {
        const { exercises, situations, tracks, wordsPacks } =
            categorizeChapters(lesson.chapters);

        await cloneWordPacks(wordsPacks);
        await cloneSituations(situations);
        await cloneTracks(tracks);
        await cloneExercises(exercises);

        setCopied(true);
    });

    const [cloneWordPack] = useFetching(async (wordPack) => {
        const { id } = wordPack;
        const { data } = await ProgressService.getCategoryWords({
            categoryId: id,
            lang,
        });

        const { items } = data;

        if (data.items) {
            for (const [index, item] of items.entries()) {
                await new Promise((resolve) => setTimeout(resolve, index * 25));
                userStore.addWord({
                    word: item.word,
                    categoryId: id,
                    status: WordStatuses.Queued,
                    targetLang: nativeLang,
                });
            }
            // for (const item of data.items) {
            //     const { data } = await wordsSource.getCachedWordByText({
            //         word: item.word,
            //         sourceLang: lang,
            //         targetLang: nativeLang,
            //     });

            //     if (data && data.data) {
            //         await ProgressService.addFavoriteWord({
            //             id: data?.data[0]?.id,
            //             word: item.word,
            //             lang: item.lang,
            //         });
            //         const mappedWordData = { ...data, wordId: data.data[0].id };
            //         userStore.addWordData(mappedWordData);
            //     } else {
            //         userStore.addWord({
            //             word: item.word,
            //             categoryId: id,
            //             status: WordStatuses.Queued,
            //             targetLang: nativeLang,
            //         });
            //     }
            // }
        }

        await ProgressService.cloneWordsCategory({ id, lang });
        userStore.addFavWordPack({ ...wordPack, words: data.items });
    });

    const categorizeChapters = (chapters) => ({
        exercises: chapters.filter((c) => c.type === Chapters.Exercise),
        situations: chapters.filter((c) => c.type === Chapters.Situation),
        tracks: chapters.filter((c) => c.type === Chapters.Track),
        wordsPacks: chapters.filter((c) => c.type === Chapters.Dictionary),
    });

    const cloneWordPacks = async (wordsPacks) => {
        if (!wordsPacks.length) return;
        await Promise.all(wordsPacks.map((wObj) => cloneWordPack(wObj)));
        const { data: wordsData } = await ProgressService.getWords({
            lang,
        });
        userStore.setWords(wordsData.items);
    };

    const cloneSituations = async (situations) => {
        if (!situations.length) return;
        await Promise.all(
            situations.map(async (sObj) => {
                const { data } = await ProgressService.addSituation({
                    situationId: sObj.id,
                    language: lang,
                    favourite: true,
                });
                userStore.addFavDialogue(data);
            })
        );
    };

    const cloneTracks = async (tracks) => {
        if (!tracks.length) return;
        await Promise.all(
            tracks.map(async (tObj) => {
                await ProgressService.addTrack({
                    trackId: tObj.id,
                    language: lang,
                });
                userStore.addFavVideo({ id: tObj.id });
            })
        );
    };

    const cloneExercises = async (exercises) => {
        if (!exercises.length) return;
        await Promise.all(
            exercises.map((eObj) =>
                ProgressService.addExercise({
                    exerciseId: eObj.id,
                    lang,
                    type: eObj.exerciseType,
                    status: StudentExerciseStatuses.Unfinished,
                    data: {},
                })
            )
        );
        const { data } = await ProgressService.getExercises({ lang });
        userStore.setExercises(data.items);
    };

    const syncLessons = async (data) => {
        const lessonData = await ExerciseService.getLesson({ id: data.id });
        const newLesson = { ...data, chapters: lessonData.data.chapters };

        let theme = themes.find((t) => t.title === data.topic);

        if (!theme || !theme.id) {
            theme = { id: uuid(), title: data.topic, lessons: [newLesson] };
            lessonsStore.addTheme(theme);
            return;
        }

        lessonsStore.addLesson(theme.id, newLesson);
    };

    return (
        <div
            className={`${cl.addButton} ${copied ? cl.copied : ''}`}
            onClick={isUser ? cloneUserContent : cloneLesson}
        >
            {isLoading || isUserLoading ? (
                <SmallLoader size={14} color={'var(--white)'} />
            ) : (
                t(
                    copied
                        ? isUser
                            ? 'user_view.copy_success'
                            : 'exercises.copy_success'
                        : isUser
                          ? 'user_view.copy_lesson'
                          : 'exercises.copy_lesson'
                )
            )}
        </div>
    );
};

export default observer(CloneLesson);
