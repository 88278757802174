import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import exercisesStore from '../../store/exercisesStore';
import languagesStore from '../../../../store/interface';
import onboardingStore from '../../../../features/Onboarding/store/onboarding';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import { GenerateExerciseModal, SelectExerciseModal } from './components';
import ExercisePreview from '../../../../features/ExercisePreview/ExercisePreview';
import {
    ChapterPlug,
    ChapterPreviewContainer,
    ChaptersList,
    ContentContainer,
} from '../../components';
import { LanguageLevels, Roles } from '../../../../data/common';
import {
    ChapterModals,
    Chapters,
} from '../../../../features/LessonsKanban/data/constants';
import {
    OnboardingModal,
    OnboardingKeys,
} from '../../../../features/Onboarding';

const ExercisesView = () => {
    const { t } = useTranslation();
    const { lang } = languagesStore;

    const {
        currentExercise,
        chaptersModalType,
        difficulty,
        exercises,
        exerciseSearch,
        type,
        mediaType,
        unassigned,
        view,
        isRefresh,
    } = exercisesStore;

    const [isFilter, setIsFilter] = useState(false);

    const [getExercises, isLoading] = useFetching(async () => {
        const { data } = await ExerciseService.getSavedExercises({
            offset: 0,
            // TODO add pagination @saratovkin
            limit: 1000,
            lang,
            type,
            mediaType,
            unassigned,
            difficulty: difficulty && LanguageLevels.indexOf(difficulty),
            searchString: exerciseSearch,
        });

        const exercises = data?.items ?? [];
        exercisesStore.setExercises(exercises);
        exercisesStore.setCurrentExercise(exercises[0]);
    });

    const [addExercise, addLoading] = useFetching(async ({ id }) => {
        const existing = exercises.find((e) => e.id === id);
        if (existing) {
            exercisesStore.setChaptersModalType('');
            setTimeout(() => {
                exercisesStore.setCurrentExercise(existing);
            }, 300);

            return;
        }
        const { data } = await ExerciseService.cloneExercise({ id });
        const { data: exerciseResponse } = await ExerciseService.getExercise({
            id: data.id,
        });
        exercisesStore.setChaptersModalType('');
        exercisesStore.setExercises([exerciseResponse, ...exercises]);

        setTimeout(() => {
            exercisesStore.setCurrentExercise(exerciseResponse);
        }, 300);
    });

    const isRenderPreview =
        currentExercise &&
        chaptersModalType !== Chapters.Exercise &&
        chaptersModalType !== ChapterModals[Chapters.Exercise];

    useEffect(() => {
        exercisesStore.setCurrentExercise();
        getExercises();
        setIsFilter(type || exerciseSearch || difficulty);
    }, [lang, type, mediaType, exerciseSearch, difficulty, unassigned]);

    useEffect(() => {
        if (isRefresh) getExercises();
    }, [isRefresh]);

    useEffect(() => {
        if (view === Chapters.Exercise) {
            exercisesStore.setCurrentExercise(exercises[0]);
        }
    }, [view]);

    return (
        <ContentContainer view={Chapters.Exercise}>
            <ChaptersList
                type={Chapters.Exercise}
                isLoading={isLoading}
                chapters={exercises}
                onDelete={exercisesStore.deleteExercise}
                current={currentExercise}
                setCurrent={exercisesStore.setCurrentExercise}
                isFilter={isFilter}
            />
            {isRenderPreview ? (
                <ChapterPreviewContainer>
                    <ExercisePreview
                        type={Roles.Teacher}
                        exerciseId={currentExercise.id}
                    />
                </ChapterPreviewContainer>
            ) : (
                <ChapterPlug />
            )}
            <SelectExerciseModal
                visible={chaptersModalType === Chapters.Exercise}
                setVisible={exercisesStore.setChaptersModalType}
                onClick={addExercise}
                isLoading={addLoading}
                alreadyAdded={exercises}
            />
            <GenerateExerciseModal
                visible={[
                    ChapterModals[Chapters.Exercise],
                    ChapterModals.ExerciseWizardWords,
                    ChapterModals.ExerciseWizardDialogues,
                ].includes(chaptersModalType)}
                setVisible={exercisesStore.setChaptersModalType}
            />
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.ExercisesInfo
                )}
                title={t('teacher_onboarding.exercises_info')}
                subtitle={t('teacher_onboarding.exercises_info_subtitle')}
                delay={500}
            />
        </ContentContainer>
    );
};

export default observer(ExercisesView);
