import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import guestOnboarding from '../../store/guestOnboarding';
import languagesStore from '../../../../store/interface';
import wordsStore from '../../../../store/words';
import { useFetching } from '../../../../hooks/useFetching';
import {
    Button,
    CloseButton,
    Loader,
    Modal,
    PrimaryButton,
    Range,
    Switch,
    ToggleButton,
} from '../../../../UI';
import { ReactComponent as IconSound } from '../../../../assets/svg/icon-sound.svg';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import userWordsStore, {
    PackSizeOptions,
    WordCardModes,
} from '../../../../features/UserPlatform/store/userWords';
import LearnWords from '../../../../features/LearnWords/LearnWords';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import InfoModal from '../InfoModal/InfoModal';
import { ContentContainer } from '../../../TeacherContent/components';
import { MediaPlayBackOptions } from '../../../../data/common';
import cl from './DemoDictionaryPreview.module.css';

const WordCard = observer(({ wordObj, onClose, className, isLoading }) => {
    const { t } = useTranslation();

    const [audio] = useState(new Audio());
    const [partsOfSpeech, setPartsOfSpeech] = useState([]);

    const handleAudioClick = (path) => {
        audio.pause();
        audio.src = path;
        setTimeout(() => {
            audio.play();
        });
    };

    const renderFormsBlock = ({ forms }) => {
        if (!forms || forms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.forms')}</p>
                <p className={cl.infoText}>{forms.join(', ')}</p>
            </div>
        );
    };

    const renderSynonymsBlock = ({ synonyms }) => {
        if (!synonyms || synonyms.length === 0) return <></>;

        return (
            <div className={cl.infoCont}>
                <p className={cl.infoLabel}>{t('demo_page.synonyms')}</p>
                <p className={cl.infoText}>{synonyms.join(', ')}</p>
            </div>
        );
    };

    useEffect(() => {
        if (wordObj) {
            audio.pause();
            audio.src = wordObj.voicePath ?? '';
        } else {
            audio.src = '';
        }
        setPartsOfSpeech(wordObj?.data?.map((w) => w.partOfSpeech ?? '') ?? []);
        if (!wordObj.data) return;
        setPartsOfSpeech(wordObj.data ?? []);
    }, [wordObj]);

    if (!wordObj) return null;

    return (
        <div className={`${cl.wordCard} ${className ? className : ''}`}>
            {isLoading ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <>
                    <div className={cl.wordText}>
                        {wordObj.language === 'de' && wordObj.article}
                        {wordObj?.word && <span>{wordObj.word}</span>}
                        {wordObj?.voicePath && (
                            <PrimaryButton
                                onClick={() =>
                                    handleAudioClick(wordObj.voicePath)
                                }
                                icon={<IconSound className={cl.soundIcon} />}
                                variant={'white'}
                            />
                        )}
                    </div>

                    {partsOfSpeech[0]?.pronunciation && (
                        <p
                            className={cl.wordText}
                        >{`${partsOfSpeech[0].pronunciation}`}</p>
                    )}
                    {partsOfSpeech.map((part) => (
                        <div className={cl.card} key={part.id}>
                            <p className={cl.partOfSpeech}>
                                {part.partOfSpeech}
                            </p>
                            {renderFormsBlock(part)}
                            {renderSynonymsBlock(part)}
                            {part.examples && part.examples.length !== 0 && (
                                <div className={cl.infoCont}>
                                    <p className={cl.infoLabel}>
                                        {t('demo_page.phrases')}
                                    </p>
                                    {part.examples.map((ex) => (
                                        <p
                                            className={cl.exampleText}
                                            key={ex.id}
                                        >
                                            <PrimaryButton
                                                onClick={() =>
                                                    handleAudioClick(
                                                        ex.voicePath
                                                    )
                                                }
                                                icon={
                                                    <IconSound
                                                        className={cl.soundIcon}
                                                    />
                                                }
                                                variant={'white'}
                                            />
                                            {ex.example}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}

            <CloseButton onClick={onClose} />
        </div>
    );
});

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <Button
                variant={'grey'}
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
                icon={<IconSettings />}
            />

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                            />
                        </div>
                    ))}
                </div>
                <p className={cl.toggleLabel}>
                    {t('glossary_settings.words_in_pack')}
                </p>
                <div className={cl.packSizeSelector}>
                    <ToggleButton
                        value={wordsPerPack}
                        onChange={userWordsStore.setWordsPerPack}
                        options={PackSizeOptions}
                        isGrid
                        variant={'transparent'}
                    />
                </div>
                <p> {t('glossary_settings.playback_speed')}</p>
                <Range
                    value={playbackSpeed}
                    min={MediaPlayBackOptions[0]}
                    step={MediaPlayBackOptions[1] - MediaPlayBackOptions[0]}
                    max={MediaPlayBackOptions[MediaPlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
            </Modal>
        </>
    );
});

const StudentWords = observer(({ wordPack }) => {
    const { words } = wordPack;

    return (
        <ContentContainer className={cl.learnWordsWrapper}>
            <LearnWords words={words} alreadyFetched={true} />
        </ContentContainer>
    );
});

const DemoDictionaryPreview = ({ wordPack, isStudent }) => {
    const { lang, nativeLang } = languagesStore;
    const { t } = useTranslation();

    const requestIdRef = useRef(0);

    const { title, words } = wordPack;

    const [currentWords, setCurrentWords] = useState([]);
    const [currentWord, setCurrentWord] = useState(null);

    const [fetchWords, isLoading] = useFetching(
        async (wordsArray, requestId) => {
            const fetchedWords = await Promise.all(
                wordsArray.map(async (wordItem) => {
                    try {
                        const { data: wObj } =
                            await wordsStore.getCachedWordByText({
                                word: wordItem.word,
                                sourceLang: lang,
                                targetLang: nativeLang,
                            });

                        return {
                            ...wordItem,
                            ...wObj,
                            ...wObj.data[0],
                            lastIteration: Math.floor(Math.random() * 8),
                        };
                    } catch (e) {
                        return {
                            ...wordItem,
                            lastIteration: Math.floor(Math.random() * 8),
                        };
                    }
                })
            );

            if (requestId !== requestIdRef.current) return;

            setCurrentWord(fetchedWords[0]);
            setCurrentWords(fetchedWords);
        }
    );

    useEffect(() => {
        if (!words || words.length === 0) return;

        const requestId = ++requestIdRef.current;
        setCurrentWords([]);
        fetchWords(words, requestId);
    }, [wordPack, lang, nativeLang]);

    return (
        <>
            {isStudent ? (
                <div className={cl.studentContainer}>
                    <StudentWords
                        wordPack={{
                            ...wordPack,
                            words: currentWords,
                        }}
                    />
                </div>
            ) : (
                <div className={cl.previewContainer}>
                    <div className={cl.category}>
                        <p className={cl.title}>{title}</p>
                        <div className={cl.line} />

                        <div className={cl.wordsList}>
                            {currentWords.map((w, i) => (
                                <div
                                    className={`${cl.word} ${
                                        currentWord?.word === w.word
                                            ? cl.active
                                            : ''
                                    }`}
                                    key={w.word}
                                    onClick={() => setCurrentWord(w)}
                                >
                                    <span>{`${i + 1}.`}</span>
                                    <p>{w.word}</p>
                                </div>
                            ))}
                        </div>

                        {currentWord && (
                            <WordCard
                                wordObj={currentWord}
                                onClose={() => setCurrentWord()}
                                lang={nativeLang}
                                isLoading={isLoading}
                            />
                        )}
                    </div>
                </div>
            )}
            <InfoModal
                delay={200}
                onboardingKey={guestOnboarding.isOnboarding(
                    Chapters.Dictionary
                )}
                variant={Chapters.Dictionary}
                title={t('teacher_tutorial.word_packs_info')}
                subtitle={t('teacher_tutorial.word_packs_info_subtitle')}
            />
        </>
    );
};

export default observer(DemoDictionaryPreview);
