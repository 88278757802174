import React from 'react';
import { useTranslation } from 'react-i18next';
import GapWord from '../GapWord/GapWord';
import { Label } from '../../../../../../../../UI';
import { GrammarExerciseTypes } from '../../../../../../data/constants';
import cl from './SubstitutionEditor.module.css';

const SubstitutionEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const { sentences } = exerciseData;

    const handleWordToggle = (sentenceId, wordId) => {
        onChange({
            sentences: sentences?.map((s) =>
                s.id === sentenceId
                    ? {
                          ...s,
                          words: s?.words?.map((w) => {
                              if (w.id !== wordId) return w;

                              const updatedWord = { ...w };

                              if (w.hidden) {
                                  delete updatedWord.hidden;
                              } else {
                                  updatedWord.hidden = true;
                              }

                              return updatedWord;
                          }),
                      }
                    : s
            ),
        });
    };

    return (
        <div className={cl.textCont}>
            <Label text={t('exercises.edit_substitution_label')} />
            <div className={cl.exerciseText}>
                <div className={cl.textInner}>
                    {sentences?.map((s) => (
                        <div key={s.id} className={cl.sentence}>
                            {s.words?.map((word) => (
                                <GapWord
                                    key={word.id}
                                    type={GrammarExerciseTypes.Substitution}
                                    word={word}
                                    onChange={() =>
                                        handleWordToggle(s.id, word.id)
                                    }
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SubstitutionEditor;
