import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AdvancedQuizStatuses } from '../../../../../../data/constants';
import {
    AdjustableTextarea,
    Label,
    ToggleButton,
} from '../../../../../../../../UI';
import { AddButton } from '../../../';
import cl from './AdvancedQuizEditor.module.css';

const AdvancedQuizEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const questions = exerciseData.questions || [];

    const handleQuestionAdd = () => {
        onChange({
            questions: [...questions, { id: uuid(), text: '', options: [] }],
        });
    };

    const handleQuestionTextChange = (id, text) => {
        onChange({
            questions: questions.map((q) => (q.id === id ? { ...q, text } : q)),
        });
    };

    const handleQuestionStatusChange = (id, status) => {
        onChange({
            questions: questions.map((q) =>
                q.id === id ? { ...q, status } : q
            ),
        });
    };

    const handleQuestionDelete = (id) => {
        onChange({
            questions: questions.filter((q) => q.id !== id),
        });
    };

    return (
        <div>
            <Label text={t('exercises.edit_advanced_quiz_label')} />
            <AddButton
                text={t(
                    questions.length
                        ? 'exercises.new_statement'
                        : 'exercises.first_statement'
                )}
                onClick={handleQuestionAdd}
            />
            <div className={cl.advancedQuizEditor}>
                {questions?.length ? (
                    questions.map((q, qIdx) => (
                        <div className={cl.questionCont} key={q.id}>
                            <AdjustableTextarea
                                className={cl.question}
                                placeholder={t('exercises.add_statement')}
                                value={q.text}
                                onChange={(text) =>
                                    handleQuestionTextChange(q.id, text)
                                }
                                onDelete={() => handleQuestionDelete(q.id)}
                                isFocus={!q.text.length}
                                showDelete
                            />
                            <ToggleButton
                                variant={'outlined'}
                                options={AdvancedQuizStatuses}
                                value={q.status}
                                onChange={(s) =>
                                    handleQuestionStatusChange(q.id, s)
                                }
                                withTranslations={'exercises'}
                                style={{ maxHeight: 25 }}
                            />
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AdvancedQuizEditor;
