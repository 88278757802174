import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userStore from '../../store/userStore';
import userDialoguesStore from '../UserDialogues/store/userDialogues';
import {
    EmptyChaptersAlert,
    Loader,
    PageWrapper,
    ViewTitle,
} from '../../../../UI';
import {
    UserChapterSection,
    UserChapterPreview,
    UserContentContainer,
} from '../../components';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { ReactComponent as IconStar } from '../../../../assets/svg/user-icon-star.svg';
import cl from './UserToDo.module.css';

const UserToDo = ({ isLoading }) => {
    const { t } = useTranslation();
    const { activeChapter } = userStore;

    const { situations } = userDialoguesStore;
    const { favDialogues, favExercises, favVideos, favWordPacks } = userStore;

    const chaptersList = [
        {
            items: favExercises,
            type: Chapters.Exercise,
        },
        {
            items: situations.length
                ? favDialogues.map((dObj) =>
                      situations.find((sObj) => sObj.id === dObj.situationId)
                  )
                : [],
            type: Chapters.Situation,
        },

        {
            items: favVideos,
            type: Chapters.Track,
        },
        {
            items: favWordPacks.filter((p) => p.words?.length),
            type: Chapters.Dictionary,
        },
    ];

    const getCurrentChapterType = () => {
        if (!activeChapter.id) return null;

        if (activeChapter.info) return Chapters.Situation;
        if (activeChapter.externalId) return Chapters.Track;
        if (activeChapter.type) return Chapters.Exercise;
        if (activeChapter.wordsCount) return Chapters.Dictionary;

        return null;
    };

    const toDoSize = chaptersList.flatMap((c) => c.items).length;

    return (
        <PageWrapper additionalClass={cl.todoWrapper}>
            <ViewTitle
                text={`${t('user_view.todo')} (${toDoSize})`}
                icon={<IconStar className={cl.iconStar} />}
            />
            {isLoading ? (
                <Loader style={{ margin: 'auto' }} />
            ) : toDoSize === 0 ? (
                <EmptyChaptersAlert
                    title={t(`user_view.empty_todo_title`)}
                    subtitle={t(`user_view.empty_todo_subtitle`)}
                />
            ) : (
                <UserContentContainer variant="round">
                    <span className={cl.plug} />
                    <div className={cl.toDoChapters}>
                        {chaptersList.map((c) => (
                            <UserChapterSection
                                key={c.type}
                                chapters={c.items}
                                type={c.type}
                                isWordPack={c.type === Chapters.Dictionary}
                            />
                        ))}
                    </div>
                    <UserChapterPreview
                        chapter={activeChapter}
                        type={getCurrentChapterType()}
                        isWordPack
                        isTodo
                    />
                </UserContentContainer>
            )}
        </PageWrapper>
    );
};

export default observer(UserToDo);
