import React from 'react';
import { motion } from 'framer-motion';
import LanguageBubble from '../LanguageBubble/LanguageBubble';
import cl from './BubblesParalax.module.css';

const languages = ['en', 'de', 'it', 'pt', 'fr', 'es'];

const BubblesParalax = () => {
    return (
        <motion.div
            className={cl.bubbles}
            initial={{ opacity: 0, y: 70 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: 'easeOut', delay: 0.2 }}
        >
            {languages.map((l) => (
                <LanguageBubble key={l} language={l} />
            ))}
        </motion.div>
    );
};

export default BubblesParalax;
