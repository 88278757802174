import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button, ViewTitle } from '../../../../UI';
import teachersStore from '../../../../pages/Teachers/store/teachersStore';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/arrow_left.svg';

const LessonsTitle = () => {
    const { t } = useTranslation();
    const { currentTeacher } = teachersStore;

    const handleTeacherClose = () => {
        teachersStore.setCurrentTeacher(null);
    };

    const getViewText = () => {
        if (currentTeacher) return `${currentTeacher.name}`;

        return t(`navbar.teachers`);
    };

    const getViewChildren = () => {
        if (!currentTeacher) return null;

        return (
            <>
                <Button
                    style={{
                        marginBottom: -2,
                        padding: '2px 11px',
                        marginRight: 20,
                    }}
                    icon={<ArrowLeft />}
                    variant={'red'}
                    onClick={handleTeacherClose}
                />
            </>
        );
    };

    return <ViewTitle text={getViewText()} children={getViewChildren()} />;
};

export default observer(LessonsTitle);
