import React, { useState, useEffect } from 'react';
import shuffleArray from '../../../../utils/shuffleArray';
import cl from './SubstitutionPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';

const SubstitutionPreview = ({
    exerciseObj,
    results,
    showAnswers,
    isPdf = false,
    isPreview = false,
}) => {
    const { data } = exerciseObj;
    const { sentences } = data;

    const [words, setWords] = useState([]);
    const [gaps, setGaps] = useState([]);
    const [gapAnswers, setGapAnswers] = useState([]);

    useEffect(() => {
        if (!sentences || !sentences.length) return;
        const flatWords = sentences.map((s) => s.words).flat();
        const gapWords = flatWords.filter((w) => w?.hidden);
        if (results) {
            const userAnswers =
                results?.userAnswers?.map((a) => a[0] || '') || [];
            setGapAnswers(
                gapWords.map((w, i) => ({
                    word: w,
                    answer: { word: userAnswers[i] },
                }))
            );

            setGaps([]);
        } else {
            setGapAnswers(gapWords.map((w) => ({ word: w, answer: {} })));
            setGaps(
                shuffleArray(
                    [...gapWords].map((w, i) => ({ word: w.word, pos: i + 1 }))
                )
            );
        }

        setWords(flatWords);
    }, [sentences]);

    const renderWord = (w) => {
        if (!w.hidden) return w.word;
        const answer = gapAnswers.find((a) => a.word.id === w.id).answer;

        const classNames = [cl.gap];

        if (results || showAnswers) {
            classNames.push(w.word === answer.word ? cl.correct : cl.wrong);
        }

        if (isPdf) classNames.push(cl.pdf);

        return (
            <span
                key={w.id}
                className={classNames.join(' ')}
                onClick={() => handleRemoveAnswer(w)}
            >
                {answer?.word?.toLowerCase()}
            </span>
        );
    };

    const handleSaveAnswer = (answer) => {
        const pos = gapAnswers.find((a) => !a.answer.word);
        setGapAnswers(
            gapAnswers.map((w) =>
                w.word.id === pos.word.id ? { ...w, answer: answer } : w
            )
        );
        setGaps(gaps.filter((g) => g.pos !== answer.pos));
    };

    const handleRemoveAnswer = (word) => {
        const answer = gapAnswers.find((a) => a.word.id === word.id).answer;
        if (!answer.pos && !answer.id) return;
        setGapAnswers(
            gapAnswers.map((w) =>
                w.word.id === word.id ? { ...w, answer: {} } : w
            )
        );
        if (answer.id) {
            setGaps(
                [{ text: word.word }, ...gaps].map((g, i) => ({
                    ...g,
                    pos: i + 1,
                }))
            );
        } else {
            setGaps([answer, ...gaps]);
        }
    };

    const displayAnswers = () => {
        setGaps([]);
        setGapAnswers(
            gapAnswers.map((a) => ({
                ...a,
                answer: a.word,
            }))
        );
        setWords(words.map((w) => ({ ...w, isCorrect: undefined })));
    };

    const resetAnswers = () => {
        if (!words.length || results) return;
        setGapAnswers(gapAnswers.map((a) => ({ ...a, answer: '' })));
        setGaps(
            shuffleArray(
                [...words.filter((w) => w.hidden)].map((w, i) => ({
                    word: w.word,
                    pos: i + 1,
                }))
            )
        );
    };

    useEffect(() => {
        showAnswers ? displayAnswers() : resetAnswers();
    }, [showAnswers]);

    const getLabel = () => {
        if (isPreview) return 'substitution_preview_info';
        if (isPdf) return 'substitution_pdf_info';
        return 'options';
    };

    return (
        <div className={cl.substitutionContainer}>
            {results && <QuestionsLabel label={'student_answers'} />}
            {words.length !== 0 && (
                <div className={cl.exerciseText}>
                    {words.map((w) => (
                        <span className={cl.wordContainer} key={w.id}>
                            {renderWord(w)}
                            {w.endChar && <span>{w.endChar}</span>}
                        </span>
                    ))}
                </div>
            )}
            {gapAnswers.length !== 0 && (
                <>
                    {!results && gaps.length !== 0 && (
                        <QuestionsLabel
                            label={getLabel()}
                            variant={isPreview ? 'demo' : ''}
                        />
                    )}
                    <div className={`${cl.answers} ${isPdf ? cl.pdf : ''}`}>
                        {gaps.map((gap, index) => (
                            <span
                                key={`${gap}${index}`}
                                className={cl.answer}
                                onClick={() => handleSaveAnswer(gap)}
                            >
                                {gap?.word?.toLowerCase()}
                            </span>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default SubstitutionPreview;
