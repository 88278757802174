import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useFetching } from '../../../../../../hooks/useFetching';
import { authStore } from '../../../../../../App';
import languagesStore from '../../../../../../store/interface';
import SituationsService, {
    URL,
} from '../../../../../../api/SituationsService';
import { getAvailableOptions } from '../../../../../SituationsAdministration/helpers/getAvailableOptions';
import {
    GENDERS,
    MODALITIES,
} from '../../../../../SituationsAdministration/data/constants';
import Statements from '../Statements/Statements';
import { MicroButton, ToggleButton } from '../../../../../../UI';
import LearnSituation from '../../../../../../features/LearnSituation/LearnSituation';
import { Chapters } from '../../../../../../features/LessonsKanban/data/constants';
import { Roles } from '../../../../../../data/common';
import { ReactComponent as IconSound } from '../../../../../../assets/svg/icon-sound.svg';
import { ReactComponent as IconHappy } from '../../../../../../assets/svg/lessons-happy.svg';
import { ReactComponent as IconNeutral } from '../../../../../../assets/svg/lessons-neutral.svg';
import { ReactComponent as IconSad } from '../../../../../../assets/svg/lessons-sad.svg';
import { ReactComponent as IconMale } from '../../../../../../assets/svg/lessons-male.svg';
import { ReactComponent as IconFemale } from '../../../../../../assets/svg/lessons-female.svg';
import { ReactComponent as IconVisible } from '../../../../../../assets/svg/visible.svg';
import cl from './Situation.module.css';

const ICONS = [
    <IconHappy className={cl.happy} />,
    <IconNeutral className={cl.neutral} />,
    <IconSad className={cl.sad} />,
];

const Situation = ({ situation, activeLang, hideSettings = false }) => {
    const { t } = useTranslation();

    const lang = activeLang ?? languagesStore.lang;
    const isUser = authStore.user.role === Roles.User;

    const { id } = situation;

    const [isStudentPreview, setIsStudentPreview] = useState(false);
    const [situationData, setSituationData] = useState({});
    const [gender, setGender] = useState();
    const [modality, setModality] = useState();
    const [availableOptions, setAvailableOptions] = useState({});
    const [audio] = useState(new Audio());
    const [currentPlaying, setCurrentPlaying] = useState('');
    const [isPlay, setIsPlay] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [selectOptions, setSelectOptions] = useState(false);

    const [getSituation, isSituationLoading, isEmpty, resetEmpty] = useFetching(
        async ({ gender, modality }) => {
            const { data } = await SituationsService.getSituation({
                id,
                language: lang.toUpperCase(),
                gender,
                modality,
                targetLanguage: lang.toUpperCase(),
            });

            const { statements, info } = data;
            if (!statements?.length) {
                setIsGenerating(true);
                return;
            }
            setIsGenerating(false);
            resetEmpty();
            setSituationData({
                ...situation,
                statements,
                situationInfoId: info.id,
                processed: info.processed,
            });
        }
    );

    const [getSituationInfo] = useFetching(async () => {
        const { data } = await SituationsService.getSituationInfo({
            id,
            language: lang.toUpperCase(),
        });
        const { info } = data;
        if (!info || !info.length) {
            setIsGenerating(true);
            return;
        }
        setIsGenerating(false);
        setAvailableOptions(info?.length ? getAvailableOptions(info) : {});
        setGender(info[0]?.gender || GENDERS[0]);
        setModality(info[0]?.modality || MODALITIES[0]);
    });

    const handleGenderChange = (gender) => {
        setSelectOptions(false);
        if (
            availableOptions[gender] &&
            Object.keys(availableOptions[gender])?.length > 0
        ) {
            handleModalityChange(Object.keys(availableOptions[gender])[0]);
        }
        setGender(gender);
    };

    const handleModalityChange = (modality) => {
        setSelectOptions(false);
        setModality(modality);
    };

    const getAvailableGenders = () => Object.keys(availableOptions) || [];

    const getAvailableModalities = () => {
        if (availableOptions[gender]) {
            return Object.keys(availableOptions[gender]) || [];
        } else {
            return [];
        }
    };

    const handleStatementsPlay = () => setIsPlay(!isPlay);

    const renderControlButtonIcon = () => {
        const modalityIcon = ICONS[MODALITIES.indexOf(modality)];
        const genderIcon =
            gender === GENDERS[0] ? <IconMale /> : <IconFemale />;

        return (
            <div className={`${cl.icons} ${selectOptions ? cl.white : ''}`}>
                {genderIcon}
                {modalityIcon}
            </div>
        );
    };

    useEffect(() => {
        if (!situationData || !situationData.statements) return;

        const { statements } = situationData;
        let index = 0;

        const playNextAudio = () => {
            if (index >= statements.length) return;
            const statement = statements[index];
            if (!statement.phraseVoice) {
                index++;
                setTimeout(() => {
                    playNextAudio();
                }, 100);
                return;
            }
            audio.src = `${URL}${statement.phraseVoice}`;
            setCurrentPlaying(statement.id);

            audio.play();
        };

        const playNextCallback = () => {
            index++;
            setTimeout(() => {
                playNextAudio();
            }, 100);
        };

        audio.addEventListener('ended', playNextCallback);
        if (isPlay) {
            playNextAudio();
        } else {
            audio.removeEventListener('ended', playNextCallback);
            audio.src = '';
            setCurrentPlaying('');
            audio.pause();
        }

        return () => {
            audio.removeEventListener('ended', playNextCallback);
        };
    }, [isPlay]);

    useEffect(() => {
        audio.src = '';
        audio.pause();
        setCurrentPlaying('');
        setIsPlay(false);
        setIsStudentPreview(false);
    }, [situation, lang]);

    useEffect(
        () => () => {
            audio.src = '';
            audio.pause();
            setIsPlay(false);
            setCurrentPlaying('');
        },
        []
    );

    useEffect(() => {
        if (gender && modality) {
            getSituation({ gender, modality });
        }
    }, [situation, gender, modality]);

    useEffect(() => {
        if (!situation.info) {
            getSituationInfo();
        } else {
            setAvailableOptions(
                situation.info?.length
                    ? getAvailableOptions(situation.info)
                    : {}
            );
            setGender(situation.info[0]?.gender || GENDERS[0]);
            setModality(situation.info[0]?.modality || MODALITIES[0]);
        }
    }, [situation]);

    useEffect(() => {
        if (isEmpty) setSituationData({});
    }, [isEmpty]);

    useEffect(() => {
        setIsPlay(false);
    }, [isStudentPreview]);

    if (isStudentPreview)
        return (
            <LearnSituation
                situation={situation}
                isModal
                onClose={setIsStudentPreview}
                hideSettings
            />
        );

    return (
        <div className={cl.situation}>
            <div className={cl.titleContainer}>
                <p className={cl.situationTitle}>{situation.title}</p>
                {!isGenerating && (
                    <div className={cl.microButtons}>
                        <MicroButton
                            onClick={() => setSelectOptions(!selectOptions)}
                            icon={renderControlButtonIcon()}
                            variant={selectOptions ? 'dark' : 'grey'}
                            size={'regular'}
                        />
                        <MicroButton
                            onClick={handleStatementsPlay}
                            variant={isPlay ? 'dark' : 'grey'}
                            size={'regular'}
                            icon={
                                <IconSound
                                    className={`${cl.buttonIcon} ${isPlay ? '' : cl.dark}`}
                                />
                            }
                        />
                        {!isUser && (
                            <MicroButton
                                icon={<IconVisible className={cl.iconStroke} />}
                                variant={Chapters.Situation}
                                size={'regular'}
                                onClick={() => setIsStudentPreview(true)}
                            />
                        )}
                    </div>
                )}
            </div>
            {selectOptions && (
                <div className={cl.infoBlock}>
                    <ToggleButton
                        options={GENDERS}
                        availableOptions={getAvailableGenders()}
                        value={gender}
                        onChange={handleGenderChange}
                        withTranslations={'situations'}
                        variant={'transparent'}
                        isGrid
                    />
                    <ToggleButton
                        options={MODALITIES}
                        icons={ICONS}
                        availableOptions={getAvailableModalities()}
                        value={modality}
                        onChange={handleModalityChange}
                        withTranslations={'situations'}
                        variant={'transparent'}
                        isGrid
                        disabled
                    />
                </div>
            )}
            {isGenerating ? (
                <div className={cl.generatingAlert}>
                    <p className={cl.alertTitle}>
                        {t('exercises.situation_generating_title')}
                    </p>
                    <p className={cl.alertSubtitle}>
                        {t('exercises.situation_generating_subtitle')}
                    </p>
                </div>
            ) : (
                <Statements
                    situation={situationData}
                    isEmpty={isEmpty}
                    isLoading={isSituationLoading}
                    currentPlaying={currentPlaying}
                />
            )}
        </div>
    );
};

export default observer(Situation);
