import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Tooltip } from '../';
import { ReactComponent as IconClose } from '../../assets/svg/lessons-cross_white.svg';
import cl from './Tree.module.css';

const OptionsBottomOffset = 4;

const Tree = ({
    value,
    variant,
    size,
    label,
    options = [],
    onChange,
    style,
    height,
    hideReset,
    ignoreOverflow,
    className,
    disabled,
    tooltip,
    alertMessage,
}) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);
    const [displayAbove, setDisplayAbove] = useState(false);
    const [expandedChapters, setExpandedChapters] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    const toggleChapter = (chapter) => {
        setExpandedChapters((prev) => ({
            ...prev,
            [chapter]: !prev[chapter],
        }));
    };

    const ref = useRef();

    function handleSelectClick(e) {
        const elClasses = e.target.classList;
        if (
            (!elClasses.contains(cl.tree) &&
                !elClasses.contains(cl.arrowUp) &&
                !elClasses.contains(cl.arrowDown) &&
                !elClasses.contains(cl.value)) ||
            !options?.length ||
            disabled
        ) {
            return;
        }
        setExpanded(!expanded);
    }

    const handleOptionClick = (value) => {
        onChange(value);
        setExpanded(false);
    };

    const getActive = () =>
        options.flatMap((o) => o.topics).find((tObj) => tObj.topic === value) ||
        value
            ? { topic: value }
            : null;

    const isChapterExpanded = (chapterData) =>
        searchValue ? true : expandedChapters[chapterData.chapter];

    const handleResetButton = (e) => {
        e.stopPropagation();
        setExpanded(false);
        onChange('');
    };

    const renderResetButton = () => {
        if (!getActive() || hideReset) return null;
        return (
            <div className={cl.resetButton}>
                <Button
                    variant={'dark'}
                    size={'tiny'}
                    icon={<IconClose style={{ width: 22 }} />}
                    onClick={handleResetButton}
                />
            </div>
        );
    };

    const getDisplayedValue = () => {
        if (!options.length) <div className={cl.value}>{''}</div>;
        if (expanded)
            return (
                <Input
                    placeholder={t('library.search')}
                    variant={'transparent'}
                    value={searchValue}
                    onChange={setSearchValue}
                    autoFocus
                    onEnterKey={handleEnterPress}
                />
            );

        const current = getActive();
        return current?.topic ? (
            <div className={`${cl.value} ${cl.active}`}>{current.topic}</div>
        ) : (
            <div className={cl.value}>{label}</div>
        );
    };

    const handleEnterPress = () => {
        if (!filteredOptions.length) {
            onChange(searchValue);
            setExpanded(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setExpanded(false);
                setExpandedChapters({});
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        if (expanded) {
            const rect = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const optionsHeight = height || 200;

            setDisplayAbove(rect.bottom + optionsHeight > windowHeight);

            if (ignoreOverflow) {
                const options = ref.current.querySelector(`.${cl.options}`);
                if (options) {
                    options.style.position = 'fixed';
                    options.style.top = displayAbove
                        ? `${rect.top - optionsHeight}px`
                        : `${rect.bottom + OptionsBottomOffset}px`;
                    options.style.left = `${rect.left}px`;
                    options.style.width = `${rect.width}px`;
                    options.style.zIndex = 1000;
                }
            }
        }
    }, [expanded, height, ignoreOverflow, displayAbove]);

    useEffect(() => {
        const lowerSearchValue = searchValue.toLowerCase();
        const newFilteredOptions = options
            .map((chapter) => ({
                ...chapter,
                topics: chapter.topics.filter((topic) =>
                    topic.topic.toLowerCase().includes(lowerSearchValue)
                ),
            }))
            .filter((chapter) => chapter.topics.length > 0);

        setFilteredOptions(newFilteredOptions);
    }, [searchValue, options]);

    useEffect(() => {
        setSearchValue('');
    }, [expanded]);

    return (
        <div
            className={`${cl.tree} ${expanded ? cl.expanded : ''} ${
                cl[variant]
            } ${size ? cl[size] : ''} ${!options?.length ? cl.disabled : ''} ${
                disabled ? cl.disabled : ''
            } ${className ? className : ''}`}
            style={style}
            onClick={handleSelectClick}
            ref={ref}
        >
            {getDisplayedValue()}
            {renderResetButton()}
            {!expanded ? (
                <div className={cl.arrowDown} />
            ) : (
                <>
                    <div className={cl.arrowUp} />
                    <div
                        className={`${cl.options} ${displayAbove ? cl.up : cl.down}`}
                        style={height ? { maxHeight: height } : {}}
                    >
                        {filteredOptions.length ? (
                            filteredOptions.map((data, chapterIndex) => (
                                <div
                                    key={chapterIndex}
                                    className={cl.treeChapter}
                                >
                                    <div
                                        className={`${cl.chapterContainer} ${isChapterExpanded(data) ? cl.active : ''}`}
                                        onClick={() =>
                                            toggleChapter(data.chapter)
                                        }
                                    >
                                        <div
                                            className={
                                                expandedChapters[data.chapter]
                                                    ? cl.arrowDown
                                                    : cl.arrowRight
                                            }
                                        />

                                        {data.chapter}
                                    </div>
                                    {isChapterExpanded(data) && (
                                        <div className={cl.topicContainer}>
                                            {data.topics.map((tObj) => (
                                                <div
                                                    key={tObj.id}
                                                    className={`${cl.topic} ${
                                                        value === tObj.topic
                                                            ? cl.active
                                                            : ''
                                                    }`}
                                                    onMouseDown={() =>
                                                        handleOptionClick(
                                                            tObj.topic
                                                        )
                                                    }
                                                >
                                                    {tObj.topic}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p className={cl.emptyAlert}>
                                {alertMessage ??
                                    t('lesson_wizard.topic_not_found')}
                            </p>
                        )}
                    </div>
                </>
            )}
            {disabled && tooltip && (
                <Tooltip
                    className={cl.tooltip}
                    variant={'warning'}
                    text={tooltip}
                    tailPosition={'bottom'}
                />
            )}
        </div>
    );
};

export default Tree;
