import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { authStore } from '../../../../App';
import languagesStore from '../../../../store/interface';
import userStore from '../../store/userStore';
import userWordsStore, { WordStatuses } from '../../store/userWords';
import wordsStore from '../../../../store/words';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import { SmallLoader } from '../../../../UI';
import { mapChapterData } from '../../../../pages/TeacherContent/helpers/mapChapterData';
import {
    Chapters,
    StudentExerciseStatuses,
} from '../../../LessonsKanban/data/constants';
import {
    ExerciseIcon,
    ExerciseMediaIcon,
} from '../../../../pages/TeacherContent/views/ExercisesView/components';
import { ChapterActionButton } from '..';
import { ReactComponent as IconFolder } from '../../../../assets/svg/lessons-folder.svg';
import cl from './UserChapterCard.module.css';
import userDialoguesStore from '../../pages/UserDialogues/store/userDialogues';
import userVideosStore from '../../pages/UserVideos/store/userVideos';
import userExercisesStore from '../../pages/UserExercises/store/userExercises';
import { UserWordsCategory } from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import {
    GrammarExerciseTypes,
    MediaExerciseTypes,
} from '../../../../pages/TeacherContent/data/constants';

const UserChapterCard = ({
    chapter,
    type,
    isActive,
    setActive,
    isDemo,
    isWordPack,
    isSelected = false,
    isTodo,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    const { lang } = languagesStore;
    const { user } = authStore;
    const { gender } = user;

    const [isLoaded, setIsLoaded] = useState(false);
    const [mappedChapter, setMappedChapter] = useState({});

    const isSelectedWordPack =
        isWordPack && (isSelected || chapter.id === UserWordsCategory);

    const { title, preview, params = [] } = mappedChapter;

    const isWord = type === Chapters.Dictionary && !isWordPack;
    const isHistory = location.pathname.includes('history');

    const handleChapterClick = () => {
        if (!setActive || isActive || chapter.pending) return;
        setActive(chapter);
    };

    const getChapterClassName = () =>
        `${cl.chapterCard} ${isActive ? cl.active : ''}  ${cl[type]} ${isWordPack ? cl.pack : ''} ${chapter.pending ? cl.pending : ''}`;

    const renderWordCount = () => {
        const count =
            chapter?.wordsCount ?? chapter?.words?.length ?? [].length;

        return Number.isNaN(count) ? '' : count;
    };

    const [toggleFavSituation, isSituationLoading] = useFetching(
        async (isFav) => {
            if (userDialoguesStore.currentSituation.id === chapter.id) {
                userDialoguesStore.setCurrentSituation({});
            }

            if (isFav) {
                await ProgressService.sendSituationProgress({
                    id: chapter.id,
                    lang,
                    favourite: false,
                });
                userStore.removeFavDialogue(chapter);
            } else {
                const { data } = await ProgressService.addSituation({
                    situationId: chapter.id,
                    language: lang,
                    gender,
                    favourite: true,
                });
                userStore.addFavDialogue(data);
            }
        }
    );

    const [toggleFavTrack, isTrackLoading] = useFetching(async (isFav) => {
        if (userVideosStore.currentTrack.id === chapter.id) {
            userVideosStore.setCurrentTrack({});
        }

        if (isFav) {
            await ProgressService.deleteTrack({ id: chapter.id });
            userStore.removeFavVideo({ id: chapter.id });
        } else {
            await ProgressService.addTrack({
                trackId: chapter.id,
                language: lang,
            });
            userVideosStore.removeTrack(chapter.id);
            userStore.addFavVideo({ id: chapter.id });
        }
    });

    const [removeFavWordPack, isWordPackLoading] = useFetching(async () => {
        if (userWordsStore.currentPack.id === chapter.id) {
            userWordsStore.setCurrentPack({});
        }

        userStore.removeFavWordPack({ id: chapter.id });
    });

    const [toggleFavExercise, isExerciseLoading] = useFetching(
        async (isFav) => {
            if (userExercisesStore.currentExercise.id === chapter.id) {
                userExercisesStore.setCurrentExercise({});
            }
            if (isFav) {
                await ProgressService.updateExerciseStatus({
                    exerciseId: chapter.id,
                    status: StudentExerciseStatuses.Done,
                });
                userStore.removeFavExercise(chapter.id);
            } else {
                await ProgressService.updateExerciseStatus({
                    exerciseId: chapter.id,
                    status: StudentExerciseStatuses.Unfinished,
                });
                userStore.addFavExercise(chapter);
            }
        }
    );

    const [toggleFavWord, isWordLoading] = useFetching(async ({ isFav }) => {
        if (userWordsStore.currentWord.word === chapter.word) {
            userWordsStore.setCurrentWord({});
        }
        if (isFav) {
            await ProgressService.updateWord({
                id: chapter.wordId,
                status: WordStatuses.Learned,
            });
            userStore.removeFavWord(chapter);
            userWordsStore.setDictionaryWords(
                userWordsStore.dictionaryWords.filter(
                    (wObj) => wObj.wordId !== chapter.wordId
                )
            );
            if (
                userWordsStore.currentWord &&
                userWordsStore.currentWord.id === chapter.id
            ) {
                userWordsStore.setCurrentWord({});
            }
        } else {
            await ProgressService.updateWord({
                id: chapter.wordId,
                status: WordStatuses.Active,
                lastIteration: 0,
            });
            userWordsStore.setDictionaryWords(
                userWordsStore.dictionaryWords.filter(
                    (wObj) => wObj.wordId !== chapter.wordId
                )
            );
            userStore.addFavWord(chapter);
        }
    });

    const [deleteWord, isWordDeleteLoading] = useFetching(async () => {
        if (chapter.pending) {
            wordsStore.deletePendingWord({
                word: chapter.word,
                categoryId: UserWordsCategory,
            });
            return;
        }

        if (userWordsStore.currentWord.word === chapter.word) {
            userWordsStore.setCurrentWord({});
        }
        await ProgressService.deleteWord({ id: chapter.wordId });
        userStore.deleteWord(chapter);
        userWordsStore.setDictionaryWords(
            userWordsStore.dictionaryWords.filter(
                (wObj) => wObj.wordId !== chapter.wordId
            )
        );
    });

    const renderProgress = () => {
        switch (type) {
            case Chapters.Track:
                const progress = userStore.getVideoProgress(chapter.id);
                if (!progress) return null;

                return (
                    <span className={cl.progressChip}>
                        <span
                            className={`${cl.progressFill} ${cl[Chapters.Track]}`}
                            style={{ width: `${progress}%` }}
                        />
                    </span>
                );

            case Chapters.Situation: {
                const progress = userStore.getDialogueProgress(chapter.id);
                if (
                    Object.values(progress)
                        .filter(Boolean)
                        .reduce((a, b) => a + b, 0) === 0
                ) {
                    return null;
                }

                return (
                    <>
                        {Object.entries(progress).map(([key, value]) => (
                            <span key={key} className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Situation]}`}
                                    style={{
                                        width: `${value ? value * 100 : 0}%`,
                                    }}
                                />
                            </span>
                        ))}
                    </>
                );
            }
            case Chapters.Exercise: {
                const progress = userStore.getExerciseProgress(chapter.id);

                switch (chapter.type) {
                    case MediaExerciseTypes.Summary: {
                        const done =
                            typeof progress?.selectedIndex === 'number';
                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{ width: `${done ? 100 : 0}%` }}
                                />
                            </span>
                        );
                    }
                    case MediaExerciseTypes.SentencesOrder: {
                        const done = progress?.userAnswers;
                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{ width: `${done ? 100 : 0}%` }}
                                />
                            </span>
                        );
                    }
                    case MediaExerciseTypes.AdvancedQuiz: {
                        const { userAnswers } = progress;
                        if (!userAnswers?.length)
                            return <span className={cl.progressChip} />;
                        const filled = userAnswers.filter(
                            (a) => typeof a === 'number'
                        );
                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / userAnswers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case MediaExerciseTypes.Quiz: {
                        const { userAnswers } = progress;
                        if (!userAnswers?.length)
                            return <span className={cl.progressChip} />;

                        const filled = userAnswers.filter(
                            (a) => typeof a === 'number'
                        );

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / userAnswers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case GrammarExerciseTypes.WordsOrder: {
                        const { filledSentences } = progress;
                        if (!filledSentences?.length)
                            return <span className={cl.progressChip} />;

                        const words = filledSentences.flat();
                        const filled = words.filter((s) => s);

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / words.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case GrammarExerciseTypes.Substitution: {
                        const { userAnswers } = progress;
                        if (!userAnswers?.length)
                            return <span className={cl.progressChip} />;

                        const filled = userAnswers.filter((s) => s.length);

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / userAnswers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case GrammarExerciseTypes.Multichoice: {
                        const { userAnswers } = progress;
                        if (!userAnswers)
                            return <span className={cl.progressChip} />;

                        const answers = Object.values(userAnswers);
                        if (!answers?.length)
                            return <span className={cl.progressChip} />;

                        const filled = answers.filter((a) => a);

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / answers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case GrammarExerciseTypes.Cloze: {
                        const { userAnswers } = progress;
                        if (!userAnswers)
                            return <span className={cl.progressChip} />;

                        const answers = userAnswers.flat();
                        const filled = answers.filter((a) => a);

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / answers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case GrammarExerciseTypes.StatementsTransformation:
                    case MediaExerciseTypes.FreeFormQuestions: {
                        const { userAnswers } = progress;
                        if (!userAnswers)
                            return <span className={cl.progressChip} />;

                        const answers = Object.values(userAnswers);
                        if (!answers?.length)
                            return <span className={cl.progressChip} />;

                        const filled = answers.filter((a) => a);

                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${(filled.length / answers.length) * 100}%`,
                                    }}
                                />
                            </span>
                        );
                    }
                    case MediaExerciseTypes.Presentation: {
                        return (
                            <span className={cl.progressChip}>
                                <span
                                    className={`${cl.progressFill} ${cl[Chapters.Exercise]}`}
                                    style={{
                                        width: `${progress.enteredText ? 100 : 0}%`,
                                    }}
                                />
                            </span>
                        );
                    }

                    default:
                        return null;
                }
            }

            default:
                return null;
        }
    };

    const groupWord = () => {
        userWordsStore.setGroupedWord(chapter);
    };

    const renderWordProgress = () => {
        if (!isWord) return null;

        const word = userStore.wordsIterations.find((w) => w.id === chapter.id);
        const levels = Object.keys(
            userWordsStore.cardModes.filter((flag) => flag)
        );

        if (
            !word ||
            !Number.isInteger(word.lastIteration) ||
            isDemo ||
            !word.lastIteration
        )
            return null;

        return (
            <div className={cl.wordProgress}>
                {levels.map((l) => (
                    <span
                        className={l < word.lastIteration ? cl.done : ''}
                        key={l}
                    />
                ))}
            </div>
        );
    };

    const renderWordCardSideControls = () => {
        const isFav = userStore.favWords.some(
            (wObj) => wObj.wordId === chapter.wordId
        );

        if (chapter.invalid) {
            return (
                <div className={cl.wordActionButtonsContainer}>
                    <ChapterActionButton
                        variant={Chapters.Dictionary}
                        color={'red'}
                        icon={'bin'}
                        onClick={() => deleteWord()}
                        isLoading={isWordDeleteLoading}
                    />
                </div>
            );
        }

        return (
            <>
                {renderWordProgress()}
                <div className={cl.wordActionButtonsContainer}>
                    {!isTodo && (
                        <ChapterActionButton
                            variant={Chapters.Dictionary}
                            color={isFav ? 'orange' : 'red'}
                            icon={isFav ? 'folder' : 'bin'}
                            onClick={isFav ? groupWord : deleteWord}
                            isLoading={isWordDeleteLoading}
                        />
                    )}
                    <ChapterActionButton
                        variant={Chapters.Dictionary}
                        color={isFav ? 'purple' : 'orange'}
                        icon={isFav ? 'check' : 'check'}
                        onClick={() => toggleFavWord({ isFav })}
                        isLoading={isWordLoading}
                    />
                </div>
            </>
        );
    };

    const getActionButtonsByChapter = () => {
        switch (type) {
            case Chapters.Situation: {
                const isFav = userStore.favDialogues.some(
                    (dObj) => dObj.situationId === chapter.id
                );
                return [
                    {
                        color: 'purple',
                        onClick: () => toggleFavSituation(isFav),
                        icon: isFav ? 'check' : 'star',
                        isLoading: isSituationLoading,
                    },
                ];
            }

            case Chapters.Track: {
                const isFav = userStore.favVideos.some(
                    (vObj) => vObj.id === chapter.id
                );

                return [
                    {
                        color: 'purple',
                        onClick: () => toggleFavTrack(isFav),
                        icon: isFav ? 'check' : 'star',
                        isLoading: isTrackLoading,
                    },
                ];
            }
            case Chapters.Exercise:
                const isFav = userStore.favExercises.some(
                    (eObj) => eObj.id === chapter.id
                );
                return [
                    {
                        color: 'purple',
                        onClick: () => toggleFavExercise(isFav),
                        icon: isFav ? 'check' : 'star',
                        isLoading: isExerciseLoading,
                    },
                ];
            case Chapters.Dictionary:
                return chapter.id === UserWordsCategory
                    ? []
                    : [
                          {
                              color: 'purple',
                              onClick: removeFavWordPack,
                              icon: 'check',
                          },
                      ];
            default:
                return [];
        }
    };

    const renderActionButtons = () => {
        if (!isActive || isHistory) return null;

        const buttons = getActionButtonsByChapter();

        const isLoading =
            isSituationLoading ||
            isTrackLoading ||
            isWordPackLoading ||
            isExerciseLoading;

        return (
            <div className={cl.actionButtonsContainer}>
                {buttons.map((bObj, i) => (
                    <ChapterActionButton
                        key={i}
                        color={bObj.color}
                        icon={bObj.icon}
                        onClick={bObj.onClick}
                        isLoading={isLoading}
                    />
                ))}
            </div>
        );
    };

    const renderChapterTranslation = () => {
        const cards = chapter?.data;
        if (cards && cards.length) {
            const firstCard = cards[0];
            if (firstCard || firstCard.translations);
            return firstCard.translations?.length
                ? firstCard.translations[0]
                : '';
        }
        return '';
    };

    useEffect(() => {
        setMappedChapter(mapChapterData(chapter, type, t));
    }, [chapter]);

    return (
        <div className={getChapterClassName()} onClick={handleChapterClick}>
            {!isWord && (
                <>
                    <div className={cl.imageContainer}>
                        {preview && (
                            <>
                                {!isLoaded && (
                                    <SmallLoader
                                        size={12}
                                        color={'var(--border-light-gray)'}
                                    />
                                )}
                                <img
                                    src={preview}
                                    alt={title}
                                    onLoad={() => setIsLoaded(true)}
                                />
                            </>
                        )}
                        <div className={cl.progressContainer}>
                            {renderProgress()}
                        </div>

                        {isWordPack && (
                            <>
                                <div
                                    className={
                                        isSelectedWordPack
                                            ? cl.greenFolder
                                            : cl.folder
                                    }
                                >
                                    <IconFolder />
                                </div>
                                <p
                                    className={
                                        isSelectedWordPack
                                            ? cl.whiteCount
                                            : cl.count
                                    }
                                >
                                    {renderWordCount()}
                                </p>
                            </>
                        )}
                        {type === Chapters.Exercise && (
                            <>
                                <ExerciseIcon
                                    type={chapter.exerciseType || chapter.type}
                                />
                                <ExerciseMediaIcon
                                    mediaType={chapter.mediaType}
                                />
                            </>
                        )}
                    </div>
                    {renderActionButtons()}
                </>
            )}
            {isWord ? (
                <div
                    className={`${cl.wordCard} ${chapter.invalid && !chapter.pending ? cl.invalid : ''} `}
                >
                    <div className={cl.titleCont}>
                        <p className={cl.title}>{title}</p>
                    </div>
                    <div className={cl.translation}>
                        {chapter.pending && (
                            <SmallLoader
                                className={cl.wordLoader}
                                size={12}
                                color={'var(--black)'}
                            />
                        )}
                        {renderChapterTranslation()}
                    </div>
                    {renderWordCardSideControls()}
                </div>
            ) : (
                <div className={cl.cardInner}>
                    <p className={cl.title}>{title}</p>
                    <div className={cl.infoContainer}>
                        {params.map((p, i) => (
                            <p className={`${cl.label} ${cl[type]}`} key={i}>
                                {p}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(UserChapterCard);
