import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import exercisesStore from '../../../TeacherContent/store/exercisesStore';
import lessonsStore from '../../../../features/LessonsKanban/store/lessonsStore';
import studentsStore from '../../store/studentsStore';
import { ChapterPreview } from '../../../../features/LessonsKanban/components';
import { MicroButton } from '../../../../UI';
import { Ages } from '../../../TeacherContent/data/constants';
import { LanguageLevels } from '../../../../data/common';
import ExerciseService from '../../../../api/ExerciseService';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import { StudentLessonStatuses } from '../../../../features/LessonsKanban/data/constants';
import cl from './StudentLesson.module.css';
import { iconMap } from '../../../TeacherContent/views/ExercisesView/data/constants';

const StudentLesson = ({ lessonObj, index }) => {
    const { lessonId } = lessonObj;
    const { currentStudent, collapseCards, notifications } = studentsStore;
    const { currentLesson } = lessonsStore;

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [data, setData] = useState({});
    const ref = useRef();

    const [getLessonData, isLoading] = useFetching(async () => {
        if (!lessonId) {
            setData({ ...lessonObj });
            return;
        }
        const { data } = await ExerciseService.getLesson({ id: lessonId });
        setData({ ...lessonObj, ...data });
    });

    const handleClick = () => {
        lessonsStore.setCurrentLesson(
            currentLesson?.id === data.id ? {} : { ...data }
        );
        exercisesStore.setCurrentStudent(currentStudent);
    };

    const handleCollapse = (e) => {
        e.stopPropagation();
        setIsCollapsed(!isCollapsed);
    };

    const renderChapters = () => {
        if (!data.chapters) return null;

        if (isCollapsed) {
            const countByType = data.chapters.reduce((acc, item) => {
                acc[item.type] = (acc[item.type] || 0) + 1;
                return acc;
            }, {});

            return (
                <div className={cl.collapsedChapters}>
                    {Object.entries(countByType).map(([type, count]) => (
                        <div key={type} className={cl.iconContainer}>
                            <div className={cl.icon}>{iconMap[type]}</div>
                            <p className={cl[type]}>{count}</p>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className={cl.chapters}>
                {data.chapters.map((c) => (
                    <ChapterPreview
                        key={c.id}
                        chapter={c}
                        variant={
                            data.status === StudentLessonStatuses.Available
                                ? 'grey'
                                : 'white'
                        }
                    />
                ))}
            </div>
        );
    };

    const renderNotification = () => {
        const currentNotifications = notifications.find(
            (n) => n.studentId === currentStudent.id
        );
        if (!currentNotifications) return null;

        const finished = data.chapters.filter((d) =>
            currentNotifications.exercises.includes(d.id)
        );

        return finished.length !== 0 ? (
            <div className={cl.notification}>{finished.length}</div>
        ) : null;
    };

    const renderInfo = () => (
        <div className={cl.infoContainer}>
            {typeof data.age === 'number' && (
                <p className={cl.statItem}>{Ages[data.age]}</p>
            )}
            {typeof data.difficulty === 'number' && (
                <p className={cl.statItem}>{LanguageLevels[data.difficulty]}</p>
            )}
        </div>
    );

    const isExerciseReady = (chapterId) => {
        const currentNotifications = notifications.find(
            (n) => n.studentId === currentStudent.id
        );
        if (!currentNotifications) return false;

        return currentNotifications.exercises.some((e) => e === chapterId);
    };

    useEffect(() => {
        getLessonData();
    }, [lessonObj]);

    useEffect(() => {
        setIsCollapsed(collapseCards);
    }, [collapseCards]);

    if (isLoading || !data.id) return null;

    return (
        <Draggable
            draggableId={data.id}
            index={index}
            isDragDisabled={data.status === StudentLessonStatuses.Done}
        >
            {(provided, snapshot) => (
                <div
                    className={`${cl.lessonCard} ${data.status ? cl[data.status] : ''}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div
                        className={cl.cardInner}
                        ref={ref}
                        onClick={handleClick}
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                        }}
                    >
                        <div className={cl.titleContainer}>
                            <p
                                className={cl.title}
                                title={`${data.vocabularyTopic}\n${data.grammarTopic}`}
                            >
                                {data.title}
                            </p>
                            {renderNotification()}
                            <MicroButton
                                icon={
                                    <IconArrow
                                        style={{
                                            filter: 'grayscale(1)',
                                            transform: isCollapsed
                                                ? 'rotate(180deg)'
                                                : '',
                                        }}
                                    />
                                }
                                variant={'white'}
                                size={'small'}
                                onClick={handleCollapse}
                            />
                        </div>

                        {renderInfo()}
                        {renderChapters()}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default observer(StudentLesson);
