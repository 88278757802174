import { makeAutoObservable } from 'mobx';
import { MediaPlayBackOptions } from '../../../data/common';

const DialoguesPlaybackKey = 'dialoguesPlaybackKey';

class LearnSituationStore {
    direction = 0;
    gender = '';
    info = [];
    modality = '';
    mode = '';
    playbackSpeed = MediaPlayBackOptions[MediaPlayBackOptions.length - 1];
    statements = [];
    statementIndex = 0;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
        this.init();
    }

    init() {
        this.playbackSpeed = localStorage.getItem(DialoguesPlaybackKey)
            ? JSON.parse(localStorage.getItem(DialoguesPlaybackKey))
            : this.playbackSpeed;
    }

    setDirection(direction) {
        this.direction = direction;
    }

    setGender(gender) {
        this.gender = gender;
    }

    setInfo(info) {
        this.info = info;

        if (info[0]) {
            this.setGender(info[0].gender);
            this.setModality(info[0].modality);
        }
    }

    setModality(modality) {
        this.modality = modality;
    }

    setMode(mode) {
        this.mode = mode;
    }

    setPlaybackSpeed(playbackSpeed) {
        this.playbackSpeed = playbackSpeed;
        localStorage.setItem(
            DialoguesPlaybackKey,
            JSON.stringify(playbackSpeed)
        );
    }

    setStatements(statements) {
        this.statements = statements;
    }

    setStatementIndex(statementIndex) {
        this.statementIndex = statementIndex ?? 0;
    }

    navigateStatement = (newDirection) => {
        const amount = this.statements.length;

        const nextIndex =
            (this.statementIndex + newDirection + amount) % amount;

        if (newDirection === 1 && nextIndex === 0) return;

        if (newDirection === -1 && nextIndex === amount - 1) {
            this.setStatementIndex(amount);
            return;
        }

        this.setDirection(newDirection);
        this.setStatementIndex(nextIndex);
    };

    nextStatement = () => this.navigateStatement(1);
    prevStatement = () => this.navigateStatement(-1);

    resetParams() {
        this.setMode('');
        this.setStatements([]);
        this.setStatementIndex(0);
    }
}

const learnSituationStore = new LearnSituationStore();
export default learnSituationStore;
