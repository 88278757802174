import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Label } from '../../../../UI';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import languagesStore from '../../../../store/interface';
import { authStore } from '../../../../App';
import userDialoguesStore from '../../pages/UserDialogues/store/userDialogues';
import userStore from '../../store/userStore';
import userVideosStore from '../../pages/UserVideos/store/userVideos';
import { GenerateChapterModal } from '../../../../pages/TeacherContent/components';
import { Chapters } from '../../../LessonsKanban/data/constants';
import {
    UserWordsCategory,
    WordStatuses,
} from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import { ReactComponent as IconBin } from '../../../../assets/svg/lessons-bin.svg';
import { ReactComponent as IconToDo } from '../../../../assets/svg/user-icon-star.svg';
import { ReactComponent as IconDone } from '../../../../assets/svg/lessons-exercise.svg';
import cl from './ConfirmChapterCloseModal.module.css';

const ConfirmChapterCloseModal = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { lang, nativeLang } = languagesStore;
    const { user } = authStore;
    const { gender } = user;
    const { activeChapter, chapterCloseModal, clickedWords, pendingEvent } =
        userStore;

    const hasWords = clickedWords.length !== 0;

    const { currentTrack } = userVideosStore;
    const { currentSituation } = userDialoguesStore;

    const isToDo = location.pathname.includes('todo');
    const isHistory = location.pathname.includes('history');

    const isVideo = currentTrack?.id || activeChapter?.type === Chapters.Track;
    const isDialogue =
        currentSituation?.id || activeChapter?.type === Chapters.Situation;

    const [toggleFavTrack, isTrackLoading] = useFetching(async (isFav) => {
        if (userVideosStore.currentTrack.id === chapter.id) {
            userVideosStore.setCurrentTrack({});
        }

        userVideosStore.removeTrack(chapter.id);
        if (isFav) {
            await ProgressService.deleteTrack({ id: chapter.id });
            userStore.removeFavVideo({ id: chapter.id });
        } else {
            await ProgressService.addTrack({
                trackId: chapter.id,
                language: lang,
            });
            userStore.addFavVideo({ id: chapter.id });
        }
    });

    const [toggleFavSituation, isSituationLoading] = useFetching(
        async (isFav) => {
            if (userDialoguesStore.currentSituation.id === chapter.id) {
                userDialoguesStore.setCurrentSituation({});
            }

            const progress = userStore.getDialogueProgress(chapter.id);
            const mappedProgress = {
                listeningCompleted: progress.listen,
                puzzleCompleted: progress.exercise,
                speakingCompleted: progress.speak,
                translationCompleted: progress.translation,
            };

            if (isFav) {
                await ProgressService.sendSituationProgress({
                    situationId: chapter.id,
                    lang,
                    favourite: false,
                    ...mappedProgress,
                });
                userStore.removeFavDialogue(chapter);
            } else {
                const { data } = await ProgressService.sendSituationProgress({
                    situationId: chapter.id,
                    lang,
                    favourite: true,
                    ...mappedProgress,
                });
                userStore.addFavDialogue(data);
            }
        }
    );

    const chapter = isToDo
        ? activeChapter
        : isVideo
          ? currentTrack
          : isDialogue
            ? currentSituation
            : {};

    const moveToHistory = () => {
        isVideo ? toggleFavTrack(true) : toggleFavSituation(true);
        closeModal();
    };

    const moveToToDo = () => {
        isVideo ? toggleFavTrack(false) : toggleFavSituation(false);
        closeModal();
    };

    const closeModal = () => {
        userStore.setChapterCloseModal(false);

        if (pendingEvent && pendingEvent.target) {
            pendingEvent.target?.click();
            userStore.setPendingEvent(null);
        } else {
            userStore.setActiveChapter({});
            userDialoguesStore.setCurrentSituation({});
            userVideosStore.setCurrentTrack({});
        }
    };

    const processWords = async () => {
        const active = clickedWords.filter((w) => w.active).map((w) => w.word);

        for (const [index, word] of active.entries()) {
            await new Promise((resolve) => setTimeout(resolve, index * 25));
            userStore.addWord({
                word,
                categoryId: UserWordsCategory,
                status: WordStatuses.Queued,
                targetLang: nativeLang,
            });
        }

        resetWords();
    };

    const resetWords = () => {
        userStore.setClickedWords([]);
        if (isToDo || isHistory) {
            userStore.setChapterCloseModal(false);
        }
    };

    return (
        <>
            <GenerateChapterModal
                className={cl.confirmModal}
                visible={chapterCloseModal}
                setVisible={() => {}}
                title={t(
                    hasWords
                        ? 'user_view.review_clicked_words'
                        : `user_view.select_${isVideo ? 'track' : 'situation'}_status`
                )}
                hideClose
                variant={isVideo ? Chapters.Track : Chapters.Situation}
            >
                {hasWords ? (
                    <div className={cl.wordsSection}>
                        <Label
                            text={t('user_view.select_clicked_words').replace(
                                '<span/>',
                                clickedWords.length
                            )}
                        />
                        <div className={cl.wordsList}>
                            {clickedWords.map((w) => (
                                <div className={cl.wordCard} key={w.word}>
                                    <Checkbox
                                        label={w.word}
                                        variant="checkmark"
                                        value={w.active}
                                        color={
                                            isVideo
                                                ? Chapters.Track
                                                : Chapters.Situation
                                        }
                                        onChange={() =>
                                            userStore.toggleClickedWord(w.word)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                        <div className={cl.buttonsContainer}>
                            <Button
                                icon={<IconDone />}
                                text={t('user_view.move_words_to_dictionary')}
                                onClick={processWords}
                                variant={
                                    isVideo
                                        ? Chapters.Track
                                        : Chapters.Situation
                                }
                                isLoading={isTrackLoading || isSituationLoading}
                            />
                            <Button
                                icon={<IconBin />}
                                text={t('user_view.reset_clicked_words')}
                                onClick={resetWords}
                                variant={'red'}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={cl.buttonsSection}>
                        <Label text={t('user_view.where_to_move_chapter')} />
                        <div className={cl.buttonsContainer}>
                            <Button
                                icon={<IconDone />}
                                text={t('user_view.move_to_history')}
                                onClick={moveToHistory}
                                variant={
                                    isVideo
                                        ? Chapters.Track
                                        : Chapters.Situation
                                }
                                isLoading={isTrackLoading || isSituationLoading}
                            />
                            <Button
                                icon={<IconToDo />}
                                text={t('user_view.move_to_todo')}
                                onClick={moveToToDo}
                                variant={'darkPurple'}
                                isLoading={isTrackLoading || isSituationLoading}
                            />
                        </div>
                    </div>
                )}
            </GenerateChapterModal>
        </>
    );
};

export default observer(ConfirmChapterCloseModal);
