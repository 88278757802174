import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import teachersStore from '../../store/teachersStore';
import { Input, Toolbar } from '../../../../UI';

const TeachersSearchBar = () => {
    const { t } = useTranslation();

    const { searchString, teacherSearchString } = teachersStore;

    return (
        <Toolbar>
            <Input
                placeholder={t('exercises.search_teachers')}
                value={teacherSearchString}
                onChange={teachersStore.setTeacherSearchString}
                search
                showReset
            />
            <Input
                placeholder={t('exercises.search_lessons')}
                value={searchString}
                onChange={teachersStore.setSearchString}
                search
                showReset
            />
        </Toolbar>
    );
};

export default observer(TeachersSearchBar);
