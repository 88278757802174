import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DragDropContext } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useFetching } from '../../hooks/useFetching';
import ExerciseService from '../../api/ExerciseService';
import { authStore } from '../../App';
import exercisesStore from '../../pages/TeacherContent/store/exercisesStore';
import languagesStore from '../../store/interface';
import lessonsStore from './store/lessonsStore';
import wizardStore from './components/LessonWizard/store/wizardStore';
import { Theme, EmptyKanban, LessonSearchBar } from './components';
import { Loader } from '../../UI';
import { filterThemes } from './helpers';
import { LessonsGroupBy } from './data/constants';
import { ViewTitle } from '../../UI';
import {
    onboardingStore,
    OnboardingModal,
    OnboardingKeys,
} from '../Onboarding';
import { LanguageLevels } from '../../data/common';
import cl from './LessonsKanban.module.css';

const LessonsKanban = ({ isLibrary = false, isUser = false }) => {
    const kanbanRef = useRef(null);

    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { grammarOptions, vocabularyOptions } = wizardStore;

    const {
        searchString,
        age,
        exam,
        difficulty,
        filterByStudent,
        themes,
        lessons,
        libraryLessons,
        libraryThemes,
        isAddLesson,
        currentStudentLessons,
        groupBy,
    } = lessonsStore;

    const { currentStudent } = exercisesStore;

    const [wasFetched, setWasFetched] = useState(false);
    const [filteredThemes, setFilteredThemes] = useState(themes);
    const [fileteredLessonThemes, setFilteredLessonsThemes] =
        useState(libraryThemes);

    const [getLessons, lessonsLoading, error, resetError] = useFetching(
        async () => {
            resetError();
            if (isLibrary || isUser) {
                const { data } = await ExerciseService.getLessons({
                    offset: 0,
                    limit: 100,
                    lang,
                    basic: true,
                });
                lessonsStore.setLibraryLessons(data?.items);
            } else {
                const { data } = await ExerciseService.getSavedLessons({
                    offset: 0,
                    limit: 100,
                    lang,
                });
                lessonsStore.setLessons(data?.items);
            }
            setWasFetched(true);
        }
    );

    const [updateLesson] = useFetching(async ({ id, topic }) => {
        await ExerciseService.updateLesson({ id, topic });
    });

    const [updateLessonOrder] = useFetching(async ({ id, order }) => {
        await ExerciseService.updateLessonOrder({ id, order });
    });

    const getLessonOrder = (lesson) => {
        let value = 0;
        if (groupBy === LessonsGroupBy.Grammar) {
            const gTopic = grammarOptions
                .flatMap((gObj) => gObj.topics)
                .find((tObj) => tObj?.topic === lesson?.grammarTopic);
            value = gTopic ? gTopic.topicOrder : 0;
        }
        if (groupBy === LessonsGroupBy.Vocabulary) {
            const vTopic = vocabularyOptions
                .flatMap((vObj) => vObj.topics)
                .find((tObj) => tObj?.topic === lesson?.grammarTopic);
            value = vTopic ? vTopic.topicOrder : 0;
        }
        return value;
    };

    const parseLessonsIntoThemes = (lessons) => {
        if (groupBy === LessonsGroupBy.Difficulty) {
            return groupLessonsByDifficulty(lessons);
        } else {
            return groupLessonsByParams(lessons);
        }
    };

    const groupLessonsByParams = (lessons) => {
        const sourceList =
            groupBy === LessonsGroupBy.Grammar
                ? grammarOptions
                : vocabularyOptions;

        const idToChapterMap = sourceList.reduce((acc, chapter) => {
            chapter.topics.forEach((tObj) => {
                acc[tObj.topic] = chapter.chapter;
            });
            return acc;
        }, {});

        const getTopicId = (lesson) =>
            lesson[
                groupBy === LessonsGroupBy.Grammar
                    ? 'grammarTopic'
                    : 'vocabularyTopic'
            ];

        const getThemeTitle = (lesson) =>
            idToChapterMap[getTopicId(lesson)] || t('exercises.unnamed_theme');

        const themeTitles = Array.from(new Set(lessons.map(getThemeTitle)));

        const themes = themeTitles.map((title) => ({
            id: uuid(),
            title,
            lessons: lessons
                .filter((lesson) => getThemeTitle(lesson) === title)
                .map((lesson) => ({
                    ...lesson,
                    chapters: lesson.chapters.map((chapter) => ({
                        ...chapter,
                        id: chapter.chapterId,
                    })),
                    order: getLessonOrder(lesson),
                }))
                .sort((a, b) => a.order - b.order),
        }));

        return { themes };
    };

    const groupLessonsByDifficulty = (lessons) => {
        const themes = LanguageLevels.map((level) => ({
            id: uuid(),
            title: level,
            lessons: lessons
                .filter(
                    (lesson) =>
                        lesson.difficulty === LanguageLevels.indexOf(level)
                )
                .map((lesson) => ({
                    ...lesson,
                    chapters: lesson.chapters.map((chapter) => ({
                        ...chapter,
                        id: chapter.chapterId,
                    })),
                }))
                .sort((a, b) => a.title.localeCompare(b.title)),
        })).filter((tObj) => tObj.lessons?.length);

        return { themes };
    };

    const syncLessonsOrder = (lessonsByTheme) => {
        // TODO find more optimal way to update lessons order @saratovkin
        for (const lessons of lessonsByTheme) {
            lessons.forEach((l, i) => {
                updateLessonOrder({ id: l.id, order: i });
            });
        }
    };

    const syncLessons = (lessons) => {
        const { themes } = parseLessonsIntoThemes(lessons);

        lessonsStore.setThemes(themes);
    };

    const syncLibraryLessons = (libraryLessons) => {
        const { themes } = parseLessonsIntoThemes(libraryLessons);

        lessonsStore.setLibraryThemes(themes);
    };

    const handleLessonDrag = async (result) => {
        const { destination, draggableId, source } = result;

        if (!source || !destination) return;

        const sourceTheme = themes.find((t) => t.id === source.droppableId);
        const destinationTheme = themes.find(
            (t) => t.id === destination.droppableId
        );

        if (!sourceTheme || !destinationTheme) return;

        if (sourceTheme.id === destinationTheme.id) {
            const newLessons = [...sourceTheme.lessons];
            const [movedLesson] = newLessons.splice(source.index, 1);
            newLessons.splice(destination.index, 0, movedLesson);
            lessonsStore.setThemeLessons(sourceTheme.id, newLessons);
            syncLessonsOrder([newLessons]);
            return;
        }

        const movedLesson = sourceTheme.lessons.find(
            (l) => l.id === draggableId
        );
        updateLesson({ id: movedLesson.id, topic: destinationTheme.title });
        const newSourceLessons = sourceTheme.lessons.filter(
            (l) => l.id !== draggableId
        );

        lessonsStore.setThemeLessons(sourceTheme.id, newSourceLessons);

        const newDestinationLessons = [...destinationTheme.lessons];
        newDestinationLessons.splice(destination.index, 0, movedLesson);
        lessonsStore.setThemeLessons(
            destinationTheme.id,
            newDestinationLessons
        );
        syncLessonsOrder([newSourceLessons, newDestinationLessons]);
    };

    useEffect(() => {
        const filtered = filterThemes(themes, {
            age,
            exam,
            difficulty,
            selected: filterByStudent ? currentStudentLessons : null,
            searchString,
        });
        setFilteredThemes(filtered);
    }, [
        age,
        exam,
        difficulty,
        searchString,
        currentStudentLessons,
        filterByStudent,
        themes,
    ]);

    useEffect(() => {
        const filtered = filterThemes(libraryThemes, {
            age,
            exam,
            difficulty,
            selected: null,
            searchString,
        });
        setFilteredLessonsThemes(filtered);
    }, [
        age,
        exam,
        difficulty,
        searchString,
        currentStudentLessons,
        filterByStudent,
        libraryThemes,
    ]);

    useEffect(() => {
        getLessons();
    }, [isLibrary, isUser, lang]);

    useEffect(() => {
        if (isAddLesson && kanbanRef.current) {
            kanbanRef.current.scrollLeft = kanbanRef.current.scrollWidth;
        }
    }, [isAddLesson]);

    useEffect(() => {
        lessonsStore.setDifficulty('');
        lessonsStore.setAge('');
        lessonsStore.setSearchString('');
        lessonsStore.setFilterByStudent(false);
    }, [currentStudent]);

    useEffect(() => {
        if (![...grammarOptions, ...vocabularyOptions].length) return;
        syncLessons(lessons);
    }, [lessons, grammarOptions, vocabularyOptions, groupBy]);

    useEffect(() => {
        if (![...grammarOptions, ...vocabularyOptions].length) return;
        syncLibraryLessons(libraryLessons);
    }, [libraryLessons, grammarOptions, vocabularyOptions, groupBy]);

    useEffect(() => {
        lessonsStore.setGroupBy(
            difficulty ? LessonsGroupBy.Grammar : LessonsGroupBy.Difficulty
        );
    }, [difficulty]);

    useEffect(() => {
        if (isUser) {
            const { user } = authStore;
            setTimeout(() => {
                lessonsStore.setDifficulty(LanguageLevels[user.level]);
            });
        }
    }, [isUser]);

    const themesToRender =
        isUser || isLibrary ? fileteredLessonThemes : filteredThemes;

    return (
        <>
            {lessonsLoading || (!wasFetched && !error) ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <>
                    <ViewTitle
                        text={t(
                            isUser || isLibrary
                                ? 'navbar.lessons_library'
                                : 'navbar.lessons'
                        )}
                        style={{ marginBottom: 12 }}
                    />
                    <LessonSearchBar isLibrary={isLibrary} isUser={isUser} />

                    <DragDropContext onDragEnd={() => {}}>
                        <div className={cl.kanban} ref={kanbanRef}>
                            {themesToRender?.length !== 0 ? (
                                themesToRender?.map((theme, index) => (
                                    <Theme
                                        key={theme.id}
                                        themeObj={theme}
                                        isLast={
                                            index === themesToRender.length - 1
                                        }
                                    />
                                ))
                            ) : (
                                <EmptyKanban
                                    isLibrary={isLibrary}
                                    isUser={isUser}
                                />
                            )}
                        </div>
                    </DragDropContext>
                </>
            )}
            <OnboardingModal
                onboardingKey={onboardingStore.isOnboarding(
                    OnboardingKeys.LessonsInfo
                )}
                title={t('teacher_onboarding.lessons_info')}
                subtitle={t('teacher_onboarding.lessons_info_subtitle')}
                delay={500}
            />
        </>
    );
};

export default observer(LessonsKanban);
