import React, { useEffect, useState } from 'react';
import cl from './SummaryPreview.module.css';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';

const SummaryPreview = ({
    exerciseObj,
    results,
    showAnswers,
    setResults,
    setShowAnswers = () => {},
    isPdf = false,
    isPreview = false,
    isUser = false,
}) => {
    const { data } = exerciseObj;

    const [active, setActive] = useState();

    const options = data?.options ?? [];

    const getOptionClassName = (o) => {
        const classNames = [cl.answer];
        if (showAnswers) {
            classNames.push(o.correct ? cl.correct : '');
        }
        if (isPdf) {
            classNames.push(cl.pdf);
        }
        classNames.push(
            active === o.id ? (o.correct ? cl.correct : cl.wrong) : ''
        );
        return classNames.join(' ');
    };

    useEffect(() => {
        if (results && options) {
            if (typeof results.selectedIndex !== 'number') return;
            const value = options[results.selectedIndex];
            setActive(value ? value.id : '');
        }
    }, [results]);

    useEffect(() => {
        if (showAnswers) setActive();
    }, [showAnswers]);

    useEffect(() => {
        if (active && setResults) {
            const selectedIndex = options.findIndex((o) => o.id === active);
            setResults({ selectedIndex });
        }
    }, [setResults, active]);

    const getLabel = () => {
        if (results || !isUser) return 'student_answers';
        if (isPdf) return 'summary_pdf_info';
        if (isPreview) return 'summary_preview_info';
        return 'statements';
    };

    return (
        <>
            <QuestionsLabel
                label={getLabel()}
                variant={isPreview ? 'demo' : ''}
            />
            <div className={cl.previewCont}>
                {options.map((o) => (
                    <div
                        className={getOptionClassName(o)}
                        onClick={() => {
                            setActive(active === o.id ? null : o.id);
                            setShowAnswers(false);
                        }}
                        key={o.id}
                    >
                        {o.text}
                    </div>
                ))}
            </div>
        </>
    );
};

export default SummaryPreview;
