import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../../../../../App';
import { useFetching } from '../../../../../../hooks/useFetching';
import ProgressService from '../../../../../../api/ProgressService';
import SituationsService from '../../../../../../api/SituationsService';
import exercisesStore from '../../../../../../pages/TeacherContent/store/exercisesStore';
import wizardStore from '../../store/wizardStore';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import {
    Button,
    Checkbox,
    FileUploader,
    Input,
    Label,
    Select,
    ToggleButton,
} from '../../../../../../UI';
import { SelectSituationModal } from '../../../../../../pages/TeacherContent/views/SituationsView/components';
import { ManageWordPackModal, SelectContentButtons } from '..';
import { Chapters } from '../../../../data/constants';
import {
    GENDERS,
    MODALITIES,
} from '../../../../../../pages/SituationsAdministration/data/constants';
import { ReactComponent as IconCloud } from '../../../../../../assets/svg/lessons-upload.svg';
import DefaultFile from '../../../../../../assets/webp/situation-cover.webp';
import { WizardViews } from '../../LessonWizard';
import { FileTypes, LanguageLevels } from '../../../../../../data/common';
import cl from './DialogueWizard.module.css';

const DefaultModality = ['Neutral'];
const DefaultCategoryId = '6142a26a-1117-492f-88c4-03d27b4e7878';

const SelectDialogue = observer(({ onSelect }) => {
    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { difficulty } = currentLesson;

    const [isModal, setModal] = useState(false);
    const [isOwn, setIsOwn] = useState(false);
    const [allSituationsCount, setAllSituationsCount] = useState(null);
    const [favSituationsCount, setFavSituationsCount] = useState(null);

    const [getSituations, isLoading] = useFetching(async () => {
        const { data: categories } =
            await SituationsService.getAvailableCategories({
                language: lang.toUpperCase(),
                level: LanguageLevels[difficulty],
            });

        const { data } = await ProgressService.getSituations({
            lang,
        });

        const ids = data.items.map((s) => s.situationId);

        const allSituations = categories.map((c) => c.situations).flat();
        const favSituations = allSituations.filter((s) => ids.includes(s.id));

        setFavSituationsCount(favSituations?.length || 0);
        setAllSituationsCount(allSituations?.length || 0);
    });

    const handleSelectClick = () => {
        setIsOwn(false);
        setTimeout(() => {
            setModal(true);
        });
    };

    const handleOwnClick = () => {
        setIsOwn(true);
        setTimeout(() => {
            setModal(true);
        });
    };

    const handleDialogueSelect = ({ id, title }) => {
        if (!isOwn) {
            ProgressService.addSituation({
                situationId: id,
                language: lang,
            }).then(() => exercisesStore.refresh());
        }
        onSelect({ id, title });
    };

    useEffect(() => {
        getSituations();
    }, []);

    return (
        <>
            <SelectContentButtons
                variant={Chapters.Situation}
                onOwnClick={handleOwnClick}
                onLibraryClick={handleSelectClick}
                onCreateClick={wizardStore.nextStep}
                ownCount={favSituationsCount}
                libraryCount={allSituationsCount}
            />

            <SelectSituationModal
                visible={isModal}
                setVisible={setModal}
                onClick={handleDialogueSelect}
                // TODO: pass already added dialogues packs @saratovkin
                alreadyAdded={[]}
                isOnboarding={false}
                isOwn={isOwn}
                presetDifficulty={LanguageLevels[difficulty]}
            />
        </>
    );
});

export const GenerateDialogue = observer(({ onGenerate }) => {
    const { t } = useTranslation();
    const { lang } = languagesStore;
    const { currentLesson } = lessonsStore;
    const { wordPack } = wizardStore;

    const { situationCategoryOptions } = exercisesStore;

    const [difficulty, setDifficulty] = useState(
        currentLesson?.difficulty ?? ''
    );
    const [addFile, setAddFile] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [maleModalities, setMaleModalities] = useState(DefaultModality);
    const [femaleModalities, setFemaleModalities] = useState(DefaultModality);
    const [requestText, setRequestText] = useState('');
    const [file, setFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const isSendDisabled = () => {
        if (![...maleModalities, ...femaleModalities].length) return true;
        return false;
    };

    const formatSituationsByModalities = () => {
        const userId = authStore.user?.id;
        const targetVocabulary = wizardStore.getPlainSelectedWords();

        return [
            ...maleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: lang.toUpperCase(),
                gender: GENDERS[0],
                modality,
                level: LanguageLevels[difficulty],
                userId,
                vocabulary: targetVocabulary,
            })),
            ...femaleModalities.map((modality) => ({
                title: '',
                categoryId,
                requestText: requestText.toLowerCase(),
                language: lang.toUpperCase(),
                gender: GENDERS[1],
                modality,
                level: LanguageLevels[difficulty],
                userId,
                vocabulary: targetVocabulary,
            })),
        ];
    };

    const [addSituation, isSituationLoading, error, resetError] = useFetching(
        async () => {
            resetError();
            const situations = formatSituationsByModalities();

            const { data } = await SituationsService.addSituationsBatch({
                situations,
                file,
            });

            if (data && data.length) {
                const info = data[0];
                onGenerate({
                    ...info,
                    id: info.situationId,
                    title: info.title,
                });
            }
        }
    );

    const handleAddImage = () => {
        setAddFile(true);
    };

    const getModality = (modality, gender) => {
        const modalities =
            gender === GENDERS[0] ? maleModalities : femaleModalities;
        const flag = modalities.includes(modality);
        return flag;
    };

    const setModality = (modality, gender) => {
        const setModalities =
            gender === GENDERS[0] ? setMaleModalities : setFemaleModalities;

        setModalities([modality]);
    };

    const setDefaultFile = async () => {
        const fileResponse = await fetch(DefaultFile);
        const blob = await fileResponse.blob();
        setFile(blob);
    };

    const handleSituationGenerate = () => {
        setFormErrors({});

        setTimeout(() => {
            const errors = {
                requestText: !requestText,
                categoryId: !categoryId,
                difficulty: typeof difficulty !== 'number',
            };
            setFormErrors(errors);

            if (Object.values(errors).some((e) => e)) return;

            addSituation();
        });
    };

    useEffect(() => {
        setMaleModalities(DefaultModality);
        setFemaleModalities(DefaultModality);
        setRequestText('');
        setDefaultFile();
    }, []);

    useEffect(() => {
        resetError();
    }, [requestText]);

    useEffect(() => {
        setCategoryOptions(situationCategoryOptions);
        const defaultCategory = situationCategoryOptions.find(
            (o) => o.value === DefaultCategoryId
        );

        setCategoryId(
            defaultCategory
                ? defaultCategory.value
                : situationCategoryOptions[0]?.value
        );
    }, [situationCategoryOptions]);

    useEffect(() => {
        setFormErrors({});
    }, [categoryId, requestText, difficulty]);

    return (
        <>
            <div>
                <Label
                    text={t(
                        error
                            ? 'situations.conflict_error'
                            : 'situations.request_label'
                    )}
                    isError={error || formErrors['requestText']}
                />
                <Input
                    value={requestText}
                    variant={
                        formErrors['requestText']
                            ? 'erroredSmall'
                            : 'outlinedSmall'
                    }
                    onChange={setRequestText}
                    placeholder={t('situations.request_example')}
                />
            </div>

            <div className={cl.flexContainer}>
                <div>
                    <Label text={t('situations.modality_label')} />
                    <div className={cl.horizontalContainer}>
                        {MODALITIES.map((m) => (
                            <Checkbox
                                color={Chapters.Situation}
                                key={`female_${m}}`}
                                style={{ marginBottom: 3, gap: 3 }}
                                label={t(`situations.${m.toLowerCase()}`)}
                                value={getModality(m, GENDERS[1])}
                                onChange={() => setModality(m, GENDERS[1])}
                            />
                        ))}
                    </div>
                </div>
                {!currentLesson && (
                    <div>
                        <Label
                            isError={error || formErrors['difficulty']}
                            text={t('situations.level')}
                        />
                        <ToggleButton
                            className={cl.levelToggle}
                            options={LanguageLevels}
                            value={LanguageLevels[difficulty]}
                            onChange={(value) =>
                                setDifficulty(LanguageLevels.indexOf(value))
                            }
                            variant={'outlined'}
                        />
                    </div>
                )}
            </div>

            <div className={cl.gridContainer}>
                <div>
                    <Label
                        text={t('exercises.category')}
                        isError={formErrors['categoryId']}
                    />
                    <Select
                        style={{ width: '100%' }}
                        height={180}
                        variant={'grey'}
                        label={t('exercises.category')}
                        value={categoryId}
                        options={categoryOptions}
                        onChange={setCategoryId}
                        ignoreOverflow
                    />
                </div>

                {file && (
                    <div
                        className={`${cl.imageCont} ${file ? '' : cl.placeholder}`}
                        onClick={handleAddImage}
                    >
                        <img
                            src={URL.createObjectURL(file)}
                            alt={'situation preview'}
                        />
                        <div className={cl.changeCoverButton}>
                            <IconCloud className={cl.iconCloud} />
                            {t('courses.change_cover')}
                        </div>
                    </div>
                )}
            </div>
            {currentLesson ? (
                wordPack.items?.length ? (
                    <ManageWordPackModal
                        variant={Chapters.Situation}
                        type="dialogue"
                    />
                ) : (
                    <Label
                        text={t(
                            'lesson_wizard.include_word_pack_dialogue_info'
                        )}
                    />
                )
            ) : (
                <ManageWordPackModal
                    variant={Chapters.Situation}
                    type="dialogue"
                    globalMode
                />
            )}
            <Button
                style={{ marginTop: 'auto' }}
                variant={Chapters.Situation}
                text={t('exercises.add_new_words')}
                onClick={handleSituationGenerate}
                isLoading={isSituationLoading}
                isDisabled={isSendDisabled()}
            />
            <FileUploader
                type={FileTypes.Image}
                visible={addFile}
                setVisible={setAddFile}
                key="categoryImagePicker"
                onSave={setFile}
                isLessons
            />
        </>
    );
});

const DialogueWizard = ({ onAdd }) => {
    const [view, step] = wizardStore.getCurrentViewAndStep();

    const handleDialogueAdd = ({ id, title }) => {
        onAdd({ chapterType: Chapters.Situation, chapterId: id, title });
    };

    if (!view || view !== WizardViews.Dialogue) return null;

    switch (step) {
        case 'select':
            return <SelectDialogue onSelect={handleDialogueAdd} />;
        case 'generate':
            return <GenerateDialogue onGenerate={handleDialogueAdd} />;

        default:
            return null;
    }
};

export default observer(DialogueWizard);
