import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import SituationsService, { URL } from '../../../../api/SituationsService';
import { getAvailableOptions } from '../../../SituationsAdministration/helpers/getAvailableOptions';
import Statements from '../../../TeacherContent/views/SituationsView/components/Statements/Statements';
import { Button, Range, Loader, Modal, ToggleButton } from '../../../../UI';
import {
    GENDERS,
    MODALITIES,
} from '../../../SituationsAdministration/data/constants';
import { observer } from 'mobx-react-lite';
import userDialoguesStore from '../../../../features/UserPlatform/pages/UserDialogues/store/userDialogues';
import languagesStore from '../../../../store/interface';
import {
    ExerciseMode,
    ListenMode,
    SpeakMode,
} from '../../../../features/LearnSituation/components';
import { ReactComponent as IconHappy } from '../../../../assets/svg/lessons-happy.svg';
import { ReactComponent as IconNeutral } from '../../../../assets/svg/lessons-neutral.svg';
import { ReactComponent as IconSad } from '../../../../assets/svg/lessons-sad.svg';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import InfoModal from '../InfoModal/InfoModal';
import guestOnboarding from '../../store/guestOnboarding';
import { Chapters } from '../../../../features/LessonsKanban/data/constants';
import cl from './DemoSituationPreview.module.css';
import { useFetching } from '../../../../hooks/useFetching';
import { MediaPlayBackOptions } from '../../../../data/common';

const ICONS = [
    <IconHappy className={cl.happy} />,
    <IconNeutral className={cl.neutral} />,
    <IconSad className={cl.sad} />,
];

const Situation = ({ situation }) => {
    const { nativeLang } = languagesStore;

    const [situationData, setSituationData] = useState({});
    const [audio] = useState(new Audio());
    const [currentPlaying, setCurrentPlaying] = useState('');
    const [isPlay, setIsPlay] = useState(false);

    const handleStatementsPlay = () => setIsPlay(!isPlay);

    useEffect(() => {
        if (!situationData || !situationData.statements) return;

        const { statements } = situationData;
        let index = 0;

        const playNextAudio = () => {
            if (index >= statements.length) return;
            const statement = statements[index];
            if (!statement.phraseVoice) {
                index++;
                setTimeout(() => {
                    playNextAudio();
                }, 100);
                return;
            }
            audio.src = `${URL}${statement.phraseVoice}`;
            setCurrentPlaying(statement.id);

            audio.play();
        };

        const playNextCallback = () => {
            index++;
            setTimeout(() => {
                playNextAudio();
            }, 100);
        };

        audio.addEventListener('ended', playNextCallback);
        if (isPlay) {
            playNextAudio();
        } else {
            audio.removeEventListener('ended', playNextCallback);
            audio.src = '';
            setCurrentPlaying('');
            audio.pause();
        }

        return () => {
            audio.removeEventListener('ended', playNextCallback);
        };
    }, [isPlay]);

    useEffect(() => {
        audio.src = '';
        audio.pause();
        setCurrentPlaying('');
        setIsPlay(false);
    }, [situation, nativeLang]);

    useEffect(
        () => () => {
            audio.src = '';
            audio.pause();
            setIsPlay(false);
            setCurrentPlaying('');
        },
        []
    );

    useEffect(() => {
        const { statements, info } = situation;
        setSituationData({
            ...situation,
            statements,
            situationInfoId: info.id,
            processed: info.processed,
        });
    }, [situation]);

    return (
        <div className={cl.situation}>
            <div className={cl.titleContainer}>
                <p className={cl.situationTitle}>{situation.title}</p>
            </div>

            <Statements
                situation={situationData}
                currentPlaying={currentPlaying}
            />
        </div>
    );
};

const LearningModes = ['listen', 'exercise', 'speak', 'translation'];

const Settings = observer(({ gender, setGender, modality, setModality }) => {
    const { t } = useTranslation();
    const { playbackSpeed } = userDialoguesStore;
    const [visible, setVisible] = useState(false);

    const handlePlaybackChange = (e) => {
        userDialoguesStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <Button
                variant={'grey'}
                className={cl.settingsButton}
                onClick={() => setVisible(true)}
                icon={<IconSettings />}
            />
            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.horizontalContainer}>
                    <div className={cl.packSizeSelector}>
                        <p className={cl.toggleLabel}>
                            {t('situations.gender')}
                        </p>
                        <ToggleButton
                            value={gender}
                            onChange={setGender}
                            options={GENDERS}
                            isGrid
                            variant={'transparent'}
                        />
                    </div>

                    <div className={cl.packSizeSelector}>
                        <p className={cl.toggleLabel}>
                            {t('situations.modality')}
                        </p>
                        <ToggleButton
                            value={modality}
                            onChange={setModality}
                            options={MODALITIES}
                            isGrid
                            variant={'transparent'}
                            icons={ICONS}
                        />
                    </div>
                </div>

                <p className={cl.rangeLabel}>
                    {t('glossary_settings.playback_speed')}
                </p>
                <Range
                    value={playbackSpeed}
                    min={MediaPlayBackOptions[0]}
                    step={MediaPlayBackOptions[1] - MediaPlayBackOptions[0]}
                    max={MediaPlayBackOptions[MediaPlayBackOptions.length - 1]}
                    color={`var(--purple)`}
                    onChange={handlePlaybackChange}
                />
                <Button
                    variant={'dark'}
                    text={t('buttons.save')}
                    onClick={() => setVisible(false)}
                />
            </Modal>
        </>
    );
});

const LearnSituation = ({ situation }) => {
    const { t } = useTranslation();

    const { lang, nativeLang } = languagesStore;

    const [progress, setProgress] = useState();
    const [direction, setDirection] = useState(0);
    const [mode, setMode] = useState(LearningModes[0]);
    const [statements, setStatements] = useState([]);
    const [statementIndex, setStatementIndex] = useState(0);
    const [info, setInfo] = useState(situation.info ?? []);
    const [gender, setGender] = useState();
    const [modality, setModality] = useState();

    const variants = {
        enter: {
            opacity: 0.5,
            transform:
                direction > 0
                    ? 'translate(-50%, 0%)'
                    : 'translate(-50%, -100%)',
        },

        center: { opacity: 1, transform: 'translate(-50%, -50%)' },
        exit: {
            opacity: 0.5,
            transform:
                direction > 0
                    ? 'translate(-50%, -100%)'
                    : 'translate(-50%, 0%)',
        },
    };

    const startY = useRef(null);
    const isSwiping = useRef(false);

    const handleMouseDown = (e) => {
        startY.current = e.clientY;
        isSwiping.current = true;
    };

    const handleMouseUp = (e) => {
        if (!isSwiping.current) return;

        const delta = e.clientY - startY.current;
        const threshold = window.innerHeight * 0.15;

        if (delta > threshold) {
            switchStatement(-1);
        } else if (delta < -threshold) {
            switchStatement(1);
        }

        isSwiping.current = false;
        startY.current = null;
    };

    const handleScroll = (e) => {
        const delta = e.deltaY;
        const threshold = window.innerHeight * 0.15;

        if (delta > threshold) {
            switchStatement(1);
        } else if (delta < -threshold) {
            switchStatement(-1);
        }
    };

    const renderMode = () => {
        if (!statements[statementIndex]) return null;
        if (statements[statementIndex].actor === 'B')
            return <ListenMode statement={statements[statementIndex]} />;
        switch (mode) {
            case LearningModes[0]:
                return <ListenMode statement={statements[statementIndex]} />;
            case LearningModes[1]:
                return <ExerciseMode statement={statements[statementIndex]} />;
            case LearningModes[2]:
                return <SpeakMode statement={statements[statementIndex]} />;
            case LearningModes[3]:
                return (
                    <SpeakMode
                        statement={statements[statementIndex]}
                        translationMode
                    />
                );
            default:
                return null;
        }
    };

    const switchStatement = (newDirection) => {
        const nextIndex =
            (statementIndex + newDirection + statements.length) %
            statements.length;

        if (newDirection === 1 && nextIndex === 0) return;
        if (newDirection === -1 && nextIndex === statements.length - 1) return;

        setDirection(newDirection);
        setStatementIndex(nextIndex);
    };

    const renderActorIcon = () => {
        if (!statements[statementIndex]) return null;
        const { actor } = statements[statementIndex];
        return (
            <div className={actor === 'A' ? cl.iconMe : cl.iconThey}>
                <div className={`${cl.equalizer} ${true ? cl.active : ''}`}>
                    <span /> <span /> <span /> <span /> <span />
                </div>
                <span />
                <span>
                    {t(`learn_dialogue.${actor === 'A' ? 'you' : 'opponent'}`)}
                </span>
            </div>
        );
    };

    const getModeStyle = (m) => {
        if (!progress || !progress[m]) return {};

        const currentProgress = progress[m] || 0;
        const fillWidth = Math.floor(currentProgress * 100 + 3);

        return {
            background: `linear-gradient(to right, var(--orange-light) ${fillWidth}%, var(--orange-transparent) ${fillWidth}%)`,
            border: m === mode ? '1px solid  var(--orange)' : 'none',
        };
    };

    const handleModeChange = (newMode) => {
        setStatementIndex(0);
        setMode(newMode);
    };

    useEffect(() => {
        const { statements, info } = situation;

        setInfo(info);
        setStatements(statements);
        setMode(LearningModes[0]);
        setStatementIndex(0);
        setProgress();
    }, [situation]);

    useEffect(() => {
        if (!info.length) return;
        setGender(info[0].gender);
        setModality(info[0].modality);
    }, [info]);

    useEffect(() => {
        if (!statements || statements.length === 0 || statementIndex === null) {
            setProgress({});
        } else {
            setProgress((prevProgress) => ({
                ...prevProgress,
                [mode]:
                    Math.round((statementIndex / statements.length) * 100) /
                    100,
            }));
        }
    }, [mode, statementIndex, statements]);

    if (!nativeLang || lang === nativeLang)
        return (
            <p className={cl.emptyAlert}>{t('user_view.select_target_lang')}</p>
        );

    return (
        <div className={cl.learnContainer}>
            <div className={cl.containerInner}>
                <div className={cl.currentMode}>
                    <div className={cl.modeSelector}>
                        {LearningModes.map((m) => (
                            <Button
                                style={getModeStyle(m)}
                                variant={m === mode ? 'lightOrange' : 'grey'}
                                className={cl.mode}
                                key={m}
                                onClick={() => handleModeChange(m)}
                                text={t(`learn_dialogue.${m}`)}
                            />
                        ))}
                        <Settings
                            gender={gender}
                            setGender={setGender}
                            modality={modality}
                            setModality={setModality}
                        />
                    </div>
                </div>

                <div
                    className={cl.modeContainer}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onWheel={handleScroll}
                >
                    <motion.div
                        className={cl.card}
                        key={statementIndex}
                        custom={direction}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.5 }}
                    >
                        {renderActorIcon()}
                        {renderMode()}
                    </motion.div>

                    <div className={cl.statementControls}>
                        <Button
                            variant={'white'}
                            icon={<IconArrow className={cl.arrowIcon} />}
                            className={cl.arrowButton}
                            onClick={() => switchStatement(-1)}
                        />
                        <Button
                            variant={'white'}
                            icon={<IconArrow className={cl.arrowIcon} />}
                            className={cl.arrowButton}
                            onClick={() => switchStatement(1)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const DemoSituationPreview = ({ className, situation, isStudent }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;

    const onboardingKey = guestOnboarding.isOnboarding(Chapters.Situation);
    const [situationData, setSituationData] = useState();

    const [getSituation, isLoading] = useFetching(
        async ({ id, gender, modality }) => {
            const { data } = await SituationsService.getSituation({
                id,
                language: lang.toUpperCase(),
                gender,
                modality,
                targetLanguage: nativeLang.toUpperCase(),
            });

            const { statements, info } = data;
            setSituationData({
                ...situation,
                statements,
                situationInfoId: info.id,
                processed: info.processed,
            });
        }
    );

    const renderSituation = () => {
        if (isLoading) return <Loader style={{ height: '100%' }} />;

        if (onboardingKey || !situationData) return null;

        return isStudent ? (
            <LearnSituation situation={situationData} />
        ) : (
            <Situation situation={situationData} />
        );
    };

    useEffect(() => {
        const { id, info } = situation;
        const { gender, modality } = info;
        getSituation({ id, gender, modality });
    }, [situation, nativeLang]);

    return (
        <div
            className={`${cl.previewContainer}  ${isStudent ? cl.studentPreview : ''} ${className}`}
        >
            {renderSituation()}

            <InfoModal
                delay={200}
                onboardingKey={onboardingKey}
                variant={Chapters.Situation}
                title={t('teacher_tutorial.dialogues_info')}
                subtitle={t('teacher_tutorial.dialogues_info_subtitle')}
            />
        </div>
    );
};

export default observer(DemoSituationPreview);
