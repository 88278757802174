import React, { useEffect, useRef, useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import lessonsStore from '../../store/lessonsStore';
import { useFetching } from '../../../../hooks/useFetching';
import ExerciseService from '../../../../api/ExerciseService';
import { MicroButton } from '../../../../UI';
import { LanguageLevels } from '../../../../data/common';
import { ChapterPreview, DeleteConfirmation } from '..';
import { Ages } from '../../../../pages/TeacherContent/data/constants';
import exercisesStore from '../../../../pages/TeacherContent/store/exercisesStore';
import { ReactComponent as IconBin } from '../../../../assets/svg/lessons-bin.svg';
import { ReactComponent as IconArrow } from '../../../../assets/svg/lessons-arrow.svg';
import { Views } from '../../data/constants';
import { iconMap } from '../../../../pages/TeacherContent/views/ExercisesView/data/constants';
import cl from './Lesson.module.css';

const Lesson = ({ lessonObj, themeId, index, isOnboarding, onSelect }) => {
    const { t } = useTranslation();

    const {
        id,
        chapters,
        age,
        difficulty,
        exam,
        title,
        grammarTopic,
        vocabularyTopic,
    } = lessonObj;
    const pdfs = lessonObj.pdfs ?? [];
    const pictures = lessonObj.pictures ?? [];

    const { view } = exercisesStore;
    const { themes, collapseCards, isClonePreview } = lessonsStore;

    const isLibrary = view === Views.Library || isClonePreview;
    const ref = useRef();

    const [isDelete, setIsDelete] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(collapseCards);

    const [deleteLesson] = useFetching(async ({ id }) => {
        // TODO: check if we need to delete lessons permanently. @saratovkin
        await ExerciseService.deleteLesson({ id });
    });

    const handleClick = () => {
        lessonsStore.setCurrentLesson({ ...lessonObj, chapters: [] });
    };

    const handleDeleteButton = (e) => {
        e.stopPropagation();
        setIsDelete(true);
    };

    const handleDelete = () => {
        const currentTheme = themes.find((t) => t.id === themeId);
        if (!currentTheme) return;

        lessonsStore.deleteThemeLesson(id);
        deleteLesson({ id });
        setIsDelete(false);
    };

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const isEmpty = () => {
        if (chapters?.length) return false;
        if (pdfs.length) return false;
        if (pictures.length) return false;
        return true;
    };

    const renderStatus = () => {
        return null;
    };

    const renderChapters = () => {
        if (isEmpty()) return null;

        if (isCollapsed) {
            const countByType = chapters.reduce((acc, item) => {
                acc[item.type] = (acc[item.type] || 0) + 1;
                return acc;
            }, {});

            return (
                <div className={cl.collapsedChapters}>
                    {Object.entries(countByType).map(([type, count]) => (
                        <div key={type} className={cl.iconContainer}>
                            <div className={cl.icon}>{iconMap[type]}</div>
                            <p className={cl[type]}>{count}</p>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className={cl.chapters}>
                {chapters
                    .sort((a, b) => a.type.localeCompare(b.type))
                    .map((c) => (
                        <ChapterPreview
                            key={c.id}
                            chapter={c}
                            variant={'grey'}
                        />
                    ))}
            </div>
        );
    };

    const renderInfo = () => (
        <div className={cl.infoContainer}>
            {typeof age === 'number' && (
                <p className={cl.statItem}>{Ages[age]}</p>
            )}
            {typeof difficulty === 'number' && (
                <p className={cl.statItem}>{LanguageLevels[difficulty]}</p>
            )}
            {exam && <p className={cl.statItem}>{exam}</p>}
        </div>
    );

    useEffect(() => {
        setIsCollapsed(collapseCards);
    }, [collapseCards]);

    if (onSelect)
        return (
            <div className={cl.lessonCard}>
                <div
                    className={cl.cardInner}
                    ref={ref}
                    onClick={() => onSelect(lessonObj)}
                >
                    <div className={cl.vericalContainer}>
                        <p
                            className={cl.title}
                            title={`${vocabularyTopic}\n${grammarTopic}`}
                        >
                            {title}
                        </p>

                        {renderStatus()}
                    </div>
                    {renderInfo()}
                    {renderChapters()}
                </div>
            </div>
        );

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={true}>
            {(provided, snapshot) => (
                <div
                    className={`${cl.lessonCard} ${
                        isOnboarding ? cl.onboarding : ''
                    }`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div
                        className={cl.cardInner}
                        ref={ref}
                        onClick={handleClick}
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                        }}
                    >
                        <div className={cl.vericalContainer}>
                            <div className={cl.titleContainer}>
                                <p
                                    className={cl.title}
                                    title={`${vocabularyTopic}\n${grammarTopic}`}
                                >
                                    {title}
                                </p>
                                <div
                                    className={cl.controlButtons}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {!isLibrary && (
                                        <>
                                            <MicroButton
                                                icon={<IconBin />}
                                                variant={'red'}
                                                size={'small'}
                                                onClick={handleDeleteButton}
                                            />
                                        </>
                                    )}
                                    <MicroButton
                                        icon={
                                            <IconArrow
                                                style={{
                                                    filter: 'grayscale(1)',
                                                    transform: isCollapsed
                                                        ? 'rotate(180deg)'
                                                        : '',
                                                }}
                                            />
                                        }
                                        variant={'white'}
                                        size={'small'}
                                        onClick={handleCollapse}
                                    />
                                </div>
                            </div>

                            {renderStatus()}
                        </div>
                        {renderInfo()}
                        {renderChapters()}
                    </div>
                    <DeleteConfirmation
                        title={t(`exercises.delete_lesson_confirmation_title`)}
                        visible={isDelete}
                        setVisible={setIsDelete}
                        onDelete={handleDelete}
                    />
                </div>
            )}
        </Draggable>
    );
};

export default observer(Lesson);
