import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import shuffleArray from '../../../../utils/shuffleArray';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import { Button } from '../../../../UI';
import { ReactComponent as IconDrag } from '../../../../assets/svg/exercises-drag.svg';
import cl from './SentenceOrderPreview.module.css';

const SentenceCard = ({ id, index, text, showAnswers, isCorrect, isPdf }) => {
    if (isPdf)
        return <div className={`${cl.sentenceCont} ${cl.pdf}`}>{text}</div>;
    return (
        <Draggable draggableId={id} index={index} isDragDisabled={isPdf}>
            {(provided) => (
                <div
                    className={`${cl.sentenceCont} ${
                        showAnswers ? (isCorrect ? cl.correct : cl.wrong) : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <IconDrag />
                    {text}
                </div>
            )}
        </Draggable>
    );
};

const SentenceOrderPreview = ({
    exerciseObj,
    results,
    showAnswers,
    isPdf,
    isPreview,
    isUser,
    setResults,
}) => {
    const { t } = useTranslation();
    const { data } = exerciseObj;
    const { sentences } = data;

    const [previewSentences, setPreviewSentences] = useState([]);

    const displayAnswers = () => {
        setPreviewSentences(sentences);
    };

    useEffect(() => {
        if (!sentences || !sentences.length) return;
        let newSentences = shuffleArray(sentences);
        if (sentences.length > 2) {
            while (JSON.stringify(sentences) === JSON.stringify(newSentences)) {
                newSentences = shuffleArray(sentences);
            }
        }
        setPreviewSentences(newSentences);
    }, [sentences]);

    useEffect(() => {
        showAnswers && displayAnswers();
    }, [showAnswers]);

    useEffect(() => {
        if (results && results.userAnswers) {
            setPreviewSentences(
                results.userAnswers.map((s, i) => ({ id: i, text: s }))
            );
        }
    }, [results]);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newSentences = [...previewSentences];
        const [removedSentence] = newSentences.splice(source.index, 1);
        newSentences.splice(destination.index, 0, removedSentence);
        setPreviewSentences(newSentences);
    };

    const getLabel = () => {
        if (results || !isUser) return 'student_answers';
        if (isPdf) return 'sentences_order_pdf_info';
        if (isPreview) return 'sentences_order_preview_info';
        return 'sentences';
    };

    const handleResultsSave = () => {
        setResults({ userAnswers: previewSentences.map((s) => s.text) });
    };

    return (
        <div>
            <QuestionsLabel
                label={getLabel()}
                variant={isPreview ? 'demo' : ''}
            />
            <DragDropContext onDragEnd={handleDragEnd} isDragDisabled={isPdf}>
                <Droppable droppableId="sentence-list">
                    {(provided) => (
                        <div
                            className={`${cl.answersCont} ${results?.userAnswers || isPdf ? cl.blocked : ''}`}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {previewSentences.map((s, index) => (
                                <SentenceCard
                                    key={s.id}
                                    id={s.id}
                                    index={index}
                                    text={s?.text ?? ''}
                                    showAnswers={
                                        showAnswers || results?.userAnswers
                                    }
                                    isPdf={isPdf}
                                    isCorrect={
                                        sentences[index]?.text === s?.text
                                    }
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {isUser && (
                <Button text={t('buttons.check')} onClick={handleResultsSave} />
            )}
        </div>
    );
};

export default SentenceOrderPreview;
