import React from 'react';
import { observer } from 'mobx-react-lite';
import { Roles } from '../../data/common';
import {
    GuestExercisePreview,
    TeacherExercisePreview,
    UserExercisePreview,
} from './components';

const ExercisePreview = ({ type, ...props }) => {
    switch (type) {
        case Roles.Guest:
            return <GuestExercisePreview {...props} />;
        case Roles.Teacher:
            return <TeacherExercisePreview {...props} />;
        case Roles.User:
            return <UserExercisePreview {...props} />;
        default:
            return <></>;
    }
};

export default observer(ExercisePreview);
