import React, { act, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useFetching } from '../../../../hooks/useFetching';
import ProgressService from '../../../../api/ProgressService';
import languagesStore from '../../../../store/interface';
import userStore from '../../store/userStore';
import userWordsStore, {
    PackSizeOptions,
    WordCardModes,
} from '../../store/userWords';
import {
    Button,
    FilledRange,
    Input,
    Label,
    MicroButton,
    Modal,
    Switch,
    ToggleButton,
    Toolbar,
} from '../../../../UI';
import { AddWord, UserChapterPreview } from '../../components';
import LearnWords from '../../../LearnWords/LearnWords';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { ReactComponent as IconSettings } from '../../../../assets/svg/settings.svg';
import { ReactComponent as IconClose } from '../../../../assets/svg/icon-close.svg';
import {
    UserWordsCategory,
    WordStatuses,
} from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import { MediaPlayBackOptions } from '../../../../data/common';
import cl from './UserWords.module.css';

const Settings = observer(() => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const { cardModes, wordsPerPack, playbackSpeed } = userWordsStore;

    const handleSettingToggle = (index) => {
        userWordsStore.toggleCardMode(index);
    };

    const handlePlaybackChange = (e) => {
        userWordsStore.setPlaybackSpeed(e.target.value);
    };

    return (
        <>
            <div className={cl.microButtons}>
                <MicroButton
                    icon={<IconSettings />}
                    variant={'grey'}
                    size={'regular'}
                    onClick={() => setVisible(true)}
                />
            </div>

            <Modal
                className={cl.settingsModal}
                visible={visible}
                setVisible={() => setVisible(false)}
                withCloseButton
                style={{ zIndex: 11 }}
            >
                <p className={cl.settingsTitle}>
                    {t('glossary_settings.title')}
                </p>
                <div className={cl.settingsContainer}>
                    {Object.values(WordCardModes).map((mode, index) => (
                        <div className={cl.switchContainer} key={mode}>
                            <p className={cl.label}>
                                {t(`glossary_settings.${mode}`)}
                            </p>
                            <Switch
                                isOn={cardModes[index]}
                                handleToggle={() => handleSettingToggle(index)}
                                id={mode}
                                color={Chapters.Dictionary}
                                isDisabled={
                                    cardModes[index] &&
                                    cardModes.filter((m) => m).length <= 1
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className={cl.horizontalContainer}>
                    <div>
                        <Label text={t('glossary_settings.words_in_pack')} />
                        <div className={cl.packSizeSelector}>
                            <ToggleButton
                                value={wordsPerPack}
                                onChange={userWordsStore.setWordsPerPack}
                                options={PackSizeOptions}
                                isGrid
                                variant={'transparent'}
                            />
                        </div>
                    </div>
                    <div className={cl.rangeSelector}>
                        <Label text={t('glossary_settings.playback_speed')} />
                        <FilledRange
                            className={cl.rangeContainer}
                            value={playbackSpeed}
                            min={MediaPlayBackOptions[0]}
                            step={
                                MediaPlayBackOptions[1] -
                                MediaPlayBackOptions[0]
                            }
                            max={
                                MediaPlayBackOptions[
                                    MediaPlayBackOptions.length - 1
                                ]
                            }
                            color={'var(--words-green)'}
                            onChange={handlePlaybackChange}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
});

const UserWords = ({ categoryId, isHistory, isTodo }) => {
    const { t } = useTranslation();
    const { lang, nativeLang } = languagesStore;
    const { favWordPacks, favWords, historyWords, processedWords, wordsData } =
        userStore;
    const { currentWord, searchString, cardModes } = userWordsStore;

    const [wordsToLearn, setWordsToLearn] = useState([]);
    const [learnMode, setLearnMode] = useState(false);

    const [updateWord] = useFetching(async ({ id, status, lastIteration }) => {
        await ProgressService.updateWord({ id, status, lastIteration });
    });

    const getActiveWords = () => {
        const mapWordsToData = (words, matchFn) =>
            words
                .map((w) => wordsData.find(matchFn(w)))
                .filter(Boolean)
                .map((wObj) => ({ ...wObj, id: wObj.wordId }));

        if (isHistory) {
            return mapWordsToData(
                historyWords,
                (w) => (obj) => obj?.wordId === w?.wordId
            );
        }

        if (categoryId === UserWordsCategory) {
            return mapWordsToData(
                favWords,
                (w) => (obj) => obj?.wordId === w?.wordId
            );
        }

        const pack = favWordPacks.find((p) => p.id === categoryId);
        if (!pack) return [];

        return mapWordsToData(
            pack.words,
            (w) => (obj) =>
                obj?.data?.length
                    ? obj.data.flatMap((d) => d.forms).some((f) => f === w.word)
                    : obj.word === w.word
        );
    };

    const getCurrentPendingWords = () => {
        if (isHistory || isTodo) return [];
        return processedWords
            .filter((w) =>
                categoryId === UserWordsCategory
                    ? true
                    : w.categoryId === categoryId
            )
            .map((w) => ({ ...w, pending: true }));
    };

    const getCurrentPackWords = () => {
        const activeWords = getActiveWords();
        const pendingWords = getCurrentPendingWords();

        return [...activeWords, ...pendingWords]
            .filter((w) =>
                w.word.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((w) => (w.data ? w : { ...w, invalid: true }))
            .sort((a, b) => a.word.localeCompare(b.word));
    };

    const startLearnWords = () => {
        const words = getCurrentPackWords();
        setWordsToLearn(words);
        setTimeout(() => {
            setLearnMode(true);
        }, 100);
    };

    const getIsLearnDisabled = () => wordsData.some((w) => !w?.translations);

    const handleLearnClose = () => {
        setLearnMode(false);
        if (isTodo) userStore.setActiveChapter({});
    };

    const updateIteration = (word, iteration) => {
        updateWord({
            id: word.id,
            lastIteration: iteration ?? word.lastIteration + 1,
        });
        userStore.updateWordIteration(word.id, iteration);
        userWordsStore.changeWordLevel(word);
    };

    const handleWordAdd = (word) => {
        userStore.addPackWord({ id: categoryId, word });
        userStore.addWord({
            word,
            categoryId,
            status: WordStatuses.Queued,
            targetLang: nativeLang,
        });
    };

    useEffect(() => userWordsStore.setPendingWords([]), [nativeLang]);

    useEffect(() => setLearnMode(false), [categoryId, isHistory, searchString]);

    useEffect(() => {
        userWordsStore.setSearchString('');

        const currentWords = getCurrentPackWords();
        setWordsToLearn(currentWords);
        if (
            !currentWord ||
            !currentWords.some((w) => w.wordId === currentWord.wordId)
        ) {
            userWordsStore.setCurrentWord(currentWords[0]);
        }
    }, [categoryId, currentWord, isHistory]);

    useEffect(() => {
        if (learnMode) userWordsStore.setCurrentWord({});
    }, [learnMode]);

    useEffect(() => {
        const currentIteration = cardModes.findIndex((flag) => flag);
        for (const word of wordsToLearn) {
            updateIteration(word, currentIteration);
        }
    }, [cardModes]);

    if (!nativeLang || lang === nativeLang) {
        return (
            <p className={cl.emptyAlert}>{t('user_view.select_target_lang')}</p>
        );
    }

    if (learnMode) {
        return (
            <div className={cl.learnWordsContainer}>
                <LearnWords words={wordsToLearn} categoryId={categoryId} />
                <div className={cl.closeButton}>
                    <MicroButton
                        icon={<IconClose />}
                        variant={'grey'}
                        size={'regular'}
                        onClick={handleLearnClose}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={cl.wordsList}>
                {!learnMode && (
                    <div
                        className={
                            isHistory || isTodo
                                ? cl.chaptersContainer
                                : cl.userChaptersContainer
                        }
                    >
                        {!isHistory && !isTodo && (
                            <Toolbar variant={Chapters.Dictionary}>
                                <AddWord
                                    onAdd={handleWordAdd}
                                    words={favWords}
                                />
                                <Input
                                    placeholder={t('library.search')}
                                    value={searchString}
                                    onChange={userWordsStore.setSearchString}
                                    search
                                    showReset
                                />
                            </Toolbar>
                        )}
                        <ChaptersList
                            chapters={getCurrentPackWords()}
                            type={Chapters.Dictionary}
                            current={currentWord}
                            setCurrent={userWordsStore.setCurrentWord}
                            isTodo={isTodo}
                        />
                        {wordsData.length !== 0 && !isHistory && (
                            <Button
                                variant={
                                    learnMode ? 'grey' : Chapters.Dictionary
                                }
                                text={t(
                                    learnMode
                                        ? 'buttons.close'
                                        : 'glossary_settings.learn_words'
                                )}
                                className={cl.learnButton}
                                onClick={startLearnWords}
                                isDisabled={getIsLearnDisabled()}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className={cl.previewContainer}>
                <UserChapterPreview
                    chapter={{ ...currentWord, id: currentWord?.word }}
                    type={Chapters.Dictionary}
                />
                {!isHistory && <Settings />}
            </div>
        </>
    );
};

export default observer(UserWords);
