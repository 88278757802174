import { LanguageLevels } from '../../../data/common';

export const ExercisesPaginationCount = 100;

export const Ages = ['7-', '7-9', '10-12', '12-14', '15-17', '18+'];

export const MediaTypes = {
    Video: 'video_type',
    Audio: 'audio_type',
    Text: 'text_type',
    GeneratedText: 'generated_type',
};

export const GrammarExerciseTypes = {
    Substitution: 'substitution',
    Multichoice: 'multichoice',
    Cloze: 'cloze',
    WordsOrder: 'words_order',
    StatementsTransformation: 'statements_transformation',
};

export const LexicalExerciseTypes = {
    Substitution: GrammarExerciseTypes.Substitution,
    Multichoice: GrammarExerciseTypes.Multichoice,
};

export const MediaExerciseTypes = {
    AdvancedQuiz: 'advanced_quiz',
    FreeFormQuestions: 'free_form_questions',
    Presentation: 'presentation',
    Quiz: 'quiz',
    SentencesOrder: 'sentence_order',
    Summary: 'summary',
};

export const ExerciseMediaTypes = {
    Grammar: 'grammar',
    Lexical: 'lexical',
    Text: 'text',
    Video: 'video',
    Info: 'info',
    Audio: 'audio',
};

export const getExerciseTypesByMedia = (type) => {
    let exercises = [];

    switch (type) {
        case ExerciseMediaTypes.Grammar: {
            exercises = GrammarExerciseTypes;
            break;
        }
        case ExerciseMediaTypes.Video:
        case ExerciseMediaTypes.Audio:
        case ExerciseMediaTypes.Info:
        case ExerciseMediaTypes.Text: {
            exercises = MediaExerciseTypes;
            break;
        }
        case ExerciseMediaTypes.Lexical: {
            exercises = LexicalExerciseTypes;
            break;
        }
        default: {
            break;
        }
    }

    return exercises;
};

export const TextGenres = {
    Text: 'text',
    Story: 'fictional story',
    Article: 'descriptive article',
    Newspaper: 'newspaper article',
    Argumentative: 'argumentative article',
    Blog: 'blog post',
    Review: 'review',
    Letter: 'formal letter',
    InformalLetter: 'informal letter',
};

export const TextModes = {
    Generated: 'generated',
    Custom: 'custom',
};

export const TextRange = {
    Min: 5,
    Step: 1,
    Max: 50,
    Default: '10',
};

export const SentencesRange = {
    Min: 1,
    Step: 1,
    Max: 10,
    Default: '5',
};

export const QuestionsRange = {
    Min: 1,
    Step: 1,
    Max: 10,
    Default: '5',
};

export const WordsRange = {
    Min: 5,
    Step: 1,
    Max: 15,
    Default: '5',
};

export const SummaryQuestionsRange = {
    Min: 3,
    Step: 1,
    Max: 5,
    Default: '4',
};

export const DefaultExerciseOptions = {
    difficulty: '',
    type: '',
    topic: '',
    setting: '',
    sentencesAmount: SentencesRange.Default,
};

export const DefaultMediaExerciseOptions = {
    title: '',
    description: '',
    difficulty: '',
    type: '',
    topic: '',
    mediaType: MediaTypes.Video,
    sentencesAmount: SentencesRange.Default,
};

export const DefaultView = 'default';

export const MaximumMediaCharactersAmount = 3000;

export const AdvancedQuizStatuses = ['true', 'false', 'not_related'];

export const TrackPaginationLimit = 30;

export const FilterModes = {
    Teacher: 'teacher_tracks',
    All: 'all_tracks',
};

export const Exams = {
    en: ['IELTS', 'PTE', 'TOEFL'],
    ru: ['ТРКИ'],
    de: ['DSH', 'Goethe'],
    // TODO add exam options @saratovkin
    es: [],
    fr: [],
    it: [],
    pt: [],
};

export const getExamOptions = (lang) =>
    Exams[lang].map((o) => ({
        value: o,
        name: o,
    }));

export const AgeOptions = Object.values(Ages).map((o, i) => ({
    value: i,
    name: o,
}));

export const LevelOptions = Object.values(LanguageLevels).map((o, i) => ({
    value: i,
    name: o,
}));

export const LessonAccessStatuses = [
    {
        value: false,
        name: 'lesson_wizard.lesson_status_public',
    },
    {
        value: true,
        name: 'lesson_wizard.lesson_status_private',
    },
];

export const TextGenresByLevel = {
    [LanguageLevels[0]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
    ],
    [LanguageLevels[1]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
    ],
    [LanguageLevels[2]]: [
        TextGenres.Text,
        TextGenres.Story,
        TextGenres.Article,
        TextGenres.InformalLetter,
        TextGenres.Blog,
    ],
    [LanguageLevels[3]]: Object.values(TextGenres),
    [LanguageLevels[4]]: Object.values(TextGenres),
    [LanguageLevels[5]]: Object.values(TextGenres),
};
