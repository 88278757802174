import React, { useEffect, useRef, useState } from 'react';
import cl from './InteractiveInput.module.css';
import { CloseButton } from '../../UI';

const InteractiveInput = ({
    value,
    placeholder,
    onChange,
    onDelete,
    isFocus,
}) => {
    const inputRef = useRef(null);
    const [isBlur, setIsBlur] = useState(true);

    useEffect(() => {
        if (isFocus) {
            inputRef.current.focus();
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            inputRef.current.blur();
        }
    };

    const handleBlur = () => {
        if (!value.length && onDelete) {
            onDelete();
        }
        setIsBlur(true);
    };

    return (
        <div className={cl.inputCont}>
            <input
                ref={inputRef}
                placeholder={placeholder}
                value={value}
                type="text"
                className={`${cl.input} ${isBlur ? cl.blur : cl.focus}`}
                onChange={(e) => onChange(e.target.value, e)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsBlur(false)}
                onBlur={handleBlur}
            />
            {onDelete && (
                <CloseButton
                    className={`${cl.deleteIcon} ${isBlur ? '' : cl.focus}`}
                    size={10}
                    right={10}
                    top={'50%'}
                    onClick={onDelete}
                />
            )}
        </div>
    );
};
export default InteractiveInput;
