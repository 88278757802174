import React, { useEffect, useRef } from 'react';
import { ReactComponent as IconWarning } from '../../assets/svg/lessons-warning.svg';
import cl from './Tooltip.module.css';
import CloseButton from '../CloseButton/CloseButton';

const Tooltip = ({
    text,
    variant,
    tailPosition,
    style,
    onHide,
    className,
    showClose,
}) => {
    const ref = useRef();

    const getIcon = () => {
        switch (variant) {
            case 'warning':
                return <IconWarning className={cl.warningIcon} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                onHide && onHide();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div
            ref={ref}
            className={`${cl.tooltip} ${cl[variant]} ${cl[tailPosition]} ${className ? className : ''}`}
            style={style}
        >
            {getIcon()}
            <span>{text}</span>
            {showClose && onHide && (
                <CloseButton
                    onClick={onHide}
                    size={10}
                    top={14}
                    right={8}
                    className={cl.closeButton}
                />
            )}
        </div>
    );
};

export default Tooltip;
