import React from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AddButton } from '../../../';
import { Label } from '../../../../../../../../UI';
import SentenceCard from './SentenceCard/SentenceCard';
import cl from './SentenceOrderEditor.module.css';

const SentenceOrderEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();

    const { sentences = [] } = exerciseData;

    const handleSentenceAdd = () => {
        onChange({ sentences: [...sentences, { id: uuid(), text: '' }] });
    };

    const handleSentenceUpdate = (index, sentence) => {
        const newSentences = [...sentences];
        newSentences[index] = { ...newSentences[index], text: sentence };
        onChange({ sentences: newSentences });
    };

    const handleSentenceDelete = (index) => {
        const newSentences = [...sentences];
        if (newSentences.length === 1) {
            onChange({ sentences: [] });
            return;
        }
        newSentences.splice(index, 1);
        onChange({ sentences: newSentences });
    };

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newSentences = [...sentences];
        const [removedSentence] = newSentences.splice(source.index, 1);
        newSentences.splice(destination.index, 0, removedSentence);
        onChange({ sentences: newSentences });
    };

    return (
        <div>
            <Label text={t('exercises.edit_sentences_order_label')} />
            <AddButton
                text={t(
                    sentences.length
                        ? 'exercises.new_sentence'
                        : 'exercises.first_sentence'
                )}
                onClick={handleSentenceAdd}
            />
            <div className={cl.sentenceOrderInner}>
                {sentences.length ? (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable
                            droppableId="sentence-list"
                            style={{ position: 'relative' }}
                        >
                            {(provided) => (
                                <div
                                    className={cl.sentenceOrderContainer}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {sentences.map((s, index) => (
                                        <SentenceCard
                                            key={s.id}
                                            sentence={s}
                                            index={index}
                                            onChange={handleSentenceUpdate}
                                            onDelete={handleSentenceDelete}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default SentenceOrderEditor;
