import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import userStore from '../../store/userStore';
import userWordsStore from '../../store/userWords';
import { Input, Loader, PageWrapper, Toolbar, ViewTitle } from '../../../../UI';
import {
    AddWordPack,
    UserWordPacksSelector,
    UserWords,
} from '../../components';
import ChaptersList from '../../components/ChaptersList/ChaptersList';
import { Chapters } from '../../../LessonsKanban/data/constants';
import { ReactComponent as IconWords } from '../../../../assets/svg/lessons-dictionary.svg';
import { UserWordsCategory } from '../../../../pages/TeacherContent/views/DictionaryView/data/constants';
import cl from './UserWordPacks.module.css';

const UserWordPacks = ({ isHistory, isLoading }) => {
    const { t } = useTranslation();

    const { favWordPacks, favWords, historyWords } = userStore;
    const { currentPack, packSearchString } = userWordsStore;

    const allWordsPack = {
        id: UserWordsCategory,
        title: t('user_view.all_words'),
        words: favWords,
        wordsCount: favWords.length,
    };

    const historyPack = {
        id: UserWordsCategory,
        title: t('user_view.learned_words'),
        words: historyWords,
        wordsCount: historyWords.length,
    };

    const getWordPacks = () => {
        return isHistory
            ? [historyPack]
            : packSearchString
              ? [allWordsPack, ...favWordPacks].filter((pObj) =>
                    pObj.title
                        .toLowerCase()
                        .includes(packSearchString.toLowerCase())
                )
              : [allWordsPack, ...favWordPacks];
    };

    useEffect(() => {
        userWordsStore.setCurrentPack(isHistory ? historyPack : allWordsPack);
    }, [isHistory]);

    return (
        <PageWrapper additionalClass={cl.wordsWrapper}>
            <ViewTitle
                text={t(
                    isHistory
                        ? 'user_view.dictionaries_history'
                        : 'user_view.my_words'
                )}
                icon={<IconWords />}
            />
            {isLoading ? (
                <Loader style={{ margin: 'auto' }} />
            ) : (
                <div className={cl.wordsInnerContainer}>
                    <div className={cl.wordPacksList}>
                        <Toolbar variant={Chapters.Dictionary}>
                            <AddWordPack />
                            <Input
                                placeholder={t('library.search')}
                                value={packSearchString}
                                onChange={userWordsStore.setPackSearchString}
                                search
                                showReset
                            />
                        </Toolbar>
                        <div className={cl.chaptersContainer}>
                            <ChaptersList
                                chapters={getWordPacks()}
                                type={Chapters.Dictionary}
                                current={currentPack}
                                setCurrent={userWordsStore.setCurrentPack}
                                isWordPack
                            />
                        </div>
                    </div>
                    <UserWords
                        categoryId={currentPack.id}
                        isHistory={isHistory}
                        isUser
                    />
                </div>
            )}
            <UserWordPacksSelector />
        </PageWrapper>
    );
};

export default observer(UserWordPacks);
