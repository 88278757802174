import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { AddButton } from '../../../';
import {
    AdjustableTextarea,
    Checkbox,
    Label,
} from '../../../../../../../../UI';
import { Chapters } from '../../../../../../../../features/LessonsKanban/data/constants';
import cl from './SummaryEditor.module.css';

const SummaryEditor = ({ exerciseData, onChange }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    const handleOptionChange = (text, id) => {
        const newOptions = [...options].map((o) =>
            o.id === id ? { ...o, text } : o
        );
        onChange({ ...exerciseData, options: newOptions });
    };

    const handleAddOption = () => {
        onChange({
            ...exerciseData,
            options: [...options, { id: uuid(), text: '', correct: false }],
        });
    };

    const handleCorrectOptionChange = (id) => {
        onChange({
            ...exerciseData,
            options: options.map((o) => ({ ...o, correct: o.id === id })),
        });
    };

    const handleOptionDelete = (id) => {
        onChange({
            ...exerciseData,
            options: options.filter((o) => o.id !== id),
        });
    };

    useEffect(() => {
        setOptions(exerciseData?.options ?? []);
    }, [exerciseData]);

    return (
        <div>
            <Label text={t('exercises.edit_summary_label')} />
            <AddButton
                text={t('exercises.add_summary')}
                onClick={handleAddOption}
            />
            <div className={cl.editorCont}>
                {options.map((o) => (
                    <div className={cl.textContainer} key={o.id}>
                        <Checkbox
                            value={o.correct}
                            variant={Chapters.Exercise}
                            onChange={() => handleCorrectOptionChange(o.id)}
                        />
                        <AdjustableTextarea
                            className={`${cl.option} ${o.correct ? cl.correct : ''}`}
                            value={o.text}
                            onChange={(text) => handleOptionChange(text, o.id)}
                            onDelete={() => handleOptionDelete(o.id)}
                            isFocus={!o.text.length}
                            showDelete
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default observer(SummaryEditor);
