import React, { useMemo } from 'react';
import QuestionsLabel from '../QuestionsLabel/QuestionsLabel';
import cl from './UserSubstitutionPreview.module.css';

const processWords = (sentences) => {
    const words = sentences.map((s) => s.words).flat();

    let hiddenIndex = 0;
    words.forEach((wordObj) => {
        if (wordObj.hidden) {
            wordObj.hiddenIndex = hiddenIndex++;
        }
    });

    const gapWords = words.filter((w) => w.hidden);
    return { words, gapWords };
};

const substractWords = (gapWords, answers) => {
    let counts = new Map();

    for (let word of answers) {
        counts.set(word, (counts.get(word) || 0) + 1);
    }

    return gapWords
        .filter((item) => {
            if (counts.has(item.word) && counts.get(item.word) > 0) {
                counts.set(item.word, counts.get(item.word) - 1);
                return false;
            }
            return true;
        })
        .sort((a, b) => a.word.localeCompare(b.word));
};

const UserSubstitutionPreview = ({
    exerciseObj,
    results,
    setResults,
    isCompleted,
}) => {
    const { data } = exerciseObj;
    const { sentences } = data;

    const { words, gapWords } = useMemo(
        () => processWords(sentences),
        [sentences]
    );

    const userAnswers = results.userAnswers || Array(gapWords.length).fill([]);
    const plainUserAnswers = userAnswers.flatMap((w) => w);
    const activeGaps = substractWords(gapWords, plainUserAnswers);

    const renderWord = (w) => {
        if (!w.hidden) return w.word;

        const answer = userAnswers[w.hiddenIndex]?.[0]?.toLowerCase() || '';

        const classNames = [cl.gap];
        if (isCompleted) {
            classNames.push(w.word === answer ? cl.correct : cl.wrong);
        }

        if (answer.length) {
            classNames.push(cl.active);
        }

        return (
            <span
                key={w.hiddenIndex}
                className={classNames.join(' ')}
                onClick={() => handleRemoveAnswer(w.hiddenIndex)}
            >
                {answer}
            </span>
        );
    };

    const handleSaveAnswer = (answer) => {
        const pos = userAnswers.findIndex((a) => !a || a.length === 0);
        if (pos !== -1) {
            setResults({
                userAnswers: userAnswers.map((a, i) =>
                    i === pos ? [answer] : a
                ),
            });
        }
    };

    const handleRemoveAnswer = (hiddenIndex) => {
        setResults({
            userAnswers: userAnswers.map((a, i) =>
                i === hiddenIndex ? [] : a
            ),
        });
    };

    return (
        <div className={cl.substitutionContainer}>
            {!isCompleted && (
                <QuestionsLabel label={'substitution_user_info'} />
            )}
            {words.length !== 0 && (
                <div className={cl.exerciseText}>
                    {words.map((w) => (
                        <span className={cl.wordContainer} key={w.id}>
                            {renderWord(w)}
                            {w.endChar && <span>{w.endChar}</span>}
                        </span>
                    ))}
                </div>
            )}
            {!isCompleted && (
                <>
                    {activeGaps.length !== 0 && (
                        <QuestionsLabel label={'substitution_user_info'} />
                    )}
                    <div className={cl.answers}>
                        {activeGaps.map((gap, index) => (
                            <span
                                key={`${gap.word}-${index}`}
                                className={cl.answer}
                                onClick={() => handleSaveAnswer(gap.word)}
                            >
                                {gap.word.toLowerCase()}
                            </span>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default UserSubstitutionPreview;
