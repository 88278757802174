import { makeAutoObservable } from 'mobx';
import { TicksInSecond } from '../data/common';

class Player {
    currentTime = 0;
    duration = 0;

    text = '';
    sentences = [];
    currentSentenceObj = {};

    playbackSpeed = '1x';
    pauseAfterEach = false;
    blurText = false;
    blurTranslation = false;

    isPlaying = false;
    wasPlayed = false;
    wasPaused = false;
    isLoaded = false;
    wasForwarded = false;

    externalId = null;
    playerRef = null;
    highlightTranslation = null;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true, deep: true });
    }

    resetStates() {
        Object.assign(this, new Player());
    }

    setPlaybackSpeed(playbackSpeed) {
        this.playbackSpeed = playbackSpeed;
        const speed = parseFloat(playbackSpeed);
        if (this.playerRef?.setPlaybackRate) {
            this.playerRef.setPlaybackRate(speed);
        } else if (this.playerRef) {
            this.playerRef.playbackRate = speed;
        }
    }

    handlePlayPauseClick() {
        if (this.isPlaying) {
            this.playerRef?.pause();
        } else {
            this.playerRef?.play();
        }
    }

    handleVideoPlay(time) {
        if (isNaN(time)) return;
        if (this.playerRef === null) return;
        this.setCurrentTime(time * TicksInSecond);
    }

    handleChangeAudioTime(time, changeTrackProgress = false) {
        if (isNaN(time) || !this.playerRef) return;

        if (changeTrackProgress) {
            const trackTime = time / TicksInSecond;
            this.playerRef.currentTime = trackTime;
            this.playerRef.seek?.(trackTime);
            this.setCurrentTime(time);
        } else {
            this.setCurrentTime(time * TicksInSecond);
        }
    }

    editTranslation(index, text, lang) {
        this.sentences[index].translations[lang] = text;
    }

    setBlurTranslation(blurTranslation) {
        this.blurTranslation = blurTranslation;
    }

    setBlurText(blurText) {
        this.blurText = blurText;
    }

    setPauseAfterEach(pauseAfterEach) {
        this.pauseAfterEach = pauseAfterEach;
    }

    setHighlightTranslation(highlightTranslation) {
        this.highlightTranslation = highlightTranslation;
    }

    setSentences(sentences) {
        this.sentences = sentences.map((s) => ({ ...s, translations: {} }));
    }

    setCurrentSentenceObj(currentSentenceObj) {
        this.currentSentenceObj = currentSentenceObj;
    }

    computeTextHighlight() {
        let lastActiveElIndex = null;
        let lastSpelledElIndex = null;

        this.sentences.forEach((sentence, index) => {
            const prevSentence = this.sentences[index - 1];
            const minusOffset = prevSentence
                ? this.calculateMinusOffset(prevSentence, sentence)
                : 0;

            const active = this.isActiveOrSpelled(
                sentence.offset - minusOffset,
                sentence.duration + minusOffset
            );

            if (active === true) lastActiveElIndex = index;
            if (active === 'spelled') lastSpelledElIndex = index;
        });

        this.sentences.forEach((sentence, index) => {
            if (index < lastActiveElIndex) {
                sentence.active = 'spelled';
            } else if (index === lastActiveElIndex) {
                sentence.active = true;
            } else {
                sentence.active = false;
            }
        });

        if (lastActiveElIndex === null && lastSpelledElIndex !== null) {
            this.sentences[lastSpelledElIndex].active = true;
        }

        this.sentences.forEach((sentence) => {
            if (sentence.active !== true) {
                sentence.words.forEach((word) => (word.active = false));
                return;
            }

            sentence.words.forEach((word) => {
                const isActive = this.isActiveOrSpelled(
                    word.offset,
                    word.duration,
                    this.currentTime
                );

                word.active =
                    (isActive === 'spelled' || isActive === true) &&
                    this.currentTime <= sentence.offset + sentence.duration;
            });
        });
    }

    initTextDisplay(sentences) {
        this.setSentences(sentences);
        this.computeTextHighlight();
    }

    isActiveOrSpelled(offset, duration) {
        const time = this.currentTime;
        if (
            typeof offset !== 'number' ||
            typeof duration !== 'number' ||
            typeof time !== 'number'
        )
            return false;
        if (time >= offset && time <= offset + duration) {
            return true;
        } else if (time >= offset + duration) {
            return 'spelled';
        } else {
            return false;
        }
    }

    calculateMinusOffset(prev, current) {
        const lastWord = prev.words.slice(-1)[0];
        if (!lastWord) return 0;
        const timeBetween =
            current.offset - (lastWord.duration + lastWord.offset);
        return Math.min(timeBetween - TicksInSecond / 10, TicksInSecond / 3);
    }

    parseTrackData(data) {
        this.setDuration(data.duration * TicksInSecond);
        this.setExternalId(data.externalId);
        this.initTextDisplay(data.sentences);
    }

    setPlayerRef(player) {
        this.playerRef = player;
    }

    setExternalId(externalId) {
        this.externalId = externalId;
    }

    setCurrentTime(currentTime) {
        this.currentTime = currentTime;
        this.computeTextHighlight();
    }

    setDuration(duration) {
        this.duration = duration;
    }

    setIsPlaying(isPlaying) {
        this.isPlaying = isPlaying;
        if (isPlaying && !this.wasPlayed) {
            this.setWasPlayed(true);
        }
    }

    setIsLoaded(isLoaded) {
        this.isLoaded = isLoaded;
    }

    setWasForwarded(wasForwarded) {
        this.wasForwarded = wasForwarded;
    }

    setWasPaused(wasPaused) {
        this.wasPaused = wasPaused;
    }

    setWasPlayed(wasPlayed) {
        this.wasPlayed = wasPlayed;
    }

    destroy() {
        this.playerRef?.destroy();
        this.setIsPlaying(false);
        this.setCurrentTime(0);
        this.setDuration(0);
    }
}

const player = new Player();
export default player;
