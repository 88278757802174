import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import { AddButton } from '../../../';
import { AdjustableTextarea, Label } from '../../../../../../../../UI';
import { MediaExerciseTypes } from '../../../../../../data/constants';
import cl from './QuestionsEditor.module.css';

const QuestionsEditor = ({ exerciseData, onChange, type }) => {
    const { t } = useTranslation();

    const { questions = [] } = exerciseData;

    const handleAddSentence = () => {
        onChange({
            questions: [...questions, { id: uuid(), question: '' }],
        });
    };

    const handleUpdateSentence = (id, question) => {
        onChange({
            questions: questions.map((s) =>
                s.id === id ? { ...s, question } : s
            ),
        });
    };

    const deleteEducatorSentence = (id) => {
        const newQuestions = questions.filter((s) => s.id !== id);
        onChange({
            questions: newQuestions,
        });
    };

    return (
        <div>
            <Label
                text={t(
                    type === MediaExerciseTypes.FreeFormQuestions
                        ? 'exercises.edit_questions_label'
                        : 'exercises.edit_statements_label'
                )}
            />
            <AddButton
                text={t(
                    type === MediaExerciseTypes.FreeFormQuestions
                        ? questions.length
                            ? 'exercises.new_question'
                            : 'exercises.first_question'
                        : questions.length
                          ? 'exercises.new_statement'
                          : 'exercises.first_statement'
                )}
                onClick={handleAddSentence}
            />
            {questions.length ? (
                <div className={cl.questions}>
                    {questions.map((s, i) => (
                        <div className={cl.questionCont} key={s.id}>
                            <p className={cl.questionNumber}>{`${i + 1}.`}</p>
                            <AdjustableTextarea
                                className={cl.question}
                                placeholder={t('exercises.add_question')}
                                value={s.question}
                                onChange={(question) =>
                                    handleUpdateSentence(s.id, question)
                                }
                                onDelete={() => deleteEducatorSentence(s.id)}
                                isFocus={!s.question?.length}
                                showDelete
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default observer(QuestionsEditor);
