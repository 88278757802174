import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ProgressService from '../../../../../../api/ProgressService';
import { useFetching } from '../../../../../../hooks/useFetching';
import languagesStore from '../../../../../../store/interface';
import lessonsStore from '../../../../store/lessonsStore';
import wizardStore from '../../store/wizardStore';
import {
    Button,
    Checkbox,
    CloseButton,
    Input,
    Label,
    Modal,
    Select,
} from '../../../../../../UI';
import { SelectDictionaryModal } from '../../../../../../pages/TeacherContent/views/DictionaryView/components';
import { ExerciseMediaTypes } from '../../../../../../pages/TeacherContent/data/constants';
import cl from './ManageWordPackModal.module.css';

const ManageWordPackModal = ({ variant, type, isError }) => {
    const { t } = useTranslation();

    const { currentLesson } = lessonsStore;
    const { lang } = languagesStore;

    const {
        wordPack,
        wordPacksArray,
        exerciseParams,
        wordsString,
        exerciseType,
    } = wizardStore;

    const isLexical = exerciseType === ExerciseMediaTypes.Lexical;

    const [currentPack, setCurrentPack] = useState('');
    const [visible, setVisible] = useState(false);
    const [globalMode, setGlobalMode] = useState(!currentLesson);
    const [showInput, setShowInput] = useState(false);

    const [globalWordPacks, setGlobalWordPacks] = useState([]);
    const [selectPack, setSelectPack] = useState(false);

    const words = wordPack?.items || wordPack?.words || [];
    const selectedWordsCount = wizardStore.getPlainSelectedWords().length;

    const wordPackOptions = (globalMode ? globalWordPacks : wordPacksArray).map(
        (wObj) => ({
            value: wObj.id,
            name: wObj.title,
        })
    );

    const handlePackChange = (packId) => {
        if (!packId) {
            setCurrentPack('');
            globalMode && wizardStore.resetWordPack();
            wizardStore.setExcludedWords(new Set(words.map((w) => w.word)));
        } else {
            setCurrentPack(packId);
            const currentPacks = globalMode ? globalWordPacks : wordPacksArray;

            const selectedPack = currentPacks.find(
                (wObj) => wObj.id === packId
            );
            wizardStore.setWordPack(selectedPack ?? {});
        }
    };

    const handleGlobalPackSelect = ({ id }) => {
        handlePackChange(id);
    };

    const [fetchGlobalWordPacks, isLoading] = useFetching(async () => {
        const { data } = await ProgressService.getUserCategories({ lang });
        const { items } = data;

        const wordPacksData = await Promise.all(
            items.map(async (wordPack) => {
                const { id: categoryId, title } = wordPack;
                const { data } = await ProgressService.getCategoryWords({
                    categoryId,
                    lang,
                });
                return data && data.items
                    ? { title, id: categoryId, ...data }
                    : null;
            })
        );

        setGlobalWordPacks(wordPacksData);
    });

    useEffect(() => {
        setCurrentPack(wordPack.id);
    }, [wordPack]);

    useEffect(() => {
        if (globalMode) fetchGlobalWordPacks();
    }, [globalMode]);

    useEffect(() => {
        setGlobalMode(!currentLesson);
    }, [currentLesson]);

    if (!globalMode && !words?.length)
        return isLexical ? (
            <Label
                isError={true}
                text={t('lesson_wizard.lexical_exercise_no_word_pack')}
            />
        ) : (
            <Label text={t('lesson_wizard.include_word_pack_exercise_info')} />
        );

    if (globalMode && !currentPack) {
        return (
            <>
                <div>
                    <Label
                        text={t(`lesson_wizard.${type}_add_word_pack`)}
                        isError={isError}
                    />
                    {showInput || wordsString ? (
                        <>
                            <Input
                                placeholder={t('lesson_wizard.type_words_here')}
                                value={wordsString}
                                variant={'outlinedSmall'}
                                onChange={wizardStore.setWordsString}
                                showReset
                                onReset={() => setShowInput(false)}
                            />
                        </>
                    ) : (
                        <div className={cl.horizontalContainer}>
                            <Button
                                text={t(
                                    'lesson_wizard.exercise_wizard_select_pack'
                                )}
                                variant={variant}
                                className={cl.modalButton}
                                onClick={() => setSelectPack(true)}
                            />
                            <Button
                                text={t('lesson_wizard.type_word_pack')}
                                variant={variant}
                                className={cl.modalButton}
                                onClick={() => setShowInput(true)}
                            />
                        </div>
                    )}
                </div>
                <SelectDictionaryModal
                    visible={selectPack}
                    setVisible={setSelectPack}
                    onClick={handleGlobalPackSelect}
                    isOnboarding={false}
                    isOwn={true}
                    presetDifficulty={exerciseParams.difficulty}
                />
            </>
        );
    }

    return (
        <div>
            <Label
                text={t(`lesson_wizard.${type}_add_word_pack`)}
                isError={isError}
            />
            <div className={cl.horizontalContainer}>
                <Select
                    variant={isError ? 'errorSmall' : 'grey'}
                    className={cl.select}
                    value={currentPack}
                    options={wordPackOptions}
                    onChange={handlePackChange}
                    hideReset={!globalMode && type === 'lexical'}
                    ignoreOverflow
                    height={wordPackOptions.length > 3 ? 200 : ''}
                />

                <Button
                    text={`${t('lesson_wizard.manage_word_pack')} (${selectedWordsCount})`}
                    onClick={() => setVisible(true)}
                    variant={variant}
                    isDisabled={!currentPack}
                    isLoading={isLoading}
                />
                <Modal
                    visible={visible}
                    setVisible={setVisible}
                    className={cl.manageWordsModal}
                    style={{ zIndex: 11 }}
                >
                    <p className={cl.title}>
                        {t('lesson_wizard.manage_word_pack')}
                    </p>
                    <p className={cl.subtitle}>
                        {t('lesson_wizard.select_unselect_words')}
                    </p>
                    <div className={cl.wordsList}>
                        {words.map((w) => (
                            <div className={cl.wordCard} key={w.word}>
                                <Checkbox
                                    label={w.word}
                                    variant="checkmark"
                                    value={wizardStore.isWordDisabled(w.word)}
                                    color={variant}
                                    onChange={() =>
                                        wizardStore.toggleWordInclusion(w.word)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <CloseButton onClick={() => setVisible(false)} />
                </Modal>
            </div>
        </div>
    );
};

export default observer(ManageWordPackModal);
